import React, { useState, useEffect, useMemo, Suspense ,lazy } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists, desencry } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";
/* import ProductivityByResources from "../components/ProductivityByResources"; */

import ListOptionsAnchors from "../components/ListOptionsAnchors";
import NoAccess from "./NoAccess";
import { useTranslation } from "react-i18next";
import SpinnerBoot from "../components/SpinnerBoot";
import CurvaS from "../components/CurvaS";
import ProductivityByMonthByResources from "../components/ProductivityByMonthByResources";

/* const ProductivityByMonthByResources = lazy(()=> import('../components/ProductivityByMonthByResources')) */
 
const ProductivityByResources = lazy(()=> import('../components/ProductivityByResources'))
const Productivity = () => {

  const [t,i18n] = useTranslation("productivity")

  const access = window.localStorage.getItem("accessToProductivity")
  const desData = desencry(access,'accessCloudProjectDevProductivity')

  const [mainData, setMainData] = useState([]);
  const [mainDataMonth, setMainDataMonth] = useState([]);
/*   const [resources, setResources] = useState([]) */

  const getAllData = () => {
    Promise.all([getData("productivity-panel", [])]).then((values) => {
      setMainData(values[0]);
    });
  };

  const getMonthData = () => {
    Promise.all([getData("productivity-panel-month", [])])
      .then((values) => {
      setMainDataMonth(values[0]);
    });
  };

  const resources = useMemo(() => {
    let uids = _.uniq(mainData.map((item) => item.IdUsuario));
    let result = uids.map((item) => {
      return {
        id: item,
        user: mainData.filter((fl) => fl.IdUsuario === item)[0].NombreUsuario,
      };
    });
    return result;
  }, [mainData]);

  const resourcesMonth = useMemo(() => {
    let uids = _.uniq(mainDataMonth.map((item) => item.IdUsuario));
    let result = uids.map((item) => {
      return {
        id: item,
        user: mainDataMonth.filter((fl) => fl.IdUsuario === item)[0].NombreUsuario,
      };
    });
    return result;
  }, [mainDataMonth]);

  const projects = useMemo(() => {
    let uids = _.uniq(mainData.map((item) => item.IdProyecto));
    let result = uids.map((item) => {
      return {
        id: item,
        project: mainData.filter((fl) => fl.IdProyecto === item)[0].Proyecto,
      };
    });
    return result;
  }, [mainData]);

  useEffect(() => {
    getAllData();
  }, []);

  const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' })
  return (
    <>
    { (desData==="true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("productivity_title"),
            icon: "star",
          }}
          //   button={{
          //     text: "Volver",
          //     icon: "arrow-left",
          //     url: "/",
          //   }}
        />
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="second"
            transition={false}
          >
            <Row className="bg-light">
              <Col>
                <StyledTabs as={Nav}>
                 {/*  <Nav.Item>
                    <Nav.Link eventKey="first">{t("productivity_button_projects")}</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="second">{t("productivity_button_resources")}</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="third">Plantillas</Nav.Link>
                  </Nav.Item> */}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  {/* <Tab.Pane
                    eventKey="first"
                    className="animate__animated animate__fadeIn"
                  >
                    <p className="mb-2 mb-md-n4">
                      Los siguientes, son{" "}
                      <b className="text-primary">proyectos en curso</b> y bajo
                      tu supervisión.
                    </p>
                  </Tab.Pane> */}
                  <Tab.Pane
                    eventKey="second"
                    className="animate__animated animate__fadeIn"
                  >
                    <p className="mb-2">
                      {t("productivity_resources_title_1")}{" "}
                      <b className="text-primary">{t("productivity_resources_title_2")}</b>{" "}
                      {t("productivity_resources_title_3")}
                    </p>
                    <hr />
                    <Suspense fallback={<SpinnerBoot/>}>
                    <ProductivityByResources {...{ resources, mainData }} />
                    </Suspense>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    </React.Fragment>
      :<>
         <NoAccess />
      </>
      }
     </>
  );
};

export default Productivity;
