import React from "react";
import { useSelector } from "react-redux";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useEffect } from "react";
import { getData } from "../../helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ActivitiesByTags = (props) => {
  const scrum = useSelector((state) => state.scrum);
  const [t,i18n] =useTranslation("planning")

  const [getAllActivitiesByTags , setGetAllActivitiesByTags] = useState([])
 
  const getActivitiesByTags = (uid) => {
    Promise.all([
      getData(`product-backlog-tags/${uid}`,[])])
      .then((values) => {
        console.log(values[0])
        setGetAllActivitiesByTags(values[0])
    });
  };
  const columns = [
    {
      caption: t("planningboard_labels_tablecolumn_label"),
      selector: "Etiqueta",
      customRender: (row) => {
        return (
          <div className="d-flex justify-content-start tag-body-modal ">
                <div style={{ background: row.data.Color || "#ffff" }} className="tag-text-modal d-flex align-items-center "></div>
                <div className="tag-triangel-modal"  style={{ borderLeftColor: row.data.Color || "#ffff" }} ></div>
          </div>
        );
      },
      width: 70,
    },
    {
      caption: "Sprint",
      selector: "SprintNombre",
      width: 110,
    },
    {
      caption: t("planningboard_labels_tablecolumn_activity"),
      selector: "Actividad",
      customRender: (row) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (row.data.IdActividad)
                document.getElementById(`card-${row.data.IdActividad}`).click();
            }}
          >
            {row.data.Destacada && (
              <small>
                <i class="fa fa-star text-warning mr-1"></i>
              </small>
            )}
            {row.data.Actividad}
          </a>
        );
      },
      minWidth: 210,
    },
    {
      caption: t("planningboard_labels_tableboard_tablecolumn_start"),
      selector: "FechaInicio",
      width: 110,
    },
    {
      caption: t("planningboard_labels_tableboard_tablecolumn_end"),
      selector: "FechaFin",
      width: 110,
    },
    {
      caption: t("planningboard_labels_tablecolumn_advanced"),
      selector: "Estado",
      width: 80,
      customRender: (row) => {
        return (
          <>
          {(row.data.pctcompletado === 100) && <>
          <span  className="mr-2">
            <i className="fa fa-circle text-success"></i> {row.data.Estado}
          </span>
          </>}
          {(row.data.pctcompletado === 0) && <>
          <span className="mr-2">
          <i className="fa fa-circle text-light"></i>{row.data.Estado}
          </span>
          </>}
          {(row.data.pctcompletado > 0 && row.data.pctcompletado < 100) && <>
          <span className="mr-2">
          <i className="fa fa-circle text-info"></i>{row.data.Estado}
          </span>
          </>}
        </>
        )
      },
    },
    {
      caption: t("planningboard_hu_tablecolumn_delays"),
      selector: "DiasAtraso",
      width: 60,
      customRender : (row) =>{
        return (<div className="text-center"><span>{row.data.DiasAtraso}</span></div>)
      }
    },
    {
      caption: t("planningboard_hu_tablecolumn_ind_delays"),
      selector: "IndicadorAtraso",
      width: 80,
      customRender: (row) => {
        const valueDelaysIndicator= () =>{
          switch (row.data.IndicadorAtraso.toLowerCase()) {
            case "por iniciar":
              return "text-info fw-bold";

            case "al día":
              return "text-success fw-bold";

            case "leve":
                return "text-warning fw-bold";

            case "moderado":
              return "text-warning fw-bold" ;

            case "crítico":
              return "text-danger fw-bold" ;

            case "extremo":
              return  "text-dark fw-bold";
            default:
              return ""
          }
        }

        return (
          <>
          <div className= {`d-flex justify-content-center`}>
          <span className= {`${valueDelaysIndicator()}`} >{row.data.IndicadorAtraso}</span>
          </div>
          </>
        ) 
      },
    },
    {
      caption: t("planningboard_labels_tableboard_tablecolumn_last"),
      selector: "ultimamodificacion",
      width: 110,
    },
  ];

  const tagsHeader = (data) => {
    let user_lg = window.localStorage.getItem("idioma")
    if(user_lg == "es"){
      return data?.map((activity) => {
        return {
          ...activity,
          Etiqueta: `${activity.NombreEtiqueta} (Actividades: ${
            activity.CantActividadesEtiqueta || "-"
          } | Inicio: ${activity.FechaInicioEtiqueta || "-"} | Fin: ${
            activity.FechaFinEtiqueta || "-"
          } | % Avance: ${activity.pctcompletadoEtiqueta } )`,
        }
      });
    }else {
      return data?.map((activity) => {
        return {
          ...activity,
          Tag: `${activity.NombreEtiqueta} (Activities: ${
            activity.CantActividadesEtiqueta || "-"
          } | Start: ${activity.FechaInicioEtiqueta || "-"} | End: ${
            activity.FechaFinEtiqueta || "-"
          } | % Advance: ${activity.pctcompletadoEtiqueta } )`,
        }
      });
    }
    
  };

  useEffect(()=>{
    getActivitiesByTags(props.uid)
  },[props.updateTags])

  
  return (
    <DataGrid
      dataSource={tagsHeader(getAllActivitiesByTags)}
      allowColumnReordering={true}
      showBorders={true}
      allowColumnResizing ={true}
    >
      <GroupPanel visible={true} />
      <SearchPanel visible={true} placeholder={t("planningboard_labels_searchbox")} />
      <Grouping autoExpandAll={false} />
      <Column dataField= {t("planningboard_labels_tablecolumn_label")} caption={t("planningboard_labels_tablecolumn_label")}  groupIndex={0} >
      </Column>
      {columns.map((itmCol, index) => {
        return (
          <Column
            key={index}
            dataField={itmCol.selector}
            caption={itmCol.caption}
            width={itmCol.width}
            minWidth={itmCol.minWidth}
            cellRender={(row) => {
              return (
                <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
              );
            }}
          />
        );
      })}
      <Paging defaultPageSize={50} />
    </DataGrid>
  );
};

export default ActivitiesByTags;
