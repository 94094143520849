import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { inputChange, saveData } from "../helpers";
import { fetchAllTags } from "../redux";
import { useTranslation } from "react-i18next";

const TagsEdit = (props) => {
  const [t,i18n] =useTranslation("tags")
  const { showModal, handleClose, tagsEdit, setTagsEdit,idProject } = props;
  const dispatch = useDispatch();
  const inputTagName = useRef();
  useEffect(() => {
    if (inputTagName.current) {
      inputTagName.current.focus();
    }
  }, [showModal]);
  
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      contentClassName="border-0 shadow-lg"
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          const success = () => {
            handleClose();
            dispatch(fetchAllTags(idProject));
          };
          saveData(`tags/${idProject}`, tagsEdit, success);
        }}
      >
        <Modal.Header>
          <Modal.Title className="h5">{t("tags_modal_create_title")}</Modal.Title>
          <Button onClick={handleClose} variant="light">X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={10} className="mb-3">
              <Form.Label>
                {t("tags_modal_create_text1")}<small className="text-danger">*</small>
              </Form.Label>
              <Form.Control
                name="tagName"
                placeholder={t("tags_modal_create_textbox1")}
                value={tagsEdit.tagName || ""}
                onChange={(e) => {
                  inputChange(e, setTagsEdit);
                }}
                required
                ref={inputTagName}
              />
            </Col>
            <Col md={2} className="mb-3">
              <Form.Label>Color</Form.Label>
              <Form.Control
                name="tagColor"
                type="color"
                value={tagsEdit.tagColor || ""}
                onChange={(e) => {
                  inputChange(e, setTagsEdit);
                }}
                list="presets"
              />
              <datalist id="presets">
                <option value="#0079bf">#0079bf</option>
                <option value="#d29034">#d29034</option>
                <option value="#519839">#519839</option>
                <option value="#b04632">#b04632</option>
                <option value="#89609e">#89609e</option>
                <option value="#cd5a91">#cd5a91</option>
                <option value="#4bbf6b">#4bbf6b</option>
              </datalist>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            <i className="fa fa-times mr-2"></i>{t("tags_modal_create_button_cancel")}
          </Button>
          <Button
            variant="success"
            type="submit"
          >
            <i className="fa fa-save mr-2"></i>{t("tags_modal_create_button_save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TagsEdit;
