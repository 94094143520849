import React, { useRef, useState ,useCallback } from 'react'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Label,
  Legend,
  Title,
  Series,
  Size,
  Tooltip,
} from 'devextreme-react/chart';

import PivotGrid, {
  FieldChooser,
  Export,
  StateStoring,
} from 'devextreme-react/pivot-grid';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import SuperTable from './SuperTable';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportPivotGrid} from 'devextreme/excel_exporter'


const ProjectsDashboard = (props) => {
    
  const [t,i18n] = useTranslation("projects")
  const [showProjectResources,setShowProjectResources] = useState(false)
  const [dataForLink, setDataForLink] = useState([])
  const handleCloseProjectResource = () => {
    setShowProjectResources(false)
  };

  /*  console.log(props.data) */
  const currencyFormatter = new Intl.NumberFormat(
    'es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
    },
    );

    const testing  = () =>{ 
      new Intl.NumberFormat(
        'es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0,
        },
      );
    }

    const data = props.data?.map((pr)=>{
     /*  let max = 1000000
      let min = 20000000 */
      /* console.log(pr) */
      return {
        
        nombre : pr.nombre,
        metodologia : pr.metodologia,
        estado :pr.estado,
        /* estado : (pr.estado === "Finalizado" )? t("projects_button_complete") : t("projects_button_inprogress"), */
        /* fechaFin : pr.fechaFin,
        fechaInicio : pr.fechaInicio, */
        gerente : pr.gerente,
        pais : pr.pais,
        id : pr.id,
       /*  porcentaje : pr.porcentaje, */
        tipoProyecto : pr.tipoProyecto,
        costPlan : pr.CostoPlaneado,
        costReal : pr.CostoReal

       /*  costPlan : Math.random() * (max - min) + min,
        costReal : Math.random() * (max - min) + min */
      }
     })
    /*  console.log(data) */
    const dataSource1 = new PivotGridDataSource({
      fields: [{
        caption: 'Region',
        width: 120,
        dataField: 'region',
        area: 'row',
        sortBySummaryField: 'Total',
      }, {
        caption: 'City',
        dataField: 'city',
        width: 150,
        area: 'row',
      }, {
        dataField: 'date',
        dataType: 'date',
        area: 'column',
      }, /* {
        groupName: 'date',
        groupInterval: 'month',
        visible: false,
      }, */ {
        caption: 'Total',
        dataField: 'amount',
        dataType: 'number',
        summaryType: 'sum',
        format: 'currency',
        area: 'data',
      }],
      /* store: sales, */
    });

    const dataSource2 = new PivotGridDataSource({
      fields : [
        {
          caption:t("projects_tablecolumn_country"),
          width:"auto",
          dataField:"pais",
          area:'row'  
        },
     /*    {
          caption:"ID",
          width:120,
          dataField:"id",
          area: 'row',
          visible: false
        }, */
        {
          caption:t("projects_tablecolumn_type"),
          dataField:"tipoProyecto",
          width:120,
          area: 'row',
          visible: true,
        },{
          caption:t("projects_tablecolumn_name"),
          dataField: "nombre",
          customizeText: function (cellInfo) {
            console.log(cellInfo)
            let projectName= cellInfo.value.split(',')[0]
            return projectName
          },
          width:150,
          selector(data) {
            console.log(data)
            return `${data.nombre},${data.id},${data.metodologia}`;
          },
          area: 'row',
        },
        /* {
          caption:"Link",
         
          customizeText: function (cellInfo) {
            console.log(cellInfo)
            return "ver Proyecto"
          },
          selector(data) {
            console.log(data)
            return `${data.id},${data.metodologia}`;
          },
          width:100,
          area: 'row',
        }, */
       /*  {
          caption:t("projects_tablecolumn_methodology"),
          dataField:"metodologia",
          width:"auto",
          area: 'column',
          visible: false,
        }, */
        {
          caption:t("projects_tablecolumn_status"),
          dataField:"estado",
          width:"auto",
          area: 'column',
          visible: true,
        },
        {
          caption:t("projects_tablecolumn_manager"),
          dataField:"gerente",
          width:"auto",
          area: 'column',
          visible: true,
        },
        /* {
          caption:"Fecha Inicio",
          dataField:"fechaInicio",
          dataType:"date",
          with:150,
          area: 'column'
        },
        {
            caption:"Fecha Fin",
            dataField:"fechaFin",
            dataType:"date",
            with:150,
            area: 'column'
        }, */
        /* {
            caption:"Gerente",
            dataField:"gerente",
            width:150,
            area: 'column'
        }, */
      /*   {
            caption:"Tipo",
            dataField:"tipoProyecto",
            width:150,
            area: 'row',
            visible: false,
        }, */
        {
          caption:t("projects_tablecolumn_actualcost"),
          dataField:"costReal",
      /*     dataType:"number",
          format:"currency", */
          width:120,
          area: 'row',
          visible: false,
        },
        {
          caption:t("projects_tablecolumn_plancost"),
          dataField:"costPlan",
          /* dataType:"number",
          format:"currency", */
          width:120,
          area: 'row',
          visible: false,
        },
        {
          caption: t("projects_tablecolumn_plancosttotal"),
          dataField: 'costPlan',
          dataType: 'number',
          summaryType: 'sum',
          format: { style: "currency", currency: "COP", useGrouping: true },
          area: 'data',
        },
        {
          caption: t("projects_tablecolumn_actualcosttotal"),
          dataField: 'costReal',
          dataType: 'number',
          summaryType: 'sum',
          format:  { style: "currency", currency: "COP", useGrouping: true } ,
          area: 'data',
        }
      ],
      store: data
    })
    

      
 
   
      
    function customizeTooltip(args) {
        const valueText = currencyFormatter.format(args.originalValue);
        return {
          html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`,
        };
    }

    let _chart= useRef(null)
    let _pivotGrid = useRef(null)

    useEffect(()=>{
      
        _pivotGrid.current.instance().bindChart(_chart.current.instance(), {
            dataFieldsDisplayMode: 'singleAxis',
              alternateDataFields: true,
        }
        
        )
         /*  this.pivotGrid.bindChart(this.chart, {
              dataFieldsDisplayMode: 'splitPanes',
              alternateDataFields: false,
            }); */
            /* setTimeout(() => {
              dataSource.expandHeaderItem('row', ['North America']);
              dataSource.expandHeaderItem('column', [2013]);
            }); */
           
  
            
      },[])

     /*  const colorPvsA = ["#c5c5c5", "#29b9d0", "#6ab41b"] */
      const colorPvsA = ["#29b9d0","#6ab41b"]
      const customColor = (arg)=> {
          const seriesName = arg.seriesName
          const typeOfCostPlan = "costo planeado"
          const typeOfCostPlanEngl = "planned cost"
         
        if(seriesName.includes(typeOfCostPlan) || seriesName.includes(typeOfCostPlanEngl) ){
          return {color: "#29b9d0"}
        }else {
          return {color:"#6ab41b"}
        }
      }
      const testAnotation = (arg)=>{
        const titleForChart = arg.seriesName.split("|")
        return {customizeText(e) {
          return "Hola";
        },}
      }
  
      const handleCellClick = (e) =>{
        console.log(e)
        if(e.area == "row" && e.cell.path.length == 3){

          
          let nameProject = e.cell?.path[2].split(',')[0]
          let idProject = e.cell?.path[2].split(',')[1]
          let metodologyProject = e.cell?.path[2].split(',')[2]
          
          let dataLink = {
            country: e.cell?.path[0],
            type:e.cell?.path[1],
            name:nameProject ,
            id:idProject ,
            metodology:metodologyProject
          }
          
          setDataForLink(dataLink)
          setShowProjectResources(true)
          console.log("entramos a la ultima")
         
        }else {
          return
        }
      } 
      const columnsProject = [
        {
          selector: "country",
          caption:t("projects_tablecolumn_country"),
          width:80,
          customRender: (row) => {
            return (
              <span>{row.data.country}</span>
            )
          }
        },
        {
          selector: t("projects_tablecolumn_name"),
          caption:"Nombre",
          
         
          customRender: (row) => {
            return (
              <span title={row.data.name}>{row.data.name}</span>
            )
          }
        },
        {
          selector: "type",
          caption:t("projects_tablecolumn_type"),
          width:120,
          customRender: (row) => {
            return (
              <span>{row.data.type}</span>
            )
          }
        },
        {
          selector: "metodology",
          caption:t("projects_tablecolumn_methodology"),
          width:120,
          customRender: (row) => {
            return (
              <span>{row.data.metodology}</span>
            )
          }
        },
        {
          selector: "",
          caption:"Link",
          width:100,
          customRender: (row) => {
            return (
              <Link
              to ={(row.data.metodology =="Waterfall" || row.data.metodology =="Tradicional") ?`/hv-project/${row.data.id}`:`/hv-agile/${row.data.id}`} 
            >
              {t("projects_btn_options_report")}
            </Link>
            )
          }
        }
      
      ]

      const handlelContentReady = (e)=> {
        console.log(e)
      }

      const handleLegend = (arg) => {
        const seriesName = arg?.map((it)=> {
          const typeOfCostPlan = "costo planeado"
          const typeOfCostPlanEngl = "planned cost"
          return {
            id: it.id,
            marker : {
              fill : (it.text?.includes(typeOfCostPlan) || it.text?.includes(typeOfCostPlanEngl)) ? "#29b9d0" : "#6ab41b" ,
              opacity : it.marker.opacity,
              size :it.marker.size,
              state : it.marker.state
            },
            series : it.series,
            size: it.size,
            states:{
              hover :{
                fill : (it.text?.includes(typeOfCostPlan) || it.text?.includes(typeOfCostPlanEngl)) ? "#29b9d0" : "#6ab41b" ,
                hatching : it.states.hover.hatching,
                state: it.states.hover.state
              },
              normal :{ 
                fill : (it.text?.includes(typeOfCostPlan) || it.text?.includes(typeOfCostPlanEngl)) ? "#29b9d0" : "#6ab41b" ,
                opacity: 1,
                state : "normal"
              },
              selection : {
                fill : "#e0301e",
                hatching : it.states.selection.hatching,
                state : it.states.selection.state
              }
            } ,
            text : it.text,
            visible : it.visible

          }
        })
       

        return seriesName
      }


      const onExporting = useCallback((e) => {

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('data');
    
        exportPivotGrid({
          component: e.component,
          worksheet,
        }).then(() => {
          console.log("exportado")
    /*       workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Data.xlsx');
          }); */
        });
      });
     
  return (
    <>
    
    <Chart ref={_chart} 
          palette={colorPvsA}
          customizePoint={customColor}
          /*   customizeLabel={testAnotation} */>
          <Size height={400} />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <CommonSeriesSettings 
            type="bar"
            barWidth={70}
          />
          {/*  <Series
            valueField="costPlan"
            name="Costo Plan."
            color="#29b9d0"
            />
            <Series
              valueField="costReal"
              name="Costo Real"
              color="#6ab41b"
            /> */}
          <AdaptiveLayout width={450} />
          <Legend
                    position="outside"
                    horizontalAlignment="center"
                    verticalAlignment="top"
                    orientation="horizontal"
                    customizeItems={handleLegend}
            />
          {/*  <Label
           customizeText={testAnotation}
           visible={true}
           horizontalAlignment="center"
           /> */}
    </Chart>
    <div className='d-flex justify-content-center pb-3' >
      <div className='mr-4'>  <i className="fa fa-square text-info mr-2"></i> <small className='text-act'>{t("projects_tablecolumn_plancosttotal")}</small></div>
      <div>  <i className="fa fa-square text-success mr-2"></i><small className='text-actt'>{t("projects_tablecolumn_actualcosttotal")}</small> </div>
    </div>
        {/* <Button
        
            className='mb-2'
            variant="outline-dark"
            size="sm"
            onClick={() => {
              
              localStorage.removeItem("dx-widget-gallery-pivotgrid-storing")
            
            }}
          >
            <i className="fa fa-sync"></i>
        </Button> */}
        <PivotGrid
          /* id={pivotg} */
          ref={_pivotGrid}
          dataSource={dataSource2}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={false}
          showRowTotals={false}
          showRowGrandTotals={false}
          onCellClick={handleCellClick}
          onContentReady={handlelContentReady}
          onExporting={onExporting}
        >
           <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="dx-widget-gallery-pivotgrid-storing"
            />
          <FieldChooser 
            enabled={true} 
            height={550}
            allowSearch={true}
          />
          <Export enabled={true} />
        </PivotGrid>
        <Modal
            show={showProjectResources}
            onHide={handleCloseProjectResource}
            contentClassName="border-0 shadow-lg"
            centered
            size="lg"
            >
            <Modal.Header closeButton>
              <Modal.Title className="h6">{t("projects_tablecolumn_information")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='pt-0'>
            <span
              className="ml-2"
              style={{ maxWidth: "340px" }}
              title={dataForLink.name}
            >
            </span>
              <SuperTable
                noSearch
                columns={columnsProject}
                data={[dataForLink]}
              />
              
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="dark" onClick={handleCloseProjectResource}>
                {t("hvagile_button_close")}
              </Button>
            </Modal.Footer> */}
          </Modal>
    </>
  )
}

export default ProjectsDashboard 