import React, { useState } from 'react'
import { useEffect, useRef } from 'react';
import { deleteDataScrum, getData, inputChange, saveDataScrum, updateDataScrum } from '../helpers';
import swal from 'sweetalert';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const NotesModal = (props) => {

    const [t,i18n] =useTranslation("planning")
    const [notesData, setNotesData]= useState([])
    const [taskInfo,setTaskInfo]= useState({})
    const [enableEdit, setEnableEdit]=useState()
    const [ newNote, setNewNote]=useState("")
    const [showNotes, setShowNotes]= useState(false)
    const [editNotes, setEditNotes] = useState(false)



    const getAllNotes = (projectId, taskId) => {
        Promise.all([
         getData(`traditional-task-notes/${projectId}/${taskId}`,{}),
        ]).then((values)=>{
          setNotesData(values[0])
        })
    }
    const noteref = useRef();

    const handleOnChangeForNotes = (e,id) => {

        if(e.target.name == "text")
         setNotesData((prev)=> {
           prev.map((itprev)=>{
             if(itprev.idComment == id){
               itprev.text = e.target.value
             }
             return itprev
           })
     
           return [...prev]
         })
         
    }
      
    const handleEditNote = (id) =>{
        setEnableEdit(id)
    }
    
    const createNewNote = (e) => {
       
        let newNoteData = {
        projUID:  props.taskEdit.IdProyecto,
        taskId: props.taskEdit.IdActividad,
        noteText: newNote.newComment,
        };
        saveDataScrum("agile-task-notes", newNoteData)
        .then((res) => {
            getAllNotes(newNoteData.projUID, newNoteData.taskId)
            noteref.current.value = ""
        })
        .catch((error) => {
            swal("¡Ups!", "Hubo un error al crear la nota", "error");
        });
    };
     
    const editNote = (id)=>{
        let noteToEdit  = notesData.filter((it)=> it.idComment == id)
        const noteEdit ={
        id : noteToEdit[0].idComment,
        text : noteToEdit[0].text,
        idProject : noteToEdit[0].idProject
        }
        updateDataScrum(`agile-task-notes/${noteEdit.id}`, noteEdit)
            .then((res) => {
            getAllNotes(props.taskEdit.IdProyecto, props.taskEdit.IdActividad)
            setEnableEdit()
            })
            .catch((err) =>
            swal(
                "¡Ups!",
                "Hubo un error al actualizar los datos la nota, por favor refresca la página.",
                "error"
            )
        );
    }
     
    const deleteNote = (id)=>{
        swal({
        title: t("modal_creationactivities_deletenote"),
        text: t("modal_creationactivities_deletenote_2"),
        icon: "warning",
        buttons: true,
        buttons: [t("modal_creationactivities_deletefile_3"), t("modal_creationactivities_deletefile_4")],
        dangerMode: true 
        }).then((willDelete) => {
        if (willDelete) {
            let noteToEdit  = notesData.filter((it)=> it.idComment == id)
            deleteDataScrum(`agile-task-notes-delete/${noteToEdit[0].idComment}`)
            .then((res)=> {
            swal(t("modal_creationactivities_done"), t("modal_creationactivities_deletenote_5"), "success").catch((err) =>
            swal(
                "¡Ups!",
                "Hubo un error al eliminar la actividad, por favor refresca la página.",
                "error"
            ))
            getAllNotes(props.taskEdit.IdProyecto, props.taskEdit.IdActividad)
            }
            )
        }
        });
    }
     
    const commentsToShow = () => { 
         if(notesData.length > 3 && showNotes == false ){
           let notesSlice= notesData.slice(0,3)
           return notesSlice
         }else
         return notesData
    }
     
    const numberOfnotes = ()=> {
        if(notesData){
        return notesData.length
        }
    }

   /*  console.log(props.taskEdit) */
   /*  useEffect(() => {
        setTaskInfo(props.taskEdit);
        console.log(taskInfo)
        return () => {
            setTaskInfo({});
        };
      }, []); */
     /*  props.ProyectoId , props.taskId */
    useEffect(() => {
        if (props.taskEdit) {
          getAllNotes(props.taskEdit.IdProyecto, props.taskEdit.IdActividad)
        }
    }, []);



  return (
    <>
    {(notesData?.length > 3) &&
                <Button 
                  variant="outline-info"
                  size ="sm"
                  className="mt-0 mb-2"
                  onClick={()=> {
                    setShowNotes(value => value = !value)
                  }}
                  >  
                      {(!showNotes)? <>
                        {t("modal_creationactivities_notes_4")}
                       ( {numberOfnotes()} )
                        </>
                        : <>{t("modal_creationactivities_notes_5")}( 3 )</>
                      }
                </Button>
                }
                <Row className="px-1 mb-0" style={{backgroundColor:"#e9ecef",borderTopLeftRadius:"5px",borderTopRightRadius:"5px", maxHeight:"365px", overflowY:"auto"}}>
                {(notesData.length == 0 ) && 
                <Form.Label className="mb-1 mt-2"><b>{t("modal_creationactivities_text19")}</b></Form.Label>
                }
                {(notesData.length >= 1) && 
                <Form.Label className="mb-1">{t("modal_creationactivities_notes_6")}</Form.Label>
                }
                <Col lg={12}  >
                  {(commentsToShow()) ? 
                  <>
                  {  commentsToShow()?.map((item,idx)=>{
                    return <Col lg={12} className="mb-0 pb-0 d-flex border-bottom rounded border-2 border-light" style={{backgroundColor:"#e9ecef"}}>
                            <div style={{width:"100%"}} className="mr-2 " >
                            <small className="mr-2">{item.name}</small>
                            <small>{t("modal_creationactivities_notes_1")} {item.createDate}</small>
                            <Form.Control
                            key={idx}
                            as="textarea"
                            className="mb-1 pb-0"
                            style={{border:"none"}}
                            name="text"
                            value={item.text}
                            disabled = {(idx == enableEdit )? false:true}
                            onChange={(e)=> {
                              handleOnChangeForNotes(e,item.idComment)
                            }}
                            />
                            </div>
                            <div className="d-flex justify-content-around align-items-center">
                            {(idx != enableEdit)? <>
                              <i className="text-info fa fa-edit mr-2" 
                                  style={{cursor:"pointer"}}
                                  onClick={(e)=>{
                                    handleEditNote(idx)
                                    
                                  }}
                                  >
                                  
                              </i>
                              <i className="text-danger fas fa-trash"
                                  style={{cursor:"pointer"}}
                                  onClick={(e)=>{
                                    deleteNote(item.idComment)
                                  }}
                              ></i>
                            </> :""
                            }
                            {(idx == enableEdit)? 
                            <>
                            <div className="d-flex flex-column">
                            <Button
                              type="button"
                              variant="info"
                              size="sm"
                              className="p-1 mb-1"
                              onClick={(e)=> {
                                editNote(item.idComment)
                              }}
                            
                            >
                            {t("modal_creationactivities_button_save")}
                            </Button>
                            <Button
                              type="button"
                              variant="dark"
                              size="sm"
                              className="p-1 m-0"
                              onClick={(e)=> {
                                setEnableEdit()
                                console.log("entrando al form local")
                                setEditNotes(false)
                              }}
                            
                            >
                            {t("modal_creationactivities_button_cancel")}
                            </Button>
                            </div>
                            </> :
                            
                            "" }
                            </div>
                            </Col>
                  })}
                  
                  </>
                  :""
                  }
                </Col>
                </Row>
                <Row>
                  <Form
                  style={{backgroundColor:"#e9ecef", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}
                  className="w-100 mb-2 px-1"
                  > 
                  {(notesData?.length >= 1 ) && 
                  
                    <Form.Label><b>{t("modal_creationactivities_notes_2")}</b></Form.Label>
                  }
                    <Col lg={12} md={12}>
                 
                      <Form.Control
                      ref={noteref}
                      as="textarea"
                      className="mb-2"
                      
                      name="newComment"
                      placeholder={t("modal_creationactivities_text20")}
                      onChange={(e) => {
                        console.log(e.target.value)
                        inputChange(e,setNewNote);
                      }}
                      />
                      <Button
                      type="button"
                      variant="success"
                      size="sm"
                      className="p-1 mb-2"
                      onClick={(e)=> {
                        createNewNote(e)
                      }
                      }
                    >
                     <icon className="fa fa-sticky-note mr-2"/>{t("modal_creationactivities_notes_3")}
                    </Button>
                    </Col>

                 {/*  <Col lg={6}>
                    <Form.Label>{t("modal_creationactivities_text19")}</Form.Label>
                    <Form.Control
                      className="border-info mb-3"
                      name="Avance"
                      as="textarea"
                      placeholder={t("modal_creationactivities_text20")}
                      style={{ height: "100px" }}
                      // value={_getValue("Avance")}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        _changeInput(e);
                      }}
                    />
                  </Col> */}
                  {/* {_getValue("UltAvance") ? (
                  
                    <Col lg={6}>
                      <Form.Label>
                        <i className="fa fa-sticky-note mr-2 text-info"></i>
                        {t("modal_creationactivities_text21")} 
                      </Form.Label>
                      <Form.Control
                        className="border-info"
                        name="Avance"
                        value={_getValue("UltAvance") || ""}
                        as="textarea"
                        disabled="true"
                        style={{ height: "100px" }} 
                        onClick={(e) => {
                          e.target.select();
                        }}
                      />
                    </Col>
                    
                  ) : null } */}
                  </Form>
                  </Row>
               {/*  </Row> */}
    </>
  )
}

export default NotesModal