import { LOAD_PROJECT_DATA, ERROR_LOADING_PROJECT,LOADING_DATA, FETCH_RESOURCES_TRADITIONAL } from "./traditionalTypes";

const initial = {
  projectData: {},
  loading: true,
  error: false,
  activitiesByResourceTraditional:[]
};

const traditionalReducer = (state = initial, action) => {
  switch (action.type) {
    case LOAD_PROJECT_DATA:
      return {
        ...state,
        projectData: action.payload,
        loading: false,
        error: false,
      };
    case ERROR_LOADING_PROJECT:
      return {
        projectData: {},
        loading: false,
        error: true,
      };
      case LOADING_DATA:
        return {
          ...state,
          projectData: {},
          loading: true,
          error: "",
          
        };
      case FETCH_RESOURCES_TRADITIONAL:
      return {
        ...state,
        activitiesByResourceTraditional: action.payload
      };
    default:
      return state;
  }
};

export default traditionalReducer;
