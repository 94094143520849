import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import TopBar from "./TopBar";
import { theme } from "./color-theme";
import MakeTooltips from "../components/MakeTooltips";
import { saveLocalStorage, settingAccessLocalStorage, } from "../helpers";

const Layout = (props) => {
  const saveData = (datos)=> {
    settingAccessLocalStorage(datos)
  }
  useEffect(() => {
    saveLocalStorage(saveData)
  }, []); 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <TopBar loadUser = {props.loadUser} users={props.users} />
      {props.children}
      <MakeTooltips />
    </ThemeProvider>
  );
};

export default Layout;
