import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import {
  deleteDataScrum,
  getData,
  saveDataScrum,
  updateDataScrum,
  updateLanes
} from "../../helpers";
import swal from "sweetalert";
import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateBacklog } from "../../redux";
import { selectTooltip , selectTooltip_en } from "../../utils/selectTooltip";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const AddActivity = (props) => {

  const [t,i18n] =useTranslation("planning")
  const rol = window.localStorage.getItem("rol")
  return (
    <>
  {(rol == "Miembro de Equipo" || rol == "Ejecutivo")? <></> : 
    <button
      className="btn btn-outline-primary btn-block text-left mt-3 mb-5"
      onClick={props.onClick}
    >
      <i className="fa fa-plus mr-2"></i>{t("planningboard_productbacklog_button_additem")}
    </button>
    }
    </>);
};

export const NewCard = (props) => {
  const [t,i18n] =useTranslation("planning")
  const activityName = useRef();
  return (
    <div className="card border-primary mt-3 mb-5">
      <div className="card-body p-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            let cardData = {
              uid: props.uid,
              backlogUID: props.backlogUID,
              laneId: props.laneId,
              title: activityName.current.value
            };
            saveDataScrum("agile-cards", cardData)
              .then((res) => {
                props.onAdd(res);
                props.getAllDataAct()
              })
              .catch((error) => {
                swal("¡Ups!", "Hubo un error al crear la actividad", "error");
                props.onCancel();
              });
          }}
        >
          <div className="form-group mb-2">
            {/* <label>Agregar tarea</label> */}
            <input
              className="form-control"
              autoFocus
              placeholder={t("planningboard_productbacklog_button_nameitem")}
              ref={activityName}
              required
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={props.onCancel}
              className="btn btn-outline-danger btn-sm mr-2"
            >
              {t("planningboard_productbacklog_button_cancel")}
            </button>
            <button type="submit" className="btn btn-success btn-sm">
              {t("planningboard_productbacklog_button_additask")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const AddSprintButton = (props) => {
  const [t,i18n] =useTranslation("planning")
  const rol = window.localStorage.getItem("rol")
  return (
   <>
   {(rol == "Miembro de Equipo" || rol == "Ejecutivo")? <></>:
    <button
      id="btn-add-sprint"
      onClick={!props.disabled ? props.onClick : null}
      className={`btn ml-3 shadow-lg mr-3 ${
        props.disabled ? "btn-dark" : "btn-primary"
      }`}
      style={{ width: "250px", marginTop: "15px" }}
      disabled={props.disabled}
      title={
        props.disabled
          ? t("planningboard_button_filter_1")
          : t("planningboard_button_filter_2")
      }
    >
      {props.disabled && (
        <small>
          <i className="fa fa-filter mr-2" />
        </small>
      )}
      {t("planningboard_sprint_button_create")}
    </button>
   }
    </>);
};

export const NewSprint = (props) => {
  useEffect(() => {

    const numberOfSprint = ()=>{
      if(props.lastSprint.length == 0 ){
        const newSprintNumber = 1
        return newSprintNumber
      }else {
        const newSprintNumber = props.lastSprint.at(-1).SprintPosicion + 1
        return newSprintNumber
      }
    }
    /* const newSprintNumber = props.lastSprint.at(-1).SprintPosicion + 1 */
    /* let lanes = document.querySelectorAll(
      "#pwc-product-backlog-kanban .react-trello-lane"
      ).length; */
    let laneData = {
      projUID: props.uid,
     /*  title: `Sprint ${newSprintNumber}`,
      pos: newSprintNumber */
      title: `Sprint ${numberOfSprint()}`,
      pos: numberOfSprint()
     /*  pos: lanes */
    };
    saveDataScrum("agile-lanes", laneData)
      .then((res) => {
        props.onAdd(res);
        props.getAllLanes()
      })
      .catch((error) => {
        swal("¡Ups!", "Hubo un error al crear el Sprint", "error");
        props.onCancel();
      });
  }, []);
  return <></>;
};

export const LaneHeader = (props) => {
  const [t,i18n] =useTranslation("planning")
  let sprintStartDate = (new Date(props.FechaInicio)).getTime()
  let sprintEndDate = (new Date(props.FechaFin)).getTime()
  
 
  const dateOfActivities = props.cards.map((card) => { 
      
    let cardStartDate = (new Date(card.metadata.FechaInicio)).getTime()
    let cardEndDate = (new Date(card.metadata.FechaFin)).getTime()
    return { 
          startDateCard : cardStartDate,
          endDateCard : cardEndDate
          }
  })
  
  const getMatchMaxDate = ( ) => {
    let match = dateOfActivities.map( (act) => 
      ( act.endDateCard > sprintEndDate) );
      return match
    }

  const getMatchMinDate = ( ) => {
    let match = dateOfActivities.map( (act) => { 
      if(act.startDateCard !== 0) {
        return act.startDateCard < (sprintStartDate - 86400000)  }  });
      return match
  } 

  const showAlertMaxDate = () => {
    if(sprintEndDate && (dateOfActivities.length >= 1) ) {      
        const saveMatchMaxDate = getMatchMaxDate()
        let showMax = saveMatchMaxDate.includes(true)
        return showMax
    }else {
        return false
      }
  }

  const showAlertMinDate = () => {
    if(sprintStartDate && (dateOfActivities.length >= 1)) {    
        const saveMatchMinDate = getMatchMinDate()
        let showMin = saveMatchMinDate.includes(true)
        return showMin
    }else {
        return false
      }
  }
  const showAlertDates = ()=> {
      if(showAlertMaxDate() && showAlertMinDate() ){
          let alertF = t("planningboard_lenguaje") == "es" ? selectTooltip(37) :selectTooltip_en(37)
        return alertF
      }else if(showAlertMaxDate()){
          let alertS = t("planningboard_lenguaje") == "es" ? selectTooltip(35) :selectTooltip_en(35)
        return alertS
      }else if(showAlertMinDate()){
        let alertT = t("planningboard_lenguaje") == "es" ? selectTooltip(36) :selectTooltip_en(36)
        return alertT
      }
  }

  const LaneFinished = useCallback((cards, status) => {
   /*  let show = false;
    if (cards.length > 0) {
      !cards.find((card) => card.metadata.progress < 100) && (show = true);
      if (show)
        return (
          <i
            title="Completado"
            className="ml-2 fa fa-check-circle text-success"
          ></i>
        );
    } */
    switch (status) {
      case 1:
        return (
          <i
            title={t("hvagile_status_1")}
            className="ml-2 fa fa-stop-circle text-muted"
          ></i>
        );
      case 2:
        return (
          <i title={t("hvagile_status_2")} 
          className="ml-2 fa fa-play-circle text-info"></i>
        );
      case 3:
        return (
          <i
            title={t("hvagile_status_3")}
            className="ml-2 fa fa-check-circle text-success"
          ></i>
        );

      default:
        return "";
    }

  }, []);

  const rol = window.localStorage.getItem("rol")

  const BacklogHeader = () => {
    return (
      <>
        <span data-tip={(t("planningboard_lenguaje") == "es") ? selectTooltip(0) : selectTooltip_en(0)}>Product Backlog</span>
      </>
    );
    };

    return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-2"
        style={{
          // height: "45px",
          cursor: props.title != "Product Backlog" ? "pointer" : "initial"
        }}
        onClick={() =>
          props.title != "Product Backlog" && props.onLaneHeaderClick(props)
        }
      >
        <div>
          <h6 className="mb-0 font-weight-bold">
            {props.title != "Product Backlog" ? (
              <span data-tip={(t("planningboard_lenguaje") == "es")? selectTooltip(4) : selectTooltip_en(4)}
              className="title-spt-backlog">
                <i className="fa fa-edit mr-1"></i>
                {props.title}
              </span>
            ) : (
              <BacklogHeader />
            )}
            {LaneFinished(props.cards, props.Estado)}
          </h6>
        </div>
        {props.title != "Product Backlog" && props.cards.length > 0 && (
          <Link
            to={`/sprint-board/${props.id}`}
            title={`${t("planningboard_tooltip_card_8")} ${props.title}`}
          >
            <Button size="sm" variant="outline-primary">
              <i className="fa fa-external-link-square-alt"></i> {t("planningboard_sprint_button_board")}
            </Button>
          </Link>
        )}
        {props.title != "Product Backlog" && props.cards.length === 0 && rol !="Miembro de Equipo" && (
          
          <button
            onClick={(e) => {
              e.stopPropagation();
              let laneData = { laneId: props.id };
              deleteDataScrum(`agile-lanes/${props.id}`, laneData)
                .then((res) => {
                  props.actions.removeLane(laneData);
                  props.getAllLanes()
                })
                .catch((error) => {
                  swal("¡Ups!", "Hubo un error al borrar el Sprint", "error");
                  // props.onCancel();
                });
            }}
            disabled={(rol =="Ejecutivo")? true : false}
            className="btn btn-outline-danger btn-sm"
            style={{
              float: "right",
              position: "absolute",
              right: 0,
              marginRight: "12px"
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        )}
      </div>
      {(props.Alias || props.FechaInicio || props.FechaFin) && (
        <>
          <div 
            className="d-flex flex-column w-100 small mr-1">
            <span className="text-muted font-weight-bold d-block">
              {props.Alias || ""}
            </span>
            <div 
            className="text-muted font-weight-bold d-flex justify-content-end align-items-center"
            >
              {(showAlertMaxDate() || showAlertMinDate()) && <>
                <i 
                  class="far fa-dot-circle mt-1 animation-point-sprint"
                  data-tip= {showAlertDates()}

              ></i>
              </>
              }
              {(props.FechaInicio != null || props.FechaFin != null ) && <>
              <span 
             /*  title="Fecha de inicio (DD/MM/YY)"  */
              title = {`${t("planningboard_tooltip_card_9")} (DD/MM/YY)`} 
              className={(showAlertMinDate()) ? "font-weight-bold text-danger mr-1" : "text-muted font-weight-bold mr-1"} >
                {/* {moment(props.FechaInicio).utc().format("DD/MM/YY") || ""} */}
                {(props.FechaInicio != null)? moment(props.FechaInicio).utc().format("DD/MM/YY"):"Sin Asignar"}
              </span> {" "}
              -{" "}
              <span className={(showAlertMaxDate()) ? "font-weight-bold text-danger ml-1" : "text-muted font-weight-bold ml-1"} 
              /* title="Fecha de fin (DD/MM/YY)" */
              title = {`${t("planningboard_tooltip_card_10")} (DD/MM/YY)`} 
              >
               {/*  {moment(props.FechaFin).utc().format("DD/MM/YY") || ""} */}
               {(props.FechaFin != null)? moment(props.FechaFin).utc().format("DD/MM/YY"):"Sin asignar"}
              </span>
              </>}
            </div>
          </div>
        </>
      )}
      {props.title != "Product Backlog" && (props.start || props.end) ? (
        <>
          <div className="d-flex w-100 align-items-end justify-content-between small">
            <span>
              {props.cards.length} {props.cards.length === 1 ? "item" : "items"}
            </span>
            <div className="text-muted">
              <span 
              /* title="Fecha de inicio (DD/MM/YY)" */
              title = {`${t("planningboard_tooltip_card_9")} (DD/MM/YY)`}
              >
                {props.start || ""}
              </span>{" "}
              -{" "}
              <span className="text-muted" 
              /* title="Fecha de fin (DD/MM/YY)" */
              title = {`${t("planningboard_tooltip_card_10")} (DD/MM/YY)`} 
              >
                {props.end || ""}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex w-100 align-items-end justify-content-between small">
          <div className="text-muted">
            {props.cards.length} {props.cards.length === 1 ? "item" : "items"}
          </div>
        </div>
      )}
    </>
  );
};

const getInitialLetters = (name) => {
  let words = name.split(" ");
  let result = "";
  for (let i = 0; i < words.length && i < 2; i++) {
    result += words[i].charAt(0);
  }
  return result;
};

const printInitialsName = (resources,t) => {
  if (resources?.length > 0) {
    return (
      <span className="small" title={t("planningboard_tooltip_card_7")}>
        <i className="fa fa-user text-muted mr-1"></i>
        {resources.map((resource, index) => {
          return index < 2 ? (
            <span key={index} className="badge bg-info text-white mr-1">
              {getInitialLetters(resource.name)}
            </span>
          ) : (
            ""
          );
        })}
        {resources?.length > 2 ? "+" : null}
      </span>
    );
  }
  return null;
};

export const CardBacklog = (props) => {
  
  const rol = window.localStorage.getItem("rol")

  const [t,i18n] =useTranslation("planning")
  let sprintStartDat = (new Date(props.metadata.FechaInicioSprint)).getTime()
  let sprintEndDat = (new Date(props.metadata.FechaFinSprint)).getTime()

  let taskStartDate = (new Date(props.metadata?.FechaInicio)).getTime()
  let taskEndDate = (new Date(props.metadata?.FechaFin)).getTime()

  let valueDate =  false
  if(sprintStartDat && taskStartDate && sprintEndDat  && taskEndDate) {
    valueDate = ( (sprintStartDat - 72000000 ) < taskStartDate && sprintEndDat > taskEndDate )  ? false : true

  }
  const scrum = useSelector((state) => state.scrum);
  const dispatch = useDispatch();
  const handleDeleteCard = (event) => {
    event.stopPropagation();
    //
    swal({
      title: t("planningboard_button_delete_1"),
      text: t("planningboard_button_delete_2"),
      buttons: true,
      buttons: [t("modal_creationactivities_deletefile_3"), t("modal_creationactivities_deletefile_4")],
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        props.onDelete();
       props.getAllDataAct()
      }
    });
    //
  };
  const handleToggleFeatured = (event, card) => {
    event.stopPropagation();
    let dummie = { ...card };
    dummie.metadata.Destacada = !card.metadata.Destacada;
    updateDataScrum(`agile-cards/${card.id}`, dummie)
      .then((res) => {
        let newBoard = updateLanes(scrum, dummie);
        dispatch(updateBacklog(newBoard));
      })
      .catch((err) =>
        swal(
          "¡Ups!",
          "Hubo un error al actualizar los datos la actividad, por favor refresca la página.",
          "error"
        )
      );
  };
  return (
    <div
      id={`card-${props.id}`}
      className="card mb-3 shadow-sm"
      style={{ width: "250px", cursor: "pointer" }}
      onClick={props.onClick}
    >
      <div
        className="progress"
        // title={`${props.metadata.progress}% Completado`}
        style={{
          height: "8px",
          cursor: "move",
          borderRadius: 0
        }}
      >
        <div
          className={`progress-bar ${
            props.metadata?.progress < 100 ? "bg-info" : "bg-success"
          }`}
          role="progressbar"
          style={{ width: `${props.metadata?.progress}%` }}
        ></div>
      </div>
      <div className="card-body p-2">
        <div className="d-flex">
          <div className="d-flex justify-content-between w-100 align-items-start">
            <p className={`m-0 ${(valueDate)? "text-danger" : ""}`} /* className="m-0 text-danger " */ style={{ whiteSpace: "break-spaces" }}>
              <small>
                <i
                  title="Destacar"
                  className={`fa fa-star mr-1 ${
                    props.metadata.Destacada ? "text-warning" : "icon-opa"
                  }`}
                  onClick={(e) => handleToggleFeatured(e, props)}
                ></i>
              </small>
              {props.title}
              {(valueDate) && 
              <small>
              <i className="ml-2 fa fa-exclamation text-danger"
                  data-tip= {(t("planningboard_lenguaje") == "es")? selectTooltip(43): selectTooltip_en(43)}
              ></i>
              </small>
              }
            </p>
            {(rol == "Miembro de Equipo")? <></>:
            <>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={handleDeleteCard}
              disabled = {(rol == "Ejecutivo")? true : false}
            >
              <i className="fa fa-trash"></i>
            </button>
            
            </>}
            
          </div>
        </div>
        {/* <hr className={`${!props.metadata.progress ? "d-none" : "my-2"}`} />
         <div
          className={`justify-content-between ${
            !props.metadata.progress ? "d-none" : "d-flex"
          }`}
        > */}
        <hr className="my-2" />
        <div className="d-flex justify-content-between">
          <div style={{ display: "inline-flex" }}>
            <span
              className="small bg-light rounded mr-1 font-weight-bold"
              title={`${t("planningboard_tooltip_card_1")}: ${props.metadata.poValue || 0}`}
            >
              {props.metadata.poValue || 0}
            </span>
            {props.metadata.HistoriaUsuarioId && (
              <span
                className="small text-muted mr-1 rounded"
                title= {t("planningboard_tooltip_card_2")}
              >
                <i className="fa fa-scroll"></i>
              </span>
            )}
            {props.metadata.UltAvance && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_3")}
              >
                <i className="fa fa-sticky-note"></i>
              </span>
            )}
             {props.metadata.tags?.length > 0 && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_4")}
              >
                <i className="fa fa-tags"></i>
              </span>
            )}
             {props.metadata.TieneAdjuntos == 1  && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_5")}
              >
                <i className="fa fa-paperclip"></i>
              </span>
            )}
          </div>
          {/* <span className="small" title="% completado">
            <i
              className={`fa fa-check-circle mr-1 ${
                props.metadata.progress < 100 ? "text-muted" : "text-success"
              }`}
            ></i>
            {props.metadata.progress || 0}%
          </span> */}
          {props.metadata.FechaVencimiento && (
            <span className="small" title={t("planningboard_tooltip_card_6")}>
              <i className="fa fa-calendar-alt text-muted mr-1"></i>
              {moment(props.metadata.FechaVencimiento)
                .add(5, "hours")
                .format("DD/MM/YYYY")}
            </span>
          )}
          {/* {props.metadata.resources > 0 && (
            <span className="small" title="Asignaciones">
              <i className="fa fa-user text-muted mr-2"></i>
              <span className="badge bg-info text-white">DM</span>
            </span>
          )} */}
          {printInitialsName(props.metadata.resources, t)}
        </div>
      </div>
    </div>
  );
};
