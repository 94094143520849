import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Layout from '../../Layout'
import TitleCommon from '../TitleCommon'
import { API_ROUTE, headersRequest, saveData } from '../../helpers'
import axios from 'axios'
import SuperTableV2 from '../SuperTableV2'
import { Button, Col, Container, Form, Nav, Row, Tab, Table } from 'react-bootstrap'
import { StyledTabs } from '../StyledCommon'
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next'
import { selectTooltip, selectTooltip_en } from '../../utils/selectTooltip'

const CreateForm = () => {

  const [t, i18n] = useTranslation("global")
  let { projectId, action } = useParams()
  const sweetAlertTest = true;
  const history = useHistory()

  const [getUserList, setGetUserList] = useState([])
  const [dataMgrProjects, setDataMgrProjects] = useState([])
  const [dataMgrAreas, setDataMgrAreas] = useState([])
  const [nombresCriticidad, setNombresCriticidad] = useState([]);
  const [colorFondo, setColorFondo] = useState([]);
  const [dataRisksIdentified, setDataRisksIdentified] = useState([])
  const [dataStateRisk, setDataStateRisk] = useState({})
  const [nameImpacto, setNameImpacto] = useState(3);
  const [nameOcurrencia, setNameOcurrencia] = useState(3);
  const [valueImpacto, setValueImpacto] = useState(3);
  const [valueOcurrencia, setValueOcurrencia] = useState(4);
  const [valorFinalCriticidad, setValorFinalCriticidad] = useState(0);
  const [activeKeyDefault, setActiveKeyDefault] = useState("first")
  const [idRecuperado, setIdRecuperado] = useState("")
  const dataEvaluated = {
    idRiesgo: idRecuperado,
    nivelImpacto: valueImpacto,
    nivelOcurrencia: valueOcurrencia
  }

  const getProjectsMgr = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}/mgr-projects/`, headersRequest());
      const filterProject = res.data.filter(project => project.IdProyecto === projectId)

      if (filterProject.length > 0) {
        const projectName = filterProject[0].Nombre;
        setDataMgrProjects(projectName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAreasMgr = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}/mgr-get-areas/`, headersRequest());
      setDataMgrAreas(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getStatesMgr = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}/mgr-get-states/`, headersRequest());
      console.log(res.data)

      if (activeKeyDefault === "first") {
        const filterState = res.data.find(state => state.EstadoRiesgo === "En plan identificación")
        setDataStateRisk(filterState)
      } else if (activeKeyDefault === "second") {
        const filterState = res.data.find(state => state.EstadoRiesgo === "En plan evaluación")
        setDataStateRisk(filterState)
      } else if (activeKeyDefault === "third") {
        const filterState = res.data.find(state => state.EstadoRiesgo === "En plan mitigación")
        setDataStateRisk(filterState)
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUsers = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}users`, headersRequest());
      setGetUserList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRisk = async (id) => {
    try {
      const res = await axios.get(`${API_ROUTE}/mgr-get-risk/${id}`, headersRequest());
      setDataRisksIdentified(res.data[0]);


    } catch (error) {
      console.error(error);
    }
  };

  const handleImpacto = (event) => {
    const newValue = parseInt(event.target.value);
    setNameImpacto(newValue);

    switch (newValue) {
      case 1:
        setValueImpacto(1);
        break;
      case 2:
        setValueImpacto(2);
        break;
      case 3:
        setValueImpacto(3);
        break;
      case 4:
        setValueImpacto(4);
        break;
      case 5:
        setValueImpacto(5);
        break;
      default:
        setValueImpacto(0);
    }
  };

  const handleOcurrencia = (event) => {
    const newValue = parseInt(event.target.value);
    setNameOcurrencia(newValue);

    switch (newValue) {
      case 1:
        setValueOcurrencia(1);
        break;
      case 2:
        setValueOcurrencia(2);
        break;
      case 3:
        setValueOcurrencia(4);
        break;
      case 4:
        setValueOcurrencia(8);
        break;
      case 5:
        setValueOcurrencia(16);
        break;
      default:
        setValueOcurrencia(0);
    }
  };

  const getLabelImpacto = (nameImpacto) => {
    switch (nameImpacto) {
      case 1:
        return 'Muy bajo';
      case 2:
        return 'Bajo';
      case 3:
        return 'Medio';
      case 4:
        return 'Alto';
      case 5:
        return 'Muy alto';
      default:
        return '';
    }
  };

  const getLabelOcurrencia = (nameOcurrencia) => {
    switch (nameOcurrencia) {
      case 1:
        return 'Muy improbable';
      case 2:
        return 'Improbable';
      case 3:
        return 'Posible';
      case 4:
        return 'Probable';
      case 5:
        return 'Muy probable';
      default:
        return '';
    }
  };

  const calcularNivelRiesgo = (impacto, ocurrencia, index = 0) => {
    if (ocurrencia !== '' && impacto !== '') {
      const multiplicacion = parseInt(ocurrencia) * parseInt(impacto);

      let nombreCriticidad = '';
      let coloresFondo = '';

      if (multiplicacion >= 1 && multiplicacion <= 4) {
        nombreCriticidad = 'Riesgo Aceptable';
        coloresFondo = '#008000';
      } else if (multiplicacion >= 5 && multiplicacion <= 15) {
        nombreCriticidad = 'Riesgo Moderado';
        coloresFondo = '#FFA401';
      } else if (multiplicacion >= 16 && multiplicacion <= 31) {
        nombreCriticidad = 'Riesgo Alto';
        coloresFondo = '#f06632';
      } else if (multiplicacion >= 32 && multiplicacion <= 80) {
        nombreCriticidad = 'Riesgo Extremo';
        coloresFondo = '#FF0606';
      }

      const newNombresCriticidad = [...nombresCriticidad];
      newNombresCriticidad[index] = nombreCriticidad;
      setNombresCriticidad(newNombresCriticidad);

      const newColorFondo = [...colorFondo];
      newColorFondo[index] = coloresFondo;
      setColorFondo(newColorFondo);
      setValorFinalCriticidad(multiplicacion);
    }
  };

  useEffect(() => {
    getProjectsMgr()
    getUsers()
    getAreasMgr()
    getStatesMgr()
  }, [])


  useEffect(() => {
    calcularNivelRiesgo(valueImpacto, valueOcurrencia);
  }, [valueImpacto, valueOcurrencia]);

  const submitIdentifyRisk = (e) => {
    e.preventDefault();

    const sendData = async () => {
      try {
        const formData = new FormData(e.target);
        const payload = Object.fromEntries(formData.entries());
        payload.IdProyectoSeleccionado = projectId;
        payload.new = true;

        const response = await axios.post(`${API_ROUTE}risks-identify-form`, payload, headersRequest());

        if (response && response.data.id) {

          if (sweetAlertTest) {
            swal({
              title: "Riesgo creado",
              text: "¿Quieres evaluar este riesgo?",
              icon: "success",
              buttons: {
                cancel: "Guardar",
                confirm: "Continuar"
              }
            }).then((actionResponse) => {
              if (actionResponse) {
                getRisk(response.data.id)
                setIdRecuperado(response.data.id)
                setActiveKeyDefault("second");
              } else {
                history.push('/risks');
              }
            });
          } else {
            swal("¡Ups!!", "Por favor revisa los datos.", "error");
          }
        } else {
          swal("Error", "No se pudo obtener el ID del riesgo creado", "error");
        }
      } catch (error) {
        console.error("Error al enviar los datos:", error);
        swal("Error", "Hubo un problema al crear el riesgo", "error");
      }
    };

    sendData();
  };

  const createEvaluationrisk = async(data)=>{
    try {
      const result =  await axios.post(`${API_ROUTE}mgr-risks-evaluated`, data, headersRequest());
      console.log(result)
    } catch (error) {
          console.error("Error al guardar los datos:", error);
          swal("¡Error!", "Hubo un problema al guardar los datos.", "error");
    }
    
  }
  const submitEvaluationRisk = () => {
    console.log(dataEvaluated)
    if (nombresCriticidad[0] === "Riesgo Aceptable") {
      swal({
        title: "El riesgo es aceptable",
        text: `No requiere un plan de acción`,
        icon: "success",
        buttons: {
          confirm: "Guardar"
        }
      }).then((actv)=>{
        if(actv){
          createEvaluationrisk(dataEvaluated).then(history.push('/risks'))
          
        }
      });
    } else if (nombresCriticidad[0] === "Riesgo Moderado" || nombresCriticidad[0] === "Riesgo Alto" || nombresCriticidad[0] === "Riesgo Extremo") {
      swal({
        title: `${nombresCriticidad}`,
        text: "Se requiere un plan de acción",
        icon: "warning",
        buttons: {
          /* cancel: "Guardar", */
          confirm: "Guardar"
        }
      }).then((actv)=>{
        if(actv){
          createEvaluationrisk(dataEvaluated).then(history.push('/risks'))
          
        }
      })
     /*  .then(async (actionResponse) => { 
        if (actionResponse) {
          try {
            setActiveKeyDefault("thrid");
          } catch (error) {
            console.error("Error al guardar los datos:", error);
            swal("¡Error!", "Hubo un problema al guardar los datos.", "error");
          }
        } else {
          history.push('/risks');
        }
      }); */
    } else {
      swal("¡Ups!!", "Por favor revisa los datos.", "error");
    }
  };
  const dateRisk = dataRisksIdentified?.RiesgoFechaIdentificacion
  const dateWithForm = new Date(dateRisk).toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' })
  
  return (
    <>
      <Layout>
        <TitleCommon
          title={{
            text: "Módulo de Riesgos",
            icon: "fas fa-exclamation-triangle"
          }}
          color={projectId ? "secondary" : "success"}
          goBack={{
            text: "Volver",
            icon: "arrow-left",
          }}
        />
        <Container fluid>
          <Tab.Container activeKey={activeKeyDefault} onSelect={(k) => setActiveKeyDefault(k)}>
            <Row className="bg-light">
              <StyledTabs as={Nav}>
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="first"> Identificación</Nav.Link>
                  </Nav.Item>
                </Col>
                <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Evaluación</Nav.Link>
                  </Nav.Item>
                </Col>
        {/*         <Col>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Gestión</Nav.Link>
                  </Nav.Item>
                </Col> */}
              </StyledTabs>
            </Row>

            <Tab.Content>

              {/* Identificación */}

              <Tab.Pane
                eventKey="first"
                className="animate__animated animate__fadeIn"
              >
                <Form onSubmit={submitIdentifyRisk}>

                  <h5 className="px-3 pt-3 text-primary">Proyecto seleccionado: <span style={{ color: "black" }}>{dataMgrProjects}</span></h5>
                  <h5 className="px-3 pt-3">Identificación del riesgo</h5>
                  <Row>
                    <Col md={6} className="pt-3 pb-1 mb-5 pr-3 pl-4">
                      <Form.Label data-tip={(t("global_lenguaje") == "es") ? selectTooltip(48) : selectTooltip_en(48)}>
                        Estado del Riesgo
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="EstadoRiesgo"
                        value={dataStateRisk.IdEstadoRiesgo}
                        readOnly
                      >
                        <option value={dataStateRisk.IdEstadoRiesgo}>{dataStateRisk.EstadoRiesgo}</option>
                      </Form.Control>
                    </Col>

                    <Col md={6} className="pt-3 pb-3 mb-5 pr-3 pl-4">
                      <Form.Label>
                        Fecha de identificación
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="RiesgoFechaIdentificacion"
                        required
                      />
                    </Col>
                    <Col md={6} className="pt-2 pb-4 mb-3 pr-3 pl-4">
                      <Form.Label data-tip={(t("global_lenguaje") == "es") ? selectTooltip(49) : selectTooltip_en(49)}>
                        Responsable del Riesgo
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="ResponsableIdentificacion"
                        required
                      >
                        <option value="">Seleccionar</option>
                        {getUserList.length > 0 ? getUserList.map(user => (
                          <option key={user.IdUsuario} value={user.IdUsuario}>{user.Nombre}</option>
                        )) : <option value="" disabled>No hay usuarios disponibles</option>}
                      </Form.Control>
                    </Col>
                    <Col md={6} className="pt-2 pb-4 mb-3 pr-3 pl-4">
                      <Form.Label data-tip={(t("global_lenguaje") == "es") ? selectTooltip(50) : selectTooltip_en(50)}>
                        Area de Impacto
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="AreaImpacto"
                        required
                      >
                        <option value="">Seleccionar</option>
                        {dataMgrAreas.length > 0 ? dataMgrAreas.map(area => (
                          <option key={area.Id} value={area.Id}>{area.Area}</option>
                        )) : <option value="" disabled>No hay areas registradas</option>}
                      </Form.Control>
                    </Col>
                    <h5 className='px-4'>Amenazas propias del proyecto</h5>
                    <Col md={12} className="pt-4 pb-2 mb-5 pr-3 pl-4">
                      <Form.Label>
                        Nombre del Riesgo
                      </Form.Label>
                      <Form.Control
                        name="RiesgoNombre"
                        placeholder="Ingresa el nombre del riesgo"
                        required
                      />
                    </Col>
                    <Col md={12} className="mb-5 pr-3 pl-4">
                      <Form.Label>
                        Descripción
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="RiesgoDescripcion"
                        row="4"
                        placeholder="Describe de manera detallada la amenaza identificada"
                        required
                      />
                    </Col>
                  </Row>
                  <Button
                    variant="success"
                    type="submit"
                    className="position-fixed"
                    style={{ bottom: "1rem", right: "1rem" }}
                  >
                    <i className="fa fa-save mr-2"></i>Guardar
                  </Button>
                </Form>
              </Tab.Pane>

              {/* Evaluación */}
              <Tab.Pane
                eventKey="second"
                className="animate__animated animate__fadeIn"
              >
                <h5 className="px-3 pt-3 text-primary mb-4">Riesgo a evaluar: <span style={{ color: "black" }}>{dataRisksIdentified?.RiesgoNombre}</span></h5>
                <Col className='risksFlexContainer'>
                  <div className="risksFlexContainerLeft">
                    <div className="assessmentRiskContainer">
                      <div className="assessmentRiskCard mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-4">
                            <div className="card border-0 shadow p-3 fixed-size-card">
                              <div className="card-body">
                                <p className="card-text" style={{ fontSize: "20px" }}>{dateWithForm}</p>
                                <p className="text-muted">Fecha de identificacion</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="card border-0 shadow p-3 fixed-size-card" style={{
                              color: nombresCriticidad == 'Riesgo Aceptable' ? '#008000' :
                                nombresCriticidad == 'Riesgo Moderado' ? '#FFA401' :
                                  nombresCriticidad == 'Riesgo Alto' ? '#f06632' :
                                    nombresCriticidad == 'Riesgo Extremo' ? '#FF0606' : "",
                              backgroundColor:
                                nombresCriticidad == 'Riesgo Aceptable' ? '#A9F7A7' :
                                  nombresCriticidad == 'Riesgo Moderado' ? '#FFE5B4' :
                                    nombresCriticidad == 'Riesgo Alto' ? '#FFC6A5' :
                                      nombresCriticidad == 'Riesgo Extremo' ? '#FF9999' : ""
                            }}>
                              <div className="card-body">
                                <p className="card-text" style={{ fontSize: "20px" }}>
                                  {nombresCriticidad == "Riesgo Aceptable" ? (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-reception-1 pb-2" viewBox="0 0 16 16">
                                    <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                  </svg>) : nombresCriticidad == "Riesgo Moderado" ? (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-reception-2 pb-2" viewBox="0 0 16 16">
                                    <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4 5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                  </svg>) : nombresCriticidad == "Riesgo Alto" ? (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-reception-3 pb-2" viewBox="0 0 16 16">
                                    <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                                  </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-reception-4 pb-2" viewBox="0 0 16 16">
                                    <path d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z" />
                                  </svg>)}
                                  {nombresCriticidad}
                                </p>
                                <p>Criticidad final</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="card border-0 shadow p-3 fixed-size-card">
                              <div className="card-body">
                                <p className='card-text text-primary' style={{ fontSize: "20px" }}>En evaluación</p>
                                <p className="text-muted"><i className="bi bi-clock"></i> Estado del riesgo</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="card border-0 shadow p-3 text-white fixed-size-card" style={{ backgroundColor: nombresCriticidad == "Riesgo Aceptable" ? '#008000' : nombresCriticidad == "Riesgo Moderado" || "Riesgo Alto" || "Riesgo Extremo" ? "#FF0606" : "" }}>
                              <div className="card-body">
                                <p style={{ fontSize: "20px" }}>
                                  {nombresCriticidad == "Riesgo Aceptable" ? 'No requiere un plan de acción' : nombresCriticidad == "Riesgo Moderado" || "Riesgo Alto" || "Riesgo Extremo" ? "Requiere un plan de acción" : ""}
                                </p>
                                <i>
                                  {nombresCriticidad == "Riesgo Aceptable" ? (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                                  </svg>) : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-circle-fill pl-1" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                                  </svg>}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="assessmentContainer">
                      <p className='font-italic pt-2'><span className='text-primary'>* </span>Para evaluar tu riesgo usa los controles de evaluación ajustando los niveles a tu requerimiento</p>
                        <div className='assessmentRiskOne mt-5'>
                          <div className="assessmentThumb">
                            <div className='sliderContainer'>
                              <div className='d-flex justify-content-between'>
                                <strong><p>Nivel de Ocurrencia: </p></strong>
                                <p className='text-primary'>{getLabelOcurrencia(nameOcurrencia)}</p>
                              </div>
                              <input
                                type="range"
                                id="sliderOcurrencia"
                                className='inputCustomRangeRisk sliderStyleRisk'
                                min="1"
                                max="5"
                                step="1"
                                value={nameOcurrencia}
                                onChange={handleOcurrencia}
                              />
                              <div className='d-flex justify-content-between w-100 mt-1'>
                                <span className='text-center flex'>|<p>1</p></span>
                                <span className='text-center flex pl-1'>|<p>2</p></span>
                                <span className='text-center flex pl-2'>|<p>4</p></span>
                                <span className='text-center flex pl-2'>|<p>8</p></span>
                                <span className='text-center flex'>|<p>16</p></span>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="assessmentThumb mt-4 mb-5 pt-3">
                            <div className='sliderContainer'>
                              <div className='d-flex justify-content-between'>
                                <strong><p>Nivel de Impacto: </p></strong>
                                <p className='text-primary'>{getLabelImpacto(nameImpacto)}</p>
                              </div>
                              <input
                                type="range"
                                id="sliderImpacto"
                                className='inputCustomRangeRisk sliderStyleRisk'
                                min="1"
                                max="5"
                                step="1"
                                value={nameImpacto}
                                onChange={handleImpacto}
                              />
                              <div className='d-flex justify-content-between w-100 mt-1'>
                                <span className='text-center flex'>|<p>1</p></span>
                                <span className='text-center flex'>|<p>2</p></span>
                                <span className='text-center flex'>|<p>3</p></span>
                                <span className='text-center flex'>|<p>4</p></span>
                                <span className='text-center flex'>|<p>5</p></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="risksFlexContainerRight">
                    <div className="containerCustomRiskMatrix">
                      <h3 className='text-center mt-2'>Matriz de Criticidad</h3>
                      <p className='font-italic pt-2'><span className='text-primary'>* </span>Al pasar el cursor por encima de los campos de niveles en la matriz podrás ver la descripción de los valores de la ocurrencia y el impacto: </p>
                      <table className="tableCustomRiskMatrix">
                        <thead>
                          <tr>
                            <th className="thCustomRiskMatrix" rowspan="1"></th>
                            <th className="thCustomRiskMatrix" colspan="5">Ocurrencia</th>
                          </tr>
                          <tr>

                            <th className="thCustomRiskMatrix">Impacto</th>

                            <th data-tip={(t("global_lenguaje") == "es") ? selectTooltip(56) : selectTooltip_en(56)} className="thwhite" style={{height: '85px'}}>Muy probable<br />1</th>
                            <th data-tip={(t("global_lenguaje") == "es") ? selectTooltip(57) : selectTooltip_en(57)} className="thwhite">Probable<br />2</th>
                            <th data-tip={(t("global_lenguaje") == "es") ? selectTooltip(58) : selectTooltip_en(58)} className="thwhite">Posible<br />4</th>
                            <th data-tip={(t("global_lenguaje") == "es") ? selectTooltip(59) : selectTooltip_en(59)} className="thwhite">Improbable<br />8</th>
                            <th data-tip={(t("global_lenguaje") == "es") ? selectTooltip(60) : selectTooltip_en(60)} className="thwhite">Muy improbable<br />16</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-tip={(t("global_lenguaje") == "es") ? selectTooltip(51) : selectTooltip_en(51)} className="tdCustomRiskMatrix thwhite">Muy alto<br />5</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valorFinalCriticidad === 5 ? "#FFE5B4" : "#FFA401",
                              color: valorFinalCriticidad === 5 ? "#FFA401" : "#FFFFFF",
                              transform: valorFinalCriticidad === 5 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valorFinalCriticidad === 5 ? 1 : 0
                            }}>Moderado<br />5</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valorFinalCriticidad === 10 ? "#FFE5B4" : "#FFA401",
                              color: valorFinalCriticidad === 10 ? "#FFA401" : "#FFFFFF",
                              transform: valorFinalCriticidad === 10 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valorFinalCriticidad === 10 ? 1 : 0
                            }}>Moderado<br />10</td>
                            <td className="tdCustomRiskMatrix altoRisk" style={{
                              backgroundColor: valorFinalCriticidad === 20 ? "#FFC6A5" : "#f06632",
                              color: valorFinalCriticidad === 20 ? "#f06632" : "#FFFFFF",
                              transform: valorFinalCriticidad === 20 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valorFinalCriticidad === 20 ? 1 : 0
                            }}>Alto<br />20</td>
                            <td className="tdCustomRiskMatrix extremoRisk" style={{
                              backgroundColor: valorFinalCriticidad === 40 ? "#FF9999" : "#FF0606",
                              color: valorFinalCriticidad === 40 ? "#FF0606" : "#FFFFFF",
                              transform: valorFinalCriticidad === 40 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valorFinalCriticidad === 40 ? 1 : 0
                            }}>Extremo<br />40</td>
                            <td className="tdCustomRiskMatrix extremoRisk" style={{
                              backgroundColor: valorFinalCriticidad === 80 ? "#FF9999" : "#FF0606",
                              color: valorFinalCriticidad === 80 ? "#FF0606" : "#FFFFFF",
                              transform: valorFinalCriticidad === 80 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valorFinalCriticidad === 80 ? 1 : 0
                            }}>Extremo<br />80</td>

                          </tr>
                          <tr>
                            <td data-tip={(t("global_lenguaje") == "es") ? selectTooltip(52) : selectTooltip_en(52)} className="tdCustomRiskMatrix thwhite">Alto<br />4</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 1 && valueImpacto === 4 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 1 && valueImpacto === 4 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 1 && valueImpacto === 4 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 1 && valueImpacto === 4 ? 1 : 0
                            }}>Bajo<br />4</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valueOcurrencia === 2 && valueImpacto === 4 ? "#FFE5B4" : "#FFA401",
                              color: valueOcurrencia === 2 && valueImpacto === 4 ? "#FFA401" : "#FFFFFF",
                              transform: valueOcurrencia === 2 && valueImpacto === 4 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 2 && valueImpacto === 4 ? 1 : 0
                            }}>Moderado<br />8</td>
                            <td className="tdCustomRiskMatrix altoRisk" style={{
                              backgroundColor: valueOcurrencia === 4 && valueImpacto === 4 ? "#FFC6A5" : "#f06632",
                              color: valueOcurrencia === 4 && valueImpacto === 4 ? "#f06632" : "#FFFFFF",
                              transform: valueOcurrencia === 4 && valueImpacto === 4 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 4 && valueImpacto === 4 ? 1 : 0
                            }}>Alto<br />16</td>
                            <td className="tdCustomRiskMatrix extremoRisk" style={{
                              backgroundColor: valueOcurrencia === 8 && valueImpacto === 4 ? "#FF9999" : "#FF0606",
                              color: valueOcurrencia === 8 && valueImpacto === 4 ? "#FF0606" : "#FFFFFF",
                              transform: valueOcurrencia === 8 && valueImpacto === 4 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 8 && valueImpacto === 4 ? 1 : 0
                            }}>Extremo<br />32</td>
                            <td className="tdCustomRiskMatrix extremoRisk" style={{
                              backgroundColor: valorFinalCriticidad === 64 ? "#FF9999" : "#FF0606",
                              color: valorFinalCriticidad === 64 ? "#FF0606" : "#FFFFFF",
                              transform: valorFinalCriticidad === 64 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valorFinalCriticidad === 64 ? 1 : 0
                            }}>Extremo<br />64</td>
                          </tr>
                          <tr>
                            <td data-tip={(t("global_lenguaje") == "es") ? selectTooltip(53) : selectTooltip_en(53)} className="tdCustomRiskMatrix thwhite">Medio<br />3</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 1 && valueImpacto === 3 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 1 && valueImpacto === 3 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 1 && valueImpacto === 3 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 1 && valueImpacto === 3 ? 1 : 0
                            }}>Bajo<br />3</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valueOcurrencia === 2 && valueImpacto === 3 ? "#FFE5B4" : "#FFA401",
                              color: valueOcurrencia === 2 && valueImpacto === 3 ? "#FFA401" : "#FFFFFF",
                              transform: valueOcurrencia === 2 && valueImpacto === 3 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 2 && valueImpacto === 3 ? 1 : 0
                            }}>Moderado<br />6</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valueOcurrencia === 4 && valueImpacto === 3 ? "#FFE5B4" : "#FFA401",
                              color: valueOcurrencia === 4 && valueImpacto === 3 ? "#FFA401" : "#FFFFFF",
                              transform: valueOcurrencia === 4 && valueImpacto === 3 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 4 && valueImpacto === 3 ? 1 : 0
                            }}>Moderado<br />12</td>
                            <td className="tdCustomRiskMatrix altoRisk" style={{
                              backgroundColor: valueOcurrencia === 8 && valueImpacto === 3 ? "#FFC6A5" : "#f06632",
                              color: valueOcurrencia === 8 && valueImpacto === 3 ? "#f06632" : "#FFFFFF",
                              transform: valueOcurrencia === 8 && valueImpacto === 3 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 8 && valueImpacto === 3 ? 1 : 0
                            }}>Alto<br />24</td>
                            <td className="tdCustomRiskMatrix extremoRisk" style={{
                              backgroundColor: valueOcurrencia === 16 && valueImpacto === 3 ? "#FF9999" : "#FF0606",
                              color: valueOcurrencia === 16 && valueImpacto === 3 ? "#FF0606" : "#FFFFFF",
                              transform: valueOcurrencia === 16 && valueImpacto === 3 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 16 && valueImpacto === 3 ? 1 : 0
                            }}>Extremo<br />48</td>
                          </tr>
                          <tr>
                            <td data-tip={(t("global_lenguaje") == "es") ? selectTooltip(54) : selectTooltip_en(54)} className="tdCustomRiskMatrix thwhite">Bajo<br />2</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 1 && valueImpacto === 2 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 1 && valueImpacto === 2 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 1 && valueImpacto === 2 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 1 && valueImpacto === 2 ? 1 : 0
                            }}>Bajo<br />2</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 2 && valueImpacto === 2 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 2 && valueImpacto === 2 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 2 && valueImpacto === 2 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 2 && valueImpacto === 2 ? 1 : 0
                            }}>Bajo<br />4</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valueOcurrencia === 4 && valueImpacto === 2 ? "#FFE5B4" : "#FFA401",
                              color: valueOcurrencia === 4 && valueImpacto === 2 ? "#FFA401" : "#FFFFFF",
                              transform: valueOcurrencia === 4 && valueImpacto === 2 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 4 && valueImpacto === 2 ? 1 : 0
                            }}>Moderado<br />8</td>
                            <td className="tdCustomRiskMatrix altoRisk" style={{
                              backgroundColor: valueOcurrencia === 8 && valueImpacto === 2 ? "#FFC6A5" : "#f06632",
                              color: valueOcurrencia === 8 && valueImpacto === 2 ? "#f06632" : "#FFFFFF",
                              transform: valueOcurrencia === 8 && valueImpacto === 2 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 8 && valueImpacto === 2 ? 1 : 0
                            }}>Alto<br />16</td>
                            <td className="tdCustomRiskMatrix extremoRisk" style={{
                              backgroundColor: valueOcurrencia === 16 && valueImpacto === 2 ? "#FF9999" : "#FF0606",
                              color: valueOcurrencia === 16 && valueImpacto === 2 ? "#FF0606" : "#FFFFFF",
                              transform: valueOcurrencia === 16 && valueImpacto === 2 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 16 && valueImpacto === 2 ? 1 : 0
                            }}>Extremo<br />32</td>
                          </tr>
                          <tr>
                            <td data-tip={(t("global_lenguaje") == "es") ? selectTooltip(55) : selectTooltip_en(55)} className="tdCustomRiskMatrix thwhite">Muy bajo<br />1</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 1 && valueImpacto === 1 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 1 && valueImpacto === 1 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 1 && valueImpacto === 1 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 1 && valueImpacto === 1 ? 1 : 0
                            }}>Bajo<br />1</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 2 && valueImpacto === 1 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 2 && valueImpacto === 1 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 2 && valueImpacto === 1 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 2 && valueImpacto === 1 ? 1 : 0
                            }}>Bajo<br />2</td>
                            <td className="tdCustomRiskMatrix bajoRisk" style={{
                              backgroundColor: valueOcurrencia === 4 && valueImpacto === 1 ? "#A9F7A7" : "#008000",
                              color: valueOcurrencia === 4 && valueImpacto === 1 ? "#008000" : "#FFFFFF",
                              transform: valueOcurrencia === 4 && valueImpacto === 1 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 4 && valueImpacto === 1 ? 1 : 0
                            }}>Bajo<br />4</td>
                            <td className="tdCustomRiskMatrix moderadoRisk" style={{
                              backgroundColor: valueOcurrencia === 8 && valueImpacto === 1 ? "#FFE5B4" : "#FFA401",
                              color: valueOcurrencia === 8 && valueImpacto === 1 ? "#FFA401" : "#FFFFFF",
                              transform: valueOcurrencia === 8 && valueImpacto === 1 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 8 && valueImpacto === 1 ? 1 : 0
                            }}>Moderado<br />8</td>
                            <td className="tdCustomRiskMatrix altoRisk" style={{
                              backgroundColor: valueOcurrencia === 16 && valueImpacto === 1 ? "#FFC6A5" : "#f06632",
                              color: valueOcurrencia === 16 && valueImpacto === 1 ? "#f06632" : "#FFFFFF",
                              transform: valueOcurrencia === 16 && valueImpacto === 1 ? "scale(1.2)" : "scale(1)",
                              transition: "transform 0.2s",
                              position: "relative",
                              zIndex: valueOcurrencia === 16 && valueImpacto === 1 ? 1 : 0
                            }}>Alto<br />16</td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </Col>
                <Button
                  variant="success"
                  type="submit"
                  className="position-fixed"
                  style={{ bottom: "1rem", right: "1rem" }}
                  onClick={submitEvaluationRisk}
                >
                  <i className="fa fa-save mr-2"></i>Guardar
                </Button>


              </Tab.Pane>

              {/* Gestión */}

              <Tab.Pane
                eventKey="third"
                className="animate__animated animate__fadeIn"
              >
                <h5 className="px-3 pt-3 text-primary">Gestión del riesgo: </h5>
                <h2>*Kanban*</h2>
                <Button
                  variant="success"
                  type="submit"
                  className="position-fixed"
                  style={{ bottom: "1rem", right: "1rem" }}
                >
                  <i className="fa fa-save mr-2"></i>Guardar
                </Button>
              </Tab.Pane>

            </Tab.Content>

          </Tab.Container>
        </Container>
      </Layout >
    </>
  )
}
export default CreateForm
