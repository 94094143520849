import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import {
  getData,
  saveData,
  inputChange,
  alreadyExists,
  saveDataScrum,
  desencry,
} from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import ProductBacklog from "../components/ProductBacklog";
import SprintBacklog from "../components/SprintBacklog";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProjectGeneral,
  createBacklogLane,
  updateBacklog,
  fetchProjectColumnsBacklogSprints,
  fetchProjectColumnsKanbanSprints,
  fetchSprintGeneral,
  fetchProjectTags
} from "../redux";
import Loader from "../components/Loader";
import emptyImg from "../assets/iso-scrum.png";
import ReactTooltip from "react-tooltip";
import NoAccess from "./NoAccess";
import { useTranslation } from "react-i18next";

const KanbanBoard = () => {
  
  const access = window.localStorage.getItem("accessToKanbanBoard")
  const desData = desencry(access,'accessCloudProjectDevKanbanBoard')
  const [activitiesCant,setActivitiesCant] = useState([]);
  const [t,i18n] =useTranslation("planning")

  const { projectId, sprintId} = useParams()
  const uid  =  sprintId
  const scrum = useSelector((state) => state.scrum);
  const projectIdForTags = scrum.general.IdProyecto
  const dispatch = useDispatch();

  const createBacklog = () => {
    let laneData = {
      projUID: uid,
      title: "Product Backlog",
      backlog: true,
    };
    saveDataScrum("agile-lanes", laneData)
      .then((res) => {
        dispatch(
          updateBacklog({
            lanes: [{ ...res }],
          })
        );
        // dispatch(createBacklogLane(uid));
      })
      .catch((error) => {
        swal("¡Ups!", "Hubo un error al crear el Product Backlog", "error");
      });
  };

  const getAllprojectData = () => {
    if (uid) {
      dispatch(fetchProjectColumnsKanbanSprints(uid));
    }
  }
  
  useEffect(() => {
    dispatch(fetchSprintGeneral(uid,projectId));
    dispatch(fetchProjectTags(projectId))
    getAllprojectData();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(()=> {
    Promise.all([
      getData(`hv-project-agile/${projectId}`, [])])
      .then((values) => {
      setActivitiesCant(values[0]);
    });
      
  },[scrum])


  const calAvance = Math.round( (activitiesCant.Terminadas / activitiesCant.CantActividades) * 100) || 0
  
  return (<>
    { (desData ==="true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: `${t("planningboard_button_board")} | ${scrum.general.Nombre || "..."}`,
            icon: "columns",
          }}
          color="secondary"
          //   titleColor="dark"
          button={{
            variant: "dark",
            text: t("planningboard_button_back"),
            icon: "arrow-left",
            url: `/boards`,
          }}
        //   subTitle={{
        //     text: `Scrum: ${scrum.general.Nombre || "..."}`,
        //     textColor: "white",
        //     bgColor: "dark",
        //   }}
        />
        {/* <Container fluid className="bg-dark" style={{ zIndex: -1 }}>
          <Row>
            <Col className="pt-3 pb-2 text-white">
              <h5>
                <span>Scrum: {scrum.general.Nombre || "..."}</span>
              </h5>
            </Col>
          </Row>
        </Container> */}
        <div className="d-flex small justify-content-between align-items-center container-fluid py-2 btn-group-kanban">
          <div className="d-flex group-kanban-1" style={{gap:"15px"}}>
            <div className="d-flex group-kanban-2">
              <h6 className="mb-0 mr-1">{t("planningboard_conventions_summary")}</h6> <span>{t("planningboard_conventions_total_task")} {activitiesCant.CantActividades}</span>
              <div className="mx-2 group-kanban-3">
                  <span className="mr-2">
                  <i style={{color:"#e9ecef"}} className="fa fa-circle mr-1"></i> {t("planningboard_conventions_notstarted")}
                   ( {activitiesCant.SinIniciar} )
                  </span>
                  <span className="mr-2">
                    <i className="fa fa-circle text-info mr-1"></i> {t("planningboard_conventions_inprogress")}
                     ( {activitiesCant.EnCurso} )
                  </span>
                  <span className="">
                    <i className="fa fa-circle text-success mr-1"></i> {t("planningboard_conventions_finished")}
                     ( {activitiesCant.Terminadas} )
                  </span>
            </div>
            </div>
            <div className="d-flex">
              <span className="h6 mb-0 mr-1">{t("planningboard_conventions_total_Progress")}</span>
              <span className="h6 mb-0">{calAvance} %</span>
            </div>
          </div>
          <div>
              <Button
                variant="outline-dark"
              /*  className="ml-2 btn-pb-informe" */
                size="sm"
                as={Link}
                to={`/tags/${scrum.general.IdProyecto}`}
              >           
              <i className="fa fa-tags mr-2" /><span>
                {(scrum.tags.length > 0)? t("planningboard_conventions_total_editTags"):t("planningboard_conventions_total_createTags") }
              </span>
              </Button>
          </div>
        </div>
        <Container fluid className="bg-light">
          {/* {
                JSON.stringify(scrum.general)
            } */}
          <Tab.Container defaultActiveKey="first" transition={false}>
            <Row className="bg-light d-none">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="first">Sprint</Nav.Link>
                  </Nav.Item>
                  {/* {scrum.backlog.data.lanes.length > 1 && (
                    <Nav.Item>
                      <Nav.Link eventKey="second">Sprints</Nav.Link>
                    </Nav.Item>
                  )} */}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3">
              {scrum.backlog.loading ? (
                      <Loader />
                    ) : scrum.backlog.error ? (
                      <h1>{scrum.backlog.error}</h1>
                    ) : scrum.backlog.data.lanes.length === 0 ? (
                      <div className="text-center pt-4">
                        {/* <img
                          src={emptyImg}
                          alt="Sin datos"
                          className="img-fluid mb-3 animated pulse slower infinite"
                          width="230"
                        />
                        <h5>Crear backlog</h5>
                        <p>
                          Aún no tienes un Backlog para tu proyecto, inicia
                          ahora creando uno.
                        </p>
                        <Button onClick={createBacklog}>
                          <i className="fa fa-plus-circle mr-2"></i>Crear
                          Backlog
                        </Button> */}
                      </div>
                    ) : (
                      <SprintBacklog {...{ uid, projectIdForTags }} hideStories={true} getAllprojectData={getAllprojectData} hideTags={false} />
                    )}
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    </React.Fragment>
      :<>
       <NoAccess />
      </>
      }
     </>
  );
};

export default KanbanBoard;
