import React from "react";
import { ListGroup } from "react-bootstrap";

const ListOptionsAnchors = (props) => {
  return (
    <div className="sticky-top calend">
      <ListGroup>
        {props.data.map((item) => {
          return (
            <ListGroup.Item
              key={item.id}
              action
              href={`#${item.id}`}
              className="p-2 "
              variant="light"
            >
              {item.user}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default ListOptionsAnchors;
