import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const HelpVideoView = (props) => {   
    console.log(props)

    const urlAgil = `https://cloudprojects.co.pwc.com/Media/Agil-${props.info.url}.mp4`
    const urlTradicional = `https://cloudprojects.co.pwc.com/Media/Tradicional-${props.info.url}.mp4`
    const urlGeneralidades = `https://cloudprojects.co.pwc.com/Media/Generalidades-${props.info.url}.mp4`
  
    return(
    <>
    {(props.info.type === "Tradicional") &&
      <div className="text-center">
         <div className="embed-responsive embed-responsive-16by9">
          <video className="embed-responsive-item" 
            src={urlTradicional} 
            controls 
            allowFullScreen
            autoPlay 
            controlsList="nodownload">
          </video>
        </div>
      </div>
    }
    {(props.info.type === "Agil") &&
      <div className="text-center">
        <div className="embed-responsive embed-responsive-16by9">
          <video className="embed-responsive-item" 
            src ={urlAgil}
            controls 
            allowFullScreen
            autoPlay 
            controlsList="nodownload">
          </video>
        </div>
        <span>  </span>
      </div>
    }
    {(props.info.type === "Generalidades") &&
      <div className="text-center">
         <div className="embed-responsive embed-responsive-16by9">
          <video className="embed-responsive-item" 
            src={urlGeneralidades}
            controls 
            allowFullScreen
            autoPlay 
            controlsList="nodownload">
          </video>
        </div>
      </div>
    }

    </>
);
};

export default HelpVideoView;