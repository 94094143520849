import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import {
  getData,
  saveData,
  inputChange,
  alreadyExists,
  saveDataScrum,
  desencry,
} from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import ProductBacklog from "../components/ProductBacklog";
import SprintBacklog from "../components/SprintBacklog";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProjectGeneral,
  createBacklogLane,
  updateBacklog,
  fetchProjectColumnsBacklogSprints,
  fetchProjectColumnsKanbanSprints,
  fetchSprintGeneral,
  fetchProjectDdlUserStories,
} from "../redux";
import Loader from "../components/Loader";
import emptyImg from "../assets/iso-scrum.png";
import ReactTooltip from "react-tooltip";
import SearchTasks from "../components/SearchTasks";
import NoAccess from "./NoAccess";
import { useTranslation } from "react-i18next";

const SprintBoard = () => {
  
  const [t,i18n] =useTranslation("planning")
  const access = window.localStorage.getItem("accessToSprintBoard")
  const desData = desencry(access,'accessCloudProjectDevSprintBoard')
  const { uid } = useParams();
  const [saveIdProject, setSaveIdProject] = useState()
  const scrum = useSelector((state) => state.scrum);
  const dispatch = useDispatch();

  const tasksForSuggest = useMemo(
    () => scrum.backlog.data.lanes.map((lane) => lane.cards).flat(2),
    [scrum]
  );

  const createBacklog = () => {
    let laneData = {
      projUID: uid,
      title: "Product Backlog",
      backlog: true,
    };
    saveDataScrum("agile-lanes", laneData)
      .then((res) => {
       
        dispatch(
          updateBacklog({
            lanes: [{ ...res }],
          })
        );
        // dispatch(createBacklogLane(uid));
      })
      .catch((error) => {
        swal("¡Ups!", "Hubo un error al crear el Product Backlog", "error");
      });
  };

  const getAllprojectData = () => {
    if (uid) {
      dispatch(fetchProjectColumnsKanbanSprints(uid));
    }
  }
  
  useEffect(() => {
    dispatch(fetchSprintGeneral(uid));
    dispatch(fetchProjectDdlUserStories(uid))
    getAllprojectData();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const enableCost = (scrum.general.IdCostosAgil == "SI" || scrum.general.IdCostosAgil == "YES") ? true : false 

  return (<>
    { (desData=="true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: `Kanban | ${scrum.general.SprintNombre || "..."}`,
            icon: "columns",
          }}
          color="secondary"
          //   titleColor="dark"
          button={{
            variant: "dark",
            text: "Backlog",
            icon: "arrow-left",
            url: `/planning-board/${scrum.general.ProyectoId}`,
          }}
          subTitle={{
            text: `Scrum: ${scrum.general.Nombre || "..."}`,
            textColor: "white",
            bgColor: "dark",
          }}
        />
        <Container fluid className="bg-white border border-bottom" style={{ zIndex: -1 }}>
          <Row>
          <Col className="py-2">
              <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <h5 className="mr-3 mb-3 mb-lg-0">
                  <span className="text-primary">Scrum: </span>
                  <span>{scrum.general.Nombre || '...'}</span>
                </h5>
                <div className="d-flex flex-column flex-lg-row">
                  <SearchTasks data={tasksForSuggest} title_search = {t("planningboard_attachments_searchbox")} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="d-flex small container-fluid py-2">
          <span className="mr-2">
            <i className="fa fa-circle text-light"></i> {t("planningboard_conventions_notstarted")}
          </span>
          <span className="mr-2">
            <i className="fa fa-circle text-info"></i> {t("planningboard_conventions_inprogress")}
          </span>
          <span className="mr-2">
            <i className="fa fa-circle text-success"></i> {t("planningboard_conventions_finished")}
          </span>
          <span className="mr-2">
            <i className="fa fa-scroll text-muted"></i> {t("planningboard_conventions_hu")}
          </span>
          <span className="mr-2">
            <i className="fa fa-sticky-note text-muted"></i> {t("planningboard_conventions_notes")}
          </span>
          <span className="mr-2">
            <i className="fa fa-tags text-muted"></i> {t("planningboard_conventions_labels")}
          </span>
          <span className="mr-2">
            <i className="fa fa-paperclip text-muted"></i> {t("planningboard_conventions_attahments")}
          </span>
        </div>
        <Container fluid className="bg-light">
          {/* {
                JSON.stringify(scrum.general)
            } */}
          <Tab.Container defaultActiveKey="first" transition={false}>
            <Row className="bg-light d-none">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="first">Sprint</Nav.Link>
                  </Nav.Item>
                  {/* {scrum.backlog.data.lanes.length > 1 && (
                    <Nav.Item>
                      <Nav.Link eventKey="second">Sprints</Nav.Link>
                    </Nav.Item>
                  )} */}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3">
              {scrum.backlog.loading ? (
                      <Loader />
                    ) : scrum.backlog.error ? (
                      <h1>{scrum.backlog.error}</h1>
                    ) : scrum.backlog.data.lanes.length === 0 ? (
                      <>
                        {/* <img
                          src={emptyImg}
                          alt="Sin datos"
                          className="img-fluid mb-3 animated pulse slower infinite"
                          width="230"
                        />
                        <h5>Crear backlog</h5>
                        <p>
                          Aún no tienes un Backlog para tu proyecto, inicia
                          ahora creando uno.
                        </p>
                        <Button onClick={createBacklog}>
                          <i className="fa fa-plus-circle mr-2"></i>Crear
                          Backlog
                        </Button> */}
                        <SprintBacklog {...{ uid, enableCost }} getAllprojectData={getAllprojectData} idProject ={scrum.backlog.data.lanes[0]?.idProyecto } />
                      </>
                    ) : (
                      <SprintBacklog {...{ uid , enableCost }} getAllprojectData={getAllprojectData} idProject ={scrum.backlog.data.lanes[0]?.idProyecto} />
                    )}
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    </React.Fragment>
     :<>
     <NoAccess />
     </>
     }
    </>
  );
};

export default SprintBoard;
