import React from "react";
import {
  CircularGauge,
  Geometry,
  Range,
  RangeContainer,
  Scale,
  Tooltip,
  Font,
  Label,
} from "devextreme-react/circular-gauge";

const format = {
  type: "fixedPoint",
  precision: 1,
};

const Tachometer = (props) => {
  const { data, labels, legend, mini } = props;
  function customizeText({ valueText }) {
    return `${valueText}%`;
  }
  function customizeTooltip({ valueText }) {
    return {
      text: `${valueText}%`,
    };
  }
  return (
    <CircularGauge
      value={props.value}
        style={{
          marginTop: "-30px",
          marginBottom: "-30px",
        }}
      //   height={230}
      //   width={230}
    >
      <Scale
        startValue={-100}
        endValue={100}
        tick={{ visible: false }}
        tickInterval={25}
      >
        {/* <Label customizeText={customizeText} /> */}
        {/* <Label visible={false} /> */}
      </Scale>
      <Geometry startAngle={220} endAngle={-40}></Geometry>
      <RangeContainer
        palette={["#f50000", "#ffb600", "#6ab41b"]}
        width={17}
        offset={15}
      >
        <Range startValue={-100} endValue={-25} />
        <Range startValue={-25} endValue={0} />
        <Range startValue={0} endValue={100} />
      </RangeContainer>
    </CircularGauge>
  );
};

export default Tachometer;
