import {
  LOADING_DATA,
  FETCH_GENERAL_DATA,
  FETCH_RESOURCES_DATA,
  FETCH_TAGS_DATA,
  FETCH_USER_STORIES,
  FETCH_RESOURCES,
  FETCH_DDL_USER_STORIES,
  FETCH_DDL_SPRINT_STATUS,
  FETCH_TAGS,
  FETCH_BACKLOG_COLUMNS,
  FETCH_ACTIVITIES_DATA,
  FETCH_SPRINT_COLUMNS,
  FETCH_TEAM,
  UPDATE_BACKLOG
} from "./scrumTypes";

const initial = {
  general: {},
  resources: {},
  backlog: {
    loading: false,
    data: { lanes: [] },
    error: ""
  },
  stories: [],
  ddlStories: [],
  ddlSprintStatus: [],
  activitiesByResource: [],
  activitiesByTags: [],
  tags: {}
};

const scrumReducer = (state = initial, action) => {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        backlog: {
          loading: true,
          data: { lanes: [] },
          error: ""
        }
      };
    case FETCH_GENERAL_DATA:
      return {
        ...state,
        general: action.payload
      };
    case FETCH_USER_STORIES:
      return {
        ...state,
        stories: action.payload
      };
    case FETCH_DDL_USER_STORIES:
      return {
        ...state,
        ddlStories: action.payload
      };
    case FETCH_DDL_SPRINT_STATUS:
      return {
        ...state,
        ddlSprintStatus: action.payload
      };
    case FETCH_RESOURCES:
      return {
        ...state,
        activitiesByResource: action.payload
      };
    case FETCH_TAGS:
        return {
          ...state,
          activitiesByTags: action.payload
        };
    case FETCH_RESOURCES_DATA:
      return {
        ...state,
        resources: action.payload
      };
    case FETCH_TAGS_DATA:
      return {
        ...state,
        tags: action.payload
      };
    case UPDATE_BACKLOG:
      return {
        ...state,
        backlog: {
          loading: false,
          data: action.payload,
          error: ""
        }
      };
    default:
      return state;
  }
};

export default scrumReducer;
