export const tooltips = [
  {
    id: 0,
    location: "En el título de todas las columnas PRODUCT BACKLOG",
    msg: "Aquí podrás crear sus PBI (Product Backlog Item), y el orden, determinará su prioridades",
  },
  {
    id: 1,
    location: "En el título de todas las columnas TO DO",
    msg: "Aquí podrás ver las actividades que están por iniciar, puedes priorizar su atención con el orden de cada tarjeta que representa una actividad",
  },
  {
    id: 2,
    location: "En el título de todas las columnas DOING",
    msg: "Aquí podrás ver las actividades que se encuentran en curso, tambien podrás ver el equipo de trabajo quien participa en su ejecución",
  },
  {
    id: 3,
    location: "En el título de todas las columnas DONE",
    msg: "Aquí podrás ver las actividades que han sido terminadas y las notas que se registraron durante su avance",
  },
  {
    id: 4,
    location: "En el título de todas las columnas SPRINT",
    msg: "En este intervalo de tiempo, podrás agrupar tus actividades para lograr objetivos que no superen las 4 semanas, en caso de superarlo, puedes crear otro Sprint y agrupar tus nuevas actividades",
  },
  {
    id: 5,
    location: "Botón proyectos",
    msg: "Aquí podrás ver y crear tus proyectos, con herramientas Gantt y Kanban",
  },
  {
    id: 6,
    location: "Botón tableros",
    msg: "Aquí podrás registrar las actividades que no quieres perder de vista y que no hacen parte de un proyecto.",
  },
  {
    id: 7,
    location: "Botón Tareas",
    msg: "Esta es tu agenda digital, que te permitirá ver con una línea de tiempo y un buscador de actividades, todo lo que tienes por ejecutar o que ha sido ejecutado,  ya sea en proyectos o en tu día a día.",
  },
  {
    id: 8,
    location: "Botón productividad",
    msg: "Aquí podrás ver tu desempeño en los proyectos y el de tu equipo de trabajo",
  },
  {
    id: 9,
    location: "Botón Recursos",
    msg: "Aquí podrás crear tu equipo de trabajo, ten en cuenta que la creación de usuarios tiene una aprobación, que te permitirá disfrutar un paquete apropiado para ti.",
  },
  {
    id: 10,
    location: "Botón Crear en el centro de proyectos",
    // msg: "Aquí podrás estructurar tus proyectos, darles una identidad, planificar tus actividades y entregas, tu línea de tiempo y conformar un equipo de trabajo que te ayudará a lograr el objetivo que estas buscando!",
    msg: "Estructura tu proyecto, planifica actividades y conforma tu equipo de trabajo.",
  },
  {
    id: 11,
    location: "En el formulario de creación de proyecto, campo COD",
    msg: "Es importante que tu proyecto pueda tener una identificación, por lo tanto puedes crear un código que sirva como identificador principal ejemplo: PRY001",
  },
  {
    id: 12,
    location: "En el formulario de creación de proyecto, campo NOMBRE",
    msg: "Asigna un nombre a tu proyecto, que sea descriptivo y que te permita diferenciarlo de los demás.",
  },
  {
    id: 13,
    location: "En el formulario de creación de proyecto, campo METODOLOGÍA",
    msg: "Elige la metodología que más te convenga, podrás seleccionar herramienta gantt o kanban para su planeación y ejecución.",
  },
  {
    id: 14,
    location: "En el formulario de creación de proyecto, campo DESCRIPCIÓN",
    msg: "Aquí podrás tener la guía principal de la caracterización de tu proyecto, por lo tanto, puedes contemplar lo que te será muy útil en este campo descriptivo y que te permitirá lograr el alcance que quieres planear.",
  },
  {
    id: 15,
    location: "En el formulario de creación de proyecto, campo TIPO",
    msg: "Selecciona la tipología de proyectos, te será muy útil a la hora de crear informes",
  },
  {
    id: 16,
    location: "En el formulario de creación de proyecto, campo PAÍS",
    msg: "Selecciona le territorio don de vas a ejecutar tu proyecto",
  },
  {
    id: 17,
    location: "En el formulario de creación de proyecto, campo GERENTE",
    msg: "Selecciona el responsable de gestionar tu proyecto",
  },
  {
    id: 18,
    location: "En el formulario de creación de proyecto, campo JUSTIFICACIÓN",
    msg: "Por favor describe las razones principales que te motivaron para ejecutar este proyecto",
  },
  {
    id: 19,
    location: "En el formulario de creación de proyecto, campo ANTECEDETES",
    msg: "Por favor describe los trabajos previos que se tuvieron en cuenta para ejecutar este proyecto o los posibles problemas que quieres solucionar",
  },
  {
    id: 20,
    location:
      "En el formulario de creación de proyecto, campo OBJETIVO GENERAL",
    msg: "Cual es el fin principal que quieres conseguir con la ejecución de este proyecto?",
  },
  {
    id: 21,
    location:
      "En el formulario de creación de proyecto, campo OBJETIVOS ESPECÍFICOS",
    msg: "Cuales son las principales actividades que quieres ejecutar para lograr el objetivo principal de este proyecto?",
  },
  {
    id: 22,
    location: "En proyecto tradicional, título entregables",
    msg: "Aquí podrás crear los principales entregables de tu proyecto, ten en cuenta que para lograr cada entrega, deberás crear el plan de trabajo en el Gantt",
  },
  {
    id: 23,
    location: "En proyecto ágil, título historias de usuario",
    msg: "Aquí podrás crear las principales características o deseables que te permitirán lograr el objetivo de tu proyecto",
  },
  {
    id: 24,
    location: "En el centro de proyectos título EN CURSO",
    msg: "Aquí podrás encontrar tus proyectos por inciar o en curso. Basta con darle clic al nombre del proyecto, o a la pestaña de opciones que lo acompaña",
  },
  {
    id: 25,
    location: "En el centro de proyectos título COMPLETADOS",
    msg: "Aquí podrás ver los proyectos que tienen todas sus actividades terminadas. En caso de que alguna de ellas nuevamente quede en ejecución, automáticamente tu proyecto estará abierto y en la vista En curso",
  },
  {
    id: 26,
    location: "Textbox Buscar en el centro de proyectos",
    msg: "Digita parte del nombre de tu proyecto y te ayudaremos a encontrarlo",
  },
  {
    id: 27,
    location:
      "En el centro de proyectos, en la pestaña de opciones dle proyecto: Ver Hoja de Vida",
    msg: "Aquí podras ver un informe ejecutivo y consolidado del estado de salud de tu proyecto",
  },
  {
    id: 28,
    location: "Botón crear tablero Kanban",
    msg: "Aquí podrás registrar las actividades que no quieres perder de vista y que no hacen parte de un proyecto, crea tableros que te permitan identificar actividades tales como: regulatorias, rutinarias, de control interno, de la operación en general, etc",
  },
  {
    id: 29,
    location: "título Tareas asignadas en el calendario",
    msg: "Esta es tu agenda digital, que te permitirá ver en el calendario todo que tienes por ejecutar o que ha sido ejecutado,  ya sea en proyectos tradicionales, ágiles o en tu día a día.",
  },
  {
    id: 30,
    location: "título proyectos en el calendario",
    msg: "En este espacio podrás encontrar tus actividades organizadas por proyectos o que no hacen parte de ellos. Podrás ubicar fácilmente las actividades en ejecución, por inciar, destacadas o terminadas.",
  },
  {
    id: 31,
    location: "Textbox Buscar en vista proyectos dentro del calendario",
    msg: "Digita parte del nombre de tu actividad y te ayudaremos a encontrarla",
  },
  {
    id: 32,
    location: "Formulario creación de proyecto, campo Metodología (Ayuda)",
    msg: "¿No sabes cuál metodología elegir?, descúbrelo realizando nuestro test, solo haz clic en el signo de pregunta.",
  },
  {
    id: 33,
    location: "Formulario creación de proyecto, campo ScrumMaster",
    msg: "Selecciona el Scrum Master para tu proyecto.",
  },
  {
    id: 34,
    location: "Formulario editar tarea ágil campo horas por ejecutar",
    msg: "Es la cantidad de horas estimada para ejecutar esta actividad.",
  },
  {
    id: 35,
    location: "PlanningBoard Lane-header alert fecha maxima",
    msg: "Una de tus actividades tiene fecha de finalización posterior a la fecha de finalización del Sprint",
  },
  {
    id: 36,
    location: "PlanningBoard Lane-header alert fecha minima",
    msg: "Una de tus actividades tiene fecha de inicio anterior a la fecha de inicio del Sprint",
  },
  {
    id: 37,
    location: "PlanningBoard Lane-header alert fecha minima y alert fecha maxima",
    msg: "Una de tus actividades tiene fecha de inicio y finalización fuera del rango del Sprint",
  },
  {
    id: 38,
    location: "título Tareas asignadas en el calendario",
    msg: "Este es tu calendario digital, que te permitirá ver todo que tienes por ejecutar o que ha sido ejecutado,  ya sea en proyectos tradicionales, ágiles o en tu día a día.",
  },
  {
    id: 39,
    location: "Botón boards",
    msg: "Aquí podrás ver información resumida de tu organización, de los proyectos que estas ejecutando y el uso de tus recursos.",
  },
  {
    id: 40,
    location: "Modal EdiTask",
    msg: "Es el valor de complejidad de la actividad de acuerdo con el criterio del Product Owner en una escala del 1 al 100.",
  },
  {
    id: 41,
    location: "Top-Bar icon-MasServiciosPAS",
    msg: "Project Advisory Services: Servicios de consultoria en Gerencia de Proyectos",
  },
  {
    id: 42,
    location: "sideBar",
    msg: "¿Necesitas Ayuda?",
  },
  {
    id: 43,
    location: "PlanningBoard Lane-header alert excedio fechas",
    msg: "La fecha de inicio o fin de esta actividad excede las fechas límites del Sprint ",
  },
  {
    id:44,
    location: "Hv Agile project's indicator",
    msg:"Criterio de desviación basado en la diferencia entre el % Planeado y % Real"
  },
  {
    id:45,
    location: "Module-Risks-Icon",
    msg:"Aquí podras gestionar tus riesgos"
  },
  {
    id:46,
    location: "RiskToolTipkOne",
    msg:"Esta es una vista simple donde puedes crear los riesgos de tus proyectos"
  },
  {
    id:47,
    location: "RiskToolTipTwo",
    msg:"Esta es una vista compacta donde puedes ver y crear tus riesgos rápidamente"
  },
  {
    id:48,
    location: "RiskFormToolTipInputOne",
    msg:"Este es el estado inicial del riesgo"
  },
  {
    id:49,
    location: "RiskFormToolTipInputTwo",
    msg:"Selecciona la persona encargada de identificar este riesgo"
  },
  {
    id:50,
    location: "RiskFormToolTipInputThree",
    msg:"Selecciona el área que se verá afectada por este riesgo"
  },
  {
    id:51,
    location: "RiskTableDescriptionImpacto MuyAlto",
    msg:"Consecuencias catastróficas con impacto significativo y prolongado."
  },
  {
    id:52,
    location: "RiskTableDescriptionImpacto Alto",
    msg:"Consecuencias graves con impacto considerable."
  },
  {
    id:53,
    location: "RiskTableDescriptionImpacto Medio",
    msg:"Consecuencias moderadas con impacto notable."
  },
  {
    id:54,
    location: "RiskTableDescriptionImpacto Bajo",
    msg:"Consecuencias menores con impacto limitado."
  },
  {
    id:55,
    location: "RiskTableDescriptionImpacto MuyBajo",
    msg:"Consecuencias insignificantes con impacto muy limitado."
  },
  {
    id:56,
    location: "RiskTableDescriptionOcurrencia MuyProbable",
    msg:"Alta probabilidad de que el evento ocurra en el corto plazo. Es casi seguro que sucederá."
  },
  {
    id:57,
    location: "RiskTableDescriptionOcurrencia Probable",
    msg:"Probabilidad significativa de que el evento ocurra en el corto o mediano plazo. Es muy posible que suceda."
  },
  {
    id:58,
    location: "RiskTableDescriptionOcurrencia Posible",
    msg:"Existe una probabilidad moderada de que el evento ocurra en el mediano plazo. Podría suceder."
  },
  {
    id:59,
    location: "RiskTableDescriptionOcurrencia Improbable",
    msg:"Baja probabilidad de que el evento ocurra en el mediano o largo plazo. Es poco probable que suceda."
  },
  {
    id:60,
    location: "RiskTableDescriptionOcurrencia MuyImprobable",
    msg:"Muy baja probabilidad de que el evento ocurra en el largo plazo. Es altamente improbable que suceda."
  },
  {
    id:61,
    location: "RiskDateToolTip",
    msg:"Si no actualizas la fecha, se guardará la fecha que ya estaba registrada."
  }
];
