import {
    FETCH_USERS_ACCESS,
    FETCH_USERS_ACCESS_SUCCESS,
  } from "./accessTypes";

  const initial = {
    loading: false,
    access:[],
    error: "",
  };
  
  const accessReducer = (state = initial, action) => {
    switch (action.type) {
        case FETCH_USERS_ACCESS:
            return {
            loading: true,
            access: [],
            error:""
            };
   
     case FETCH_USERS_ACCESS_SUCCESS:
        return {
        loading:false,
        access: action.payload,
        error:""
        };
      
      default:
        return state;
    }
  };
  
  export default accessReducer;