import React from "react";
import {
 /*  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row, */
  Tab,
  Tabs,
} from "react-bootstrap";
import SuperTable from "./SuperTable";
import { useTranslation } from "react-i18next";

const HvActivitiesResume = (props) => {
  const [t,i18n] =useTranslation("schedule")
  const { data } = props;

  const columns = [
    {
      caption : "N°",
      selector: "Posicion",
      width: 50,
    },
    {
      caption: t("schedule_modal_resources_tablecolumn_activity"),
      selector: "NombreActividad",
      width: 420,
    },
    // {
    //   caption: "WBS",
    //   selector: "WBS",
    // },
    {
      caption: t("hvproject_owners"),
      selector: "Responsables",
    },
    {
      caption: t("hvproject_start"),
      selector: "FechaInicioProg",
      width: 100,
    },
    {
      caption: t("hvproject_end"),
      selector: "FechaFinProg",
      width: 100,
    },
    {
      caption: t("schedule_modal_resources_tablecolumn_advanced"),
      selector: "PctCompletado",
      width: 70,
      customRender: (row) => <span>{row.data.PctCompletado}%</span>,
    },
  ];

  return (
    <Tabs defaultActiveKey="First" className="border-bottom">
      <Tab eventKey="First" title={t("hvproject_summaryactivities_button_inprogress")}>
        <div className="mt-2 pt-3">
          <SuperTable
            title_search = {props.title_search}
            data={data?.filter((item) => item.estado === "En curso")}
            columns={columns}
          />
        </div>
      </Tab>
      <Tab eventKey="Second" title={t("hvproject_summaryactivities_button_next")}>
        <div className="mt-2 pt-3">
          <SuperTable
            data={data?.filter((item) => item.estado === "Sin iniciar")}
            columns={columns}
          />
        </div>
      </Tab>
      <Tab eventKey="Third" title={t("hvproject_summaryactivities_button_finished")}>
        <div className="mt-2 pt-3">
          <SuperTable
            data={data?.filter((item) => item.estado === "Terminadas")}
            columns={columns}
          />
        </div>
      </Tab>
      <Tab eventKey="Fourth" title={t("hvproject_summaryactivities_button_overdue")}>
        <div className="mt-2 pt-3">
          <SuperTable
            data={data?.filter((item) => item.estado === "Atrasadas")}
            columns={columns}
          />
        </div>
      </Tab>
      <Tab eventKey="Fifth" title={t("hvproject_summaryactivities_button_milestone")}>
        <div className="mt-2 pt-3">
          <SuperTable
            data={data?.filter((item) => item.estado === "Hitos")}
            columns={columns}
          />
        </div>
      </Tab>
      {/* <Tab eventKey="Sixth" title="Entregables">
        <div className="mt-2 pt-3">
          <SuperTable
            data={data?.filter((item) => item.estado === "Entregables")}
            columns={columns}
          />
        </div>
      </Tab> */}
    </Tabs>
  );
};

export default HvActivitiesResume;
