import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import {
  deleteDataScrum,
  saveDataScrum,
  updateDataScrum,
  updateLanes,
} from "../../helpers";
import swal from "sweetalert";
import moment from "moment";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBacklog } from "../../redux";
import { selectTooltip, selectTooltip_en } from "../../utils/selectTooltip";
import { useTranslation } from "react-i18next";

export const AddActivity = (props) => {
  const [t,i18n] =useTranslation("planning")
  const rol= window.localStorage.getItem("rol")
  return (
    <>
    {(rol == "Miembro de Equipo" || rol == "Ejecutivo")? <></> : 
    <button
      className="btn btn-outline-primary btn-block text-left mt-3 mb-5"
      onClick={props.onClick}
    >
      <i className="fa fa-plus mr-2"></i>{t("planningboard_productbacklog_button_additem")}
    </button>
    }
    </>
  );
};

export const NewCard = (props) => {
  const [t,i18n] =useTranslation("planning")
  const activityName = useRef();
  return (
    <div className="card border-primary mt-3 mb-5">
      <div className="card-body p-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(props);
            console.log("POST new card");
            let cardData = {
              sprintId: props.uid,
              // sprintId: props.backlogUID,
              columnId: props.laneId,
              title: activityName.current.value,
              isKanban: true,
            };
            saveDataScrum("agile-cards-in-sprints", cardData)
              .then((res) => {
                
                props.onAdd(res);
              })
              .catch((error) => {
                console.log(error);
                swal("¡Ups!", "Hubo un error al crear la actividad", "error");
                props.onCancel();
              });
          }}
        >
          <div className="form-group mb-2">
            {/* <label>Agregar tarea</label> */}
            <input
              className="form-control"
              autoFocus
              placeholder={t("planningboard_productbacklog_button_nameitem")}
              ref={activityName}
              required
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={props.onCancel}
              className="btn btn-outline-danger btn-sm mr-2"
            >
              {t("planningboard_productbacklog_button_cancel")}
            </button>
            <button type="submit" className="btn btn-success btn-sm">
              {t("planningboard_productbacklog_button_additask")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const AddSprintButton = (props) => {
  const [t,i18n] =useTranslation("planning")
  const rol = window.localStorage.getItem("rol")
  return (
    <>
    {(rol == "Miembro de Equipo" || rol == "Ejecutivo")? <></> : 
    <button
      id="btn-add-sprint"
      onClick={props.onClick}
      className="btn btn-primary ml-3 shadow-lg mr-3"
      style={{ width: "250px", marginTop: "15px" }}
    >
      {t("planningboard_hu_tablecolumn_column_new")}
    </button>
    }
    </>
  );
};

export const NewSprint = (props) => {
  const newName = useRef();
  const saveColumn = () => {
    let lanes = document.querySelectorAll(
      "#pwc-product-backlog-kanban .react-trello-lane"
    ).length;
    console.log("POST new lane");
    let laneData = {
      SprintId: props.uid,
      title: newName.current.value,
      pos: lanes,
    };
    if(laneData.title === "Product Backlog"){
      swal("¡Ups!Nombre reservado", "Por favor cambie el nombre de la nueva columna", "error");
    }else {
    saveDataScrum("agile-kanban-lanes", laneData)
      .then((res) => {
       
        props.onAdd(res);
      })
      .catch((error) => {
        swal("¡Ups!", "Hubo un error al crear la columna", "error");
        props.onCancel();
      });
    }
  };
  
  return (
    <div
      className="card shadow-lg mt-3 ml-3 bg-light"
      style={{ minWidth: "280px" }}
    >
      <div className="card-body p-2">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveColumn();
            // props.onAdd({
            //   title: newName.current.value,
            //   metadata: {
            //     progress: 0,
            //   },
            //   update: true,
            // });
          }}
        >
          <div className="form-group mb-2">
            {/* <label>Agregar tarea</label> */}
            <input
              className="form-control"
              autoFocus
              placeholder="Nombre de la columna."
              ref={newName}
              required
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              onClick={props.onCancel}
              className="btn btn-outline-secondary btn-sm mr-2"
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              Añadir columna
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const LaneHeader = (props) => {
  const [t,i18n] =useTranslation("planning")
  const LaneFinished = useCallback((cards) => {
    let show = false;
    if (cards.length > 0) {
      !cards.find((card) => card.metadata.progress < 100) && (show = true);
    }
    return show ? (
      <i
        title="Completado"
        className="ml-2 fa fa-check-circle text-success"
      ></i>
    ) : (
      ""
    );
  }, []);

  const LaneHeaderTooltip = useCallback(() => {
    switch (props.title) {
      case "To Do":
        return (t("planningboard_lenguaje") == "es")?selectTooltip(1) : selectTooltip_en(1);
      case "Doing":
        return (t("planningboard_lenguaje") == "es")?selectTooltip(2) : selectTooltip_en(2);
      case "Done":
        return (t("planningboard_lenguaje") == "es")? selectTooltip(3) : selectTooltip_en(3);
      default:
        return null;
    }
  }, []);
  const rol = window.localStorage.getItem("rol")
  return (
    <div className="d-flex justify-content-between" 
      style={{ height: "45px" }}
    >
      <div>
        <div className="kanban-title-col"
         onClick={() =>{
          if(props.title === "To Do" || props.title === "Doing" || props.title === "Done" ){
              return
          }else {
            props.onLaneHeaderClick(props) 
          }}}
        > 
        {(props.title === "To Do" || props.title === "Doing" || props.title === "Done")? "": <i className="fa fa-edit mr-1"></i>}
        <h5 className="mb-0 text-dark kanban-title-col ">
          <span data-tip={LaneHeaderTooltip()}>{props.title}</span>
          {LaneFinished(props.cards)}
        </h5>
        </div> 
        <small className="text-muted">
          {props.cards.length}{" "}
          {props.cards.length === 1 ? "item" : "items"}
        </small>
      </div>
      {props.title != "Product Backlog" && props.cards.length === 0 && rol != "Miembro de Equipo" && (
        <button
          onClick={() => {
            let laneData = { laneId: props.id };
            deleteDataScrum(`agile-kanban-lanes/${props.id}`, laneData)
              .then((res) => {
                
                props.actions.removeLane(laneData);
              })
              .catch((error) => {
                console.log(error);
                swal("¡Ups!", "Hubo un error al borrar el Sprint", "error");
                // props.onCancel();
              });
          }}
          className="btn btn-danger btn-sm"
          disabled={(rol == "Ejecutivo")? true : false}
          style={{
            float: "right",
            position: "absolute",
            right: 0,
            marginRight: "12px",
          }}
        >
          <i className="fa fa-trash"></i>
        </button>
      )}
    </div>
  );
};

const getInitialLetters = (name) => {
  let words = name.split(" ");
  let result = "";
  for (let i = 0; i < words.length && i < 2; i++) {
    result += words[i].charAt(0);
  }
  return result;
};

const printInitialsName = (resources,t) => {
  
  if (resources?.length > 0) {
    return (
      <span className="small" title={t("planningboard_tooltip_card_7")}>
        <i className="fa fa-user text-muted mr-1"></i>
        {resources.map((resource, index) => {
          return index < 2 ? (
            <span key={index} className="badge bg-info text-white mr-1">
              {getInitialLetters(resource.name)}
            </span>
          ) : (
            ""
          );
        })}
        {resources?.length > 2 ? "+" : null}
      </span>
    );
  }
  return null;
};

export const CardBacklog = (props) => {
  const [t,i18n] =useTranslation("planning")
  const rol = window.localStorage.getItem("rol")
  const scrum = useSelector((state) => state.scrum);
  const dispatch = useDispatch();

  const handleDeleteCard = (event) => {
    event.stopPropagation();
    //
    swal({
      title: t("planningboard_button_delete_1"),
      text: t("planningboard_button_delete_2"),
      icon: "warning",
      buttons: true,
      buttons: [t("modal_creationactivities_deletefile_3"), t("modal_creationactivities_deletefile_4")],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.onDelete();
      }
    });
    //
  };
  const handleToggleFeatured = (event, card) => {
    event.stopPropagation();
    let dummie = { ...card };
    dummie.metadata.Destacada = !card.metadata.Destacada;
    updateDataScrum(`agile-cards/${card.id}`, dummie)
      .then((res) => {
        let newBoard = updateLanes(scrum, dummie);
        dispatch(updateBacklog(newBoard));
      })
      .catch((err) =>
        swal(
          "¡Ups!",
          "Hubo un error al actualizar los datos la actividad, por favor refresca la página.",
          "error"
        )
      );
  };
  return (
    <div
      id={`card-${props.id}`}
      className="card mb-3 shadow-sm"
      style={{ width: "250px", cursor: "pointer" }}
      onClick={props.onClick}
    >
      <div
        className="progress"
        // title={`${props.metadata.progress}% Completado`}
        style={{
          height: "8px",
          cursor: "move",
          borderRadius: "4px 4px 0 0",
        }}
      >
        <div
          className={`progress-bar ${
            props.metadata?.progress < 100 ? "bg-info" : "bg-success"
          }`}
          role="progressbar"
          style={{ width: `${props.metadata?.progress}%` }}
        ></div>
      </div>
      <div className="card-body p-2">
        <div className="d-flex">
          <div className="d-flex justify-content-between w-100 align-items-start">
            <p className="m-0" style={{ whiteSpace: "break-spaces" }}>
              <small>
                <i
                  title="Destacar"
                  className={`fa fa-star mr-1 ${
                    props.metadata.Destacada ? "text-warning" : "icon-opa"
                  }`}
                  onClick={(e) => handleToggleFeatured(e, props)}
                ></i>
              </small>
              {props.title}
            </p>
            {(rol == "Miembro de Equipo")? <></> : 
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={handleDeleteCard}
              disabled={(rol == "Ejecutivo")? true : false}
            >
              <i className="fa fa-trash"></i>
            </button>
             }
          </div>
        </div>
        {/* <hr className={`${!props.metadata.progress ? "d-none" : "my-2"}`} />
        <div
          className={`justify-content-between ${
            !props.metadata.progress ? "d-none" : "d-flex"
          }`}
        > */}
        <hr className="my-2" />
        <div className="d-flex justify-content-between">
          <div style={{ display: "inline-flex" }}>
            <span
              className="small bg-light rounded mr-1 font-weight-bold"
              title={`${t("planningboard_tooltip_card_1")}: ${props.metadata.poValue || 0}`}
            >
              {props.metadata.poValue || 0}
            </span>
            {props.metadata.HistoriaUsuarioId && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_2")}
              >
                <i className="fa fa-scroll"></i>
              </span>
            )}
             {props.metadata.UltAvance && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_3")}
              >
                <i className="fa fa-sticky-note"></i>
              </span>
            )}
            {props.metadata.tags?.length > 0 && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_4")}
              >
                <i className="fa fa-tags"></i>
              </span>
            )}
            {props.metadata.TieneAdjuntos == 1  && (
              <span
                className="small text-muted mr-1 rounded"
                title={t("planningboard_tooltip_card_5")}
              >
                <i className="fa fa-paperclip"></i>
              </span>
            )}
          </div>
          {/* <span className="small" title="% completado">
            <i
              className={`fa fa-check-circle mr-1 ${
                props.metadata.progress < 100 ? "text-muted" : "text-success"
              }`}
            ></i>
            {props.metadata.progress || 0}%
          </span> */}
          {props.metadata.FechaVencimiento && (
            <span className="small" title={t("planningboard_tooltip_card_6")}>
              <i className="fa fa-calendar-alt text-muted mr-1"></i>
              {moment(props.metadata.FechaVencimiento)
                .add(5, "hours")
                .format("DD/MM/YYYY")}
            </span>
          )}
          {/* {props.metadata.resources > 0 && (
            <span className="small" title="Asignaciones">
              <i className="fa fa-user text-muted mr-2"></i>
              <span className="badge bg-info text-white">DM</span>
            </span>
          )} */}
          {printInitialsName(props.metadata.resources,t)}
        </div>
      </div>
    </div>
  );
};
