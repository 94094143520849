import styled from "styled-components";

export const StyledTabs = styled.div`
  .nav-item {
    font-weight: bold;
    a {
      color: ${(props) => props.theme.muted};
      padding: 0.5rem 1rem;
      border-top: 0.3rem solid transparent;
      border-bottom: 0.3rem solid transparent;
      transition: all ease 0.2s;
    }
    .active {
      color: ${(props) => props.theme.secondary};
      border-bottom: 0.3rem solid;
    }
  }
`;
