import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SelectInModal = (props) => {
  const [t,i18n] =useTranslation("planning")

  const [searchWord, setSearchWord] = useState("");

  const searchResults = useMemo(
    () => props.items?.filter((item) => item.label?.includes(searchWord)),
    [searchWord]
  );

  const _isSelected = (id) => {
    if (props.value === id) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // reset search when close
    if (!props.show) setSearchWord("");
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      centered
      onHide={() => {
        props.onClose();
      }}
      contentClassName="border-0 shadow-lg"
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Modal.Header>
          <Modal.Title className="h6 text-info">{props.title}</Modal.Title>
          <Button onClick={props.onClose} variant="light">X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  placeholder={t("modal_creationactivities_text11_2")}
                  value={searchWord}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <ListGroup>
            {searchResults?.length ? (
              <ListGroup.Item
                className="py-2 px-3"
                action
                onClick={() => {
                  props.onChange({
                    target: {
                      name: props.name,
                      value: ""
                    }
                  });
                  props.onClose();
                }}
              >
                {props.noneLabel}
              </ListGroup.Item>
            ) : (
              <>
                <div className="h1 mb-3 text-muted text-center">
                  <i className="fa fa-folder-open"></i>
                </div>
                <p className="text-center">{t("modal_creationactivities_text11_3")}</p>
              </>
            )}
            {searchResults?.map((item, index) => {
              return (
                <ListGroup.Item
                  key={item.value}
                  className={`py-2 px-3 ${
                    _isSelected(item.value) && "bg-light"
                  }`}
                  action
                  onClick={() => {
                    props.onChange({
                      target: {
                        name: props.name,
                        value: item.value
                      }
                    });
                    props.onClose();
                  }}
                >
                  {_isSelected(item.value) && (
                    <small>
                      <i className="fa fa-check text-info mr-2"></i>
                    </small>
                  )}
                  {item.label}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onClose} variant="outline-dark" size="sm">
            {t("modal_creationactivities_text11_4")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default React.memo(SelectInModal);
