import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import ProductBurndown from "./ProductBurndown";
import SprintBurndown from "./SprintBurndown";
import SuperTable from "./SuperTable";
import { API_ROUTE, getData, headersRequest } from "../helpers";
import ErrorMsg from "../components/ErrorMsg";
import { useTranslation } from "react-i18next";
import axios from "axios";
const columns = [
  {
    caption: "Actividad",
    selector: "task",
    minWidth: 400,
  },
  {
    caption: "WBS",
    selector: "wbs",
  },
  {
    caption: "Responsable",
    selector: "responsible",
  },
  {
    caption: "Inicio",
    selector: "start",
  },
  {
    caption: "Fin",
    selector: "end",
  },
  {
    caption: "Avance",
    selector: "progress",
  },
];
const SprintsResume = (props) => {

  const ID_ORGANIZACION = "1727EA57-9E8F-44CE-938B-8F377491F135"  // Produccion
  /* const ID_ORGANIZACION = "8CAADED8-A836-4CB3-8822-878440CC4E30" */ //desarrollo

  const [t,i18n] =useTranslation("planning")
  const [sprintGeneralData, setSprintGeneralData] = useState({ empty: true });
  const [burndownChart, setBurndownChart] = useState([]);
  const [storyData , setStoryData] = useState([])
  const [showActivitiesByHistoryBySprint, setShowActivitiesByHistoryBySprint] = useState(false);
  const [storieTitle, setStorietitle] = useState();
  const [storyActivitiesBySprint, setStoryActivitiesBySprint] = useState([])

  const [saveIdSprint, setSaveIdSprint] = useState()

  const handleCloseActivities = () => {
    setShowActivitiesByHistoryBySprint(false)
  };

  const getHistoryActivitySprint = async (IdHistoriaUsuario,idSprint) => {
      try {
        const res = await axios.get(`${API_ROUTE}stories-activities/${IdHistoriaUsuario}`,headersRequest()
        );
        const  resFilterBySprint = res.data?.filter((act) => {
          return act.SprintId == idSprint
        })

        setStoryActivitiesBySprint(resFilterBySprint)
      }
      catch (error) {
        console.error(error)
      }
    }

  const handleActivitiesByHistory = (storyinfo, idSprint) => {
    setStorietitle(storyinfo.HistoriaUsuario)
    getHistoryActivitySprint(storyinfo.IdHistoriaUsuario,idSprint)
    setShowActivitiesByHistoryBySprint(true)
  }
  //   const { data } = props.data;
  const data = [
    {
      task: "Elit excepteur consequat magna id.",
      wbs: "424",
      responsible: "responsible",
      start: "start",
      end: "end",
      progress: "progress",
    },
    {
      task: "Do qui et proident deserunt do nostrud ariatur.",
      wbs: "262",
      responsible: "responsible",
      start: "start",
      end: "end",
      progress: "progress",
    },
    {
      task: "Non veniam aliquip incididunt velit nosequat eiusmod.",
      wbs: "416",
      responsible: "responsible",
      start: "start",
      end: "end",
      progress: "progress",
    },
    {
      task: "Proident magna veniam nostrud is ea aliqua.",
      wbs: "28",
      responsible: "responsible",
      start: "start",
      end: "end",
      progress: "progress",
    },
  ];

  const getSprintData = (sprintId) => {
    Promise.all([
      getData(`hv-project-agile-sprint-data/${sprintId}`, []),
      getData(`hv-project-agile-burndown-by-sprint/${sprintId}`, []),
      getData(`hv-project-agile-stories-data/${sprintId}`, []),
    ]).then((values) => {
      setSprintGeneralData(values[0]);
      setBurndownChart(values[1]);
      setStoryData(values[2])
    });
  };

  const idOrganization = window.localStorage.getItem("OID")

  const columnsActivitiesHistorySprint = [
    {
      selector: "NombreActividad",
      caption: t("planningboard_hu_tablecolumn_activity"),
       
      customRender: (row) => {
        return (
          <span>{row.data.NombreActividad}</span>
        )
      }
    },
    {
      selector: "FechaInicio",
      caption: t("planningboard_hu_tablecolumn_start"),
      customRender: (row) => {
        return (<>
          <span>{row.data.FechaInicio}</span>
        </>
        )
      },
      align: "center",
      width: 100,
    },
    {
      selector: "FechaFin",
      caption: t("planningboard_hu_tablecolumn_end"),
      customRender: (row) => {
        return (<>
          <span>{row.data.FechaFin}</span>
        </>
        )
      },
      align: "center",
      width: 100,
    },
    {
      selector: "TotalKM",
      caption: "Km Planeados",
      customRender: (row) => {
        return (<>
          <span>{row.data.TotalKM}</span>
        </>
        )
      },
      align: "center",
      width: 110,
    },
    {
      selector: "TotalKMEjecutados",
      caption: "Km Ejecutados",
      customRender: (row) => {
        return (<>
          <span>{row.data.TotalKMEjecutados}</span>
        </>
        )
      },
      align: "center",
      width: 110,
    },
    {
      selector: "Desviacion",
      caption: "Desviación",
      customRender: (row) => {
          const taskDesv = (row.data.TotalKMEjecutados - row.data.TotalKM).toFixed(2)
        return (<>
          <span className={( taskDesv < 0 )? "text-secondary" : "text-success"} >{taskDesv}</span>
        </>
        )
      },
      align: "center",
      width: 110,
    },
    {
      selector: "Avance",
      caption: t("planningboard_hu_tablecolumn_advanced"),
      customRender: (row) => {
        return (<>
          {(row.data.Avance === "Done") && <>
            <span className="mr-2">
              <i className="fa fa-circle text-success"></i> {row.data.Avance}
            </span>
          </>}
          {(row.data.Avance === "Doing") && <>
            <span className="mr-2">
              <i className="fa fa-circle text-info"></i> {row.data.Avance}
            </span>
          </>}
          {(row.data.Avance === "To Do") && <>
            <span className="mr-2">
              <i className="fa fa-circle text-light"></i> {row.data.Avance}
            </span>
          </>}
        </>
        )
      },
      align: "center",
      width: 100,
    },
    {
      selector: "FechaModificacion",
      caption: t("planningboard_hu_tablecolumn_last"),
      customRender: (row) => {
        return (<>
          <span>{row.data.FechaModificado}</span>
        </>
        )
      },
      align: "center",
      width: 120,
    },
  ];
  
  return (
    <div>
      <Form.Group>
        {/* <Form.Label>Seleccionar sprint</Form.Label> */}
        <Form.Control
          as="select"
          name="Sprint"
          onChange={(e) => { 
            getSprintData(e.target.value)
            setSaveIdSprint(e.target.value)
          }
            }
        >
          <option>{t("hvagile_sprint_text1")}</option>
          {props.sprintsList.length > 0 &&
            props.sprintsList.map((sprintItem) => {
              return (
                <option key={sprintItem.Id} value={sprintItem.Id}>
                  {sprintItem.Sprint} {sprintItem.Alias? `- ${sprintItem.Alias}`:""}
                </option>
              );
            })}
        </Form.Control>
      </Form.Group>

      {!sprintGeneralData.empty ? (
        <>
          <div className="d-flex justify-content-between mb-3 pb-2 border-bottom">
            <small>
              <i className="fa fa-play mr-2 text-secondary"></i>{t("hvagile_start")}:{" "}
              {sprintGeneralData.FechaInicio}
            </small>
            {/* <small className="text-primary">14 días</small> */}
            <small>
              <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
              {t("hvagile_end")}: {sprintGeneralData.FechaFin}
            </small>
          </div>
          <Row>
            <Col className="mb-3 mb-md-0">
              <ListGroup className="mb-2">
                <ListGroup.Item>
                  <i className="fa fa-tasks text-primary mr-2"></i>
                  {sprintGeneralData.CantActividades}
                  <small>{t("hvagile_activities")}</small>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex">
                    <small>
                      <b className="bg-light rounded text-muted px-1">
                        {sprintGeneralData.SinIniciar}
                      </b>{" "}
                      ToDo
                    </small>
                    <small className="mx-2">
                      <b className="bg-info rounded text-white px-1">
                        {sprintGeneralData.EnCurso}
                      </b>{" "}
                      Doing
                    </small>
                    <small>
                      <b className="bg-success rounded text-white px-1">
                        {sprintGeneralData.Terminadas}
                      </b>{" "}
                      Done
                    </small>
                   {/*  <small>
                      <b style={{backgroundColor :"#fdce56"}} className=" rounded text-white px-1">
                        {sprintGeneralData.Otros}
                      </b>{" "}
                      Otros
                    </small> */}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fa fa-users text-primary mr-2"></i>
                  {sprintGeneralData.CantRecursos}
                  <small>{t("hvagile_chartburndown_conventionts_resources")}</small>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item>
                  <i className="fa fa-business-time text-info mr-2"></i>
                  {sprintGeneralData.TotalPuntos}
                  <small className="ml-1">{t("hvagile_chartburndown_conventionts_expected")}</small>
                </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fa fa-business-time text-success mr-2"></i>
                  {sprintGeneralData.VelocidadSprint}
                  <small  className="ml-1">{t("hvagile_chartburndown_conventionts_remaining")}</small>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={9}>
              <h6>Burndown Chart</h6>
              <SprintBurndown data={burndownChart} />
            </Col>
          </Row>
          {(idOrganization == ID_ORGANIZACION ) && <> 
          <div className="w-100">
            <Card className="mb-2">
            <Card.Body>
             {/*  <Card.Title>{t("hvagile_userstories_tittle")}</Card.Title> */}
              {/* {JSON.stringify(stories)} */}
              <Table bordered responsive size="sm">
                <thead>
                  <tr>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_history")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_activities")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tabletories_tablecolumn_start")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_end")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_advanced")}</th>
                    {(idOrganization != ID_ORGANIZACION) &&
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_score")}</th>
                    }
                    {(idOrganization == ID_ORGANIZACION) && <>
                      <th className="text-muted text-center">Km Meta Sprint</th>
                      <th className="text-muted text-center">Km Ejecutados Sprint</th>
                      <th className="text-muted text-center">Km Restantes Sprint</th>
                    </>
                    }
                  </tr>
                </thead>
                <tbody>
                  {storyData?.map((story) => {
                    const kmRestantes = (story?.TotalKMEjecutados - story?.TotalKM).toFixed(2)
                    const porceKm= ((story?.TotalKM  == 0 )? "" : ((story?.TotalKMEjecutados / story?.TotalKM  ) * 100).toFixed(0)) 
                    const porceNow = (idOrganization == ID_ORGANIZACION) ? porceKm : story.pctcompletado
                    const porceVariant = (idOrganization == ID_ORGANIZACION) ? ( porceKm == 100)? "success" : "info" : (story.pctcompletado === 100)? "success" : "info"
                    return (
                      <tr>
                        <td>
                          <a href="#" onClick={() => handleActivitiesByHistory(story, saveIdSprint )} title={story.HistoriaUsuario}>
                            {story.HistoriaUsuario?.slice(0, 50)}
                          </a>
                        </td>
                        <td className="text-center">{story.CantActividades}</td>
                        <td>{story.FechaInicio}</td>
                        <td>{story.FechaFin}</td>
                        <td>
                          <span className="d-flex align-items-center justify-content-between">
                            <ProgressBar
                              animated
                              /* variant = {(story.pctcompletado === 100)? "success" : "info"} */
                               /*  variant = {(story.pctcompletado === 100)? "success" : "info"}  */
                               /*  now={story.pctcompletado} 
                                */
                              variant= {porceVariant} 
                              now= {porceNow}
                              style={{ height: "10px", width: "calc(60%)" }}
                              className="mr-2"
                            />
                            
                            {
                              (idOrganization == ID_ORGANIZACION)? 
                              <small>{porceKm}%</small>
                              :
                              <small>{story.pctcompletado}</small>
                            }
                          </span>
                        </td>
                        {(idOrganization != ID_ORGANIZACION) &&
                         <td className="text-center">{story?.PuntosHistoria}</td>
                        }
                       
                        {(idOrganization == ID_ORGANIZACION) && <>
                        <td className="text-center">{story?.TotalKM}</td>
                        <td className="text-center">{story?.TotalKMEjecutados}</td>
                        <td className={(kmRestantes < 0)? "text-danger text-center": "text-success text-center"}>{kmRestantes}</td>
                        </>
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Modal
            show={showActivitiesByHistoryBySprint}
            onHide={handleCloseActivities}
            contentClassName="border-0 shadow-lg"
            centered
            size="xl"

          >
            <Modal.Header >
              <Modal.Title className="h5">{t("hvagile_userstories_list")} {storieTitle} </Modal.Title>
              <Button onClick={handleCloseActivities} variant="light">X</Button>
            </Modal.Header>
            <Modal.Body>
              <SuperTable
                noSearch
                columns={columnsActivitiesHistorySprint}
                data={storyActivitiesBySprint}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={handleCloseActivities}>
                {t("hvagile_button_close")}
              </Button>
            </Modal.Footer>
          </Modal>
          </div>
        </>}
        </>
      ) : (
        <ErrorMsg msg= {t("hvagile_sprint_text2")} />
      )}
    </div>
  );
};

export default SprintsResume;
