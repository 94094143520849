import { tooltips } from "./tooltips";
import {tooltips_en} from "./tooltips_en"

/* let lenguaje_user = window.localStorage.getItem("idioma")
console.log(lenguaje_user) */
/* let fileToUse= (lenguaje_user == "es")? tooltips : tooltips_en */

export const selectTooltip = (id) => {
  return tooltips?.filter((tooltip) => tooltip.id === id)[0].msg || null;
};

export const selectTooltip_en = (id) => {
  return tooltips_en?.filter((tooltip) => tooltip.id === id)[0].msg || null;
};