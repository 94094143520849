import React, { useEffect, useRef, useState } from 'react'
import { Gantt} from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import  { 
	gantLayout,
	setLightBoxSections,
	sectionForMilestones,
	sectionForProjects,
	configColumns,
	getGanttData,
	zoomConfig
} from "./helpersToGantt"
import { getData } from '../../data/data';
import { useTranslation } from "react-i18next";


var demo_tasks = {
	"data":[
		{"id":11, "text":"Project #1", "start_date":"28-03-2023", "duration":"11", "progress": 0.6, "open": true},
		{"id":1, "text":"Project #2", "start_date":"01-04-2023", "duration":"18", "progress": 0.4, "open": true},

		{"id":2, "text":"Task #1", "start_date":"02-04-2023", "duration":"8", "parent":"1", "progress":0.5, "open": true},
		{"id":3, "text":"Task #2", "start_date":"11-04-2023", "duration":"8", "parent":"1", "progress": 0.6, "open": true},
		{"id":4, "text":"Task #3", "start_date":"13-04-2023", "duration":"6", "parent":"1", "progress": 0.5, "open": true},
		{"id":5, "text":"Task #1.1", "start_date":"02-04-2023", "duration":"7", "parent":"2", "progress": 0.6, "open": true},
		{"id":6, "text":"Task #1.2", "start_date":"03-04-2023", "duration":"7", "parent":"2", "progress": 0.6, "open": true},
		{"id":7, "text":"Task #2.1", "start_date":"11-04-2023", "duration":"8", "parent":"3", "progress": 0.6, "open": true},
		{"id":8, "text":"Task #3.1", "start_date":"14-04-2023", "duration":"5", "parent":"4", "progress": 0.5, "open": true},
		{"id":9, "text":"Task #3.2", "start_date":"14-04-2023", "duration":"4", "parent":"4", "progress": 0.5, "open": true},
		{"id":10, "text":"Task #3.3", "start_date":"14-04-2023", "duration":"3", "parent":"4", "progress": 0.5, "open": true},
		
		{"id":12, "text":"Task #1", "start_date":"03-04-2023", "duration":"5", "parent":"11", "progress": 1, "open": true},
		{"id":13, "text":"Task #2", "start_date":"02-04-2023", "duration":"7", "parent":"11", "progress": 0.5, "open": true},
		{"id":14, "text":"Task #3", "start_date":"02-04-2023", "duration":"6", "parent":"11", "progress": 0.8, "open": true},
		{"id":15, "text":"Task #4", "start_date":"02-04-2023", "duration":"5", "parent":"11", "progress": 0.2, "open": true},
		{"id":16, "text":"Task #5", "start_date":"02-04-2023", "duration":"7", "parent":"11", "progress": 0, "open": true},

		{"id":17, "text":"Task #2.1", "start_date":"03-04-2023", "duration":"2", "parent":"13", "progress": 1, "open": true},
		{"id":18, "text":"Task #2.2", "start_date":"06-04-2023", "duration":"3", "parent":"13", "progress": 0.8, "open": true},
		{"id":19, "text":"Task #2.3", "start_date":"10-04-2023", "duration":"4", "parent":"13", "progress": 0.2, "open": true},
		{"id":20, "text":"Task #2.4", "start_date":"10-04-2023", "duration":"4", "parent":"13", "progress": 0, "open": true},
		{"id":21, "text":"Task #4.1", "start_date":"03-04-2023", "duration":"4", "parent":"15", "progress": 0.5, "open": true},
		{"id":22, "text":"Task #4.2", "start_date":"03-04-2023", "duration":"4", "parent":"15", "progress": 0.1, "open": true},
		{"id":23, "text":"Task #4.3", "start_date":"03-04-2023", "duration":"5", "parent":"15", "progress": 0, "open": true},
		{"id":24, "text":"Task #4.2", "start_date":"03-04-2023", "duration":"4", "parent":"15", "progress": 0.1, "open": true},
		{"id":25, "text":"Task #4.3", "start_date":"03-04-2023", "duration":"5", "parent":"15", "progress": 0, "open": true},
		{"id":26, "text":"Task #4.2", "start_date":"03-04-2023", "duration":"4", "parent":"15", "progress": 0.1, "open": true},
		{"id":27, "text":"Task #4.3", "start_date":"03-04-2023", "duration":"5", "parent":"15", "progress": 0, "open": true}
		
	],
	"links":[
		{"id":"1","source":"1","target":"2","type":"1"},
		{"id":"2","source":"2","target":"3","type":"0"},
		{"id":"3","source":"3","target":"4","type":"0"},
		{"id":"4","source":"2","target":"5","type":"2"},
		{"id":"5","source":"2","target":"6","type":"2"},
		{"id":"6","source":"3","target":"7","type":"2"},
		{"id":"7","source":"4","target":"8","type":"2"},
		{"id":"8","source":"4","target":"9","type":"2"},
		{"id":"9","source":"4","target":"10","type":"2"},
		{"id":"10","source":"11","target":"12","type":"1"},
		{"id":"11","source":"11","target":"13","type":"1"},
		{"id":"12","source":"11","target":"14","type":"1"},
		{"id":"13","source":"11","target":"15","type":"1"},
		{"id":"14","source":"11","target":"16","type":"1"},
		{"id":"15","source":"13","target":"17","type":"1"},
		{"id":"16","source":"17","target":"18","type":"0"},
		{"id":"17","source":"18","target":"19","type":"0"},
		{"id":"18","source":"19","target":"20","type":"0"},
		{"id":"19","source":"15","target":"21","type":"2"},
		{"id":"20","source":"15","target":"22","type":"2"},
		{"id":"21","source":"15","target":"23","type":"2"}
	]
};

function GanttTest({uid,showColumns,showDuration, btnsFns}) {
	const [ganttInstance ,setGanttInstance] = useState("")
	const [t, i18n] = useTranslation("schedule");
	const [rol ,setRol] = useState("")
	const [user_lg, setUser_lg] = useState("")


    let container = useRef();
    useEffect(() => {
		// instanciando la clase Gantt
        const gantt = Gantt.getGanttInstance();
		setGanttInstance(gantt)
		// obteniendo rol usuario
		setRol(window.localStorage.getItem("rol"));
		setUser_lg(window.localStorage.getItem("idioma"));
		//configuracion de layout (grid and tasklist)
        gantt.config.layout = gantLayout
		//configurando secciones del modal segun el type
		setLightBoxSections(gantt,t);
		sectionForMilestones(gantt)
		sectionForProjects(gantt)
		// configuracion de plugins 
		gantt.plugins({
			auto_scheduling: true,
			click_drag: true,
			critical_path: true,
			drag_timeline: true,
			fullscreen: true,
			grouping: true,
			keyboard_navigation: true,
			marker: true,
			multiselect: true,
			overlay: true,
			quick_info: false,
			tooltip: true,
			undo: true,
			export_api: true,
		  });
		// asignando el codigo del proyecto
		gantt.serverList("projectId", [uid]);
		
		// configuraciones adicionales 

		gantt.config.order_branch = false; //permite reordenar verticalmente las tareas dentro del mismo nivel de arbol
		gantt.config.bar_height = 25; // altura de las barras en el grid
		gantt.config.row_height = 40; // altura de las filas
		gantt.config.show_progress = true; // visualizar el progreso dentro de las barras de tareas
		gantt.config.resource_property = "owner_id";	// define la propiedad del objeto que almacena el id del recurso asociado con la tarea
		gantt.config.order_branch_free = false; // permite reordenar tareas dentro de todo el gantt
		gantt.config.drag_progress = true; // permite la posibilidad de cambiar el progreso desde el grid
		gantt.i18n.setLocale(user_lg); // usar el idioma para el gantt 
		/* gantt.config.autosize = false; */ // ojo
		gantt.config.auto_types = false; // cambia de tipo de forma automatica 
		gantt.config.keyboard_navigation_cells = true; // permite navegar con el teclado
		gantt.config.reorder_grid_columns = true; // permite reordenar las columnas
		gantt.config.smart_rendering =true // renderizar de manera inteligente
		gantt.config.show_task_cells  = true // habilita o desabilidad la visualizacion de los bordes 
		/* gantt.ext.fullscreen.getFullscreenElement = () => {
		  return document.getElementById("show-btns-gantt");
		}; */

		//traer data y cargarla
		getGanttData(uid,gantt)

		//texto lateral barras
		/* gantt.templates.rightside_text = function (start, end, task) {
			return getResourceNames(gantt.serverList("resourceList"), task.owner_id);
		} */ 
		  
    // Permitir reordenar verticamente dentro del mismo nivel
    /*   gantt.config.order_branch = "marker"; */
    /*  gantt.config.drag_progress = false; */
		//iniciando gantt

		//otras configuraciones 
		zoomConfig(gantt)
		gantt.ext.zoom.setLevel("quarter");
		btnsFns.zoomIn(gantt)
        gantt.init(container.current);
      /*   gantt.parse(demo_tasks); */
        return () => {
          gantt.destructor();
          /* container.current.innerHTML = ""; */
        };
    }, []);

	useEffect(()=>{

		//configuracion de columnas y render
		if(ganttInstance){
			configColumns(ganttInstance, t, showColumns, rol, showDuration) 
			ganttInstance.render()
		}
		
	  },[ganttInstance,showColumns , showDuration])


  return (
    <div ref={container} style={{width: "100%", height: "100%" }}></div>
  )
}

export default GanttTest