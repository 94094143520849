import DataGrid, { Column, SearchPanel } from "devextreme-react/data-grid";
import React from "react";

const SuperTableV2 = (props) => {
  return (
    <DataGrid
      dataSource={props.data}
      columnHidingEnabled={props.hideCols}
      noDataText="Sin datos."
      height={props.height || null}
      className={"table-ddl " + (props.className || "")}
      showBorders={false}
      showRowLines={false}
      showColumnLines={false}
      hoverStateEnabled={true}
      onRowClick={props.onRowClick || null}
      allowColumnReordering={(props.noGroup)? true : false}
    >
      {!props.noSearch && (
        <SearchPanel visible={true} width={250} placeholder={props.title_searchbox} />
      )}
      {props.columns.map((itmCol, index) => {
        return (
          <Column
            key={index}
            sortOrder={(index == 0 && props.sortAsc)? 'asc':undefined}
            dataField={itmCol.selector}
            caption={itmCol.caption}
            // width={itmCol.width}
            minWidth={itmCol.width}
            alignment={itmCol.align}
            headerCellRender={itmCol.headerCellRender? itmCol.headerCellRender :null}
            cellRender={(row) => {
              return (
                <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
              );
            }}
          />
        );
      })}
    </DataGrid>
  );
};

export default SuperTableV2;
