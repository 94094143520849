import React, { useState } from "react";
import { Scheduler } from "devextreme-react";
import moment from "moment";
import TasksModalEdit from "./TasksModalEdit";
import { useRef } from "react";
import { saveData } from "../helpers";

const TasksCalendar = (props) => {

  const { myTasks, currentDate, handleShow, setTaskEdit,showCompleted } = props;
  const compTask = myTasks.filter((task)=> task.PctCompletado === 100)
  const allTaskLessCompl = myTasks.filter((task)=> task.PctCompletado !== 100)
  const [saveInfo, setSaveInfo]= useState({})
  return (
    <>
    <Scheduler
      timeZone="America/Bogota"
      dataSource={showCompleted ? compTask : allTaskLessCompl}
      views={["month"]}
      defaultCurrentView="month"
      editing={false}
      defaultCurrentDate={currentDate}
      height={500}
      startDayHour={8}
      onAppointmentClick={(e) => {
        setSaveInfo(e.appointmentData)
        handleShow();
        /* setTaskEdit(e.appointmentData); */
      }}
      appointmentTemplate={(model) => {
        return (
          "<div class='act_" +
          model.targetedAppointmentData.Estado +
          "'>" +
          model.targetedAppointmentData.NombreActividad +
          "</div>"
        );
      }}
      appointmentTooltipTemplate={(model) => {
        moment.locale("es");
        return (
          "<div class='dx-tooltip-appointment-item'><div class='dx-tooltip-appointment-item-marker'><div class='dx-tooltip-appointment-item-marker-body act_" +
          model.targetedAppointmentData.Estado +
          "'></div></div>" +
          "<div class='dx-tooltip-appointment-item-content'><div class='dx-tooltip-appointment-item-content-subject'>" +
          model.targetedAppointmentData.NombreActividad +
          "</div><div class='dx-tooltip-appointment-item-content-date'>" +
          ` ${moment(model.targetedAppointmentData.FechaInicioProg).format(
            "D/MMMM HH:mm"
          )} - ${model.targetedAppointmentData.FechaFinProg}` +
          "</div></div></div>"
        );
      }}
    />
    <TasksModalEdit
        saveData={saveData}
        taskEditParent={saveInfo}
        setSaveInfo={setSaveInfo}
        show={props.show}
        handleClose={props.handleClose}
        getAllData={props.getAllData}
        excludedDates={props.excludedDates}
      />
    </>
  );
};

export default React.memo(TasksCalendar);
