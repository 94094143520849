import React from "react";

import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  ConstantLine,
  Label,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";

const sprintsData = [
  {
    date: "Planning",
    ptsRemaining: 90,
  },
  {
    date: "12 Abril",
    ptsRemaining: 50,
    velocity: 23,
  },
  {
    date: "13 Abril",
    ptsRemaining: 35,
    velocity: 35,
  },
  {
    date: "14 Abril",
    ptsRemaining: 10,
    velocity: 21,
  },
  {
    date: "15 Abril",
    ptsRemaining: 8,
    velocity: 26,
  },
  {
    date: "16 Abril",
    ptsRemaining: 6,
    velocity: 24,
  },
  {
    date: "17 Abril",
    ptsRemaining: 5,
    velocity: 24,
  },
  {
    date: "18 Abril",
    ptsRemaining: 4,
    velocity: 36,
  },
  {
    date: "19 Abril",
    ptsRemaining: 1,
    velocity: 36,
  },
  //   {
  //     sprint: 8,
  //     ptsRemaining: null,
  //     velocity: null,
  //   },
  //   {
  //     sprint: 9,
  //     ptsRemaining: null,
  //     velocity: null,
  //   },
  //   {
  //     sprint: 10,
  //     ptsRemaining: null,
  //     velocity: null,
  //   },
];

const SprintBurndown = (props) => {
  const [t,i18n] =useTranslation("planning")

  return (
    <Chart
      dataSource={props.data}
      //   palette="Harmony Light"
      style={{ height: "238px" }}
    >
      <CommonSeriesSettings argumentField="sprint" />
      <Series
        name={t("hvagile_chartburndown_conventionts_remaining")}
        valueField="ptsRemaining"
        axis="points"
        type="line"
        color="#29b9d0"
        // barPadding={0.5}
      />
      <Series
        name={t("hvagile_chartburndown_conventionts_expected")}
        valueField="velocity"
        axis="points"
        type="line"
        color="#6ab41b"
      />

      <ArgumentAxis>
        <Label overlappingBehavior="stagger" />
      </ArgumentAxis>

      <ValueAxis name="points" position="left" tickInterval={30} />
      <Tooltip
        enabled={true}
        shared={true}
        // customizeTooltip={customizeTooltip}
      />

      <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true} />
    </Chart>
  );
};

function customizeTooltip(pointInfo) {
  return {
    html: `<div><div class="tooltip-header">${pointInfo.argumentText}</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${pointInfo.points[0].seriesName}</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div><div class="series-name"><span class='bottom-series-name'>${pointInfo.points[1].seriesName}</span>: </div><div class="value-text"><span class='bottom-series-value'>${pointInfo.points[1].valueText}</span>% </div></div></div>`,
  };
}

function customizePercentageText({ valueText }) {
  return `${valueText}%`;
}

export default SprintBurndown;
