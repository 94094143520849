import axios from "axios";
export const API_ROUTE = process.env.REACT_APP_API;

export const uploadAttachments = (payload) => {
  return axios.put(
    API_ROUTE + "agile-task-attachments/" + payload.id,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        tokenuserid: localStorage.getItem("tokenuserid")
      }
    }
  );
};

export const getAttachments = (taskId) => {
  return axios.get(
    API_ROUTE + "agile-task-attachments/" + taskId,
    {
      headers: {
        tokenuserid: localStorage.getItem("tokenuserid")
      }
    }
  );
};

export const downloadAttachment = (filePath) => {
  return axios.get(API_ROUTE + "agile-task-attachment/" + filePath, {
    responseType: "blob",
    timeout: 30000,
    headers: {
      tokenuserid: localStorage.getItem("tokenuserid")
    }
  });
};
//borrado sin nodejs
/* export const deleteAttachments = (fileId) => {
  return axios.delete(
    API_ROUTE + "agile-task-attachments/" + fileId,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        tokenuserid: localStorage.getItem("tokenuserid")
      }
    }
  );
}; */

//borrado con nodejs
export const deleteAttachments = (dataForDelete) => {
  return axios.delete(
    API_ROUTE + "agile-task-attachments/" + dataForDelete.filurl,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        tokenuserid: localStorage.getItem("tokenuserid"),
        idFileToDelete : dataForDelete.filId
      }
    }
  );
};