import React from "react";
import { useSelector } from "react-redux";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useEffect } from "react";
import { getData } from "../../helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const HightlightActivities = (props) => {
  const scrum = useSelector((state) => state.scrum);
  const [t,i18n] =useTranslation("planning")

  const [hightlightsTasks , setHightlightsTasks] = useState([])

  const getHightLightTaks = (uid) => {
    Promise.all([
      getData(`product-hightlights-tasks/${uid}`,[])])
      .then((values) => {
        setHightlightsTasks(values[0])
    });
  };
  const columns = [
   /*  {
      caption: t("planningboard_labels_tablecolumn_label"),
      selector: "Etiqueta",
      customRender: (row) => {
        return (
          <div className="d-flex justify-content-start tag-body-modal ">
                <div style={{ background: row.data.Color || "#ffff" }} className="tag-text-modal d-flex align-items-center "></div>
                <div className="tag-triangel-modal"  style={{ borderLeftColor: row.data.Color || "#ffff" }} ></div>
          </div>
        );
      },
      width: 70,
    }, */
   
    {
      caption: t("planningboard_labels_tablecolumn_activity"),
      selector: "Actividad",
      customRender: (row) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (row.data.IdActividad)
                document.getElementById(`card-${row.data.IdActividad}`).click();
            }}
          >
            {/* {row.data.Destacada && (
              <small>
                <i class="fa fa-star text-warning mr-1"></i>
              </small>
            )} */}
             <small>
                <i class="fa fa-star text-warning mr-1"></i>
              </small>
            {row.data.NombreActividad}
          </a>
        );
      },
      minWidth: 180,
    },
    {
      caption: "Sprint",
      selector: "Sprint",
      width: 90,
    },
    {
      caption: t("planningboard_labels_tableboard_tablecolumn_start"),
      selector: "FechaInicio",
      width: 110,
    },
    {
      caption: t("planningboard_labels_tableboard_tablecolumn_end"),
      selector: "FechaFin",
      width: 110,
    },
    {
      caption: t("planningboard_labels_tablecolumn_advanced"),
      selector: "Estado",
      width: 80,
      customRender: (row) => {
        return (
          <>
          {(row.data.Estado == "Done") &&
          <span  className="mr-2">
            <i className="fa fa-circle text-success"></i> {row.data.Estado}
          </span>
          }
           {(row.data.Estado == "Doing") &&
          <span  className="mr-2">
            <i className="fa fa-circle text-info"></i> {row.data.Estado}
          </span>
          }
           {(row.data.Estado == "ToDo") &&
          <span  className="mr-2">
            <i className="fa fa-circle" style={{color:"#e9ecef"}}></i> {row.data.Estado}
          </span>
          }
        </>
        )
      },
    },
    {
      caption: t("planningboard_labels_tableboard_tablecolumn_last"),
      selector: "FechaModificado",
      width: 110,
    },
    {
      caption: t("planningboard_hu_tablecolumn_value"),
      selector: "ValorPO",
      width: 70,
    },
  ];

  const tagsHeader = (data) => {
    let user_lg = window.localStorage.getItem("idioma")
    if(user_lg == "es"){
      return data?.map((activity) => {
        return {
          ...activity,
          Etiqueta: `${activity.NombreEtiqueta} (Actividades: ${
            activity.CantActividadesEtiqueta || "-"
          } | Inicio: ${activity.FechaInicioEtiqueta || "-"} | Fin: ${
            activity.FechaFinEtiqueta || "-"
          } | % Avance: ${activity.pctcompletadoEtiqueta } )`,
        }
      });
    }else {
      return data?.map((activity) => {
        return {
          ...activity,
          Tag: `${activity.NombreEtiqueta} (Activities: ${
            activity.CantActividadesEtiqueta || "-"
          } | Start: ${activity.FechaInicioEtiqueta || "-"} | End: ${
            activity.FechaFinEtiqueta || "-"
          } | % Advance: ${activity.pctcompletadoEtiqueta } )`,
        }
      });
    }
    
  };

  useEffect(()=>{
    getHightLightTaks(props.uid)
  },[])

  
  return (
    <DataGrid
     /*  dataSource={tagsHeader(getAllActivitiesByTags)} */
      dataSource={hightlightsTasks}
      allowColumnReordering={true}
      showBorders={true}
    >
      <GroupPanel visible={true}  emptyPanelText=" " />
      <SearchPanel visible={true} placeholder={t("planningboard_labels_searchbox")} />
      <Grouping autoExpandAll={false} />
     {/*  <Column dataField= {t("planningboard_labels_tablecolumn_label")} caption={t("planningboard_labels_tablecolumn_label")}  groupIndex={0} > */}
     {/*  </Column> */}
      {columns.map((itmCol, index) => {
        return (
          <Column
            key={index}
            dataField={itmCol.selector}
            caption={itmCol.caption}
            width={itmCol.width}
            minWidth={itmCol.minWidth}
            cellRender={(row) => {
              return (
                <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
              );
            }}
          />
        );
      })}
      <Paging defaultPageSize={50} />
    </DataGrid>
  );
};

export default HightlightActivities;
