import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Badge,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists, settingLocalStorage, encry, saveLocalStorage } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useHistory,useParams } from "react-router-dom";
import CreateTemplate from "../components/CreateTemplate";
import emptyImg from "../assets/people-1.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProjects, fetchUsersAccess } from "../redux";
import Loader from "../components/Loader";
import ErrorMsg from "../components/ErrorMsg";
import { selectTooltip ,selectTooltip_en } from "../utils/selectTooltip";
import ReactTooltip from "react-tooltip";
import WelcomeModal from "../components/WelcomeModal";
import OnBoarding from "../components/OnBoarding";
import swal from "sweetalert";
import { deleteProjectById } from "../services/projects";
import SuperTableV2 from "../components/SuperTableV2";
import ScrollRangeBoard from "../components/ScrollRangeBoard";
import Tags from "./Tags";
import { useTranslation } from "react-i18next";
import ProjectsDashboard from "../components/ProjectsDashboard";
import { exportToExcelAllProjects } from "../services/agile";
import exportFromJSON from "export-from-json";
/* import { useParams } from "react-router-dom/cjs/react-router-dom.min"; */

const Projects = () => {
  const ID_ORGANIZACION = "1727EA57-9E8F-44CE-938B-8F377491F135"  // Produccion
 /*  const ID_ORGANIZACION = "8CAADED8-A836-4CB3-8822-878440CC4E30" */ //desarrollo
  const [t,i18n] = useTranslation("projects")
  const [selectedTab, setSelectedTab] = useState("");
  const projects = useSelector((state) => state.projects);
  const [ showTagsModal, setShowTagsModal ] = useState(false)
  const handleCloseTags = () => {setShowTagsModal(false)}
  const dispatch = useDispatch();
  const [save, setSave] = useState([]);
  const access = useSelector((state)=> state.access);

  const [ screenWidth ,setScreenWidth]= useState(window.innerWidth)
  const { idView } = useParams();
  const dataForProjectsDashboard = projects.projects?.map((proj)=> {
    return {
      id: proj.IdProyecto,
      nombre :proj.Nombre,
      pais: proj.Pais,
      estado: proj.Estado,
      fechaInicio : new Date(proj.FechaInicioProg),
      fechaFin : new Date(proj.FechaFinProg),
      gerente : proj.Gerente,
      metodologia : proj.Metodologia,
      porcentaje : proj.PctCompletadoProyecto,
      tipoProyecto : proj.TipoProyecto,
      CostoPlaneado : proj.CostoPlaneado,
      CostoReal:proj.CostoReal
    }
  })

  
  const deleteProject = (uid) => {  
    swal({
      title: t("projects_swal_delete_title") ,
      text: t("projects_swal_delete_text"),
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        deleteProjectById(uid, () => window.location.reload(),
        {text1:t("project_modal_done_1"),text2:t("project_modal_done_4"), text3:t("project_modal_done_5")});
      }
    });
  };

  const showCompletedProjects = useMemo(() => {
    let areThereCompletedProjects = projects.projects.find(
      (project) => project.PctCompletadoProyecto === 100
    );
    if (areThereCompletedProjects) {
      return true;
    }
    return false;
  }, [projects]);

  const printBadgeStatus = useCallback((pctComplete) => {
    switch (pctComplete) {
      case 0:
        return (
          <div className="text-left">
            <span className="badge bg-warning text-white">{t("projects_progress_pa_status_todo")}</span>
          </div>
        );
      case 100:
        return (
          <div className="text-left">
            <span className="badge bg-success text-white">{t("projects_progress_pa_status_completed")}</span>
          </div>
        );

      default:
        return (
          <div className="text-left">
            <span className="badge bg-info text-white">{t("projects_progress_pa_status_inprogress")}</span>
          </div>
        );
    }
  }, []);
  const idOrganization = window.localStorage.getItem("OID")

  const renderIndicatorHeader = (data) => {
    if(idOrganization == ID_ORGANIZACION ){
      return <div data-tip={((t("projects_lenguaje"))=="es")? selectTooltip(44) : selectTooltip_en(44)}>{data.column.caption}</div>;
    }else {
      return <div>{data.column.caption}</div>;
    }
  }

  const columns = [
    {
      caption: t("projects_tablecolumn_name"),
      selector: "Nombre",
      width: 400,
      customRender: (row) => {
        return (
          <div className="d-flex align-items-center dropdown-in-table">
            <Dropdown onClick={(e) => e.stopPropagation()}>
              <Dropdown.Toggle
                variant="outline-primary"
                size="sm"
                className="dropdown-btn-ellipsis"
              >
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item 
                  as={Link} 
                  to={`/project/${row.data.IdProyecto}`}
                  disabled ={(showBtns == "Ejecutivo"  || showBtns == 'Miembro de Equipo')? true : false}
                  >
                  {t("projects_btn_options_edit")}
                </Dropdown.Item>
                {row.data.Metodologia === t("projects_tablecolumn_waterfall") ? (
                  <Dropdown.Item
                    as={Link}
                    to={`/schedule/${row.data.IdProyecto}`}
                  >
                   {t("projects_btn_options_schedule")}
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    as={Link}
                    to={`/planning-board/${row.data.IdProyecto}`}
                  >
                    Product backlog
                  </Dropdown.Item>
                )}
                {row.data.Metodologia === t("projects_tablecolumn_waterfall") ? (
                  <Dropdown.Item
                    as={Link}
                    to={`/hv-project/${row.data.IdProyecto}`}
                    title={(t("projects_lenguaje") == "es")? selectTooltip(27) : selectTooltip_en(27)}
                  >
                   {t("projects_btn_options_report")}
                  </Dropdown.Item>
                ) : (<>
                  <Dropdown.Item
                    as={Link}
                    to={`/hv-agile/${row.data.IdProyecto}`}
                    title={(t("projects_lenguaje") == "es")? selectTooltip(27) : selectTooltip_en(27)}
                  >
                    {t("projects_btn_options_report")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/tags/${row.data.IdProyecto}`}
                  >
                    {t("projects_btn_options_mytags")}
                  </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item
                  onClick={() => deleteProject(row.data.IdProyecto)}
                  disabled ={(showBtns == "Ejecutivo"  || showBtns == 'Miembro de Equipo')? true : false}
                >
                  {t("projects_btn_options_delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span
              className="ml-2"
              style={{ maxWidth: "340px" }}
              title={row.data.Nombre}
            >
              <Link
                to={`/${
                  row.data.Metodologia === t("projects_tablecolumn_waterfall")
                    ? "schedule"
                    : "planning-board"
                }/${row.data.IdProyecto}`}
              >
                {row.data.Nombre}
              </Link>
            </span>
          </div>
        );
      }
    },
    {
      caption: t("projects_tablecolumn_COD"),
      selector: "CODProyecto",
      width: 200
    },
    {
      caption: t("projects_tablecolumn_startdate"),
      selector: "FechaInicioProg",
      width: 110
    },
    {
      caption: t("projects_tablecolumn_finishdate"),
      selector: "FechaFinProg",
      width: 110
    },
    {
      caption: "Mod.",
      selector: "FechaModificacion",
      width: 110
    },
    {
      caption: t("projects_tablecolumn_methodology"),
      selector: "Metodologia",
      width: 110
    },
    {
      caption: t("projects_tablecolumn_type"),
      selector: "TipoProyecto",
      width: 130
    },
    {
      caption: t("projects_tablecolumn_progress"),
      align: "left",
      selector: "PctCompletadoProyecto",
      customRender: (row) => {
        return row.data.Metodologia === t("projects_tablecolumn_waterfall") ? (
          <div className="d-flex flex-column align-items-start mb-1">
            <small className="align-left">
              {row.data.PctPlaneadoProyecto}% Plan.
            </small>
            <div style={{ width: "80%" }}>
              <ProgressBar
                now={row.data.PctPlaneadoProyecto}
                style={{ height: "5px" }}
                variant="info"
              />
            </div>
            <small className="align-left mt-1">
              {row.data.PctCompletadoProyecto}% Comp.
            </small>
            <div style={{ width: "80%" }}>
              <ProgressBar
                now={row.data.PctCompletadoProyecto}
                style={{ height: "5px" }}
                variant="success"
              />
            </div>
          </div>
        ) : (
          <>
          {(idOrganization == ID_ORGANIZACION)? 
          
          <div>
            <div className="d-flex flex-column align-items-start mb-1">
                <small className="align-left">
                  {row.data.PorEsperado}% Plan.
                </small>
                  <div style={{ width: "80%" }}>
                    <ProgressBar
                      now={row.data.PorEsperado}
                      style={{ height: "5px" }}
                      variant="info"
                    />
                  </div>
                  <small className="align-left mt-1">
                    {row.data.PorCompletado}% Comp.
                  </small>
                  <div style={{ width: "80%" }}>
                    <ProgressBar
                      now={row.data.PorCompletado}
                      style={{ height: "5px" }}
                      variant="success"
                    />
                  </div>
              </div>
          </div>
          
          :printBadgeStatus(row.data.PctCompletadoProyecto) 
          
          }
        
          {/* <div className="d-flex flex-column align-items-start mb-1">
            <small className="align-left">
              {row.data.PorEsperado}% Plan.
            </small>
            <div style={{ width: "80%" }}>
              <ProgressBar
                now={row.data.PorEsperado}
                style={{ height: "5px" }}
                variant="info"
              />
            </div>
            <small className="align-left mt-1">
              {row.data.PorCompletado}% Comp.
            </small>
            <div style={{ width: "80%" }}>
              <ProgressBar
                now={row.data.PorCompletado}
                style={{ height: "5px" }}
                variant="success"
              />
            </div>
          </div> */}
          </>
          /* printBadgeStatus(row.data.PctCompletadoProyecto) */
        );
      },
      width: 150
    },
    {
      caption: t("projects_tablecolumn_indicator"),
      customRender: (data) => {
        let row = data.data;

        const desvTradicional = Math.round( row.PctCompletadoProyecto - row.PctPlaneadoProyecto )
        const desvAgileAfinia = Math.round( row.PorEsperado - row.PorCompletado) 
        const desvAgil = 0
        const desvTradicionalAfinia = Math.round( row.PctPlaneadoProyecto  - row.PctCompletadoProyecto )
       
        if(row.Metodologia == t("projects_tablecolumn_waterfall")){
          if(idOrganization == ID_ORGANIZACION){
           /*  let desv = Math.round( row.PctCompletadoProyecto - row.PctPlaneadoProyecto ) */
            if( desvTradicionalAfinia < 2){
              return  (
                <div className="d-flex">
                  <i
                    className="fa fa-circle mr-2 my-auto"
                    /* title= {t("projects_progress_pa_status_ontime")} */
                    style={{color:"#71E21B"}}
                  ></i>
                  {t("projects_progress_pa_status_ontime")}
                </div>
              );
            }else if(desvTradicionalAfinia >= 10){
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    /* title= {t("projects_progress_pa_status_ontime")} */
                    style={{color:"#231a1a"}}
                  ></i>
                  {t("projects_progress_pa_status_criticalExt")}
                </div>
              )
            }
            else if( desvTradicionalAfinia >= 2 && desvTradicionalAfinia < 5 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle  mr-2 my-auto"
                   /*  title={t("projects_progress_pa_status_moderate")} */
                    style={{color:"#FCE940"}}
                  ></i>
                  {t("projects_progress_pa_status_moderate")}
                </div>
              );
            }else if( desvTradicionalAfinia >= 5 && desvTradicionalAfinia < 10 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    style={{color:"#FF313A"}} 
                    /* title={t("projects_progress_pa_status_critical")} */
                  ></i>
                  {t("projects_progress_pa_status_critical")}
                </div>
              );
            }

          }else {
            /* let desv = Math.round( row.PctCompletadoProyecto - row.PctPlaneadoProyecto ) */
              if( desvTradicional >= 0){
                return  (
                  <div className="d-flex">
                    <i
                      className="fa fa-check-circle text-success mr-2 my-auto"
                      title= {t("projects_progress_pa_status_ontime")}
                    ></i>
                    {t("projects_progress_pa_status_ontime")}
                  </div>
                );
              }else if( desvTradicional <= -1 && desvTradicional >= -25 ) {
                return (
                  <div className="d-flex">
                    <i
                      className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                      title={t("projects_progress_pa_status_moderate")}
                    ></i>
                    {t("projects_progress_pa_status_moderate")}
                  </div>
                );
              }else if( desvTradicional < -25) {
                return (
                  <div className="d-flex">
                    <i
                      className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                      title={t("projects_progress_pa_status_critical")}
                    ></i>
                    {t("projects_progress_pa_status_critical")}
                  </div>
                );
              }

          }
        }else {
          if(idOrganization == ID_ORGANIZACION){
           /*  let desv = Math.round( row.Esperado - row.PorCompletado ) */
            if( desvAgileAfinia < 2){
              return  (
                <div className="d-flex">
                  <i
                    className="fa fa-circle mr-2 my-auto"
                    /* title= {t("projects_progress_pa_status_ontime")} */
                    style={{color:"#71E21B"}}
                  ></i>
                  {t("projects_progress_pa_status_ontime")}
                </div>
              );
            }else if(desvAgileAfinia >= 10){
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    /* title= {t("projects_progress_pa_status_ontime")} */
                    style={{color:"#231a1a"}}
                  ></i>
                  {t("projects_progress_pa_status_criticalExt")}
                </div>
              )
            }
            else if( desvAgileAfinia >= 2 && desvAgileAfinia < 5 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle  mr-2 my-auto"
                   /*  title={t("projects_progress_pa_status_moderate")} */
                    style={{color:"#FCE940"}}
                  ></i>
                  {t("projects_progress_pa_status_moderate")}
                </div>
              );
            }else if( desvAgileAfinia >= 5 && desvAgileAfinia < 10 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    style={{color:"#FF313A"}} 
                    /* title={t("projects_progress_pa_status_critical")} */
                  ></i>
                  {t("projects_progress_pa_status_critical")}
                </div>
              );
            }
            
          }else {
            if( desvAgil >= 0){
              return  (
                <div className="d-flex">
                  <i
                    className="fa fa-check-circle text-success mr-2 my-auto"
                    title= {t("projects_progress_pa_status_ontime")}
                  ></i>
                  {t("projects_progress_pa_status_ontime")}
                </div>
              );
            }else if( desvAgil <= -1 && desvAgil >= -25 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                    title={t("projects_progress_pa_status_moderate")}
                  ></i>
                  {t("projects_progress_pa_status_moderate")}
                </div>
              );
            }else if( desvAgil < -25) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                    title={t("projects_progress_pa_status_critical")}
                  ></i>
                  {t("projects_progress_pa_status_critical")}
                </div>
              );
            }
          }
        }
       /*  let desv = Math.round( row.PctCompletadoProyecto - row.PctPlaneadoProyecto )
        if( desv >= 0){
          return  (
            <div className="d-flex">
              <i
                className="fa fa-check-circle text-success mr-2 my-auto"
                title= {t("projects_progress_pa_status_ontime")}
              ></i>
              {t("projects_progress_pa_status_ontime")}
            </div>
          );
        }else if( desv <= -1 && desv >= -25 ) {
          return (
            <div className="d-flex">
              <i
                className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                title={t("projects_progress_pa_status_moderate")}
              ></i>
              {t("projects_progress_pa_status_moderate")}
            </div>
          );
        }else if( desv < -25) {
          return (
            <div className="d-flex">
              <i
                className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                title={t("projects_progress_pa_status_critical")}
              ></i>
              {t("projects_progress_pa_status_critical")}
            </div>
          );
        } */
       /*  switch (true) {
          case desv >= 0:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-check-circle text-success mr-2 my-auto"
                  title="Al Día"
                ></i>
                Al Día
              </div>
            );
          case desv = -40:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                  title="En Riesgo Moderado"
                ></i>
                Moderado
              </div>
            );
          case desv <= -25:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                  title="En Riesgo Alto"
                ></i>
                Crítico
              </div>
            );

          default:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-minus-circle text-secondary  mr-2 my-auto"
                  title="Sin Clasificar"
                ></i>
                Sin Clasificar
              </div>
            );
        } */
      },
      width: 130,
      headerCellRender:renderIndicatorHeader
    },
    {
      caption: t("projects_tablecolumn_manager"),
      selector: "Gerente",
      width: 200
    }
  ];

  let history = useHistory();
  const goToEditProject = (e) => {
    if (e.data.Metodologia === t("projects_tablecolumn_waterfall")) {
      history.push(`/schedule/${e.data.IdProyecto}`);
    } else {
      history.push(`/planning-board/${e.data.IdProyecto}`);
    }
  };

  const totalProgressProjects = useMemo(
    () =>
      _.filter(projects.projects, (item) =>
        item.PctCompletadoProyecto < 100 ? true : false
      ).length,
    [projects.projects]
  );

  const totalCompletedProjects = useMemo(
    () =>
      _.filter(projects.projects, {
        PctCompletadoProyecto: 100
      }).length,
    [projects.projects]
  );

  useEffect(() => {
    dispatch(fetchAllProjects());
    /* dispatch(fetchUsersAccess()) */
  }, []);

  useEffect(() => {
    /* settingLocalStorage(access.access) */
    /* let saveLoca = JSON.parse(window.localStorage.getItem('accessToUsers')) */
    /* setSave(saveLoca[0]?.Rol) */
  }, [access.access]);
 
  useEffect(() => {

      ReactTooltip.rebuild();
  });
  /* const getScreenWidth = () =>{
    setScreenWidth(window.screen.width)
    console.log(screenWidth)
  } */
  useEffect(()=>{

   const handleResize = () => {
    setScreenWidth(window.screen.width)
   }    
   window.addEventListener('resize', handleResize)
  },[])
  
  let title_i18 = t("projects_title")

  const exportData = async () => {
    let result = await exportToExcelAllProjects();
    if (result.data.length) {
      const data = result.data;
      const fileName = `${title_i18}-${localStorage.getItem("nombreOrganizacion")}`
      /* const fileName = `tasks-${encodeURI(scrum.general.Nombre)}`; */
      const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data, fileName, exportType });
    } else {
      swal("¡Ups!", "Sin datos para exportar.", "warning");
    }
  };

  const savePage = (value) => {
    localStorage.setItem('savePage',value)
  }
  const currentPage = localStorage.getItem("savePage") || "first"
  
  const [showBtns,setShowBtns] = useState()
  
  const change = window.localStorage.getItem("rol")
  useEffect(()=>{
    setShowBtns(change)
  },[change])
  return (
    <React.Fragment>
      <Layout save = {save}>
        <TitleCommon
          title={{
            text: title_i18,
            icon: "clipboard"
          }}
          //   button={{
          //     text: "Volver",
          //     icon: "arrow-left",
          //     url: "/",
          //   }}
        />
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
           /*  defaultActiveKey={viewDefault} */
           defaultActiveKey={(idView)? idView : currentPage }
           /* defaultActiveKey="first" */
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row className="bg-light" >
              <Col >
                <StyledTabs as={Nav} >
                  <Nav.Item data-tip={(t("projects_lenguaje") == "es")? selectTooltip(24) : selectTooltip_en(24)}>
                    <Nav.Link eventKey="first" onClick={()=>savePage("first")} data-tour="step-3">
                      {t("projects_button_inprogress")}
                    </Nav.Link>
                  </Nav.Item>
                  {showCompletedProjects ? (
                    <Nav.Item  data-tip={(t("projects_lenguaje") == "es")? selectTooltip(25) : selectTooltip_en(25)}>
                      <Nav.Link eventKey="second"  onClick={()=>savePage("second")}>{t("projects_button_complete")}</Nav.Link>
                    </Nav.Item>
                  ) : null}
                  <Nav.Item /* data-tip={selectTooltip(25)} */>
                      <Nav.Link eventKey="third" onClick={()=>savePage("third")}>{
                      (screenWidth > 500) ? 
                      t("projects_button_report") : t("projects_button_report2")
                      }</Nav.Link>
                    </Nav.Item>
                  {/* {_.filter(projects.projects, {
                    PctCompletadoProyecto: 100,
                  }).length ? (
                    <Nav.Item>
                      <Nav.Link eventKey="second">Completados</Nav.Link>
                    </Nav.Item>
                  ) : null} */}

                  {/* <Nav.Item>
                    <Nav.Link eventKey="templates">Plantillas</Nav.Link>
                  </Nav.Item> */}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    className="animate__animated animate__fadeIn"
                  >
                    {projects.loading ? (
                      <Loader />
                    ) : projects.error ? (
                      <ErrorMsg
                        title="¡Ups!"
                        msg="Hubo un error al cargar los proyectos."
                      />
                    ) : (
                      <React.Fragment>
                        <div className="mb-2 mt-2">
                          <ScrollRangeBoard target=".dx-datagrid-content" title_scroll ={t("projects_button_slide")} />
                        </div>
                        <div className="d-flex ">
                        <p className="mb-2 mb-md-n4 mr-3">
                          {t("projects_title_numberprojects_1")}{" "}
                          <b className="text-primary">
                            ({totalProgressProjects}) {t("projects_title_numberprojects_2")}
                          </b>{" "}
                          {t("projects_title_numberprojects_3")}
                        </p>
                        <Button
                          variant="info"
                          size="sm"
                          title={t("projects_button_export")}
                          onClick={exportData}
                        >
                          <i className="fa fa-download"></i>
                        </Button>
                        </div>
                        <div>
                          {totalProgressProjects ? (
                            <SuperTableV2
                              data={_.filter(projects.projects, (item) =>
                                item.PctCompletadoProyecto < 100 ? true : false
                              )}
                              columns={columns}
                              onRowClick={goToEditProject}
                              title_searchbox = {t("projects_searchbox")}
                            />
                          ) : (
                            <div className="text-center pt-4 mt-4">
                              <img
                                src={emptyImg}
                                alt="Sin datos"
                                className="img-fluid mb-3 animated pulse slower infinite"
                                // width="30"
                              />
                              <h5>{t("projects_noproject_create")}</h5>
                              <p>
                                {t("projects_noproject_noyet")}
                              </p>
                              <Button as={Link} to="/project">
                                <i className="fa fa-plus-circle mr-2"></i>{t("projects_noproject_create")}
                              </Button>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    className="animate__animated animate__fadeIn"
                  >
                   {/*  <div className="mb-2 mt-2">
                          <ScrollRangeBoard target=".dx-scrollable-content" title_scroll ={t("projects_button_slide")} />
                      </div> */}
                    <p className="mb-2 mb-md-n4">
                      {t("projects_title_numberprojects_1")}{" "}
                      <b className="text-primary">
                        ({totalCompletedProjects}) {t("projects_title_numberprojects_4")}
                      </b>{" "}
                      {t("projects_title_numberprojects_5")}
                    </p>
                    <div className="table-with-dropdown">
                      <SuperTableV2
                        data={_.filter(projects.projects, {
                          PctCompletadoProyecto: 100
                        })}
                        columns={columns}
                        onRowClick={goToEditProject}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="third"
                    className="animate__animated animate__fadeIn"
                  >
                    <div style={{width:'80%', margin :'0 auto'}}>
                      <ProjectsDashboard  data ={dataForProjectsDashboard }/>
                    </div> 
                  </Tab.Pane>
                  {/* <Tab.Pane
                    eventKey="templates"
                    className="animate__animated animate__fadeIn"
                  >
                    <p className="mb-2 mb-md-n4">
                      A continuación podrás gestionar tus{" "}
                      <b className="text-primary">plantillas</b> de proyecto.
                    </p>
                    <div className="table-with-dropdown">
                      <SuperTable
                        data={_.filter(projects.projects, {
                          PctCompletadoProyecto: 100,
                        })}
                        columns={columns}
                      />
                    </div>
                  </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
      <React.Fragment>
        {selectedTab != "templates" && !projects.error && !projects.loading ? (
          <Button
            as={Link}
            to="/project"
            variant="success"
            className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
            style={{ bottom: "1rem", right: "1rem" }}
            data-tip={(t("projects_lenguaje") == "es")? selectTooltip(10) : selectTooltip_en(10)}
            data-place="top"
            data-tour="step-2"
          >
            <i className="fa fa-plus mr-2"></i>{t("projects_button_create")}
          </Button>
        ) : (
          // <CreateTemplate />
          ""
        )}
      </React.Fragment>
      <OnBoarding
        show={
          !projects.loading && projects.projects.length === 0 ? true : false
        }
      />
      {!projects.loading && projects.projects.length === 0 ? (
        <WelcomeModal />
      ) : null}
      {/* <WelcomeModal /> */}
    </React.Fragment>
  );
};

export default Projects;
