import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Collapse, Modal } from 'react-bootstrap'
import HelpVideoView from './HelpVideoView'
import { useTranslation } from 'react-i18next'

const BodyVirtualAsist = ({dataType}) => {
  const [t,i18n] =useTranslation("onBoarding")
    
    const [open, setOpen] = useState(false)
    const [dataToShow, setDataToShow]= useState()
    const [openEstru, setOpenEstru] = useState(false)
    const [openEjecu, setOpenEjecu] = useState(false)
    const [openSegui, setOpenSegui] = useState(false)
    const [openCierre, setOpenCierre] = useState(false)
    const [openAuxiTra, setOpenAuxiTra] = useState(false)

    //Show Modal

    const [showHelps, setShowHelps] = useState(false);
    const [nameVideoHelp, setNameVideoHelp] = useState("");
    const handleCloseHelps = () => setShowHelps(false);
    const [idHelp, setIdHelp] = useState()

    //Agil//

    const [openEstruAgil, setOpenEstruAgil] = useState(false)
    const [openEjecuAgil, setOpenEjecuAgil] = useState(false)
    const [openSeguiAgil, setOpenSeguiAgil] = useState(false)
    const [openCierreAgil, setOpenCierreAgil] = useState(false)
    const [openAuxiTraAgil, setOpenAuxiTraAgil] = useState(false)

    const handleOpenTitle =(id)=>{
        if(dataType == "Tradicional"){
            if(id == 1 ){
            return setOpenEstru((value)=>value =!value)
            }else if(id == 2 ){
                return setOpenEjecu((value)=>value =!value)
            }else if(id == 3 ){
                return setOpenSegui((value)=>value =!value)
            }else if(id == 4 ){
                return setOpenCierre((value)=>value =!value)
            }else {
                return setOpenAuxiTra((value)=>value =!value)
            }
        }else {
            if(id == 1 ){
                return setOpenEstruAgil((value)=>value =!value)
                }else if(id == 2 ){
                    return setOpenEjecuAgil((value)=>value =!value)
                }else if(id == 3 ){
                    return setOpenSeguiAgil((value)=>value =!value)
                }else if(id == 4 ){
                    return setOpenCierreAgil((value)=>value =!value)
                }else {
                    return setOpenAuxiTraAgil((value)=>value =!value)
                }

        }
    }
 
    const  dataTradicional = [
        {   
            id:1,
            title: t("help_title_structuration"),
            show: openEstru,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_structuration_Creation of the Project"),
                    url : "CrearUnProyecto",
                    type : "Generalidades"
                },
                {
                    id:2,
                    nameVideo : t("help_title_structuration_Creation"),
                    url : "CreacionDelPlanDeProyectoCronograma",
                    type : "Tradicional"
                }
            ]
    
       },
       {   
            id:2,
            title: t("help_title_Execution"),
            show: openEjecu,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_Execution_1"),
                    url : "ReporteDeAvanceDeActividades",
                    type : "Tradicional"
                },
            ]
    
        },
        {   
            id:3,
            title: t("help_title_Tracking"),
            show: openSegui,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_Tracking_1"),
                    url : "SeguimientoAmiProyecto",
                    type : "Tradicional"
                },
            ]
    
        },
        {   
            id:4,
            title: t("help_title_closing"),
            show: openCierre,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_closing_1"),
                    url : "CierreDeProyecto",
                    type : "Tradicional"
                },
            ]
    
        },
        {   
            id:5,
            title: t("help_title_aux_videos"),
            show: openAuxiTra,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_aux_videos_1"),
                    url : "agregarTarea",
                    type : "Tradicional"
                },
                    {
                    id:2 ,
                    nameVideo : t("help_title_aux_videos_2"),
                    url : "agregarRecurso",
                    type : "Tradicional"
                },
                    {
                    id:3 ,
                    nameVideo : t("help_title_aux_videos_3"),
                    url : "crearDependenciaTarea",
                    type : "Tradicional"
                }, 
                    {
                    id:4,
                    nameVideo : t("help_title_aux_videos_4"),
                    url : "crearHito",
                    type : "Tradicional"
                }, 
                    {
                    id:5 ,
                    nameVideo : t("help_title_aux_videos_5"),
                    url : "GestionDocumental",
                    type : "Tradicional"
                },
            ]
    
        }
    ]
    const  dataAgil = [
        {   
            id:1,
            title: t("help_title_structuration"),
            show: openEstruAgil,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("Creation of the Project (Project Charter)"),
                    url : "CrearUnProyecto",
                    type : "Generalidades"
                },
                {
                    id:2,
                    nameVideo : t("Creation of the project plan (Planning Board)"),
                    url : "CreacionDelProyectoPlanningBoard",
                    type : "Agil"
                },
                {
                    id:3,
                    nameVideo : t("help_title_structuration_Creation_p"),
                    url : "ComoCrearHistoriasDeUsuario",
                    type : "Agil"
                }
            ]
    
       },
       {   
            id:2,
            title: t("help_title_Execution"),
            show: openEjecuAgil,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_Execution_1"),
                    url : "ReporteDeAvanceDeActividades",
                    type : "Agil"
                },
            ]
    
        },
        {   
            id:3,
            title: t("help_title_Tracking"),
            show: openSeguiAgil,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_Tracking_1"),
                    url : "SeguimientoAmiProyecto",
                    type : "Agil"
                },
            ]
    
        },
        {   
            id:4,
            title: t("help_title_closing"),
            show: openCierreAgil,
            videos : [
                {
                    id:1 ,
                    nameVideo : t("help_title_closing_1"),
                    url : "ComoCerrarUnProyecto",
                    type : "Agil"
                },
            ]
    
        },
        {   
            id:5,
            title: t("help_title_aux_videos"),
            show: openAuxiTraAgil,
            videos : [
                {
                    id:1,
                    nameVideo : t("help_title_aux_videos_a_1"),
                    url : "crearSprint",
                    type : "Agil"
                  },
                  {
                    id:2 , 
                    nameVideo : t("help_title_aux_videos_a_2"),
                    url : "ordenarTablaRecursos",
                    type : "Agil"
                  },
                  {
                    id:3 ,
                    nameVideo : t("help_title_aux_videos_a_3"),
                    url : "ordenarTablaHistoriaUsuario",
                    type : "Agil"
                  }, 
                  {
                    id:4 ,
                    nameVideo : t("help_title_aux_videos_2"),
                    url : "agregarRecurso",
                    type : "Agil"
                  },
                  {
                    id:5 ,
                    nameVideo : t("help_title_aux_videos_a_4"),
                    url : "filtrarSprint",
                    type : "Agil"
                  },
                    {
                    id:6 ,
                    nameVideo : t("help_title_aux_videos_a_5"),
                    url : "UsoDeEtiquetas",
                    type : "Agil"
                },
            ]
    
        }
    ]

    const handleVideosHelp = (info) => {
        setNameVideoHelp(info.nameVideo)
        setIdHelp(info)
        setShowHelps(true)
      }

  return (
    <> 
        {((dataType == "Tradicional")? dataTradicional : dataAgil)?.map((item,idx)=> {
            return <> <Button
                        key={idx}
                        onClick={()=>handleOpenTitle(item.id)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className='mb-2 btn-light btn-link-asis text-left'
                    >
                        <i className="fa fa-circle text-dark mr-2 "></i>
                        {item.title}
                    </Button>
                    <div  style={{ minHeight: 'auto' }}>
                        <Collapse in={item.show} dimension="width">
                        <div id="example-collapse-text">
                            <Card className='border-0 mb-2' style={{ width: 'auto' }}>
                                <Card.Body className="p-0 ml-4">
                                <ul className="list-group list-group-flush">
                                    {item?.videos.map((list,idxx)=> {
                                        return <li 
                                                    onClick={()=>handleVideosHelp({id:list.id, type: list.type ,nameVideo: list.nameVideo, url : list.url})} 
                                                    style={{cursor:"pointer"}} 
                                                    key={idxx} 
                                                    className="list-group-item border-0 
                                                    list-group-item-action 
                                                    d-flex flex-column 
                                                     align-items-start p-0 mb-1">
                                                    {idxx + 1}-{list.nameVideo}
                                                </li>
                                    })
                                    } 
                                </ul>
                                </Card.Body>
                            </Card>
                        </div>
                        </Collapse>
                    </div>
            </>
        })
       
        }
         <Modal
            show={showHelps}
            onHide={handleCloseHelps}
            contentClassName="border-0 shadow-lg"
            centered
            size="lg"
            >
            <Modal.Header>
                <Modal.Title className="h5">Tutorial: {nameVideoHelp} </Modal.Title>
                <Button  onClick={handleCloseHelps} variant="light">X</Button>
            </Modal.Header>
            <Modal.Body>
                <HelpVideoView info = {idHelp} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={handleCloseHelps}>
                {t("help_button_close")}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    
  )
}

export default BodyVirtualAsist