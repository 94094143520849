import { LOAD_TAGS, ERROR_TAGS } from "./tagsTypes";
import axios from "axios"
import { API_ROUTE, headersRequest } from "../../helpers";

export const fetchAllTags = (idProject) => { 
    return ( dispatch )=> {
        axios
        .get(`${API_ROUTE}tags/${idProject}`, headersRequest())
        .then((res)=>{
            dispatch({
                type:LOAD_TAGS,
                payload: res.data,
            })
        })
        .catch((err)=> { 
            dispatch({
                type:ERROR_TAGS,
            })
        })

    }
}