import React, { useState } from "react";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
const _ = require("lodash");

const ModalSelectTags = (props) => {
  const _isSelected = (id) => {
    let search = _.findIndex(props.selectedTags, function (o) {
      return o.Id == id;
    });
    if (search != -1) {
      return true;
    } else {
      return false;
    }
  };
  const _pushTags = (data) => {
    let search = _.findIndex(props.selectedTags, function (o) {
      return o.Id == data.Id;
    });
    let dummie = [...props.selectedTags];
    if (search != -1) {
      dummie = dummie.filter((item) => item.Id !== data.Id);
    } else {
      dummie.push(data);
    }
    props.setSelectedTags([...dummie]);
  };
  return (
    <Modal
      show={props.show}
      centered
      onHide={() => {
        props.setShow(false);
      }}
      contentClassName="border-0 shadow-lg"
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Modal.Header >
          <Modal.Title className="h6 text-info">
            <i className="fa fa-tags mr-2"></i>Tus Etiquetas
          </Modal.Title>
          <Button onClick={() => props.setShow(false)} variant="light">X</Button>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {props.tags.map((item, index) => {
              return (
                <ListGroup.Item
                  key={item.Id}
                  className={`py-2 px-3 text-white ${_isSelected(item.Id) && "bg-light text-dark"}`}
                  style = {{backgroundColor: item.Color}}
                  action
                  onClick={() => {
                    _pushTags(item);
                  }}
                >
                  {_isSelected(item.Id) && (
                    <i className="fa fa-check small text-info mr-2"></i>
                  )}
                  {item.NombreEtiqueta}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.setShow(false)} variant="outline-dark" size="sm">
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default React.memo(ModalSelectTags);