import React, { useState, useEffect } from "react";
import {
  BarGauge,
  Label,
  Export,
  Title,
  Font,
  Legend,
  Subtitle,
} from "devextreme-react/bar-gauge";

const format = {
  type: "fixedPoint",
  precision: 1,
};

const ProgressIndicator = (props) => {
  let { data, labels, legend, mini } = props;
  return (
    <BarGauge
      startValue={0}
      endValue={100}
      values={data}
      defaultValues={[0, 0]}
      style={{
        marginTop: "-30px",
        marginBottom: "-30px",
      }}
      palette={["#17a2b8", "#6ab41b"]}
      // width={props.width}
      // className="mx-auto"
    >
      <Legend
        visible={true}
        customizeText={(arg) => {
          //   return `${labels[arg.item.index]}: ${arg.text}%`;
          return `${labels[arg.item.index]}`;
        }}
        verticalAlignment="bottom"
        horizontalAlignment="center"
      />
    </BarGauge>
  );
};

export default ProgressIndicator;
