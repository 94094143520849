import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from "./usersTypes";

const initial = {
  loading: false,
  users: [],
  edit: null,
  modal: false,
  error: "",
};

const usersReducer = (state = initial, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        loading: true,
        users: [],
        error: "",
      };
    case FETCH_USERS_SUCCESS:
      return {
        loading: false,
        users: action.payload,
        error: "",
      };
    case FETCH_USERS_FAILURE:
      return {
        loading: false,
        users: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
