import React, { useState } from "react";
import _ from "lodash";
/* import SuperTable from "./SuperTable";
import { Card } from "react-bootstrap"; */
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  /* Pager, */
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TasksModalEdit from "./TasksModalEdit";
import { saveData } from "../helpers";

const MyTasksByProject = (props) => {
  const [t,i18n] = useTranslation("mytasks")
  const { myTasks, setTaskEdit, handleShow, showCompleted } = props;

  const [projectsList, setProjectsList] = useState([]);
  const [startDate, setStartDate]= useState(null);
  const [endDate, setEndDate]= useState(null);
  const [compStartDate, setCompStartDate]= useState(null);
  const [compEndDate, setCompEndDate]= useState(null);
  const [saveInfo, setSaveInfo]= useState({})

  let test = {
    NombreProyecto: "Estabilización de Archivo",
    IdActividad: "DC9B4453-5EE4-4F8B-BF92-94BF911585D4",
    IdProyecto: "11D46D7E-3722-495F-85B8-1F2678744E94",
    NombreActividad: "Probando Activvidad",
    Nivel: 1,
    WBS: "1.1",
    Predecesora: null,
    IdTipoActividad: 1,
    FechaInicioProg: "2020/06/16",
    FechaFinProg: "2020/06/26",
    DuracionProg: 8,
    IdAsignaciones: "619DE6A8-64E0-46B2-9D23-FE3882A4D7F4",
    CostoPlan: 0,
    CostoReal: 0,
    FechaInicioReal: "2020/06/29",
    FechaFinReal: "2020/07/10",
    DuracionReal: 10,
    FechaInicioLB0: null,
    FechaFinLB0: null,
    DuracionLB0: 0,
    DuracionRestante: 0,
    PctCompletado: 100,
    PctPlaneado: 0,
    FechaCreado: "2020/07/03",
    FechaModificado: "2020/07/06",
    Posicion: 1,
    Gerente: "Daniel Molina",
    text: "Probando Activvidad",
    startDate: "2020-06-16T05:00:00.000Z",
    endDate: "2020-06-26T05:00:00.000Z",
  };

  const getDateFromForm = (e) => {
    e.preventDefault();
    let dateValue = e.target.startDate.value
    let datePieces = dateValue.split("/")
    let dateCompa = new Date(datePieces[2],(datePieces[1]-1), datePieces[0])
    setCompStartDate((dateCompa.getTime()) - (18000000))

    let dateValueEnd= e.target.endDate.value;
    let datePiecesEnd = dateValueEnd.split("/")
    let dateCompaEnd = new Date(datePiecesEnd[2],(datePiecesEnd[1]-1), datePiecesEnd[0])
    setCompEndDate(dateCompaEnd.getTime() - (18000000))
  }


  const taskByProject = () => {
    if(compStartDate === null) {
      return myTasks.filter((task) => {
        return (props.filterTask.showCompleted
          ? true
          : task.PctCompletado < 100) && (props.filterTask.onlyFeatured
          ? task.Destacada
          : true);
      })
    }else if( compStartDate !== null && compEndDate !== null ) {
      let taskFilter = myTasks.filter((task) => {
        return (props.filterTask.showCompleted
          ? true
          : task.PctCompletado < 100) && (props.filterTask.onlyFeatured
          ? task.Destacada    
          : true);
      })

      if(compStartDate === compEndDate){
        let beforeDay = compStartDate 
        let afterDay = compEndDate + 18000700
        let taskFilterByDate = taskFilter.filter( ta => {
          if(ta.nFechaInicioReal === null) {
            ta.nFechaInicioReal = ta.nFechaInicioProg
          }else{
            ta.nFechaInicioReal = ta.nFechaInicioReal 
          }
          return (ta.nFechaInicioProg >= beforeDay && ta.nFechaInicioProg  <= afterDay) || (ta.nFechaInicioReal >= beforeDay && ta.nFechaInicioReal  <= afterDay) } )
      return taskFilterByDate

      } else if (compStartDate !== compEndDate) {
        let beforeDay = compStartDate
        let afterDay = compEndDate + 18000700
        let taskFilterByDate = taskFilter.filter( ta => { 
          if(ta.nFechaInicioReal === null) {
            ta.nFechaInicioReal = ta.nFechaInicioProg
          }
          return (ta.nFechaInicioProg >= beforeDay && ta.nFechaInicioProg  <= afterDay) || (ta.nFechaInicioReal >= beforeDay && ta.nFechaInicioReal  <= afterDay) } )
      return taskFilterByDate
      }
    }
  }


  const columns = [
    {
      caption: t("tasks_projects_table_column_manager"),
      selector: "Gerente",
      width: 180,
      customRender: (row) => {
        return <span>{row.data.Gerente}</span>;
      },
    },
    {
      caption: t("tasks_projects_table_column_methodology"),
      selector: "Metodologia",
      width: 110,
    },
    {
      caption: t("tasks_projects_table_column_activity"),
      selector: "NombreActividad",
      customRender: (row) => {
        return (
          <>
            <div>
              <span
                onClick={() => {
                  setSaveInfo(row.data);
                  handleShow();
                }}
                style={{ cursor: "pointer", color: "red" }}
              >
                {row.data.Destacada && (
                  <small>
                    <i class="fa fa-star text-warning mr-1"></i>{" "}
                  </small>
                )}
                {row.data.NombreActividad}
              </span>
            </div>
          </>
        );
      },
    },
    {
      caption: t("tasks_projects_table_column_plannedstart"),
      selector: "vFechaInicioProg",
      width: 110,
    },
    {
      caption: t("tasks_projects_table_column_plannedend"),
      selector: "vFechaFinProg",
      width: 110,
    },
    {
      caption: t("tasks_projects_table_column_realstart"),
      selector: "FechaInicioReal",
      width: 110,
    },
    {
      caption:t("tasks_projects_table_column_realend") ,
      selector: "FechaFinReal",
      width: 110,
    },
    {
      caption: t("tasks_projects_table_column_avanced"),
      selector: "PctCompletado",
      width: 110,
      customRender: (row) => {
        return <span>{row.data.PctCompletado}%</span>;
      },
    },
  ];

  const getProjectName = (uid) => {
    return myTasks.filter((task) => task.IdProyecto === uid)[0].NombreProyecto;
  };

  // useEffect(() => {
  //   setProjectsList(_.uniq(myTasks.map((item) => item.IdProyecto)));
  // }, [myTasks]);

  return (
    <React.Fragment>

<Form
        onSubmit= {getDateFromForm}
      >
        <Row>
          <Col>
            <Form.Group className="mb-0" >
            <FormLabel>{t("tasks_projects_searchdate_tilte")}</FormLabel> <br/>
            <Row className="task-group-1">
              <Col xl={2} md={2} >
                <ReactDatePicker
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                  wrapperClassName="w-100"
                  className="form-control"
                  required
                  placeholderText={t("tasks_projects_searchdate_from")}
                  name="startDate"
                  fixedHeight={false}
                  onChange={(date) => { setStartDate(date)}}
                />
              </Col>
              <Col xl={2} md={2} >
                <ReactDatePicker
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                  wrapperClassName="w-100"
                  className="form-control"
                  placeholderText={t("tasks_projects_searchdate_to")}
                  name="endDate"
                  fixedHeight={false}
                  required
                  onChange={(date) => { setEndDate(date)}}
                />
              </Col>
              <Col className="align-items-end d-flex">
                {compStartDate && (
                  <Button
                    className="mb-5 mb-sm-3 mr-2"
                    variant="light"
                    onClick={() => { 
                      setCompStartDate(null)
                      setCompEndDate(null)
                    }}
                  >
                    <i className="fa fa-times"></i>
                   </Button>
                   )}
                  <Button variant="outline-primary" className="mb-5 mb-sm-3" type="submit">
                    <i className="fa far fa-search"/>
                  </Button>
              </Col>
            </Row>
            </Form.Group>
          </Col>
            
        </Row>
      </Form>
      <hr />
      <DataGrid
        // dataSource={myTasks.filter((task) =>
        //   showCompleted ? true : task.PctCompletado < 100
        // )}
        dataSource={taskByProject()}
        allowColumnReordering={true}
        showBorders={true}
        //   onContentReady={this.onContentReady}
      >
        <GroupPanel visible={true} />
        <SearchPanel visible={true} placeholder={t("tasks_projects_searchbox")} />
        <Grouping autoExpandAll={false} />
        <Column dataField="NombreProyecto" caption={t("tasks_projects_table_column_project")} groupIndex={0} />
        {columns.map((itmCol, index) => {
          return (
            <Column
              key={index}
              dataField={itmCol.selector}
              caption={itmCol.caption}
              width={itmCol.width}
              cellRender={(row) => {
                return (
                  <>
                    {itmCol.customRender ? itmCol.customRender(row) : row.text}
                  </>
                );
              }}
            />
          );
        })}

        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        <Paging defaultPageSize={50} />
      </DataGrid>
      {/* {projectsList.length > 0
        ? projectsList.map((item, index) => {
            return (
              <Card key={item} className="mb-4 shadow-sm">
                <Card.Body className="p-3">
                  <Card.Title className="mb-md-n4">
                    <span className="text-primary">{index + 1}. </span>
                    {getProjectName(item)}
                  </Card.Title>
                  {}
                  <SuperTable
                    data={myTasks.filter(
                      (task) =>
                        task.IdProyecto === item &&
                        (showCompleted ? true : task.PctCompletado < 100)
                    )}
                    columns={columns}
                    height={300}
                  />
                </Card.Body>
              </Card>
            );
          })
        : ""} */}
        <TasksModalEdit
          saveData={saveData}
          taskEditParent = {saveInfo}
          setSaveInfo = {setSaveInfo}
          show = {props.show}
          handleClose = { props.handleClose }
          getAllData={props.getAllData}
          excludedDates={props.excludedDates}
          onlyForTaskInProject = {true}
        />
    </React.Fragment>
  );
};

export default React.memo(MyTasksByProject);
