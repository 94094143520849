import React from "react";
import ReactTooltip from "react-tooltip";

const MakeTooltips = (props) => {
  return (
    <ReactTooltip
      className="pwc-tooltip shadow-lg py-3"
      effect="solid"
      multiline={true}
      backgroundColor="white"
      borderColor="#29b9d0"
      textColor="#000000"
      arrowColor="#ffb600"
    />
  );
};


export default MakeTooltips;
