export const theme = {
  primary: "#e0301e",
  secondary: "#db526a",
  success: "#6ab41b",
  warning: "#ffb600",
  danger: "#f50000",
  light: "#f3f3f3",
  info: "#17a2b8",
  dark: "#343a40",
  muted: "#6c757d",
};