import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams, useLocation } from "react-router-dom";
import swal from "sweetalert";
import FormProjectGeneral from "../components/FormProjectGeneral";
import UserStoriesDeliverables from "../components/UserStoriesDeliverables";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProjects } from "../redux";
import { selectTooltip,selectTooltip_en } from "../utils/selectTooltip";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const EditProject = () => {
  const [t,i18n] = useTranslation("create")
  const [mainData, setMainData] = useState({ new: true });
  const [ddlForm, setDdlForm] = useState([]);
  const [currentProj, setcurrentProj] = useState(null);
  const [key, setKey] = useState("general");
  // const currentProj = useRef(null);

  const dispatch = useDispatch();

  let { uid } = useParams();
  const { search } = useLocation();
  let queryParams = new URLSearchParams(search);

  const getAllData = () => {
    Promise.all([getData(`project-detail/${uid}`, [])]).then((values) => {
      setMainData(values[0]);
      setcurrentProj(values[0]);
      // currentProj.current = values[0];
      const currentProje = values;
      
    });
  };
  useEffect(() => {
    dispatch(fetchAllProjects());
    if (uid) {
      getAllData();
    }
    Promise.all([getData("edit-project-ddls", [])]).then((values) => {
      setDdlForm(values[0]);
    });
    // return () => {
    //   // const [mainData, setMainData] = useState({ new: true });
    //   // const [ddlForm, setDdlForm] = useState([]);
    //   currentProj.current = null;
    // };
    if (queryParams.get("tab")) setKey(queryParams.get("tab"));
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: uid ? t("project_title_edit") : t("project_title_create"),
            icon: uid ? "pen" : "plus",
          }}
          color={uid ? "secondary" : "success"}
        /*   button={{
            text: "Volver",
            icon: "arrow-left",
            url: "/projects",
          }} */
          goBack={{
            text: t("project_title_back"),
            icon: "arrow-left",
          }}
        />
        <Container fluid>
          <Tab.Container
            activeKey={key}
            onSelect={(k) => setKey(k)}
            transition={false}
          >
            <Row className="bg-light">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="general">{t("project_title_generaldata")}</Nav.Link>
                  </Nav.Item>
                  {!mainData.new && (
                    <Nav.Item
                      data-tip={
                        (t("create_lenguaje") == "es")? 
                        selectTooltip(
                        mainData.Metodologia === "Tradicional" ? 22 : 23
                        ): 
                        selectTooltip_en(
                          mainData.Metodologia === "Tradicional" ? 22 : 23
                        )
                      }
                    >
                      <Nav.Link eventKey="deliverables">
                        {mainData.Metodologia === "Tradicional"
                          ? t("project_title_deliverables")
                          : t("project_title_user_history")}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="general"
                    className="animate__animated animate__fadeIn"
                  >
                    <FormProjectGeneral
                      mainData={mainData}
                      currentProj={currentProj}
                      setMainData={setMainData}
                      ddlForm={ddlForm}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="deliverables"
                    className="animate__animated animate__fadeIn"
                  >
                    {!mainData.new && (
                      <UserStoriesDeliverables
                        uid={uid}
                        method={mainData.Metodologia /*Tradicional o Ágil*/}
                      />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
        {!mainData.new && mainData.Metodologia === "Ágil" ? (
          <Button
            variant="info"
            as={Link}
            to={`/planning-board/${uid}`}
            // className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
            className="position-fixed"
            style={{ bottom: "1rem", left: "1rem" }}
          >
            <i className="fa fa-external-link-alt mr-2"></i>Planning
          </Button>
        ) : null}
      </Layout>
    </React.Fragment>
  );
};

export default EditProject;
