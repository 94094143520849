import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
import ErrorMsg from "../components/ErrorMsg";
import Loader from "../components/Loader";
import TagsEdit from "../components/TagsEdit";
import TagsModel from "../components/TagsModel";
import TitleCommon from "../components/TitleCommon";
import { desencry, removeData } from "../helpers";
import Layout from "../Layout";
import { fetchAllBoards } from "../redux";
import { fetchAllTags } from "../redux"
import { selectTooltip ,selectTooltip_en } from "../utils/selectTooltip";
import { useParams } from "react-router-dom";
import NoAccess from "./NoAccess";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const initialEditTags = {
    new: true,
    id: null,
    tagName: "",
    tagColor: "#cd5a91",
  };

const Tags = () => {
  const [t,i18n] =useTranslation("tags")
  const [selectedTab, setSelectedTab] = useState("");
  const [tagsEdit, setTagsEdit] = useState(initialEditTags)
  const [showModal, setShowModal] = useState(false);
  const access = window.localStorage.getItem("accessToBoards") //adaptar
  const desData = desencry(access,'accessCloudProjectDevBoard')//adaptar
  const handleShow = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setTagsEdit(initialEditTags)
  };
  let { uid } = useParams();

  const selectTagToEdit = (data) => {
    handleShow();
    setTagsEdit({
      new: false,
      id: data.Id,
      tagName: data.NombreEtiqueta || "",
      tagColor: data.Color || "#cd5a91",
    });
  };
  const deleteTag = (data) => {
    const tagDeleted = () => {
      dispatch(fetchAllTags(uid));
    };
    swal({
      title: t("tags_modal_warning_1"),
      text: t("tags_modal_warning_2"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        removeData(`tags/${uid}`, data.Id, tagDeleted); 
      }
    });
  };
  const tags = useSelector((state)=> state.tags)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllTags(uid))
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();  //adaptar
  });
  
  return (
  <>
    {(desData == "true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("tags_tittle"),
            icon: "tags",
          }}
          color="secondary"
          goBack={{
            text: t("tags_back"),
            icon: "arrow-left",
          }}
        />
        
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row className ="d-flex justify-content-center">
              <Col className="pt-3 pb-5 mb-5 w-100 mx-5 d-flex justify-content-center">
                <Tab.Content className="w-100">
                  <Tab.Pane
                    eventKey="first"
                    className="animate__animated animate__fadeIn"
                  >
                    {tags.loading ? (
                      <Loader />
                    ) : tags.error ? (
                      <ErrorMsg
                        title="¡Ups!"
                        msg="Hubo un error al cargar las Etiquetas."
                      />
                    ) : (
                      <React.Fragment>
                        {(tags.tags.length > 0 ) ? 
                        <p className="mb-4">
                          {t("tags_title_up_1")}{" "}
                          <b className="text-primary">
                            {t("tags_title_up_2")}
                          </b>
                          {t("tags_title_up_3")}
                        </p>
                        : 
                        <p className="mb-4">
                         {t("tags_title_up_4")} <b className="text-primary">
                            {t("tags_title_up_2")}
                          </b>, {t("tags_title_up_6")}{" "}
                          
                        </p>
                        }
                        <div className="table-with-dropdown mb-2">
                            <TagsModel
                             handleShow={handleShow}
                             selectTagToEdit={selectTagToEdit}
                             deleteTag={deleteTag}
                             data={tags.tags} 
                             addCard = {true}
                            />
                        </div>
                      </React.Fragment>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
      <TagsEdit
       showModal={showModal}
       handleClose={handleClose}
       tagsEdit={tagsEdit}
       setTagsEdit={setTagsEdit}
       idProject = {uid}
      />
      <React.Fragment>
        {!tags.error && !tags.loading ? (
          <Button
            variant="success"
            type="button"
            onClick={handleShow}
            className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
            style={{ bottom: "1rem", right: "1rem" }}
            data-tip={(t("tags_lenguaje") == "es")? selectTooltip(28):selectTooltip_en(28)}
          >
            <i className="fa fa-plus mr-2"></i>{t("tags_modal_create_button_create")}
          </Button>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  : 
  <>
   <NoAccess />
  </>
  }
   </> 
   );
};

export default Tags;
