import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import usersReducer from "./users/usersReducer";
import scrumReducer from "./scrum/scrumReducer";
import projectsReducer from "./projects/projectsReducer";
import boardsReducer from "./boards/boardsReducer";
import traditionalReducer from "./traditional/traditionalReducer";
import accessReducer from "./access/accessReducer";
import tagsReducer from "./tags/tagsReducer";

const rootReducer = combineReducers({
  users: usersReducer,
  projects: projectsReducer,
  boards: boardsReducer,
  traditional: traditionalReducer,
  scrum: scrumReducer,
  access: accessReducer,
  tags: tagsReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
