import { LOAD_BOARDS, ERROR_BOARDS } from "./boardsTypes";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";

export const fetchAllBoards = () => {
  return (dispatch) => {
    axios
      .get(`${API_ROUTE}boards`, headersRequest())
      .then((res) => {
        dispatch({
          type: LOAD_BOARDS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ERROR_BOARDS,
        });
      });
  };
};
