import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Nav,
  ProgressBar,
  Row,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
import emptyImg from "../assets/people-1.png";
import BoardsEdit from "../components/BoardsEdit";
import BoardsPanel from "../components/BoardsPanel";
import ErrorMsg from "../components/ErrorMsg";
import Loader from "../components/Loader";
import { StyledTabs } from "../components/StyledCommon";
import SuperTable from "../components/SuperTable";
import TitleCommon from "../components/TitleCommon";
import { desencry, removeData } from "../helpers";
import Layout from "../Layout";
import { fetchAllBoards } from "../redux";
import { selectTooltip ,selectTooltip_en } from "../utils/selectTooltip";
import NoAccess from "./NoAccess";
import { useTranslation } from "react-i18next";

const initialEditBoard = {
  new: true,
  id: null,
  boardName: "",
  boardColor: "#cd5a91",
};

const Boards = () => {
  const [t,i18n] =useTranslation("boards")
  const [selectedTab, setSelectedTab] = useState("");

  const [boardEdit, setBoardEdit] = useState(initialEditBoard);
  const [showModal, setShowModal] = useState(false);
  const access = window.localStorage.getItem("accessToBoards")
  const desData = desencry(access,'accessCloudProjectDevBoard')
  const handleShow = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setBoardEdit(initialEditBoard);
  };

  const selectBoardToEdit = (data) => {
    handleShow();
    setBoardEdit({
      new: false,
      id: data.SprintId,
      boardName: data.Nombre || "",
      boardColor: data.KanbanColor || "#cd5a91",
    });
  };

  const deleteBoard = (data) => {
    const boardDeleted = () => {
      dispatch(fetchAllBoards());
    };

    swal({
      title: t("boards_delete_1"),
      text: t("boards_delete_2"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        removeData("boards", data.SprintId, boardDeleted);
      }
    });
  };

  const boards = useSelector((state) => state.boards);
  const dispatch = useDispatch();

  const showCompletedProjects = useMemo(() => {
    let areThereCompletedProjects = boards.projects.find(
      (project) => project.PorCompletado === 100
    );
    if (areThereCompletedProjects) {
      return true;
    }
    return false;
  }, [boards]);

  const printBadgeStatus = useCallback((pctComplete) => {
    switch (pctComplete) {
      case 0:
        return (
          <div className="text-left">
            <span className="badge bg-warning text-white">Por Iniciar</span>
          </div>
        );
      case 100:
        return (
          <div className="text-left">
            <span className="badge bg-success text-white">Completado</span>
          </div>
        );

      default:
        return (
          <div className="text-left">
            <span className="badge bg-info text-white">En curso</span>
          </div>
        );
    }
  }, []);

  const columns = [
    {
      caption: "Nombre",
      selector: "Nombre",
      width: 400,
      customRender: (row) => {
        return (
          <div className="d-flex align-items-center dropdown-in-table">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="sm"
              ></Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/project/${row.data.IdProyecto}`}>
                  Editar proyecto
                </Dropdown.Item>
                {row.data.Metodologia === "Tradicional" ? (
                  <Dropdown.Item
                    as={Link}
                    to={`/schedule/${row.data.IdProyecto}`}
                  >
                    Ver cronograma
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    as={Link}
                    to={`/planning-board/${row.data.IdProyecto}`}
                  >
                    Product backlog
                  </Dropdown.Item>
                )}
                {row.data.Metodologia === "Tradicional" ? (
                  <Dropdown.Item
                    as={Link}
                    to={`/hv-project/${row.data.IdProyecto}`}
                  >
                    Ver informe
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    as={Link}
                    to={`/hv-agile/${row.data.IdProyecto}`}
                  >
                    Ver informe
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <span
              className="ml-2"
              style={{ maxWidth: "340px" }}
              title={row.data.Nombre}
            >
              <Link
                to={`/${
                  row.data.Metodologia === "Tradicional"
                    ? "schedule"
                    : "planning-board"
                }/${row.data.IdProyecto}`}
              >
                {row.data.Nombre}
              </Link>
            </span>
          </div>
        );
      },
    },
    {
      caption: "COD",
      selector: "CODProyecto",
      width: 200,
    },
    {
      caption: "Metodología",
      selector: "Metodologia",
      width: 150,
    },
    {
      caption: "Tipo",
      selector: "TipoProyecto",
      width: 200,
    },
    {
      caption: "Avance",
      align: "left",
      selector: "PctCompletadoProyecto",
      customRender: (row) => {
        return row.data.Metodologia === "Tradicional" ? (
          <div className="d-flex flex-column align-items-start mb-1">
            <small className="align-left">
              {row.data.PctPlaneadoProyecto}% Plan.
            </small>
            <div className="w-100">
              <ProgressBar
                now={row.data.PctPlaneadoProyecto}
                style={{ height: "5px" }}
                variant="info"
              />
            </div>
            <small className="align-left mt-1">
              {row.data.PctCompletadoProyecto}% Comp.
            </small>
            <div className="w-100">
              <ProgressBar
                now={row.data.PctCompletadoProyecto}
                style={{ height: "5px" }}
                variant="success"
              />
            </div>
          </div>
        ) : (
          printBadgeStatus(row.data.PctCompletadoProyecto)
        );
      },
      width: 200,
    },
    {
      caption: "Indicador",
      customRender: (data) => {
        let row = data.data;
        let desv = row.PctCompletadoProyecto - row.PctPlaneadoProyecto;
        switch (true) {
          case desv >= 0:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-check-circle text-success mr-2 my-auto"
                  title="Al Día"
                ></i>
                Al Día
              </div>
            );
          case desv > -10:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                  title="En Riesgo Moderado"
                ></i>
                Moderado
              </div>
            );
          case desv <= -10:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                  title="En Riesgo Alto"
                ></i>
                Crítico
              </div>
            );

          default:
            return (
              <div className="d-flex">
                <i
                  className="fa fa-minus-circle text-secondary  mr-2 my-auto"
                  title="Sin Clasificar"
                ></i>
                Sin Clasificar
              </div>
            );
        }
      },
      width: 120,
    },
    {
      caption: "Gerente",
      selector: "Gerente",
      width: 200,
    },
  ];

  useEffect(() => {
    dispatch(fetchAllBoards());
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const allBoards = boards.projects;
  const myBoards = allBoards.filter((board) => board.CreadoPorUsuario === 1 && board.PorCompletado < 100)
  const participeBoards = allBoards.filter((board) => board.CreadoPorUsuario === 0 && board.PorCompletado < 100)
  const allBoardsComplet = allBoards.filter((board)=> board.PorCompletado === 100)

  const savePageBoard = (value) => {
    localStorage.setItem('savePageBoard',value)
  }

  const currentPageBoard = localStorage.getItem("savePageBoard") || "first"
 
  return (
  <>
    {(desData == "true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("boards_title"),
            icon: "border-all",
          }}
          //   button={{
          //     text: "Volver",
          //     icon: "arrow-left",
          //     url: "/",
          //   }}
        />
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={currentPageBoard}
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row className="bg-light">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="first" onClick={()=>{savePageBoard("first")}}>{t("boards_tab_open")}</Nav.Link>
                  </Nav.Item>
                  {showCompletedProjects ? (
                    <Nav.Item>
                      <Nav.Link eventKey="second" onClick={()=>{savePageBoard("second")}}>{t("boards_tab_closed")}</Nav.Link>
                    </Nav.Item>
                  ) : null}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    className="animate__animated animate__fadeIn"
                  >
                    {boards.loading ? (
                      <Loader />
                    ) : boards.error ? (
                      <ErrorMsg
                        title="¡Ups!"
                        msg= {t("boards_loaderror")}
                      />
                    ) : (
                      <React.Fragment>
                        <p className="mb-4">
                          {t("boards_text1_1")}{" "}
                          <b className="text-primary">
                            {t("boards_text1_2")}
                          </b>
                        </p>
                        <div className="table-with-dropdown mb-2">
                          <BoardsPanel
                            handleShow={handleShow}
                            selectBoardToEdit={selectBoardToEdit}
                            deleteBoard={deleteBoard}
                            data={myBoards}
                            addCard = {true}
                            // data={_.filter(boards.projects, (item) =>
                            //   item.PctCompletadoProyecto < 100 ? true : false
                            // )}
                          />
                        </div>
                        {(participeBoards.length > 0) && <>
                        <p className="mb-4 mt-4">
                          {t("boards_text2_1")} {" "}
                          <b className="text-primary">
                            {t("boards_text2_2")}
                          </b>
                        </p>
                        <div className="table-with-dropdown">
                          <BoardsPanel
                            handleShow={handleShow}
                            selectBoardToEdit={selectBoardToEdit}
                            deleteBoard={deleteBoard}
                            data={participeBoards}
                            addCard= {false}
                          />
                        </div>
                        </>
                      }
                      </React.Fragment>
                    )}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    className="animate__animated animate__fadeIn"
                  >
                    <p className="mb-2 mb-md-4">
                      {t("boards_text1_1")}{" "}
                      <b className="text-primary">{t("boards_text1_3")}</b>
                    </p>
                    <div className="table-with-dropdown">
                     {/*  <SuperTable
                        data={_.filter(boards.projects, {
                          PorCompletado: 100,
                        })}
                        columns={columns}
                        withDd
                      /> */}

                          <BoardsPanel
                            handleShow={handleShow}
                            selectBoardToEdit={selectBoardToEdit}
                            deleteBoard={deleteBoard}
                            data={_.filter(boards.projects, {
                              PorCompletado: 100,
                            })}
                            addCard = {false}
                            // data={_.filter(boards.projects, (item) =>
                            //   item.PctCompletadoProyecto < 100 ? true : false
                            // )}
                          />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
      <BoardsEdit
        showModal={showModal}
        handleClose={handleClose}
        boardEdit={boardEdit}
        setBoardEdit={setBoardEdit}
      />
      <React.Fragment>
        {!boards.error && !boards.loading && boards.projects.length < 10 ? (
          <Button
            variant="success"
            type="button"
            onClick={handleShow}
            className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
            style={{ bottom: "1rem", right: "1rem" }}
            data-tip={(t("boards_lenguaje") == "es") ? selectTooltip(28) : selectTooltip_en(28)}
          >
            <i className="fa fa-plus mr-2"></i>{t("modal_boards_buttoncreate")}
          </Button>
        ) : null}
      </React.Fragment>
    </React.Fragment>
  : 
  <>
   <NoAccess />
  </>
  }
   </> 
   );
};

export default Boards;
