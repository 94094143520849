
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";



const ModalEditTaskTraditional = (props) => {

  const [resourceByTaskDefault, setResourceByTaskDefault] = useState([]);

  const [taskData, setTaskData] = useState({});

  const _getValue = (selector) => {
    // fn to return a valid value from state
    if (
      typeof taskData != "undefined" &&
      typeof taskData[selector] != "undefined"

    ) {
      let getVale = taskData[selector];
      const converDate = (getVale) => {
        let datePieces = getVale.split('/')
        return new Date(datePieces[2], (datePieces[1] - 1), datePieces[0])
      }
      let val = converDate(getVale);
      return val;
    } else {
      return null;
    }
  };
  const formTask = useRef();
  useEffect(() => {
    if (props.dataTask) {
      setTaskData(props.dataTask);
      /* setSelectedResources(props.data.current.metadata.resources || []); */
    }
  }, [props.show]);

  useEffect(() => {
    if (props.options) {
      setResourceByTaskDefault(props.options);
    }
  }, [props.options])

  const nameResources = () => {
    const nameResource = resourceByTaskDefault.map((reso) => {
      return reso.label
    })
    return nameResource
  }

  const names = nameResources()

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          props.setShowModalEditTask(false);
        }}
        size="md"
        contentClassName="border-0 shadow-lg"
      >
        <Modal.Header>
          <Modal.Title className="h5">Task Detail</Modal.Title> 
          <Button  onClick={() => {
                props.setShowModalEditTask(false);
              }}  variant="light">X</Button>
        </Modal.Header>
        <Form ref={formTask}>
          <Modal.Body>
            <Col>
              <Row>
                <Col md={11}>
                  <Form.Group >
                    <Form.Label>Title:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre del item"
                      name="title"
                      value={props.dataTask.Actividad || ""}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row >
              <Row >
                <Col md={11}>
                  <Form.Group >
                    <Form.Label >Start:<span className="text-danger">*</span></Form.Label>
                    <ReactDatePicker
                      wrapperClassName="w-100"
                      selected={
                        _getValue("FechaInicio")
                      }
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      disabled
                    />
                    <br />
                  </Form.Group>
                </Col>
              </Row>
              <Row >
                <Col md={11}>
                  <Form.Group >
                    <Form.Label>End:<span className="text-danger">*</span></Form.Label>
                    <ReactDatePicker
                      wrapperClassName="w-100"
                      selected={
                        _getValue("FechaFin") &&
                        moment(_getValue("FechaFin")).add(5, "hours")._d
                      }
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      disabled
                    />
                    <br />
                  </Form.Group>
                </Col>
              </Row >
              <Row  >
                <Col md={11}>
                  <Form.Group >
                    <Form.Label >
                      Progress:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      min="0"
                      max="100"
                      placeholder="Progreso"
                      name="progress"
                      value={`${props.dataTask.pctcompletado} %` || 0}
                      disabled
                    />
                    <br />
                  </Form.Group>
                </Col>
              </Row>
              <Row >
                <Col md={11}>
                  <Form.Group>
                    <Form.Label>Resources</Form.Label>
                    <div className="container_resource">
                      {(props.options.length !== 0) ? <>
                        {names.map((name, idx) => {
                          return <div className="resourcer_names" key={idx}>
                            <span >{name}</span> <p>x</p>
                          </div>
                        })}
                      </> : <>
                        <div className="resourcer_names">
                          <span>No hay recursos asignados para esta actividad.</span>
                        </div>
                      </>}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              </Row>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="dark"
              onClick={() => {
                props.setShowModalEditTask(false);
              }}
            >
              <i className="fa fa-times mr-2"></i>Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );

};

export default React.memo(ModalEditTaskTraditional);
// export default ModalEditTask;
