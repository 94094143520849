import axios from "axios";
import swal from "sweetalert";

export const deleteProjectById = (uid, success ,text) => {

  let config = {
    method: "delete",
    url: `${process.env.REACT_APP_API}projects/${uid}`,
    headers: {
      tokenuserid: localStorage.getItem("tokenuserid")
    }
  };
  axios(config)
    .then(() => {
      swal(text.text1, text.text2, "success").then(() => {
        if (success) success();
      });
    })
    .catch(() => {
      swal("¡Ups!", text.text3, "error");
    });
};
