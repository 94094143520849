import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const WelcomeStep = (props) => {
  const [t,i18n] = useTranslation("onBoarding")
  return (
    <div className="pt-3 text-center">
      <img src={props.img} width="250" className="mb-4" />
      <h5 className="text-dark mb-4">{props.title}</h5>
      <div>{props.msg}</div>
      <div className="d-flex justify-content-between mt-5">
        {props.totalSteps > 1 && props.currentStep > 1 ? (
          <Button variant="primary" onClick={props.previousStep} size="md">
            <i className="fa fa-arrow-left mr-2"></i>{t("stepbystep_welcome_button_previous")}
          </Button>
        ) : (
          <div></div>
        )}
        {props.currentStep === props.totalSteps ? (
          <Button variant="success" onClick={props.close} size="md">
            {t("stepbystep_welcome_button_known")}<i className="fa fa-check-circle ml-2"></i>
          </Button>
        ) : (
          <Button variant="primary" onClick={props.nextStep} size="md">
            {t("stepbystep_welcome_button_next")}<i className="fa fa-arrow-right ml-2"></i>
          </Button>
        )}
      </div>
    </div>
  );
};

export default WelcomeStep;
