import { locale } from "devextreme/localization";
import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import MyTasksByProject from "../components/MyTasksByProject";
import { StyledTabs } from "../components/StyledCommon";
import TasksCalendar from "../components/TasksCalendar";
import TasksModalEdit from "../components/TasksModalEdit";
import TitleCommon from "../components/TitleCommon";
import { desencry, excludedDates as daysList, getData } from "../helpers";
import Layout from "../Layout";
import { selectTooltip, selectTooltip_en } from "../utils/selectTooltip";
import NoAccess from "./NoAccess";
import { useTranslation } from "react-i18next";

locale("es");

const Tasks = () => {

  const access = window.localStorage.getItem("accessToTasks")
  const idProfileRol = window.localStorage.getItem("cm9sX3VzdWFyaW8=")
  const desData =(idProfileRol == 108 )? "false": desencry("U2FsdGVkX18FzKgyzqkUKKjVsN9ublyQFsOH2Z1WQSs=",'accessCloudProjectDevTask')
  const [t,i18n] = useTranslation("mytasks")

  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [myTasks, setMyTasks] = useState([]);
  const [userResume, setUserResume] = useState({});
  const [taskEdit, setTaskEdit] = useState({});
  const [showCompleted, setShowCompleted] = useState(false);
  const [filterTask, setFilterTask] = useState({
    showCompleted: false,
    onlyFeatured: false,
  });
  // tabs

  let excludedDates = useMemo(
    () =>
      daysList.map((item) => {
        return new Date(item);
      }),
    []
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   const currentDate = new Date(2020, 9, 13);
  const currentDate = Date.now();

  // const formatDate = (date) => {
  //   if (date) {
  //     let dateReturn = moment(date, "YYYY-MM-DD")._d;
  //     return dateReturn;
  //   } else {
  //     return null;
  //   }
  // };

  const getAllData = () => {
    Promise.all([
      getData("users", []),
      getData("my-tasks", []),
      getData("my-tasks-resume", []),
    ]).then((values) => {
      // setUsers(values[0]);
      let dummieTasks = values[1].map((item) => {
        return {
          ...item,
          text: item.NombreActividad,
          startDate: new Date(item.FechaInicioProg) || "",
          endDate: new Date(item.FechaFinProg) || "",
        };
      });
      setMyTasks(dummieTasks);
      console.log(values[2])
      setUserResume(values[2]);
    });
  };

  useEffect(() => {
    getAllData();
  }, []);
  
 
  const progressPerMonth = () => {
    if( userResume.AsignadasMes === 0) {
      return 0
    }else{
      return Math.round( (userResume.CompletadasMes / userResume.AsignadasMes) * 100)
    }
  }
  const rol = window.localStorage.getItem("rol")

  const [tab, setTab] = useState((rol == "Miembro de Equipo" || rol == 81)? "projects":"tasks");
/*   const [ tab, setTab]= useState("tasks") */

  let activitiesComple = myTasks?.filter((it)=> it.PctCompletado == 100).length
  let activitiesProgress = myTasks?.filter((it)=> it.PctCompletado > 0  && it.PctCompletado < 100).length
  let activitiesToBeStarted = myTasks?.filter((it)=> it.PctCompletado == 0).length
  const calAvance = Math.round( ( activitiesComple / myTasks?.length ) * 100) || 0

  return (<>
    { (desData ==="true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("tasks_title"),
            icon: "tasks",
            tooltip: t("tasks_lenguaje") == "es" ? selectTooltip(29): selectTooltip_en(29),
          }}
          // button={{
          //   text: "Volver",
          //   icon: "arrow-left",
          //   url: "/",
          // }}
        />
        <Container fluid>
          <Tab.Container
            defaultActiveKey={(rol == "Miembro de Equipo" || rol == 81)? "projects":"tasks"}
            /* defaultActiveKey="projects" */
           /*  activeKey={tab} */
            transition={false}
          >
            <Row className="bg-light">
              <Col>
                {(rol == "Miembro de Equipo" || rol == 81)?
                <StyledTabs as={Nav}>
                  <Nav.Item data-tip={t("tasks_lenguaje") == "es" ? selectTooltip(30): selectTooltip_en(30)}>
                    <Nav.Link
                      eventKey="projects"
                      onClick={() => setTab("projects")}
                    >
                      {t("task_button_projects")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item data-tip={t("tasks_lenguaje") == "es" ? selectTooltip(38) : selectTooltip_en(38)}>
                    <Nav.Link eventKey="tasks" onClick={() => setTab("tasks")}>
                      {t("task_button_calendar")}
                    </Nav.Link>
                  </Nav.Item>
                </StyledTabs>
                :
                <StyledTabs as={Nav}>
                  <Nav.Item data-tip={t("tasks_lenguaje") == "es" ? selectTooltip(38) : selectTooltip_en(38)}>
                    <Nav.Link eventKey="tasks" onClick={() => setTab("tasks")}>
                      {t("task_button_calendar")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item data-tip={t("tasks_lenguaje") == "es" ? selectTooltip(30) : selectTooltip_en(30)}>
                    <Nav.Link
                      eventKey="projects"
                      onClick={() => setTab("projects")}
                    >
                      {t("task_button_projects")}
                    </Nav.Link>
                  </Nav.Item>
                </StyledTabs>
                }
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="tasks"
                    className="animate__animated animate__fadeIn"
                  >
                    {tab === "tasks" ? (
                      <>
                        <div className="mb-3 d-flex justify-content-between">
                          <span>
                            {t("tasks_calendar_tasksassigned_1")}{" "}
                            <b className="text-primary">{t("tasks_calendar_tasksassigned_2")}</b>{" "}
                            {t("tasks_calendar_tasksassigned_3")}
                          </span>
                          <Form.Check
                            type="switch"
                            id="show-completed-schedule"
                            label={t("tasks_calendar_switchbutton")}
                            onChange={(e) => {
                              // document.getElementById(
                              //   "show-completed"
                              // ).checked = e.target.checked;
                              setShowCompleted(e.target.checked);
                            }}
                          />
                        </div>
                        <Row>
                          <Col>
                            <Card className="bg-light border-0 mb-3">
                              <Card.Body className="pt-3 pb-3 pl-2 pr-2">
                                <Card.Title className="m-0">
                                  <i className="fa fa-user mr-2 text-secondary"></i>
                                  <small>{t("tasks_calendar_currentprogress_percent")}</small><b className="h5"> {progressPerMonth()}%</b>
                                  {/* <b> {avance} %</b> */}
                                  { (progressPerMonth() === 0 ) && <i title="Por iniciar" className="ml-2 fa fa-stop-circle text-muted"></i> }
                                  { (progressPerMonth() === 100 ) && <i title="Completado" className="ml-2 fa fa-check-circle text-success"></i>}
                                  { (progressPerMonth() >= 1 && progressPerMonth() <= 99 ) && <i title="En curso" className="ml-2 fa fa-play-circle text-info"></i> }
                                  {/*  <i className="fa fa-check-circle text-success mr-2 animate__animated animate__pulse animate__infinite"></i> */}
                                  {/*{userResume.Desempeno} */}
                                </Card.Title>
                              </Card.Body>
                            </Card>
                            <h5>
                              {t("tasks_calendar_currentprogress_month_1")} <small>{t("tasks_calendar_currentprogress_month_2")}</small>
                            </h5>
                            <ListGroup className="mb-2 mb-4">
                              <ListGroup.Item>
                                <i className="fa fa-business-time text-info mr-2"></i>
                                {userResume.AsignadasMes}{" "}
                                <small>{t("tasks_calendar_currentprogress_assigned")}</small>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <i className="fa fa-check-circle text-success mr-2"></i>
                                {userResume.CompletadasMes}{" "}
                                <small>{t("tasks_calendar_currentprogress_complete")}</small>
                              </ListGroup.Item>
                            </ListGroup>
                            <h6>
                              {t("tasks_calendar_currentprogress_quarter")} <small>{t("tasks_calendar_currentprogress_month_2")}</small>
                            </h6>
                            <ListGroup variant="flush" className="mb-2">
                              <ListGroup.Item>
                                <i className="fa fa-business-time text-info mr-2"></i>
                                {userResume.AsignadasTrimestre}{" "}
                                <small>{t("tasks_calendar_currentprogress_assigned")}</small>
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <i className="fa fa-check-circle text-success mr-2"></i>
                                {userResume.CompletadasTrimestre}{" "}
                                <small>{t("tasks_calendar_currentprogress_complete")}</small>
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                          <Col md={9}>
                            <div className="d-flex small mb-2">
                              <div className="mr-3">
                                <i
                                  className="mr-2 fa fa-circle"
                                  style={{ color: "#E0301E" }}
                                ></i>
                                <span>
                                  {t("tasks_calendar_calendar_conventions_overdue")}</span>
                              </div>
                              <div className="mr-3">
                                <i
                                  className="mr-2 fa fa-circle"
                                  style={{ color: "#00B1C4" }}
                                ></i>
                                <span>{t("tasks_calendar_calendar_conventions_inprogress")}</span>
                              </div>
                              <div className="mr-3">
                                <i
                                  className="mr-2 fa fa-circle"
                                  style={{ color: "#2D9E41" }}
                                ></i>
                                <span>{t("tasks_calendar_calendar_conventions_ontime")}</span>
                              </div>
                              <div className="mr-3">
                                <i
                                  className="mr-2 fa fa-circle"
                                  style={{ color: "gray" }}
                                ></i>
                                <span>{t("tasks_calendar_calendar_conventions_unstarted")}</span>
                              </div>
                            </div>
                            {/* <TasksCalendar
                          timeZone="America/Bogota"
                          dataSource={myTasks}
                          views={["month"]}
                          defaultCurrentView="month"
                          editing={false}
                          defaultCurrentDate={currentDate}
                          height={500}
                          startDayHour={8}
                          onAppointmentClick={(e) => {
                            handleShow();
                            setTaskEdit(e.appointmentData);
                          }}
                          appointmentTemplate={(model) => {
                            return (
                              "<div class='act_" +
                              model.targetedAppointmentData.Estado +
                              "'>" +
                              model.targetedAppointmentData.NombreActividad +
                              "</div>"
                            );
                          }}
                          appointmentTooltipTemplate={(model) => {
                            moment.locale("es");
                            return (
                              "<div class='dx-tooltip-appointment-item'><div class='dx-tooltip-appointment-item-marker'><div class='dx-tooltip-appointment-item-marker-body act_" +
                              model.targetedAppointmentData.Estado +
                              "'></div></div>" +
                              "<div class='dx-tooltip-appointment-item-content'><div class='dx-tooltip-appointment-item-content-subject'>" +
                              model.targetedAppointmentData.NombreActividad +
                              "</div><div class='dx-tooltip-appointment-item-content-date'>" +
                              ` ${moment(
                                model.targetedAppointmentData.FechaInicioProg
                              ).format("D/MMMM HH:mm")} - ${
                                model.targetedAppointmentData.FechaFinProg
                              }` +
                              "</div></div></div>"
                            );
                          }}
                        /> */}
                            <TasksCalendar
                              myTasks={myTasks}
                              showCompleted={showCompleted}
                              currentDate={currentDate}
                              handleShow={handleShow}
                              /* setTaskEdit={setTaskEdit} */
                             /*  taskEdit={taskEdit} */
                              show={show}
                              handleClose={handleClose}
                              getAllData={getAllData}
                              excludedDates={excludedDates}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="projects"
                    className="animate__animated animate__fadeIn"
                  >
                    {tab === "projects" ? (
                      <>
                        <div className="mb-1 d-flex justify-content-between btn-task-group">
                          <span>
                            {t("tasks_calendar_tasksassigned_1")}{" "}
                            <b className="text-primary">{t("tasks_calendar_tasksassigned_2")}</b>{" "}
                            {t("tasks_calendar_tasksassigned_3")}
                          </span>
                          <div className="d-flex">
                            <Form.Check
                              type="switch"
                              id="show-completed"
                              label={t("tasks_calendar_switchbutton")}
                              className="mr-4"
                              onChange={(e) => {
                                // document.getElementById(
                                //   "show-completed-schedule"
                                // ).checked = e.target.checked;
                                setFilterTask((currFilter) => {
                                  return {
                                    ...currFilter,
                                    showCompleted: e.target.checked,
                                  };
                                });
                                // setShowCompleted(e.target.checked);
                              }}
                            />
                            <Form.Check
                              type="switch"
                              id="show-featured"
                              // label="Solo destacadas"
                              label={
                                <>
                                  {t("tasks_projects_switchbutton")}{" "}
                                  <i
                                    className={`fa fa-star mr-1 ${
                                      filterTask.onlyFeatured
                                        ? "text-warning"
                                        : "text-light"
                                    }`}
                                  ></i>
                                </>
                              }
                              onChange={(e) => {
                                setFilterTask((currFilter) => {
                                  return {
                                    ...currFilter,
                                    onlyFeatured: e.target.checked,
                                  };
                                });
                                // setShowCompleted(e.target.checked);
                              }}
                            />
                          </div>
                          {/* <Form.Check
                            type="switch"
                            id="show-featured"
                            label="Ver Destacadas"
                            onChange={(e) => {
                              document.getElementById(
                                "show-featured-schedule"
                              ).checked = e.target.checked;
                              setShowCompleted(e.target.checked);
                            }}
                          /> */}
                        </div>
                        <div className="d-flex small justify-content-between align-items-center container-fluid py-2 px-0 mb-3 btn-group-kanban">
                          <div className="d-flex group-kanban-1" style={{gap:"15px"}}>
                            <div className="d-flex group-kanban-2">
                              <h6 className="mb-0 mr-1">{t("tasks_projects_summary")}</h6> <span>{t("tasks_projects_totaltask")}
                              { myTasks?.length }
                               </span>
                              <div className="mx-2 group-kanban-3">
                                  <span className="mr-2">
                                  <i style={{color:"#e9ecef"}} className="fa fa-circle mr-1"></i> {t("tasks_calendar_calendar_conventions_unstarted")}
                                  ( {activitiesToBeStarted} )
                                  </span>
                                  <span className="mr-2">
                                    <i className="fa fa-circle text-info mr-1"></i> {t("tasks_calendar_calendar_conventions_inprogress")}
                                    ( {activitiesProgress} )
                                  </span>
                                  <span className="">
                                    <i className="fa fa-circle text-success mr-1"></i> {t("tasks_calendar_currentprogress_complete")}
                                    ( {activitiesComple} )
                                  </span>
                            </div>
                            </div>
                            <div className="d-flex">
                              <span className="h6 mb-0 mr-1">{t("modal_tasks_progress")}</span>
                              <span className="h6 mb-0">{calAvance} %</span>
                            </div>
                          </div>
                        </div>
                        <MyTasksByProject
                          {...{
                            myTasks,
                            setTaskEdit,
                            filterTask,
                            showCompleted,
                            handleShow,
                            show,
                            handleClose,
                            getAllData,
                            excludedDates
                          }}
                        />
                      </>
                    ) : null}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    {/*   <TasksModalEdit
        taskEditParent={taskEdit}
        show={show}
        handleClose={handleClose}
        getAllData={getAllData}
         excludedDates={excludedDates}
         formatDate={formatDate}
         calcularDuracion={calcularDuracion}
         inputChange={inputChange}
         isWeekEnd={isWeekEnd}
         saveData={saveData}
      /> */}
    </React.Fragment>
     :<>
          <NoAccess />
     </>
     }
    </>
  );
};

export default Tasks;
