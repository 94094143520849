import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import {
  getData,
  saveData,
  inputChange,
  alreadyExists,
  saveDataScrum,
  desencry,
} from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import ProductBacklog from "../components/ProductBacklog";
import SprintBacklog from "../components/SprintBacklog";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProjectGeneral,
  createBacklogLane,
  updateBacklog,
  fetchProjectColumnsBacklogSprints,
  fetchProjectUserStories,
  fetchProjectDdlUserStories,
  fetchProjectResources,
  fetchProjectDdlSprintStatus,
  fetchProjectTags,
  fetchAllTags,
  fetchAllTagsProject,
} from "../redux";
import Loader from "../components/Loader";
import emptyImg from "../assets/iso-scrum.png";
import emptyImgHu from "../assets/iso.png";
import ReactTooltip from "react-tooltip";
import UserStories from "../components/ProductBacklog/UserStories";
import ActivitiesByResources from "../components/ProductBacklog/ActivitiesByResources";
import { exportToExcel } from "../services/agile";
import exportFromJSON from "export-from-json";
import SearchTasks from "../components/SearchTasks";
import NoAccess from "./NoAccess";
import ActivitiesByTags from "../components/ProductBacklog/ActivitiesByTags";
import AttachmentsByProject from "../components/ProductBacklog/AttachmentsByProject";
import { useTranslation } from "react-i18next";
import HightlightActivities from "../components/ProductBacklog/HightlightActivities";
import GanttView from "../components/ProductBacklog/GanttView";

const PlanningBoard = () => {
  const [t,i18n] =useTranslation("planning")

  const access = window.localStorage.getItem("accessToPlanningBoard")
  const desData = desencry(access,'accessCloudProjectDevPlanningBoard')

  const [showStories, setShowStories] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [summary, setSummary] = useState(false);

  const [noLoader,setNoLoader]= useState(false)
  const [actiAnimation, setActiAnimation]= useState()
  const [allLanes, setAllLanes]= useState([])

  const [updateUS, setUpdateUS] = useState(false)
  const [updateActiByReso, setUpdateActiByReso] = useState(false)
  const [updateTags, setUpdateTags] = useState(false)

  const [activitiesCant,setActivitiesCant] = useState([]);
  const [allFilesByProject,setAllFilesByProject] = useState([]);
  const [filterSpsByStatus, setFilterSpsByStatus] = useState(parseInt(localStorage.getItem("filterSpStatus")) || null)
  const [view , setView] = useState("productbacklog")
  const handleCloseStories = () => setShowStories(false);
  const handleShowStories = () => setShowStories(true);
  const handleCloseResources = () => setShowResources(false);
  const handleShowResources = () => setShowResources(true);
  const handleShowTags = () => setShowTags(true);
  const handleCloseTags = () => setShowTags(false);
  const handleShowFiles = () => setShowFiles(true);
  const handleShowHight = () => setHighlight(true);
  const handleCloseFiles = () => setShowFiles(false);
  const handleCloseHight = () => setHighlight(false);
  const handleProjectSummary = (val) => {
    setSummary(val => val =! val)
  }


  /* const handleShowHelps = (id) => {
   
    setIdHelp(id)
    console.log(id)
    setShowHelps(true);
  } */

  
  const { uid } = useParams();
  const scrum = useSelector((state) => state.scrum);
  const dispatch = useDispatch(); 

  const tasksForSuggest = useMemo(
    () => scrum.backlog.data.lanes.map((lane) => lane.cards).flat(2),
    [scrum]
  );

  const createBacklog = () => {
    let laneData = {
      projUID: uid,
      title: "Product Backlog",
      backlog: true,
    };
    saveDataScrum("agile-lanes", laneData)
      .then((res) => {
        dispatch(
          updateBacklog({
            lanes: [{ ...res }],
          })
        );
        // dispatch(createBacklogLane(uid));
      })
      .catch((error) => {
        swal("¡Ups!", "Hubo un error al crear el Product Backlog", "error");
    });
  };

  const exportData = async () => {
    let result = await exportToExcel(uid);
    if (result.data.length) {
      const data = result.data;
      const fileName = `tasks-${encodeURI(scrum.general.Nombre)}`;
      const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data, fileName, exportType });
    } else {
      swal("¡Ups!", "Sin datos para exportar.", "warning");
    }
  };

  const getAllprojectData = () => {
    if (uid) {
      dispatch(fetchProjectColumnsBacklogSprints(uid));
    }
  }

  const handleFilterSpsByStatus = (statusId) => {
    if (!statusId) {
      localStorage.removeItem("filterSpStatus");
      window.location.reload();
      return;
    }
    localStorage.setItem("filterSpStatus", statusId);
    window.location.reload();
  };

  const handleHelpers = (helperId) => { 
    console.log(helperId)
  }

  const getAllData = () => {
    Promise.all([
      /* getData(`hv-project-agile/${uid}`, []), */
      getData(`agile-project-attachments/${uid}`,[])])
      .then((values) => {
      /* setActivitiesCant(values[0]); */
      /* setAllFilesByProject(values[1]); */
      setAllFilesByProject(values[0]);
    });
  };

  const getAllDataAct = () => {

    Promise.all([
      getData(`hv-project-agile/${uid}`, [])])
      .then((values) => {
      setActivitiesCant(values[0]);
    });
  };

  const getAllLanes = () => {
    Promise.all([
      getData(`product-backlog-sprints/${uid}`, [])])
      .then((values) => {
        setAllLanes(values[0])
    });
  };

  useEffect(() => {
    if (uid) {
      getAllprojectData()
      dispatch(fetchProjectGeneral(uid));
      dispatch(fetchProjectUserStories(uid));
      dispatch(fetchProjectDdlUserStories(uid));
      dispatch(fetchProjectDdlSprintStatus());
      dispatch(fetchProjectResources(uid));
      dispatch(fetchProjectTags(uid))
      getAllData()
      getAllDataAct()
      getAllLanes()
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  
  const arraytest = [
    {Id:1,
    EstadoSprint:"inicio"
  },
  {Id:2,
    EstadoSprint:"medio"
  },
  {Id:3,
    EstadoSprint:"terminado"
  }
  ]

  const helpers = [
    {Id:1,
    HelpTitle:"Creación Sprint"
  },
  {Id:2,
    HelpTitle:"Ordenar tablas recursos"
  },
  {Id:3,
    HelpTitle:"Ordenar tabla historias"
  }
  ]

  const enableCost = (scrum.general.IdCostosAgil == "SI" || scrum.general.IdCostosAgil == "YES") ? true : false
  
  const costoPlan = `$ ${new Intl.NumberFormat().format(
    activitiesCant.CostoPlaneado || 0
  )}`;
  
  const costoReal = `$ ${new Intl.NumberFormat().format(
    activitiesCant.CostoReal || 0
  )}`;
  
  //id comite advisory pwc Colombia
  const idOrganization = localStorage.getItem('OID')
  const idUser = localStorage.getItem('UID')
  const idSuperAdmin = '4D4C695F-EBDF-4C9D-8F98-DAF391239E13' // real
  //iduser es el de angelica
  //id organizacion pwc colombia activitiesCant?.IdUsuario 
  const conditionManagerAdvisory = ( 
    idOrganization == 'B5B7D93B-D594-42FF-AD89-12901EBAEFAE' && idUser != idSuperAdmin  && activitiesCant?.IdUsuario != idUser) 

  const animateIn = `animate__animated animate__fadeInUp animate__repeat-1`
  const animateOut = 'animate__animated animate__fadeInDown animate__repeat-1'

  return (<>
    { (desData ==="true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon className="mb-5"
          title={{
            text: "Planning Board",
            icon: "chalkboard-teacher"
          }}
          color="secondary"
          /* button={{
            text: t("planningboard_button_back"),
            icon: "arrow-left",
            url: "/projects"
          }} */
          goBack={{
            text: t("planningboard_button_back"),
            icon: "arrow-left",
          }
          }
          btnPB
        />
   {/*       <div className="bg-light" >
              <Col >
                <StyledTabs as={Nav} >
                  <Nav.Item data-tip={(t("projects_lenguaje") == "es")? selectTooltip(24) : selectTooltip_en(24)}>
                    <Nav.Link defaultChecked eventKey="first" onClick={()=>savePage("first")} data-tour="step-3">
                      {t("projects_button_inprogress")}
                     Planning Board
                    </Nav.Link>
                  </Nav.Item>
                 
                  <Nav.Item data-tip={selectTooltip(25)}>
                      <Nav.Link eventKey="second" onClick={()=>savePage("third")}>
                      Historias de Usuario</Nav.Link>
                    </Nav.Item>
                    <Nav.Item data-tip={selectTooltip(25)}>
                      <Nav.Link eventKey="third" onClick={()=>savePage("third")}>
                      Épicas</Nav.Link>
                    </Nav.Item>
                </StyledTabs>
              </Col>
            </div> */}
        <Container fluid className="bg-light">
          <Row>
            <Col className="py-2">
              <div className="d-flex flex-column flex-lg-row align-items-center pb-btn-tablet">
                  {/*    <h5 className="mr-3 mb-3 mb-lg-0">
                    <span className="text-primary">{t("hvagile_projectname")} </span>
                    {(scrum.general.Nombre?.length > 25) ?
                    <span className="scrum-text-pb" title={scrum.general.Nombre}>{scrum.general.Nombre.slice(0,25)}...</span>
                    :
                    <span>{scrum.general.Nombre}</span>
                    }
                  
                  </h5> */}
                <Col md={5}>           
                  {(scrum.general.Nombre?.length > 25) ? 
                  <h6 className="mr-1 mb-0 mb-lg-0">
                      <span className="text-primary h5">{t("hvagile_projectname")} </span>
                      <span className="scrum-text-pb" title={scrum.general.Nombre}>{scrum.general.Nombre.slice(0,38)}...</span>
                  </h6>
                  :
                  <h5 className="mr-3 mb-0 mb-lg-0">
                    <span className="text-primary">{t("hvagile_projectname")} </span>
                    <span>{scrum.general.Nombre}</span>
                  </h5>}
                 {/*  <SearchTasks data={tasksForSuggest} costW ="180px" title_search= {t("planningboard_button_searchbox")} /> */}
                </Col>
                <Col lg={7} md={12}>
                <div className="d-flex flex-column flex-lg-row all-btns-pb">
                  
                    <div className="d-flex mb-3 mb-lg-0 btn-planning-board">
                    <Dropdown className="mx-1 btn-pb-views-father">
                      <Dropdown.Toggle size="sm" variant="outline-info" id="dropdown-basic" className="btn-pb-views">
                        Vistas
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> setView("productbacklog") } >Planning Board</Dropdown.Item>
                        <Dropdown.Item onClick={()=> setView("sprintsView")} >Timeline Sprints </Dropdown.Item>
                        <Dropdown.Item onClick={()=> setView("huView")} >Timeline HU </Dropdown.Item>
                     {/*    <Dropdown.Item onClick={()=> setView("epicsView")} >Timeline Épicas </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle
                          variant={filterSpsByStatus ? "info" : "outline-info"}
                          size="sm"
                          id="dropdown-basic"
                          className="mr-2 d-flex align-items-center btn-pb-filter"
                          title={t("planningboard_button_filter_x_status")}
                        >
                          <i className="fa fa-filter mr-2"></i>{t("planningboard_button_filter")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu 
                          id="dropdown-button-dark-example2"
                          variant="secondary"
                          >
                          { arraytest !== undefined &&
                            arraytest.map((item) => {
                              return (
                                <Dropdown.Item
                                  key={item.Id}
                                  onClick={() => handleFilterSpsByStatus(item.Id)}
                                  className={
                                    filterSpsByStatus === item.Id
                                      ? "bg-info text-white"
                                      : "bg-filterOnActive"
                                  }
                                >{(item.EstadoSprint === "inicio") && <>{t("planningboard_button_filter_option1")}</> }
                                {(item.EstadoSprint === "medio") && <>{t("planningboard_button_filter_option2")}</> }  
                                {(item.EstadoSprint === "terminado") && <>{t("planningboard_button_filter_option3")}</> } 
                                  {/* SP {item.EstadoSprint} */}
                                </Dropdown.Item>
                              );
                            })}
                          {filterSpsByStatus && (
                            <Dropdown.Item
                              className="bg-filterOnActive"
                              onClick={() => handleFilterSpsByStatus(null)}
                            >
                              <i className="fa fa-times mr-2"></i>{t("planningboard_button_filter_option4")}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                      <Col className="border-right border-left border-dark btn-pb-hre" >
                        <Row className="btn-pb-hre-son">
                            <Dropdown className="ml-2 btn-pb-hre-son-us">
                            <Dropdown.Toggle
                              variant="outline-dark"
                              size="sm"
                            /*  id="dropdown-basic" */
                              className="mr-1 d-flex align-items-center"
                              title={t("hvagile_userstories_tittle")}
                            >
                              <i className="fa fa-tasks mr-2"></i>{t("planningboard_button_stories")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu 
                            /*  id="dropdown-button-dark-example2"
                              variant="secondary" */
                              >
                                <Dropdown.Item
                                    as={Link}
                                    to={`/project/${uid}?tab=deliverables`}
                                  className="bg-tags"
                                >{t("planningboard_button_stories_option1")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={handleShowStories}
                                  className="bg-tags"
                                >{t("planningboard_button_stories_option2")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                          <Button
                          variant="outline-dark"
                          size="sm"
                          title={t("planningboard_resources_tittle")}
                          onClick={handleShowResources}
                          className="mr-1"
                        >
                          <i className="fa fa-users mr-2"></i>{t("planningboard_button_resources")}
                        </Button>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-dark"
                            size="sm"
                            className="mr-2 d-flex align-items-center btn-pb-hre-son-tags"
                            title={t("planningboard_button_tags")}
                          >
                            <i className="fa fa-tags mr-2"></i>{t("planningboard_button_tags")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu 
                            >
                              <Dropdown.Item
                                  as={Link}
                                  to={`/tags/${uid}}`}
                                className="bg-tags"
                              > {t("planningboard_button_labels_option1")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={handleShowTags}
                                className="bg-tags"
                              > {t("planningboard_button_labels_option2")}
                              </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </Row>
                      </Col>
                    </div>
                        <div className="btn-pb-agru">
                          <div className="d-flex justify-content-between pb-2">
                            <Button
                              variant="outline-primary"
                              className="ml-2 btn-pb-informe"
                              size="sm"
                              as={Link}
                              to={`/hv-agile/${uid}`}
                            >
                              <i className="fa fa-tachometer-alt mr-2"></i>{t("planningboard_button_report")}
                            </Button>
                            <Button
                              className="ml-2 btn-pb-show"
                              variant="outline-primary"
                              size="sm"
                              title="Ver Resumen"
                              onClick={handleProjectSummary}
                            >
                              <i className="fa fa-eye"></i>
                            </Button>
                            <Button
                              className="ml-2 btn-pb-expor"
                              variant="info"
                              size="sm"
                              title={t("planningboard_button_export")}
                              onClick={exportData}
                            >
                              <i className="fa fa-download"></i>
                            </Button>
                          </div>
                          <div className="d-flex justify-content-between pb-2">
                            <Button
                              className="ml-2 btn-pb-adjuntos"
                              variant="outline-info"
                              size="sm"
                              title={t("planningboard_button_attachments")}
                              onClick={handleShowFiles}
                            >
                              <i className="fa fa-paperclip"></i>
                            </Button>
                            <Button
                              className="ml-2 btn-pb-hight"
                              variant="outline-warning"
                              size="sm"
                              title={t("planningboard_button_hight")}
                              onClick={handleShowHight}
                            >
                              <i className="fa fa-star"></i>
                            </Button>
                          </div>
                        </div>
                      
                  </div>
                 {/*  <SearchTasks data={tasksForSuggest} costW ="180px" title_search= {t("planningboard_button_searchbox")} /> */}
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
        {summary && <> 
        <div
          className={`d-flex small flex-column  pt-2 tags-pb ${(summary)? animateOut : ""}`} 
          style={{ marginBottom: "-5px" }}
        >
            <div className="d-flex px-5 ">
            <Col md={3} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                  ( {activitiesCant.SinIniciar} )
              
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                    <i className="fa fa-circle text-light"></i> {t("planningboard_conventions_notstarted")}
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                  ( {activitiesCant.EnCurso} )
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                    <i className="fa fa-circle text-info"></i> {t("planningboard_conventions_inprogress")}
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                  ( {activitiesCant.Terminadas} )
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                  <i className="fa fa-circle text-success"></i> {t("planningboard_conventions_finished")}
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                  ( {activitiesCant.CantActividades} )
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                  <i className="fa fa-tasks text-primary mr-2"></i> {t("planningboard_conventions_activities")} 
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            </div>
            <div className="d-flex px-5">
            <Col md={6} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                  {costoPlan}
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                  <i className="fa fa-money-bill-wave mr-2 text-info mr-2"></i>Costo Planeado 
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                    {costoReal}
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                    <i className="fa fa-money-bill-wave mr-2 text-success mr-2"></i>Costo Ejecutado 
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md={4} className="p-1">
            <Card >
                <Card.Body className="d-flex flex-column align-items-center p-2">
                  <Card.Subtitle >
                  50
                  </Card.Subtitle>
                  <Card.Text>
                  <span className="mr-2 text-act">
                  <i className="fa fa-clock mr-2 text-danger mr-1"></i>Horas a totales a Ejecutar
                  </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col> */}
            </div>
            {/* <span className="mr-2 text-act">
              <i className="fa fa-circle text-light"></i> {t("planningboard_conventions_notstarted")} ( {activitiesCant.SinIniciar} )
            </span> */}
            {/* <span className="mr-2 text-act">
              <i className="fa fa-circle text-info"></i> {t("planningboard_conventions_inprogress")} ( {activitiesCant.EnCurso} )
            </span> */}
            {/* <span className="mr-2 text-act">
              <i className="fa fa-circle text-success"></i> {t("planningboard_conventions_finished")} ( {activitiesCant.Terminadas} )
            </span> */}
            {/*   <span className="mr-2 text-act">
              <i className="fa fa-tasks text-primary mr-2"></i> {t("planningboard_conventions_activities")} ( {activitiesCant.CantActividades} )
            </span> */}
            {/*      <span className="mr-2 text-act">
              <i className="fa fa-money-bill-wave mr-2 text-info mr-2"></i>Costo Planeado: {costoPlan}
            </span> */}
            {/*    <span className="mr-2 text-act">
              <i className="fa fa-money-bill-wave mr-2 text-success mr-2"></i>Costo Ejecutado: {costoReal}
            </span> */}
            {/*   <span className="mr-2 text-act">
              <i className="fa fa-clock mr-2 text-danger mr-1"></i>Horas a totales a Ejecutar
            </span> */}
          </div>
        </>}
        <div className={`${view == "productbacklog" ? "mx-2 mt-2 d-flex justify-content-between pb-search-conv": "d-none"}`}>
          <div
            className={`d-flex small container-fluid pt-2 tags-pb`}
            style={{ marginBottom: "-10px" }}
          >
            <span className="mr-2 text-act">
              <i className="fa fa-scroll text-muted"></i> {t("planningboard_conventions_hu")}
            </span>
            <span className="mr-2 text-act">
              <i className="fa fa-sticky-note text-muted"></i> {t("planningboard_conventions_notes")}
            </span>
            <span className="mr-2 text-act">
              <i className="fa fa-tags text-muted"></i>{t("planningboard_conventions_labels")}
            </span>
            <span className="mr-2 text-act">
              <i className="fa fa-paperclip text-muted"></i>{t("planningboard_conventions_attahments")}
            </span>
          </div>
          <Col md={4}>
            <SearchTasks data={tasksForSuggest} costW ="180px" title_search= {t("planningboard_button_searchbox")} />
          </Col>
          
         {/*  </div> */}
          </div>
        <Container fluid>
          <Row>
            <Col className="pt-3">
              {scrum.backlog.loading ? (
                  <>
                  {/* {(!noLoader) &&<Loader /> } */}
                  {/*  */}
                  </>
                ) : scrum.backlog.error ? (
                  <h1>{scrum.backlog.error}</h1>
                ) : scrum.backlog.data.lanes.length === 0 ?(
                <div className="text-center pt-4">
                  <img
                    src={emptyImg}
                    alt="Sin datos"
                    className="img-fluid mb-3 animated pulse slower infinite"
                    width="230"
                  />
                  <h5>{t("planningboard_create_backlog")}</h5>
                  <p>
                   {t("planningboard_create_backlog_1")}
                  </p>
                  <Button onClick={createBacklog}>
                    <i className="fa fa-plus-circle mr-2"></i>{t("planningboard_sprint_button_create")}
                  </Button>
                </div>
                ): (
               /*  <>{createBacklog()}</>)
                 :   ( */<>
                 { view == "productbacklog" ?              
                 <ProductBacklog
                 {...{ uid, allLanes,getAllLanes, filterSpsByStatus, getAllprojectData, getAllDataAct, activitiesCant, enableCost,conditionManagerAdvisory, setUpdateUS , setUpdateActiByReso ,setUpdateTags }}
                  />
                 : view == "sprintsView" ?
                 <GanttView uid={uid} view={view} dataTask/> 
                 :view == "huView" ?
                    <GanttView uid={uid} view={view} dataHu  /> 
                  :
                  <GanttView uid={uid} view={view} dataEpic/> 
                 }
                  </>
                  )
              }
              {scrum.stories.length > 0 ? (
                <Modal
                  show={showStories}
                  onHide={handleCloseStories}
                  contentClassName="border-0 shadow-lg"
                  centered
                  size="xl"
                >
                  <Modal.Header>
                    <Modal.Title className="h5 d-flex justify-content-between">
                      <div>
                      {t("planningboard_hu_tittle")}
                      </div>
                      <Button onClick={handleCloseStories} variant="light">X</Button>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <UserStories uid={uid} updateUS={updateUS} />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="dark" onClick={handleCloseStories}>
                      {t("planningboard_button_close")}
                    </Button>
                   {/*  <Button
                      as={Link}
                      to={`/project/${uid}?tab=deliverables`}
                      variant="info"
                      onClick={handleCloseStories}
                    >
                      <i className="fa fa-edit mr-2"></i>Editar Historias
                    </Button> */}
                  </Modal.Footer>
                </Modal>
              ) : (
                <Modal
                  show={showStories}
                  onHide={handleCloseStories}
                  contentClassName="border-0 shadow-lg"
                  centered
                  size="xl"
                >
                  <Modal.Header >
                    <Modal.Title className="h5">
                      Historias de Usuario
                    </Modal.Title>
                    <Button onClick={handleCloseStories} variant="light">X</Button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center pt-4 mt-4">
                      <img
                        src={emptyImgHu}
                        alt="Sin datos"
                        className="img-fluid mb-3 animated pulse slower infinite"
                        width="180"
                      />
                      <h5>Sin Historias de Usuario</h5>
                      <p>
                        Aún no tienes ninguna historia de usuario asociada a
                        este proyecto, inicia ahora creando una.
                      </p>
                     {/*  <Button
                        as={Link}
                        variant="info"
                        to={`/project/${uid}?tab=deliverables`}
                      >
                        <i className="fa fa-plus-circle mr-2"></i>Crear historia
                      </Button> */}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="dark" onClick={handleCloseStories}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
              <Modal
                show={showResources}
                onHide={handleCloseResources}
                contentClassName="border-0 shadow-lg"
                centered
                size="xl"
              >
                <Modal.Header >
                  <Modal.Title className="h5">{t("planningboard_resources_tittle")}</Modal.Title>
                  <Button onClick={handleCloseResources} variant="light">X</Button>
                </Modal.Header>
                <Modal.Body>
                  <ActivitiesByResources uid={uid} updateActiByReso={updateActiByReso} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="dark" onClick={handleCloseResources}>{t("planningboard_button_close")}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={showTags}
                onHide={handleCloseTags}
                contentClassName="border-0 shadow-lg"
                centered
                size="xl"
              >
                <Modal.Header >
                  <Modal.Title className="h5">{t("planningboard_labels_tittle")}</Modal.Title>
                  <Button onClick={handleCloseTags} variant="light">X</Button>
                </Modal.Header>
                <Modal.Body>
                  <ActivitiesByTags uid={uid} updateTags={updateTags} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="dark" onClick={handleCloseTags}>
                    {t("planningboard_button_close")}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={showFiles}
                onHide={handleCloseFiles}
                contentClassName="border-0 shadow-lg"
                centered
                size="lg"
              >
                <Modal.Header >
                  <Modal.Title className="h5">{t("planningboard_attachments_tittle")} ( {allFilesByProject?.length} )</Modal.Title>
                  <Button onClick={handleCloseFiles} variant="light">X</Button>
                </Modal.Header>
                <Modal.Body>
                  <AttachmentsByProject uid={uid}/>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="dark" onClick={handleCloseFiles}>
                  {t("planningboard_button_close")}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={highlight}
                onHide={handleCloseHight}
                contentClassName="border-0 shadow-lg"
                centered
                size="lg"
              >
                <Modal.Header >
                  <Modal.Title className="h5">{t("planningboard_hightlight_task")} {/* ( {allFilesByProject?.length} ) */}</Modal.Title>
                  <Button onClick={handleCloseHight} variant="light">X</Button>
                </Modal.Header>
                <Modal.Body>
                 {/*  <AttachmentsByProject uid={uid}/> */}
                 {/* <ActivitiesByTags uid={uid} /> */}
                 <HightlightActivities  uid={uid}/>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="dark" onClick={handleCloseHight}>
                  {t("planningboard_button_close")}
                  </Button>
                </Modal.Footer>
              </Modal>
           
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
     :<>
      <NoAccess />
     </>
     }
    </>
  );
};

export default PlanningBoard;
