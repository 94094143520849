import React, { useState, useMemo} from "react";
import { Button, Form, ListGroup, Modal,Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const _ = require("lodash");

const ModalSelectResources = (props) => {

  const [t,i18n] =useTranslation("planning")
  const [searchWord, setSearchWord] = useState("");
  const searchResults = useMemo(
    () => props.resources?.filter((item) => item.text.toLowerCase()?.includes(searchWord.toLowerCase())),
    [searchWord]
  );
  const _isSelected = (id) => {
    let search = _.findIndex(props.selectedResources, function (o) {
      return o.id == id;
    });
    if (search != -1) {
      return true;
    } else {
      return false;
    }
  };
  const _pushResource = (data) => {
    let search = _.findIndex(props.selectedResources, function (o) {
      return o.id == data.id;
    });
    let dummie = [...props.selectedResources];
    if (search != -1) {
      dummie = dummie.filter((item) => item.id !== data.id);
    } else {
      dummie.push(data);
    }
    props.setResources([...dummie]);
  };
  return (
    <Modal
      show={props.show}
      centered
      onHide={() => {
        props.setShow(false);
      }}
      contentClassName="border-0 shadow-lg"
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Modal.Header >
          <Modal.Title className="h6 text-info">
            <i className="fa fa-user mr-2"></i>({props.selectedResources.length}
            ) recursos asignados
          </Modal.Title>
          <Button  onClick={() => props.setShow(false)}  variant="light">X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Control
                  placeholder={t("modal_creationactivities_text11_2")}
                  value={searchWord}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setSearchWord(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <ListGroup>
          {searchResults.length ? (
            searchResults.map((item, index) => {
              return (
                <ListGroup.Item
                  key={item.id}
                  className={`py-2 px-3 ${_isSelected(item.id) && "bg-light"}`}
                  action
                  onClick={() => {
                    _pushResource(item);
                  }}
                >
                  {_isSelected(item.id) && (
                    <i className="fa fa-check small text-info mr-2"></i>
                  )}
                  {item.name}
                </ListGroup.Item>
              );
            })
          ) :  (
            <>
            <div className="h1 mb-3 text-muted text-center">
              <i className="fa fa-folder-open"></i>
            </div>
            <p className="text-center">{t("modal_creationactivities_text11_3")}</p>
          </>
            )
          }
          </ListGroup>
       {/*    <ListGroup>
            {searchResults?.map((item, index) => {
              return (
                <ListGroup.Item
                  key={item.value}
                  className={`py-2 px-3 ${
                    _isSelected(item.value) && "bg-light"
                  }`}
                  action
                  onClick={() => {
                    props.onChange({
                      target: {
                        name: props.name,
                        value: item.value
                      }
                    });
                    props.onClose();
                  }}
                >
                  {_isSelected(item.value) && (
                    <small>
                      <i className="fa fa-check text-info mr-2"></i>
                    </small>
                  )}
                  {item.label}
                </ListGroup.Item>
              )
            })}
          </ListGroup> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.setShow(false)} variant="outline-dark" size="sm">
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default React.memo(ModalSelectResources);
// export default ModalSelectResources;
