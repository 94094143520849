import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";

const ModalEditColTitle = (props) => {
  const { show, setShow } = props;
  const [formData, setFormData] = useState(props.data.current || {});
  const ddlSprintStatus = useSelector((state) => state.scrum.ddlSprintStatus);

  const handleChange = (e) => {
    setFormData((prev) => {
      let dummie = { ...prev };
     /*  if (e.target.name === "FechaInicio") dummie.FechaFin = ""; */
      return { ...dummie, [e.target.name]: e.target.value };
    });
  };

  const handleFocus = (e) => {
    try {
      e.target.select();
    } catch (err) {
      // can't select input text
    }
  };

  const handleSaveData = () => {
    console.log(formData)
    if(formData.title === "Product Backlog"){
      swal("¡Ups!Nombre reservado", "Por favor cambie el nombre de la nueva columna", "error");
    }else {
      props.update(formData);
    }
   
  };

  const handleProps = {
    onFocus: handleFocus,
    onChange: handleChange
  };

  /* change input data */
  const _getValue = (selector) => {
    if (
      typeof formData != "undefined" &&
      typeof formData[selector] != "undefined"
    ) {
      let val = formData[selector];
      return val;
    } else {
      return null;
    }
  };
  const dateChanged = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
          [e.target.name]: e.target.value,
        }
      });
  };

  useEffect(() => {
    setFormData(props.data.current || {});
  }, [show]);

  // render tooltips
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="md"
        contentClassName="border-0 shadow-lg"
        animation={false}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveData();
          }}
        >
          <Modal.Header>
            <Modal.Title className="h5 w-100">
              Detalles de la columna : {formData.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Row>
                 
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Título de la Columna</Form.Label>
                      <Form.Control
                        {...handleProps}
                        placeholder="Nombre de la Columna"
                        name="title"
                        value={formData.title || ''}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="dark"
              onClick={() => {
                setShow(false);
              }}
            >
              <i className="fa fa-times mr-2"></i>Cancelar
            </Button>
            <Button type="button" variant="success" onClick={handleSaveData}>
              <i className="fa fa-save mr-2"></i>Guardar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(ModalEditColTitle);
