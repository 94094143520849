import { LOAD_TAGS, ERROR_TAGS } from "./tagsTypes";

const initial = {
    tags: [],
    loading: true,
    error: false,
  };

const tagsReducer = (state = initial, action) => {
switch (action.type) {
    case LOAD_TAGS:
    return {
        tags: action.payload,
        loading: false,
        error: "",
    };
    case ERROR_TAGS:
    return {
        tags: [],
        loading: false,
        error: true,
    };
    default:
    return state;
}
};

export default tagsReducer;