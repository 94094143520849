import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyledRange = styled.div`
  background: linear-gradient(white, transparent);
  opacity: 0.4;
  transition: all ease 0.3s;
  &:hover {
    opacity: 1;
  }
  span {
    white-space: nowrap;
  }
  /* form {
      max-width: 500px;
  } */
`;

const ScrollRangeBoard = (props) => {
  const [rangeValue, setRangeValue] = useState(0);

  const selectRange = (e) => {
    let target = document.querySelector(props.target);
    let totalScroll = target.scrollWidth - target.offsetWidth;
    let newPos = (e.target.value * totalScroll) / 100;
    target.scrollLeft = newPos;
    setRangeValue(e.target.value);
  };
  const handleScroll = (e) => {
    let target = document.querySelector(props.target);
    let newPos = e.target.scrollLeft;
    let totalScroll = target.scrollWidth - target.offsetWidth;
    setRangeValue((newPos * 100) / totalScroll);
  };
  useEffect(() => {
    let target = document.querySelector(props.target);
    target.addEventListener("scroll", handleScroll);
    return () => {
      target.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <StyledRange className="sticky-top">
      <Form>
        <Form.Group className="mb-0 d-flex">
          {/* <Form.Label>Range</Form.Label> */}
          <span className="small mr-3 d-none d-lg-block">
            {props.title_scroll}
          </span>
          <Form.Range
            bsPrefix={'custom-range'}
            value={rangeValue}
            onChange={selectRange}
          />
        </Form.Group>
      </Form>
    </StyledRange>
  );
};

export default ScrollRangeBoard;
