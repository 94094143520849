import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { inputChange, saveData } from "../helpers";
import { fetchAllBoards } from "../redux";
import { useTranslation } from "react-i18next";

const BoardsEdit = (props) => {
  const [t,i18n] =useTranslation("boards")
  const { showModal, handleClose, boardEdit, setBoardEdit } = props;
  const dispatch = useDispatch();
  const inputBoardName = useRef();
  useEffect(() => {
    if (inputBoardName.current) {
      inputBoardName.current.focus();
    }
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      contentClassName="border-0 shadow-lg"
    >
      <Form
        onSubmit={(e) => {
          console.log("submiting");
          e.preventDefault();
          const success = () => {
            handleClose();
            dispatch(fetchAllBoards());
          };
          saveData("boards", boardEdit, success);
        }}
      >
        <Modal.Header>
          <Modal.Title className="h5">{t("boards_buttoncreate")}</Modal.Title>
          <Button onClick={handleClose} variant="light">X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={10} className="mb-3">
              <Form.Label>
                {t("modal_boards_name")}<small className="text-danger">*</small>
              </Form.Label>
              <Form.Control
                name="boardName"
                placeholder={t("modal_boards_textbox")}
                value={boardEdit.boardName || ""}
                onChange={(e) => {
                  inputChange(e, setBoardEdit);
                }}
                required
                ref={inputBoardName}
              />
            </Col>
            <Col md={2} className="mb-3">
              <Form.Label>Color</Form.Label>
              <Form.Control
                name="boardColor"
                type="color"
                value={boardEdit.boardColor || ""}
                onChange={(e) => {
                  inputChange(e, setBoardEdit);
                }}
               /*  list="presets" */
              />
              <datalist id="presets">
                <option value="#0079bf">#0079bf</option>
                <option value="#d29034">#d29034</option>
                <option value="#519839">#519839</option>
                <option value="#b04632">#b04632</option>
                <option value="#89609e">#89609e</option>
                <option value="#cd5a91">#cd5a91</option>
                <option value="#4bbf6b">#4bbf6b</option>
              </datalist>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            <i className="fa fa-times mr-2"></i>{t("modal_boards_buttoncancel")}
          </Button>
          <Button
            variant="success"
            type="submit"
            // disabled={
            //   (alreadyExists(users.users, {
            //     Username: userEdit.Username,
            //   }) &&
            //     userEdit.new) ||
            //   (alreadyExists(users.users, { Correo: userEdit.Correo }) &&
            //     userEdit.new)
            //     ? true
            //     : false
            // }
          >
            <i className="fa fa-save mr-2"></i>{t("modal_boards_buttonsave")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BoardsEdit;
