import axios from "axios";
import swal from "sweetalert";

export const exportToExcel = (uid) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API}product-backlog-export/${uid}`,
    headers: {
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
  };
  return axios(config);
};

export const exportToExcelAllProjects = () => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API}projects`,
    headers: {
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
  };
  return axios(config);
};
export const updateSprint = (uid, payload) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API}sprint/${uid}`,
    headers: {
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
    data: payload
  };
  return axios(config);
};
export const updateTitleColKanban = (uid, payload) => {
  let config = {
    method: "put",
    url: `${process.env.REACT_APP_API}agile-kanban-lanes-title/${uid}`,
    headers: {
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
    data: payload 
  };
  return axios(config);
};