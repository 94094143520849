import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Board from "react-trello";
import styled from "styled-components";
import swal from "sweetalert";
import { deleteDataScrum, getData, updateDataScrum, updateLanes } from "../../helpers";
import { updateBacklog } from "../../redux";
import { updateTitleColKanban } from "../../services/agile";
import ModalEditColTitle from "../ProductBacklog/ModalEditColTitle";
import ModalEditTask from "../ProductBacklog/ModalEditTask";
import ScrollRangeBoard from "../ScrollRangeBoard";
import {
  AddActivity, AddSprintButton, CardBacklog, LaneHeader, NewCard, NewSprint
} from "./CustomComponents";
import tasksound from '../../assets/sounds/tasksound.mp3';
import projectSound from '../../assets/sounds/projectsound.mp3'
import ReactHowler from "react-howler";
import ReactConfetti from "react-confetti";
import { useEffect } from "react";

const _ = require("lodash");

const StyledBoard = styled.div`
  .react-trello-board {
    *::-webkit-scrollbar {
      width: 8px;
    }
    *::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    *::-webkit-scrollbar-thumb {
      background: #b1b1b1;
    }
    *::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    background-color: transparent;
    height: 100vh;
    .react-trello-lane {
      background: white;
      border-radius: 0;
    }
    .smooth-dnd-stretcher-element.vertical {
      background: rgba(0, 0, 0, 0.05);
    }
    .smooth-dnd-container.vertical {
      padding-top: 0 !important;
    }
    .smooth-dnd-container.vertical {
      padding-top: 1rem;
      .react-trello-card {
        border: 1px solid #d6d6d6;
      }
    }
  }
`;

const SprintBacklog = (props) => {
  const scrum = useSelector((state) => state.scrum);
  const [showColTitle, setShowColTitle]= useState(false)
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [activeSoundCompletedTask, setActiveSoundCompletedTask] = useState(false);
  const [activeSoundCompletedProject, setActiveSoundCompletedProject] = useState(false);
  const [activeAnimationCompleProj,setActiveAnimationCompleProj] = useState(false)
  const [customFields, setCustomFiels]= useState([])
  const [rol,setRol]=useState("")
  const profileRol = window.localStorage.getItem("rol")
  useEffect(()=>{
    setRol(profileRol)
  },[profileRol])

  const taskEdit = useRef({});
  const titleColumn = useRef({});
  const titleColToEdit = useRef({})

  const statusProject = (projectID) => {
    Promise.all([
      getData(`agile-cards-status-project/${projectID}`, [])])
      .then((values) => {
        activeConfe(values[0].PromedioAvance)
      });
  
    
  };
/*   const activeConfe = (progress)=>{
     
    if(progress === 100){
      setActiveAnimationCompleProj(true)
      setActiveSoundCompletedProject(true)
      setTimeout(()=> {
        setActiveAnimationCompleProj(false)
        setActiveSoundCompletedProject(false)
        
      },6000)
      }
  } */

  const activeConfe = (progress)=>{
    if(progress === 100){
      setActiveAnimationCompleProj(true)
      setActiveSoundCompletedProject(true)
      swal({
        title: "¡Excelente Trabajo!",
        text: "Han terminado todas las actividades del proyecto. Sigamos adelante con la misma calidad y empeño.",
        icon: "success",
      })
      .then((willDelete) => {
        if (willDelete) {
            setActiveAnimationCompleProj(false)
            setActiveSoundCompletedProject(false)
        }
      });
      }
  }

  const activeSountTaskComplete = (taskProgress) =>{
    if(taskProgress == 100)
      setActiveSoundCompletedTask(true)
    setTimeout(()=>{
      setActiveSoundCompletedTask(false)
    },3000)
  }
  const [windowDimen, setWindowDimen] = useState({
    width: window.innerWidth,
    height: window.innerHeight  
  })
  
  const detectSize = () =>{
    setWindowDimen({
      width: window.innerWidth,
      height: window.innerHeight  
    })
  }

  useEffect(() => {
    window.addEventListener('resize',detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    }
  }, [windowDimen]);

  const ediTitleCol = (payload)=> {
    titleColToEdit.current = payload;
    setShowColTitle(true)
  }

  let resources = [];
  if (scrum.resources.length) {
    resources = scrum.resources.map((resource) => {
      return { ...resource, selected: false, name: resource.text };
    });
  }
  let tags = [];
  if (scrum.tags.length > 0) {
    tags = scrum.tags.map((tags) => {
      return { ...tags, selected: false, name: tags.NombreEtiqueta };
    });
  }

  const filterTask = (idCard) => {
    let dummie = _.find(
      scrum.backlog.data.lanes,
      _.flow(_.property("cards"), _.partialRight(_.some, { id: idCard }))
    );
    dummie = _.filter(dummie.cards, (item) => item.id === idCard);
    return dummie[0];
  };
  
  const filterTitleCol = (idCard) => {
    let dummie = _.find(
      scrum.backlog.data.lanes,
      _.flow(_.property("cards"), _.partialRight(_.some, { id: idCard }))
    );
    return dummie.title
  }

  const updateCardBoard = (data) => {
    data.ProyectoIdReal = props.uid
    updateDataScrum(`agile-cards/${data.id}`, data)
      .then((res) => {
        if(data.metadata.Avance){
          // refrescar actividades si el usuario agregó una nota
          props.getAllprojectData()
        } else {
          let newBoard = updateLanes(scrum, data);
          dispatch(updateBacklog(newBoard));
          if(res.PromedioAvance != 100){
            activeSountTaskComplete(data.metadata.progress)
          }
        }
      })
      .catch((err) =>
      setTimeout(()=> {
        swal(
          "¡Ups!",
          "Hubo un error al actualizar los datos la actividad, por favor refresca la página.",
          "error"
        )
      },3000)
      );
  };
  const updateTitleCol = (payload) => {
    updateTitleColKanban(payload.id, payload)
      .then(() => {
        props.getAllprojectData();
        setShowColTitle(false)
      })
      .catch(() =>
        swal("¡Ups!", "Hubo un error al actualizar el título de la columna", "error")
      );
  };

  const handleOnCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    let cardMovedData = {
      fromLaneId,
      toLaneId,
      cardId,
      index,
      isKanban: true,
      metadata:{
        ProyectoId : props.uid,
      }
    };
    let laneData = scrum.backlog.data.lanes.find(
      (lane) => lane.id === toLaneId
    );
    let cardData = laneData.cards.find((card) => card.id === cardId);
    cardMovedData.ProyectoIdReal = cardData.metadata.ProyectoId
    let updateProgress = true;
    switch (laneData.title) {
      case "To Do":
        cardData.metadata.progress = 0;
        break;
      case "Doing":
        cardData.metadata.progress = 50;
        break;
      case "Done":
        cardData.metadata.progress = 100;
        break;
      default:
        updateProgress = false;
        break;
    }
    updateDataScrum(`agile-cards/${cardMovedData.cardId}`, cardMovedData)
      .then((res) =>{
        activeConfe(res.PromedioAvance)
        if(res.PromedioAvance != 100){
          activeSountTaskComplete(cardData.metadata.progress)
        }

       /*  if(cardData.metadata.progress == 100 && res.PromedioAvance != 100) {
          setActiveSoundCompletedTask(true)
          
          setTimeout(()=> {
            props.getAllprojectData();
           
          },300)

        }else if(cardData.metadata.progress == 100 && res.PromedioAvance == 100){
          statusProject(cardData.metadata.ProyectoId)
          setTimeout(()=>{
            props.getAllprojectData();
          },5000)

        }else {
          console.log("entrando al else")
          props.getAllprojectData();
        } */
      } )
      .catch((err) =>
        swal(
          "¡Ups!",
          "Hubo un error al actualizar la actividad, por favor refresca la página.",
          "error"
        )
      );
    if (updateProgress) {
      updateCardBoard(cardData);
    }
  };

  const handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
    let lanesPos = scrum.backlog.data.lanes.filter(
      (lane) => lane.id != payload.id
    );
    lanesPos.splice(addedIndex, 0, payload);
    lanesPos.forEach((lane, index) => {
      updateDataScrum(`agile-kanban-lanes/${lane.id}`, {
        id: lane.id,
        pos: index,
      })
        .then((res) => console.log(res))
        .catch((err) =>
        setTimeout(() => {
          swal(
            "¡Ups!",
            "Hubo un error al actualizar la actividad, por favor refresca la página.",
            "error"
          )
        },3000)
        );
    });
    // end foreach
  };

  const handleDragEndCard = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    let arrCards = scrum.backlog.data.lanes.find(
      (lane) => lane.id === targetLaneId
    ).cards;
    arrCards = arrCards.filter((card) => card.id !== cardId);
    arrCards.splice(position, 0, cardDetails);
    let arrToSend = arrCards.map((card) => card.id).join();
    updateDataScrum(`agile-kanban-cards-position/${targetLaneId}`, {
      projectId: props.uid,
      idsOrdered: arrToSend, // string de UID de actividades para ordenarlas posición.
    })
      .then((res) => console.log(res))
      .catch((err) =>
      setTimeout(()=> {
        swal(
          "¡Ups!",
          "Hubo un error al actualizar la posición de la actividad, por favor refresca la página.",
          "error"
        )
      },3000)
      );
  };

  const handleCardDelete = (cardId) => {
    deleteDataScrum(`agile-cards-delete/${cardId}`)
      .then((res) => console.log(res))
      .catch((err) =>
        swal(
          "¡Ups!",
          "Hubo un error al eliminar la actividad, por favor refresca la página.",
          "error"
        )
      );
  };

  const getCustomFields = (idOrganization)=>{
    Promise.all([
      getData(`custom-fields/${idOrganization}`,[])])
      .then((values) => {
        setCustomFiels(values[0])
      })
  }
 
  
  useEffect(()=>{
    const idOrganization = localStorage.getItem("OID")
    getCustomFields(idOrganization)
  },[])

  const uid = window.localStorage.getItem("UID")

  const dataForSprintBacklog = () => {
    if(rol != "Miembro de Equipo"){
      return scrum.backlog.data
    }else {
      const dataByRol = scrum.backlog.data?.lanes.map((lanes)=>{
        return {...lanes,
          cards: lanes.cards?.filter((items)=> {
            return items.metadata.resources.map((resource)=> resource.id).includes(uid)
          })
         }
      })
      return {lanes:dataByRol}
    }
  }
  return (
    <>
      <ScrollRangeBoard target=".react-trello-board" />
      <StyledBoard id="pwc-product-backlog-kanban">
        <Board
          /* data={scrum.backlog.data} */
          data = {dataForSprintBacklog()}
          editable
          canAddLanes
          draggable
          laneDraggable = {(rol == "Ejecutivo")? false : true}
          cardDraggable={(rol == "Ejecutivo")? false : true}
          onDataChange={(data) => {
            dispatch(updateBacklog(data));
          }}
          onCardClick={(cardId) => {
            taskEdit.current = filterTask(cardId);
            titleColumn.current = filterTitleCol(cardId)
            setShow(true);
          }}
          onCardMoveAcrossLanes={handleOnCardMoveAcrossLanes}
          handleLaneDragEnd={handleLaneDragEnd}
          handleDragEnd={handleDragEndCard}
          onCardDelete={handleCardDelete}
          components={{
            AddCardLink: AddActivity,
            NewCardForm: (actionProps) => (
              <NewCard
                uid={props.uid}
                backlogUID={scrum.backlog.data.lanes[0].id}
                {...actionProps}
              />
            ),
            NewLaneSection: AddSprintButton,
            NewLaneForm: (actionProps) => (
              <NewSprint uid={props.uid} {...actionProps} />
            ),
            LaneHeader: (args) => (
              <LaneHeader {...args} onLaneHeaderClick={ediTitleCol} />
            ),
            Card: CardBacklog,
          }}
        />
      </StyledBoard>
      <ModalEditTask
        rol = {rol}
        enableCost={props.enableCost}
        show={show}
        setShow={setShow}
        data={taskEdit}
        customFields= {customFields}
        titleColumn = {titleColumn}
        update={updateCardBoard}
        idProject={props.idProject}
        sprints={scrum.backlog.data.lanes.map((item) => {
          return {
            id: item.id,
            title: item.title,
          };
        })}
        resources={resources}
        tags={tags}
        hideStories={props.hideStories || false}
        hideTags = {props.hideTags || false}
      />
      <ModalEditColTitle
        data-name="modal-edit-titleCol"
        show={showColTitle}
        setShow={setShowColTitle}
        data={titleColToEdit}
        update={updateTitleCol}
      />
      <ReactHowler
        src={tasksound}
        xhr={{withCredentials:true}}
        playing={activeSoundCompletedTask}
       
      />
       {(activeAnimationCompleProj) && <>
        <ReactHowler
          src={projectSound}
          xhr={{withCredentials:true}}
          playing={activeSoundCompletedProject}
          
        />
        <ReactConfetti
          width={(windowDimen.width - 50)}
          height={windowDimen.height}
          tweenDuration={3000}
        /> 
       
      </>}
    </>
  );
};

export default React.memo(SprintBacklog);
