import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import TitleCommon from '../components/TitleCommon';
import { API_ROUTE, headersRequest } from '../helpers';
import axios from 'axios';
import SuperTableV2 from '../components/SuperTableV2';
import DataGrid, {
  Column,
  GroupPanel,
  SearchPanel,
  Grouping,
  Paging,
} from 'devextreme-react/data-grid';
import { Col, Dropdown, Modal, Nav, Row, Tab, Container, Button, Form } from 'react-bootstrap';
import { StyledTabs } from '../components/StyledCommon';
import MyTasksByProject from '../components/MyTasksByProject';
import { selectTooltip, selectTooltip_en } from "../utils/selectTooltip";
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert'

/* import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
 */

const ModuleRisks = () => {

  const [t, i18n] = useTranslation("global")

/*   const history = useHistory()
 */
  const [showModalRisks, setShowModalRisks] = useState(false);
  const [modalCreateRisks, setModalCreateRisks] = useState(false);
  const [modalActionsRisksIdentified, setModalActionsRisksIdentified] = useState(false);
  const [modalActionsRisksEvaluated, setModalActionsRisksEvaluated] = useState(false);
  const [activeKeyDefault, setActiveKeyDefault] = useState("first")
  const [dataMgrProjects, setDataMgrProjects] = useState([])
  const [allDataRisks, setAllDataRisks] = useState([])
  const [getUserList, setGetUserList] = useState([])
  const [dataMgrAreas, setDataMgrAreas] = useState([])
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedEditRisk, setSelectedEditRisk] = useState({
    idProyectoSeleccionado: '',
    estadoRiesgo: '',
    riesgoNombre: '',
    idNombreResponsable: '',
    idRiesgoIdentificacion: '',
    responsableNombre: '',
    idAreaImpacto: '',
    areaImpactoNombre: '',
    riesgoDescripcion: '',
    fechaIdentificacion: ''
  });

  const getProjectsMgr = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}mgr-projects`, headersRequest());
      setDataMgrProjects(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}users`, headersRequest());
      setGetUserList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRisksMgr = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}mgr-get-risks`, headersRequest());
      setAllDataRisks(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAreasMgr = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}/mgr-get-areas/`, headersRequest());
      setDataMgrAreas(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProjectsMgr()
    getRisksMgr()
    getUsers()
    getAreasMgr()
  }, [])


  const handleSelectChange = (event) => {
    const selectedProjectName = event.target.value;
    console.log(selectedProjectName)
    const selectedProject = dataMgrProjects.find(project => project.IdProyecto === selectedProjectName);
    setSelectedProjectId(selectedProject ? selectedProject.IdProyecto : "");
  };

  const handleEditedRisk = (e) => {
    const { name, value } = e.target;
    setSelectedEditRisk((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  /* const onNavigateEditRisk = (id) => { 
    history.push(`/risk-create-form/${id}/edit`)
  } */

  const handleUpdateRisk = async (e) => {

    const { idRiesgoIdentificacion, estadoRiesgo, fechaIdentificacion, responsableNombre, areaImpactoNombre, riesgoNombre, riesgoDescripcion } = selectedEditRisk;

    const dataSend = {
      IdRiesgoIdentificacion: idRiesgoIdentificacion,
      EstadoRiesgo: estadoRiesgo,
      RiesgoFechaIdentificacion: fechaIdentificacion,
      ResponsableIdentificacion: responsableNombre,
      AreaImpacto: areaImpactoNombre,
      RiesgoNombre: riesgoNombre,
      RiesgoDescripcion: riesgoDescripcion
    };

    try {
      e.preventDefault();

      const result = await swal({
        title: "Atención",
        text: "Los datos a actualizar, ¿son correctos?",
        icon: "warning",
        buttons: {
          cancel: "Guardar",
          confirm: "Continuar"
        }
      });

      if (result) {
        await axios.put(`${API_ROUTE}update-risks-identify`, dataSend, headersRequest());

        await swal({
          text: 'Riesgo Actualizado!',
          icon: 'success',
          timer: 2000,
        });

        getRisksMgr();
        setModalActionsRisksIdentified(false) 
      } else {
        return;
      }
    } catch (error) {
      console.error(error);

      await swal({
        title: 'Error!',
        text: 'Hubo un problema al actualizar.',
        icon: 'error',
        timer: 2000,
      });
    }
  };



  const handleDeleteRisk = () => {
    swal({
      title: "Atención",
      text: "¿Estás seguro de eliminar este riesgo?",
      icon: "warning",
      buttons: {
        cancel: "No",
        confirm: "Si, Eliminar"
      }
    }).then((actionResponse) => {
      if (actionResponse) {
        swal({
          text: "El riesgo se ha eliminado correctamente",
          icon: "success"
        });
      } else {
        swal({
          text: "Riesgo no borrado",
          icon: "error"
        });
      }
    })
  }

  const handleChangeModal = () => {
    setModalActionsRisksIdentified(false)
    setModalActionsRisksEvaluated(true)
  }

  const columns = [
    {
      selector: 'RiesgoNombre', caption: 'Nombre del riesgo', customRender: (row) => {
        return (
          <>
            <div>
              <span
                onClick={() => {
                  setModalActionsRisksIdentified(true)
                  setSelectedEditRisk({
                    idProyectoSeleccionado: row.data.IdProyectoSeleccionado,
                    estadoRiesgo: row.data.EstadoRiesgo[0],
                    idRiesgoIdentificacion: row.data.IdRiesgoIdentificacion,
                    riesgoNombre: row.data.RiesgoNombre,
                    idNombreResponsable: row.data.ResponsableIdentificacion,
                    responsableNombre: row.data.ResponsableIdentificacion,
                    idAreaImpacto: row.data.AreaImpacto,
                    areaImpactoNombre: row.data.AreaImpacto,
                    riesgoDescripcion: row.data.RiesgoDescripcion,
                    fechaIdentificacion: row.data.RiesgoFechaIdentificacion
                  });
                  /* onNavigateEditRisk(row.data.IdRiesgoIdentificacion) */

                }}
                style={{ cursor: "pointer", color: "red" }}
              >
                {row.data.RiesgoNombre}
              </span>
            </div>
          </>
        );
      },
    },
    { selector: 'Nombre', caption: 'Nombre del proyecto' },
    { selector: 'ResponsableNombre', caption: 'Gerente' },
    { selector: 'Impacto', caption: 'Impacto' },
    { selector: 'Ocurrencia', caption: 'Ocurrencia' },
    { selector: 'Criticidad', caption: 'Criticidad' },
    { selector: `EstadoRiesgo[1]`, caption: 'Estado del riesgo' },
    { selector: `RiesgoFechaIdentificacion`, caption: 'Fecha de Identificación' }
  ];

  const currentProjects = [{
    Nombre: "Proyecto 1",
    CODProyecto: 'D2211323-2E42-449C-B505-331CB00E5DD4',
    TipoProyecto: 'Técnico',
    CantidadRiesgos: "0",
    Gerente: 'Daniel Rodriguez',
    FechaInicioProg: "10-07-2024",
    FechaFinProg: "17-07-2024",
    PctCompletadoProyecto: "50%",
    Estado: "Sin Riesgos"
  },
  {
    Nombre: "Proyecto 2",
    CODProyecto: '5A211323-2E42-449C-B505-331CB00E5DD4',
    TipoProyecto: 'Técnico',
    CantidadRiesgos: "1",
    Gerente: 'Daniel Rodriguez',
    FechaInicioProg: "10-07-2024",
    FechaFinProg: "17-07-2024",
    PctCompletadoProyecto: "20%",
    Estado: "En plan identificación"
  },
  {
    Nombre: "Proyecto 3",
    CODProyecto: '5A211323-2E42-449C-B505-331CB00E5DD4',
    TipoProyecto: 'Técnico',
    CantidadRiesgos: "2",
    Gerente: 'Daniel Rodriguez',
    FechaInicioProg: "10-07-2024",
    FechaFinProg: "17-07-2024",
    PctCompletadoProyecto: "20%",
    Estado: "En plan evaluación"
  },
  {
    Nombre: "Proyecto 4",
    CODProyecto: '5A211323-2E42-449C-B505-331CB00E5DD4',
    TipoProyecto: 'Técnico',
    CantidadRiesgos: "3",
    Gerente: 'Daniel Rodriguez',
    FechaInicioProg: "10-07-2024",
    FechaFinProg: "17-07-2024",
    PctCompletadoProyecto: "20%",
    Estado: "En plan mitigación"
  }]

  const columnsProjects = [
    {
      caption: "Nombre del Proyecto",
      selector: "Nombre",
      width: 200,
      customRender: (row) => {
        return (
          <div className="d-flex align-items-center dropdown-in-table">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="sm"
                className="dropdown-btn-ellipsis"
              >
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setShowModalRisks(true)}
                >
                  Ver riesgos
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span
              className="ml-2 text-primary"
              style={{ maxWidth: "340px" }}
              title={row.data.Nombre}
            >
              {row.data.Nombre}
            </span>
          </div>
        );
      }
    },
    {
      caption: "Gerente del Proyecto",
      selector: "Gerente",
      width: 200
    },
    {
      caption: "Cantidad de Riesgos",
      selector: "CantidadRiesgos",
      width: 200
    },
    {
      caption: "Estado de Riesgos en el proyecto",
      selector: "Estado",
      width: 220
    },
    {
      caption: "Indicador Global",
      selector: "",
      width: 200
    }
  ];

  const columnsIdentifyRisk = [
    {
      caption: "Nombre del Riesgo",
      selector: "Nombre",
      width: 210,
      customRender: (row) => {
        return (
          <div className="d-flex align-items-center dropdown-in-table">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="sm"
                className="dropdown-btn-ellipsis"
              >
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href={`/risk-create-form/${row.data.CODProyecto}`}
                >
                  Evaluar
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setShowModalRisks(true)}
                >
                  Gestionar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span
              className="ml-2 text-primary"
              style={{ maxWidth: "340px" }}
              title={row.data.Nombre}
            >
              {row.data.Nombre}
            </span>
          </div>
        );
      }
    },
    {
      caption: "ID del Riesgo",
      selector: "CODProyecto",
      width: 100
    },
    {
      caption: "Estado del riesgo",
      selector: "TipoProyecto",
      width: 200
    },
    {
      caption: "Responsable del riesgo",
      selector: "Gerente",
      width: 170
    },
    {
      caption: "Area de impacto",
      selector: "FechaInicioProg",
      width: 200
    },
    {
      caption: "Fecha de Inicio",
      selector: "FechaFinProg",
      width: 200
    },
    {
      caption: "Fecha de Cierre",
      selector: "PctCompletadoProyecto",
      width: 200
    },
  ];

  return (
    <Layout>
      <TitleCommon
        title={{
          text: "Módulo de Riesgos",
          icon: "fas fa-exclamation-triangle"
        }}
      />

      <Container fluid>
        <Tab.Container activeKey={activeKeyDefault} onSelect={(k) => setActiveKeyDefault(k)}>
          <Row className="bg-light">
            <StyledTabs as={Nav}>
              <Col>
                <Nav.Item>
                  <Nav.Link style={{ width: "174px" }} eventKey="first" data-tip={(t("global_lenguaje") == "es") ? selectTooltip(47) : selectTooltip_en(47)}>Todos los riegos</Nav.Link>
                </Nav.Item>
              </Col>
           {/*    <Col>
                <Nav.Item>
                  <Nav.Link style={{ width: "204px" }} eventKey="second" data-tip={(t("global_lenguaje") == "es") ? selectTooltip(46) : selectTooltip_en(46)}>Riesgos por proyecto</Nav.Link>
                </Nav.Item>
              </Col> */}
            </StyledTabs>
          </Row>
          <Tab.Content>

            <Tab.Pane
              eventKey="first"
              className="animate__animated animate__fadeIn"
            >
              <div className="mb-3 mt-2">
                <p className="mb-2 pt-2 mb-md-n4 pl-1">
                  Estos son todos los riesgos&nbsp;
                  <b className="text-primary">
                    para visualizarlos de forma rápida:&nbsp;
                  </b>
                </p>
              </div>

              <Row>
                <Col>
                  <hr className='mt-4'/>
                  <DataGrid
                    dataSource={allDataRisks}
                    allowColumnReordering={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    className='mb-5'
                  >

                    <GroupPanel visible={false} />
                    <SearchPanel visible={true} placeholder="Buscar riesgo" />
                    <Grouping autoExpandAll={false} />
                    {columns.map((itmCol, index) => {
                      return (
                        <Column
                          key={index}
                          dataField={itmCol.selector}
                          caption={itmCol.caption}
                          width={itmCol.width}
                          cellRender={(row) => {
                            return (
                              <>
                                {itmCol.customRender ? itmCol.customRender(row) : row.text}
                              </>
                            );
                          }}
                        />
                      );
                    })}
                    <Paging defaultPageSize={60} />
                  </DataGrid>

                  <Modal
                    size='lg'
                    show={modalActionsRisksIdentified}
                    onHide={() => setModalActionsRisksIdentified(false)}
                    dialogClassName='modal-90w'
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header >
                      <Modal.Title id="example-custom-modal-styling-title" className='pl-3'>
                        Edición del riesgo
                      </Modal.Title>
                      <Button onClick={(e) => {
                        setModalActionsRisksIdentified(false)
                      }} variant="light">X</Button>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className='ml-3'>Datos Identificados:</h5>
                      <Form onSubmit={handleUpdateRisk}>
                        <Row>
                          <Col md={6} className="pt-3 pb-1 mb-5 pr-3 pl-4">
                            <Form.Label>Nombre del riesgo:</Form.Label>
                            <Form.Control
                              type="text"
                              name="riesgoNombre"
                              value={selectedEditRisk.riesgoNombre}
                              onChange={handleEditedRisk}
                            />
                          </Col>

                          <Col md={6} className="pt-3 pb-1 mb-5 pr-3 pl-4">
                            <Form.Label>Responsable del riesgo:</Form.Label>
                            <Form.Control
                              as="select"
                              name="responsableNombre"
                              value={selectedEditRisk.responsableNombre}
                              onChange={handleEditedRisk}
                            >
                              <option value="">{selectedEditRisk.responsableNombre}</option>
                              {getUserList.length > 0
                                ? getUserList.map((user) => (
                                  <option key={user.IdUsuario} value={user.IdUsuario}>
                                    {user.Nombre}
                                  </option>
                                ))
                                : <option value="" disabled>No hay usuarios disponibles</option>}
                            </Form.Control>
                          </Col>

                          <Col md={6} className="pt-3 mb-5 pr-3 pl-4">
                            <Form.Label>
                              Fecha de identificación del riesgo:
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="fechaIdentificacion"
                              onChange={handleEditedRisk}
                            />
                            <p className='font-italic mt-2'><span className='text-primary'>* </span>{(t("global_lenguaje") == "es") ? selectTooltip(61) : selectTooltip_en(61)}</p>
                          </Col>

                          <Col md={6} className="pt-3 mb-5 pr-3 pl-4">
                            <Form.Label>Área de impacto:</Form.Label>
                            <Form.Control
                              as="select"
                              name="areaImpactoNombre"
                              value={selectedEditRisk.areaImpactoNombre}
                              onChange={handleEditedRisk}
                            >
                              <option value="">{selectedEditRisk.areaImpactoNombre}</option>
                              {dataMgrAreas.length > 0 ? dataMgrAreas.map(area => (
                                <option key={area.Id} value={area.Id}>{area.Area}</option>
                              )) : <option value="" disabled>No hay areas registradas</option>}
                            </Form.Control>
                          </Col>

                          <Col md={12} className="pb-1 mb-2 pr-3 pl-4">
                            <Form.Label>Descripción del riesgo:</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="riesgoDescripcion"
                              value={selectedEditRisk.riesgoDescripcion}
                              onChange={handleEditedRisk}
                            />
                            <div className="d-flex justify-content-end mt-3">
                            {/*   <Button className="mt-3 mr-3" type="button" variant="success" onClick={handleChangeModal}>
                                <i className="fas fa-pen mr-2"></i>Editar Evaluación
                              </Button> */}
                           {/*    <Button className="mt-3 mr-3" type="button" onClick={handleDeleteRisk}>
                                <i className="fas fa-trash-alt mr-2"></i>Eliminar Riesgo
                              </Button> */}
                              <Button className="btn btn-dark mt-3" type="submit">
                                <i className="fa fa-save mr-2"></i>Guardar cambios
                              </Button>
                            </div>
                          </Col>

                        </Row>
                      </Form>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    size='lg'
                    show={modalActionsRisksEvaluated}
                    onHide={() => setModalActionsRisksEvaluated(false)}
                    dialogClassName='modal-90w'
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title" className='pl-3'>
                        Edición del riesgo
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className='ml-3'>Datos Identificados:</h5>
                   {/*    <Form onSubmit={handleUpdateRisk}>
                        <Form.Row>
                          <Col md={6} className="pt-3 pb-1 mb-5 pr-3 pl-4">
                            <Form.Label>Nombre del riesgo:</Form.Label>
                            <Form.Control
                              type="text"
                              name="riesgoNombre"
                              value={selectedEditRisk.riesgoNombre}
                              onChange={handleEditedRisk}
                            />
                          </Col>

                          <Col md={6} className="pt-3 pb-1 mb-5 pr-3 pl-4">
                            <Form.Label>Responsable del riesgo:</Form.Label>
                            <Form.Control
                              as="select"
                              name="responsableNombre"
                              value={selectedEditRisk.responsableNombre}
                              onChange={handleEditedRisk}
                            >
                              <option value="">{selectedEditRisk.responsableNombre}</option>
                              {getUserList.length > 0
                                ? getUserList.map((user) => (
                                  <option key={user.IdUsuario} value={user.IdUsuario}>
                                    {user.Nombre}
                                  </option>
                                ))
                                : <option value="" disabled>No hay usuarios disponibles</option>}
                            </Form.Control>
                          </Col>

                          <Col md={6} className="pt-3 mb-5 pr-3 pl-4">
                            <Form.Label>
                              Fecha de identificación del riesgo:
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="fechaIdentificacion"
                              onChange={handleEditedRisk}
                            />
                            <p className='font-italic mt-2'><span className='text-primary'>* </span>{(t("global_lenguaje") == "es") ? selectTooltip(61) : selectTooltip_en(61)}</p>
                          </Col>

                          <Col md={6} className="pt-3 mb-5 pr-3 pl-4">
                            <Form.Label>Área de impacto:</Form.Label>
                            <Form.Control
                              as="select"
                              name="areaImpactoNombre"
                              value={selectedEditRisk.areaImpactoNombre}
                              onChange={handleEditedRisk}
                            >
                              <option value="">{selectedEditRisk.areaImpactoNombre}</option>
                              {dataMgrAreas.length > 0 ? dataMgrAreas.map(area => (
                                <option key={area.Id} value={area.Id}>{area.Area}</option>
                              )) : <option value="" disabled>No hay areas registradas</option>}
                            </Form.Control>
                          </Col>

                          <Col md={12} className="pb-1 mb-2 pr-3 pl-4">
                            <Form.Label>Descripción del riesgo:</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="riesgoDescripcion"
                              value={selectedEditRisk.riesgoDescripcion}
                              onChange={handleEditedRisk}
                            />
                            <div className="d-flex justify-content-end mt-3">
                              <Button className="mt-3 mr-3" type="button" variant="success" onClick={handleDeleteRisk}>
                                <i className="fas fa-pen mr-2"></i>Editar Evaluación
                              </Button>
                              <Button className="mt-3 mr-3" type="button" onClick={handleDeleteRisk}>
                                <i className="fas fa-trash-alt mr-2"></i>Eliminar Riesgo
                              </Button>
                              <Button className="btn btn-dark mt-3" type="submit">
                                <i className="fa fa-save mr-2"></i>Guardar cambios
                              </Button>
                            </div>
                          </Col>

                        </Form.Row>
                      </Form> */}
                    </Modal.Body>
                  </Modal>

                  <Modal
                    size='lg'
                    show={modalCreateRisks}
                    onHide={() => setModalCreateRisks(false)}
                    dialogClassName='modal-90w'
                    aria-labelledby="example-custom-modal-styling-title"
                  >
                    <Modal.Header >
                      <Modal.Title id="example-custom-modal-styling-title" className='pl-3'>
                        Creación del riesgo
                      </Modal.Title>
                      <Button onClick={(e) => {
                        setModalCreateRisks(false)
                      }} variant="light">X</Button>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col md={12} className="pt-2 pr-3 pl-4">
                          <Form.Label data-tip={(t("global_lenguaje") === "es") ? selectTooltip(49) : selectTooltip_en(49)}>
                            Selecciona el proyecto al que pertenecerá este riesgo:
                          </Form.Label>
                          <Form.Control as="select" name="riskResponsible" onChange={handleSelectChange}>
                            <option value="">Seleccionar</option>
                            {dataMgrProjects.length > 0 ? dataMgrProjects.map(data => (
                              <option key={data.IdProyecto} value={data.IdProyecto}>{data.Nombre}</option>
                            )) : <option value="" disabled>Sin proyectos</option>}
                          </Form.Control>
                          <Button
                            variant="success"
                            className="mt-3"
                            href={`/risk-create-form/${selectedProjectId}/create`}
                            disabled={!selectedProjectId}
                          >
                            Crear Riesgo
                          </Button>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>

                  <Button
                    variant="success"
                    type="submit"
                    className="position-fixed"
                    style={{ bottom: "1rem", right: "1rem" }}
                    onClick={() => setModalCreateRisks(true)}
                  >
                    <i className="fa fa-plus mr-2"></i>Crear riesgo
                  </Button>

                </Col>
              </Row>
            </Tab.Pane>

            <Tab.Pane
              eventKey="second"
              className="animate__animated animate__fadeIn"
            >
              <div className="mb-1 mt-2">
                <p className="mb-2 pt-2 mb-md-n4 pl-1">
                  Estos son los riesgos por proyecto&nbsp;
                  <b className="text-primary">
                    para identificar, evaluar y gestionar:&nbsp;
                  </b>
                </p>
              </div>
              <Row>
                <Col>
                  <SuperTableV2
                    data={currentProjects}
                    columns={columnsProjects}
                  />
                </Col>
              </Row>

              <Modal
                size='lg'
                show={showModalRisks}
                onHide={() => setShowModalRisks(false)}
                dialogClassName='modal-90w'
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Riesgos de este proyecto
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <SuperTableV2
                    data={currentProjects}
                    columns={columnsIdentifyRisk}
                  />
                </Modal.Body>
              </Modal>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </Layout >

  );
};

export default ModuleRisks;
