import { 
  LOAD_PROJECT_DATA, 
  ERROR_LOADING_PROJECT,
  LOADING_DATA, 
  FETCH_RESOURCES_TRADITIONAL
 } from "./traditionalTypes";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";

const loadingData = () => {
  return {
    type: LOADING_DATA,
  };
};
const fetchResourcesTraditional = (resourcesTraditional) => {
  return {
    type: FETCH_RESOURCES_TRADITIONAL,
    payload: resourcesTraditional,
  };
};
export const getProjectData = (uid) => {
  return (dispatch) => {
    axios
      .get(`${API_ROUTE}/projects/${uid}`, headersRequest())
      .then((res) => {
        dispatch({
          type: LOAD_PROJECT_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ERROR_LOADING_PROJECT,
        });
      });
  };
};

export const fetchProjectResourcesTraditional = (uid) => {
  // this fetch brings all the data of the schudle traditional
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/product-resources-traditional/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchResourcesTraditional(res.data));
      });
  };
};
