import { LOAD_PROJECTS, ERROR_PROJECTS } from "./projectsTypes";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";

export const fetchAllProjects = () => {
  return (dispatch) => {
    axios
      .get(`${API_ROUTE}projects`, headersRequest())
      .then((res) => {
        dispatch({
          type: LOAD_PROJECTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ERROR_PROJECTS,
        });
      });
  };
};
