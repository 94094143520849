import React from "react";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { downloadAttachment } from "../../services/tasks.services";
import { getData } from "../../helpers";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AttachmentsByProject = (props) => { 

  const [t,i18n] =useTranslation("planning")
  const [getAllFilesByProject, setGetAllFilesByProject] = useState([])
 
  const getAllFiles = (uid) => {
    Promise.all([
      
      getData(`agile-project-attachments/${uid}`,[])])
      .then((values) => {
        setGetAllFilesByProject(values[0]);
    });
  };

  useEffect(()=> {
    getAllFiles(props.uid)
  },[])

  const columns = [
    {
      caption: t("planningboard_attachments_tablecolumn_file"),
      selector: "NombreArchivo",

      customRender: (row) => {
        return (
          <>
          {(row.data.Extension ==="doc"|| row.data.Extension ==="docx" ) &&
          <i className="fa fa-file-word mr-2 word-icon"></i>
          }
          {(row.data.Extension ==="png" || row.data.Extension ==="jpeg" || row.data.Extension ==="jpg" ) &&
          <i className="fa fa-file-image mr-2 png-icon"></i>
          }
          {(row.data.Extension ==="pdf") &&
          <i className="fa fa-file-pdf mr-2 text-danger pdf-icon"></i>
          }
          {(row.data.Extension ==="xls" || row.data.Extension ==="xlsx") &&
          <i className="fa fa-file-excel mr-2 excel-icon"></i>
          }
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              console.log(row.data.RutaArchivo)
              let rutaFile = row.data.RutaArchivo.split('/')
             /*  console.log(rutaFile ) */
            /*  Desarrolo */
             /*  let pathBuilFile = `${rutaFile[3]}/${rutaFile[4]}/${rutaFile[5]}` */
             /*  Produccion */
              let pathBuilFile = `${rutaFile[6]}/${rutaFile[7]}/${rutaFile[8]}`
             /*  downloadFile(row.data.RutaArchivo) */
              downloadFile(pathBuilFile)
              /* if (row.data.IdActividad)
                document.getElementById(`card-${row.data.IdActividad}`).click(); */
            }}
          >
            {row.data.NombreArchivo}
          </a>
          </>
        );
      },
      minWidth: 180,
    },
    {
      caption: "Sprint",
      selector: "SprintNombre",
      width: 110,
    },
    {
      caption: t("planningboard_attachments_tablecolumn_activity"),
      selector: "NombreActividad",
      width: 150,
    },
    {
      caption: t("planningboard_attachments_tablechments_tablecolumn_date"),
      selector: "FechaCreacion",
      width: 150,
    },
    {
      caption: t("planningboard_attachments_tablecolumn_owner"),
      selector: "CreadoPor",
      width: 150,
    },
  ];
  const downloadFile = (filePath) => {
    console.log(filePath)
    downloadAttachment(filePath).then((res) => {
      var file = window.URL.createObjectURL(res.data);
      window.open(file);
    });
  };

  return (
    <DataGrid
      dataSource={getAllFilesByProject}
      allowColumnReordering={true}
      showBorders={true}
    >
      <GroupPanel visible={true}  emptyPanelText=" " />
      <SearchPanel visible={true} placeholder={t("planningboard_attachments_searchbox")} />
      <Grouping autoExpandAll={false} />
      {columns.map((itmCol, index) => {
        return (
          <Column
            key={index}
            dataField={itmCol.selector}
            caption={itmCol.caption}
            width={itmCol.width}
            minWidth={itmCol.minWidth}
            cellRender={(row) => {
              return (
                <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
              );
            }}
          />
        );
      })}
      <Paging defaultPageSize={50} />
    </DataGrid>
  );

};

export default AttachmentsByProject;