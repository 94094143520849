import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { API_ROUTE, getData, headersRequest, inputChange } from "../../helpers";
import swal from "sweetalert";
import axios from "axios";

const initialState = {
  proyecto: 0,
};
const ModalAvancedOptions = ({
  setShow,
  show,
  handleClose,
  data,
  handleValidations,
  getAllprojectData,
}) => {
  const [validation, setValidation] = useState(false);
  const [projects, setProjects] = useState([]);
  const [saveData, setSaveData] = useState(initialState);
  const { uid } = useParams();

  const getProjects = () => {
    try {
      getData(`projects-ddl`, []).then((res) => {
        const filterProjects = res.filter((item) => item.IdProyecto != uid);
        setProjects(filterProjects);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const searchProjectByName = (id) => {
    return projects.filter((proj) => proj.IdProyecto == id)[0].Nombre;
  };

  const moveTaskToOtherProject = (task) => {
    try {
      axios
        .put(
          `${API_ROUTE}move-to-other-project/${task.data.id}`,
          task,
          headersRequest()
        )
        .then((res) => {
          if (res.status == 200) {
            swal(
              "¡Hecho!",
              `Tu actividad ha sido movida exitosamente, la encontraras en el Producto Backlog del proyecto: ${searchProjectByName(
                task.proyecto
              )}`,
              "success"
            ).then((val) => {
              setSaveData(initialState);
              handleClose();
              getAllprojectData();
            });

            // handleValidations(task.data)
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveData = () => {
    if (saveData.proyecto != 0 && saveData.data.id) {
      moveTaskToOtherProject(saveData);
      setValidation(false);
    } else {
      setValidation(true);
    }
  };

  useEffect(() => {
    getProjects();
    return () => {
      setProjects([]);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setSaveData((prev) => ({
        ...prev,
        data,
      }));
    }
  }, [show]);

  console.log(saveData)
  return (
    <>
      <Modal size="lg" show={show}>
        <Modal.Header >
          <Modal.Title className="text-center">Opciones Avanzadas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Mover Actividad:</h6>
            <p className="small text-gray">
              Esta funcionalidad te permite mover actividades de este proyecto a
              otro dentro de tu organizacion, ten en cuenta que solo podras
              conservar los recursos asignados.
            </p>
            <div className="d-flex">
              <Col md={5}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Nombre Actividad:</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={data.metadata?.NombreActividad}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={7}>
                <Form.Group className="mb-3" controlId="validationSelect">
                  <Form.Label>Asignar Proyecto:</Form.Label>
                  <Form.Control
                    as="select"
                    name="proyecto"
                    variant="outline-dark"
                    required
                    onChange={(e) => {
                      inputChange(e, setSaveData);
                    }}
                  >
                    <option value={0}>Seleccionar Proyecto...</option>
                    {projects?.map((proje, idx) => {
                      return (
                        <option key={idx} value={proje.IdProyecto}>
                          {proje.Nombre}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {validation && (
                    <p className="text-danger small">
                      Por favor seleccione un Proyecto.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </div>
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => {
                  setSaveData(initialState);
                  handleClose();
                }}
              >
                Close
              </Button>
              <Button variant="success" onClick={handleSaveData}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAvancedOptions;