import React from 'react'
import { useState } from 'react'

import BodyVirtualAsist from './BodyVirtualAsist'
import { useTranslation } from 'react-i18next'

const VirtualAsistentSideBar= () => {
  const [t,i18n] =useTranslation("onBoarding")

   const [showPanel, setShowPanel] = useState(false)
   const [selected ,setSelected] = useState(true)

    const handleShowPanel = () =>{
        setShowPanel((value)=>value =!value)
    }
    const handleSelectLabel = (labelType) =>{
        if(labelType =="Tradicional"){
            setSelected(true)
        }else {
            setSelected(false)
        }
    }

const animateIn = 'animate__animated animate__fadeInRight animate__repeat-1'
const animateOut = 'animate__animated animate__fadeOutRight animate__repeat-1'
//${(showPanel) ? {animateIn} : {animateOut}} 

  return (
    <>
        <div className='cont-general-va-sidebar'  /* style={{ width:400 + 'px' }} */ /* className="p-4 fixed-bottom" style={{ width: 400 + 'px',position:"absolute" }} */>
            <div>
                {(showPanel) && <>
                   
                    <div className={`ma-contenedor-panel-sidebar d-flex flex-column
                                     p-3 pt-4 pb-5 shadow-lg 
                                     ${(showPanel)? animateIn : animateOut } 
                                     ${(selected)? 'bg-conte-panel-tra' :'bg-conte-panel-agil'} `}>
                            <div className="ma-titulo-modulo text-center">
                                <h5>{t("help_tittle_assistant")}</h5>
                            </div>
                            <div className="btn-group mb-2" role="group" aria-label="Basic example">
                                <button onClick={()=> handleSelectLabel("Tradicional")} type="button" className={`pt-1 mr-1 pb-1 btn ma-btn-tipo bg-traditional text-center ${(selected)? 'activo bg-traditional-active' :''}`}>
                                    <i className="ma-icono-tipo fa fa-project-diagram mr-2"></i>
                                    
                                   
                                    <span>{t("help_button_traditional")}</span>
                                </button>
                                <button onClick={()=> handleSelectLabel("Agil")} type="button" className={`pt-1 ml-1 pb-1 btn bg-agil ma-btn-tipo text-center ${!selected ? 'activo bg-agil-active' : ''}`} >
                                    <i className="ma-icono-tipo fa fa-tasks mr-2"></i>
                                    <span>{t("help_button_agile")}</span>
                                </button>
                            </div>
                            {(selected) ?
                            <div className='overflow-auto' style={{maxHeight:310}}> 
                                <BodyVirtualAsist dataType="Tradicional"/>
                            </div>
                            :
                            <div className='overflow-auto' style={{maxHeight:310}}> 
                                <BodyVirtualAsist dataType="Agil"/>
                            </div> 
                            }                     
                </div>
                        
                </>
                }
                <div title={t("help_tittle_assistant1")} onClick={handleShowPanel} className='bg-info ma-btn-principal-sidebar rounded-circle bg-info text-white animate__animated animate__fadeInRight ' /* className="ma-btn-principal rounded-circle shadow position-fixed animated bounceInUp bg-info text-white"*/> 
                    {(!showPanel)? 
                    <i className="fa fa-headset animate__animated animate__bounceIn"></i> 
                    :
                    <i className="fa fa-times-circle animate__animated  animate__rotateIn"></i>
                    }
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default VirtualAsistentSideBar