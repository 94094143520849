import React from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
} from "devextreme-react/data-grid";

const SuperTable = (props) => {

  return (
    <DataGrid
      dataSource={props.data}
      showBorders={true}
      showRowLines={true}
      allowColumnResizing = {true}
      columnHidingEnabled={props.hideCols}
      noDataText="Sin datos."
      height={props.height || null}
      className={props.withDd ? 'table-ddl' : ''}
    >
      {!props.noSearch && (
        <SearchPanel visible={true} width={250} placeholder={props.title_search} />
      )}
      {props.columns.map((itmCol, index) => {
        return (
          <Column
            key={index}
            dataField={itmCol.selector}
            caption={itmCol.caption}
            width={itmCol.width}
            alignment={itmCol.align}
            cellRender={(row) => {
              return (
                <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
              );
            }}
          />
        );
      })}
    </DataGrid>
  );
};

export default SuperTable;
