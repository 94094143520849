import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { getData, saveData, inputChange, alreadyExists, settingLocalStorage, encry, saveLocalStorage } from "../helpers";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import emptyImg from "../assets/people-1.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProjects, fetchUsersAccess, fetchAllBoards, fetchUsers } from "../redux";
import Loader from "../components/Loader";
import ErrorMsg from "../components/ErrorMsg";
import { selectTooltip, selectTooltip_en } from "../utils/selectTooltip";
import ReactTooltip from "react-tooltip";
import WelcomeModal from "../components/WelcomeModal";
import OnBoarding from "../components/OnBoarding";
import swal from "sweetalert";
import SuperTableV2 from "../components/SuperTableV2";
import ScrollRangeBoard from "../components/ScrollRangeBoard";
import Tags from "./Tags";
import PieChart, {
  Series,
  Label,
  Connector,
  SmallValuesGrouping,
  Legend,
  Export,
  CommonSeriesSettings,
  Tooltip,
  Title
} from "devextreme-react/pie-chart";

import Chart, {
  ArgumentAxis, Format, Font, Size, SeriesTemplate, ValueAxis, Break, VisualRange
} from "devextreme-react/chart";
import VirtualAsistent from "../components/VirtualAsistent";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const [t,i18n] =useTranslation("landing")

  const ID_ORGANIZACION = "1727EA57-9E8F-44CE-938B-8F377491F135"  // Produccion
 /*  const ID_ORGANIZACION = "8CAADED8-A836-4CB3-8822-878440CC4E30" */ //desarrollo
  // data from redux //
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);
  const projects = useSelector((state) => state.projects);
  const users = useSelector((state) => state.users);

  // instance usetHistory
  let history = useHistory();

  //local states //
  const [selectedTab, setSelectedTab] = useState("");
  const [allAct, setAllAct] = useState([])
  const [recAct, setRecAct] = useState([])
  const [proAct, setProAct] = useState([])
  const [projectsToShow, setProjectsToShow] = useState()

  const [save, setSave] = useState([]);
  const [btnChart, setBtnChart] = useState(1)
  const [showBtnChart, setShowBtnChart] = useState(0)
  const access = useSelector((state) => state.access);
  const [actBtn, setActBtn] = useState(1)

  //conver currency

  const formatterCOP= new Intl.NumberFormat('es-CO',{
    style: 'currency',
    currency :'COP',
    minimumFractionDigits:0
  })

  // projecNoComple //
  const projectNoComple = projects.projects?.filter(item => item.PctCompletadoProyecto < 100)
  // projects extracting data //
  const projPorIniciar = projects.projects.filter((item) => {
    return (item.PctCompletadoProyecto == 0 || item.PctCompletadoProyecto == null)
  })
  const projEnCurso = projects.projects.filter((item) => {
    return (item.PctCompletadoProyecto > 0 && item.PctCompletadoProyecto < 100)
  })

  const projComple = projects.projects.filter((item) => {
    return item.PctCompletadoProyecto == 100
  })

  const projeTypeTra = projects.projects.filter((item) => {
    return item.Metodologia == t("landing_chartbar_convention_traditionals")
  })?.length
  const projeTypeAg = projects.projects.filter((item) => {
    return item.Metodologia == t("landing_chartbar_convention_agiles")
  })?.length


  const projCritico = projects.projects.filter(item => item.PctCompletadoProyecto < 100).filter((item) => {
    let desv = Math.round(item.PctCompletadoProyecto - item.PctPlaneadoProyecto)
    return (desv < -25)
  })
  const projModerado = projects.projects.filter(item => item.PctCompletadoProyecto < 100).filter((item) => {
    let desv = Math.round(item.PctCompletadoProyecto - item.PctPlaneadoProyecto)
    return (desv <= -1 && desv >= -25)
  })
  const projAlDia = projects.projects.filter(item => item.PctCompletadoProyecto < 100).filter((item) => {
    let desv = Math.round(item.PctCompletadoProyecto - item.PctPlaneadoProyecto)
    return (desv >= 0)
  })

  // board extracting inf // 

  const ownBoards = boards.projects.filter(item => item.CreadoPorUsuario == 1)?.length
  const collectBoards = boards.projects.filter(item => item.CreadoPorUsuario == 0)?.length

  //  users extracting inf // 

  const actUsers = users.users.filter(item => item.Estado == "Activo")?.length
  const inacUsers = users.users.filter(item => item.Estado == "Inactivo")?.length

  //data for chart // 

  const dataChartBar = [{
    title: "",
    tradicionales: projeTypeTra,
    agiles: projeTypeAg
  }]
  const dataChartPie = [{
    arg: t("landing_tablecolumn_indicator_status_critical"),
    val: projCritico.length
  },
  {
    arg: t("landing_tablecolumn_indicator_status_moderate"),
    val: projModerado.length
  },
  {
    arg: t("landing_tablecolumn_indicator_status_ontime"),
    val: projAlDia.length
  }
  ]

  const colorPvsA = ["#c5c5c5", "#29b9d0", "#6ab41b"]
  const colorTvsA = ["#DB5C17", "#EFB21E"]
  const colorCost = ["#29b9d0", "#6ab41b"]

  const customize = (pointInfo) => {
    return pointInfo.argument === "Crítico" || pointInfo.argument === "Critical"
      ? { color: "#EF000B" }
      : pointInfo.argument === "Moderado" || pointInfo.argument === "Moderate"
        ? { color: "#FDA80A" }
        : pointInfo.argument === "Al día" ||  pointInfo.argument === "On time"
          ? { color: "#59A915" }
          : { color: "#fdce56" }
  }


  function formatLabel(e) {
    /* `${e.argumentText}\n (${e.valueText})`; */
    return `${e.argumentText}\n (${e.valueText})`;
  }

  function formatLabelCost(e) {
   /*  console.log(e) */
    let valueCost = formatterCOP.format(e.value)
    return `${valueCost}`;
  }
  function formatTooltip(e) {
    return {
      text: `${e.argumentText}\n (${e.valueText})`
    }
  }
  function formatTooltipRvA(e) {
    return {
      text: `${e.argument}\n${e.seriesName}\n (${e.valueText})`
    }
  }

  const showCompletedProjects = useMemo(() => {
    let areThereCompletedProjects = projects.projects.find(
      (project) => project.PctCompletadoProyecto === 100
    );
    if (areThereCompletedProjects) {
      return true;
    }
    return false;
  }, [projects]);

  const printBadgeStatus = useCallback((pctComplete) => {
    switch (pctComplete) {
      case 0:
        return (
          <div className="text-left">
            <span className="badge bg-warning text-white">Por Iniciar</span>
          </div>
        );
      case 100:
        return (
          <div className="text-left">
            <span className="badge bg-success text-white">Completado</span>
          </div>
        );

      default:
        return (
          <div className="text-left">
            <span className="badge bg-info text-white">En curso</span>
          </div>
        );
    }
  }, []);
  const idOrganization = window.localStorage.getItem("OID")
  const columns = [
   /*  {
      caption: "Mod.",
      selector: "FechaModificacion",
      width: 110,
    }, */
    {
      caption: t("landing_tablecolumn_indicator"),
      customRender: (data) => {
        let row = data.data;
        let desv = Math.round(row.PctCompletadoProyecto - row.PctPlaneadoProyecto)

        const desvTradicional = Math.round( row.PctCompletadoProyecto - row.PctPlaneadoProyecto )
        const desvAgileAfinia = Math.round( row.PorEsperado - row.PorCompletado) 
        const desvAgil = 0
        const desvTradicionalAfinia = Math.round( row.PctPlaneadoProyecto  - row.PctCompletadoProyecto )

        if(row.Metodologia == t("landing_chartbar_convention_traditionals")){
          if(idOrganization == ID_ORGANIZACION){
            if( desvTradicionalAfinia < 2){
              return  (
                <div className="d-flex">
                  <i
                    className="fa fa-circle mr-2 my-auto"
                    style={{color:"#71E21B"}}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_ontime")}
                </div>
              );
            }else if(desvTradicionalAfinia >= 10){
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    style={{color:"#231a1a"}}
                  ></i>
                  {t("landing_progress_pa_status_criticalExt")}
                </div>
              )
            }
            else if( desvTradicionalAfinia >= 2 && desvTradicionalAfinia < 5 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle  mr-2 my-auto"
                    style={{color:"#FCE940"}}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_moderate")}
                </div>
              );
            }else if( desvTradicionalAfinia >= 5 && desvTradicionalAfinia < 10 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    style={{color:"#FF313A"}} 
                  ></i>
                  {t("landing_tablecolumn_indicator_status_critical")}
                </div>
              );
            }

          }else {
              if( desvTradicional >= 0){
                return  (
                  <div className="d-flex">
                    <i
                      className="fa fa-check-circle text-success mr-2 my-auto"
                      title= {t("landing_tablecolumn_indicator_status_ontime")}
                    ></i>
                    {t("landing_tablecolumn_indicator_status_ontime")}
                  </div>
                );
              }else if( desvTradicional <= -1 && desvTradicional >= -25 ) {
                return (
                  <div className="d-flex">
                    <i
                      className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                      title={t("landing_tablecolumn_indicator_status_moderate")}
                    ></i>
                    {t("landing_tablecolumn_indicator_status_moderate")}
                  </div>
                );
              }else if( desvTradicional < -25) {
                return (
                  <div className="d-flex">
                    <i
                      className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                      title={t("landing_tablecolumn_indicator_status_critical")}
                    ></i>
                    {t("landing_tablecolumn_indicator_status_critical")}
                  </div>
                );
              }

          }
        }else {
          if(idOrganization == ID_ORGANIZACION){
            if( desvAgileAfinia < 2){
              return  (
                <div className="d-flex">
                  <i
                    className="fa fa-circle mr-2 my-auto"
                    style={{color:"#71E21B"}}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_ontime")}
                </div>
              );
            }else if(desvAgileAfinia >= 10){
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    style={{color:"#231a1a"}}
                  ></i>
                  {t("landing_progress_pa_status_criticalExt")}
                </div>
              )
            }
            else if( desvAgileAfinia >= 2 && desvAgileAfinia < 5 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle  mr-2 my-auto"
                    style={{color:"#FCE940"}}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_moderate")}
                </div>
              );
            }else if( desvAgileAfinia >= 5 && desvAgileAfinia < 10 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle mr-2 my-auto"
                    style={{color:"#FF313A"}} 
                  ></i>
                  {t("landing_tablecolumn_indicator_status_critical")}
                </div>
              );
            }
            
          }else {
            if( desvAgil >= 0){
              return  (
                <div className="d-flex">
                  <i
                    className="fa fa-check-circle text-success mr-2 my-auto"
                    title= {t("landing_tablecolumn_indicator_status_ontime")}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_ontime")}
                </div>
              );
            }else if( desvAgil <= -1 && desvAgil >= -25 ) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                    title={t("landing_tablecolumn_indicator_status_moderate")}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_moderate")}
                </div>
              );
            }else if( desvAgil < -25) {
              return (
                <div className="d-flex">
                  <i
                    className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                    title={t("landing_tablecolumn_indicator_status_critical")}
                  ></i>
                  {t("landing_tablecolumn_indicator_status_critical")}
                </div>
              );
            }
          }
        }
      },
      width: 120
    },
    {
      caption: t("landing_tablecolumn_name"),
      selector: "Nombre",
      width: 300,
      customRender: (row) => {
        return (
          <div className="d-flex align-items-center dropdown-in-table">
            <span
              className="ml-2"
              style={{ maxWidth: "340px" }}
              title={row.data.Nombre}
            >
              <Link
                to={`/${row.data.Metodologia === t("landing_btn_project_meto")
                    ? "schedule"
                    : "planning-board"
                  }/${row.data.IdProyecto}`}
              >
                {row.data.Nombre}
              </Link>
            </span>
          </div>
        );
      }
    },
    {
      caption: t("landing_tablecolumn_startdate"),
      selector: "FechaInicioProg",
      width: 100
    },
    {
      caption: t("landing_tablecolumn_finishdate"),
      selector: "FechaFinProg",
      width: 100
    },
   /*  {
      caption: t("landing_tablecolumn_indicator"),
      customRender: (data) => {
        let row = data.data;
        let desv = Math.round(row.PctCompletadoProyecto - row.PctPlaneadoProyecto)
        if (desv >= 0) {
          return (
            <div className="d-flex">
              <i
                className="fa fa-check-circle text-success mr-2 my-auto"
                title="Al Día"
              ></i>
              {t("landing_tablecolumn_indicator_status_ontime")}
            </div>
          );
        } else if (desv <= -1 && desv >= -25) {
          return (
            <div className="d-flex">
              <i
                className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                title="En Riesgo Moderado"
              ></i>
              {t("landing_tablecolumn_indicator_status_moderate")}
            </div>
          );
        } else if (desv < -25) {
          return (
            <div className="d-flex">
              <i
                className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                title="En Riesgo Alto"
              ></i>
              {t("landing_tablecolumn_indicator_status_critical")}
            </div>
          );
        }
      },
      width: 120
    }, */
  ];
 
  const goToEditProject = (e) => {
    if (e.data.Metodologia === t("landing_btn_project_meto") ) {
      history.push(`/schedule/${e.data.IdProyecto}`);
    } else {
      history.push(`/planning-board/${e.data.IdProyecto}`);
    }
  };

  const totalProgressProjects = useMemo(
    () =>
      _.filter(projects.projects, (item) =>
        item.PctCompletadoProyecto < 100 ? true : false
      ).length,
    [projects.projects]
  );

  const totalCompletedProjects = useMemo(
    () =>
      _.filter(projects.projects, {
        PctCompletadoProyecto: 100
      }).length,
    [projects.projects]
  );


  const getAllData = () => {
    Promise.all([
      getData("productivity-panel", []),
      getData("dashboard-resources-activities", []),
      getData("dashboard-projects-activities", [])]
    ).then((values) => {
      setAllAct(values[0]);
      setRecAct(values[1]);
      setProAct(values[2]);
    });
  };

  useEffect(() => {
    dispatch(fetchAllProjects());
    dispatch(fetchAllBoards());
    dispatch(fetchUsers());
    getAllData();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  // extracting info for main user //
  const userUID = localStorage.getItem("UID")
  const myOwnAct = allAct?.filter(item => item.IdUsuario == userUID)
  const myOwnActToDo = myOwnAct?.filter(item => item.PctAvance == 0 || item.PctAvance == null)?.length
  const myOwnActDoing = myOwnAct?.filter(item => item.PctAvance > 0 && item.PctAvance < 100)?.length
  const myOwnActDone = myOwnAct?.filter(item => item.PctAvance == 100)?.length

  // events in chart //
  const handleClickPieChart = (e) => {
    setActBtn(0)
    if (btnChart != 1) {
      setBtnChart(1)
    }
    if (e.target.argument === "Al día" || e.target.argument === "On time") {
      setProjectsToShow(projAlDia)
      setShowBtnChart(1)
    }
    if (e.target.argument === "Moderado" || e.target.argument === "Moderate") {
      setProjectsToShow(projModerado)
      setShowBtnChart(2)
    }
    if (e.target.argument === "Crítico" || e.target.argument === "Critical") {
      setProjectsToShow(projCritico)
      setShowBtnChart(3)
    }
  }

  const handleShowBtnChart = () => {
    if (btnChart === 1) {
      setActBtn(1)
    }
    setShowBtnChart(0)
    setProjectsToShow()
  }

  const handleCards = (page) => {
    history.push(page)
  }

  const textPie = `${t("landing_pie_title")} \n <b>(${projectNoComple.length})</b>`



  /* const reducer = (accumulator, currentValue) => accumulator + currentValue  */
  const totalCostPlan = projects.projects?.map((item) => item.CostoPlaneado).reduce(function (total, curr) {return total + curr;},0);
  const totalCostReal = projects.projects?.map((item) => item.CostoReal).reduce(function (total, curr) {return total + curr;},0);
  const totalCostPlanForm= formatterCOP.format(totalCostPlan)
  const totalCostRealForm = formatterCOP.format(totalCostReal)
  const  organitationHasCost = () => {
    return totalCostPlan + totalCostReal
  }
  const dataCostChartBar = [{
    title: "",
    costoPlanificado: totalCostPlan,
  
  },
  {
    title: "",
    costoReal: totalCostReal,
 
  }]
  return (
    <React.Fragment>
      <Layout save={save}>
        <TitleCommon
          title={{
            text: "Dashboard",
            icon: "chart-line"
          }}
        />
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Row className=" d-flex justify-content-between mx-2">
                  <Col md={6} lg={3} className="mb-2" >
                    <Card className="border-primary card-dashboard bg-users card-projects">
                      <Card.Body onClick={() => handleCards("/projects")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center ">
                          <i className="fa fa-clipboard dashboard-icons mr-2"></i>
                          <span className="h5 mb-0">{t("landing_card_projects_title")}</span>
                        </div>
                        <h2 className="my-0">{projects.projects?.length}</h2>
                        <div className="d-flex">
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>{t("landing_card_projects_convention_start")}</small>
                            <small className="text-primary">( {projPorIniciar?.length} )</small>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-info mr-2"></i>{t("landing_card_projects_convention_inprogress")}</small>
                            <small className="text-primary">( {projEnCurso?.length} )</small>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-success mr-2"></i>{t("landing_card_projects_convention_complete")}</small>
                            <small className="text-primary">( {projComple?.length} )</small>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} lg={3} className="mb-2" >
                    <Card className="border-primary card-dashboard card-calender">
                      <Card.Body onClick={() => handleCards("/tasks")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center ">
                          <i className="fa fa-tasks dashboard-icons mr-2"></i>
                          <span className="h5 mb-0">{t("landing_card_activities_title")}</span>
                        </div>
                        <h2 className="my-0">{myOwnAct?.length}</h2>
                        <div className="d-flex">
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>{t("landing_card_activities_convention_start")}</small>
                            <small className="text-primary">( {myOwnActToDo} )</small>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-info mr-2"></i>{t("landing_card_activities_convention_inprogress")}</small>
                            <small className="text-primary">( {myOwnActDoing} )</small>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-success mr-2"></i>{t("landing_card_activities_convention_complete")}</small>
                            <small className="text-primary">( {myOwnActDone} )</small>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} lg={3} className="mb-2">
                    <Card className="border-primary card-dashboard card-boards">
                      <Card.Body onClick={() => handleCards("/boards")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center ">
                          <i className="fa fa-border-all dashboard-icons mr-2"></i>
                          <span className="h5 mb-0">{t("landing_card_boards_title")}</span>
                        </div>
                        <h2 className="my-0">{boards.projects?.length}</h2>
                        <div className="d-flex">
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>{t("landing_card_boards_convention_own")}</small>
                            <small className="text-primary">( {ownBoards} )</small>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-info mr-2"></i>{t("landing_card_boards_convention_participe")}</small>
                            <small className="text-primary">( {collectBoards} )</small>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} lg={3} className="mb-2">
                    <Card className="border-primary card-dashboard card-users">
                      <Card.Body onClick={() => handleCards("/resources")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center ">
                          <i className="fa fa-users dashboard-icons mr-2"></i>
                          <span className="h5 mb-0">{t("landing_card_resources_title")}</span>
                        </div>
                        <h2 className="my-0">{users.users?.length}</h2>
                        <div className="d-flex">
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-primary mr-2"></i>{t("landing_card_resources_convention_active")}</small>
                            <small className="text-primary">( {actUsers} )</small>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-2">
                            <small className="text-dark"><i class="fa fa-circle text-dark mr-2"></i>{t("landing_card_resources_convention_inactive")}</small>
                            <small className="text-primary">( {inacUsers} )</small>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-0 pb-0 mb-0">
                    <Row className=" d-flex justify-content-around mx-2">
                      <Col lg={3} 
                          className={`dashboard-chart-pie-bar ${(organitationHasCost() > 0)? "": "d-none"}`}
                          >
                        <div className={(projects.projects?.length === 0) ? "d-none" : ""}>

                   
                          <Card 
                            className="border-primary mb-2 card-dashboard"
                          /* className="border-primary card-dashboard card-users mt-2" */

                          >
                            <Card.Body onClick={() => handleCards("/projects/third")} 
                            /* className="py-2 px-2 d-flex flex-column justify-content-between align-items-center" */
                            className="py-2 px-2 d-flex flex-column border-none justify-content-between"
                            >
                              <div className="d-flex justify-content-start align-items-center">
                                <span className="h6 mb-0">{t("landing_card_cost_title_1")}</span>
                              </div>
                              <div className="d-flex justify-content-center align-items-center mb-2">
                                  <i className="fa fa-money-bill-wave mr-2"></i>
                                  <h5 className="my-0" style={{color:"#29b9d0"}}>{totalCostPlanForm}</h5>
                              </div>
                              <div className="d-flex justify-content-start align-items-center ">
                                <span className="h6 mb-0">{t("landing_card_cost_title_2")}</span>
                              </div>
                              <div className="d-flex justify-content-center align-items-center ">
                                <i className="fa fa-money-bill-wave mr-2"></i>
                                <h5 className="my-0" style={{color:"#6ab41b"}}>{totalCostRealForm}</h5>
                              </div>
                            </Card.Body>
                          </Card>
                       
                       
                        </div>
                        <div className={(projects.projects?.length === 0) ? "d-none" : ""}>
                       {/*    <PieChart
                            id="pie"
                            type="doughnut"
                            dataSource={dataChartPie}
                            innerRadius={0.7}
                            style={{ cursor: "pointer" }}
                            customizePoint={customize}
                            onPointClick={(e) => handleClickPieChart(e)}
                          >
                            <Title text={textPie}>
                              <Font size={20} />
                            </Title>
                            <Series
                              argumentField="arg"
                              valueField="val"
                            >
                              <Label
                                visible={false}
                                customizeText={formatLabel}
                                format="fixedPoint"
                                position="columns"
                              >
                                <Font size={11} />
                                <Connector visible={false} width={0} />
                              </Label>
                              <SmallValuesGrouping threshold={1} mode="smallValueThreshold" />
                            </Series>
                            <Tooltip enabled={true} customizeTooltip={formatTooltip} />
                            <Size width={300} height={220} />
                            <Legend horizontalAlignment="center" verticalAlignment="bottom" visible={true} />
                            <Export enabled={false} />
                          </PieChart> */}
                             <Chart
                            dataSource={dataCostChartBar}
                            id="chart"
                            palette={colorCost}
                          >
                           {/*  <Title
                             text={t("landing_chartbar_title")}
                             >
                              <Font size={20} />
                            </Title> */}
                                      
                            <CommonSeriesSettings
                            argumentField="title"
                              type="bar"
                        /*       hoverMode="allArgumentPoints"
                              selectionMode="allArgumentsPoints"
                                 format="fixedPoint"
                                position="columns"
                              */
                              barPadding={0.3}
                            >
                              <Label 
                                  customizeText={formatLabelCost}
                                visible={true}>
                                <Format type="fixedPoint" precision={0} />
                              </Label>
                            </CommonSeriesSettings>
                            <ValueAxis maxValueMargin={0.01}>
                           {/*  <VisualRange startValue={0} endValue={100} /> */}
                            </ValueAxis>
                            <Series
                              valueField="costoPlanificado"
                              name={t("landing_card_cost_title_1")}
                            />
                            <Series
                              argumentField="title"
                              valueField="costoReal"
                              name={t("landing_card_cost_title_2")}
                            />
                         
                           
                            <Legend verticalAlignment="bottom" horizontalAlignment="center"
                              visible={true} />
                            <Size width={300} height={310} />
                          </Chart>
                        </div>

                      </Col>
                      <Col lg={3} className="dashboard-chart-pie-bar">
                        <div className={(projects.projects?.length === 0) ? "d-none" : ""}>
                          <Chart
                            dataSource={dataChartBar}
                            id="chart"
                            palette={colorTvsA}
                          >
                            <Title text={t("landing_chartbar_title")}>
                              <Font size={20} />
                            </Title>
                            <CommonSeriesSettings
                              argumentField="title"
                              type="bar"
                              barPadding={0.3}
                            >
                              <Label visible={true}>
                                <Format type="fixedPoint" precision={0} />
                              </Label>
                            </CommonSeriesSettings>
                            <Series
                              valueField="tradicionales"
                              name={t("landing_chartbar_convention_traditional")}
                            />
                            <Series
                              valueField="agiles"
                              name={t("landing_chartbar_convention_agile")}
                            />
                            <Legend verticalAlignment="bottom" horizontalAlignment="center"
                              visible={true} />
                            <Size width={300} height={220} />
                          </Chart>
                        </div>
                        <div className={(projects.projects?.length === 0) ? "d-none" : ""}>
                          <PieChart
                            id="pie"
                            type="doughnut"
                            dataSource={dataChartPie}
                            innerRadius={0.7}
                            style={{ cursor: "pointer" }}
                            customizePoint={customize}
                            onPointClick={(e) => handleClickPieChart(e)}
                          >
                            <Title text={textPie}>
                              <Font size={20} />
                            </Title>
                            <Series
                              argumentField="arg"
                              valueField="val"
                            >
                              <Label
                                visible={false}
                                customizeText={formatLabel}
                                format="fixedPoint"
                                position="columns"
                              >
                                <Font size={11} />
                                <Connector visible={false} width={0} />
                              </Label>
                              <SmallValuesGrouping threshold={1} mode="smallValueThreshold" />
                            </Series>
                            <Tooltip enabled={true} customizeTooltip={formatTooltip} />
                            <Size width={300} height={220} />
                            <Legend horizontalAlignment="center" verticalAlignment="bottom" visible={true} />
                            <Export enabled={false} />
                          </PieChart>
                        </div>

                      </Col>
                      <Col lg={(projects.projects?.length === 0) ? 12 : 6} className={(projects.projects?.length === 0) ? "d-flex justify-content-center" : "mt-3"}>
                        <Row className={(projects.projects?.length === 0) ? "d-none" : "group-btns-dashboard"}>
                          {(showBtnChart === 1) && <Button
                            variant="outline-success"
                            className="ml-2 btn-dashboard"
                            size="sm"
                            as={Link}
                            onClick={() => { handleShowBtnChart() }}
                            active={true}
                          >
                            {t("landing_button_ontime")} <bold className="border-left border-white pl-1">X</bold>
                          </Button>
                          }
                          {(showBtnChart === 2) && <Button
                            variant="outline-warning"
                            className="ml-2 btn-dashboard"
                            size="sm"
                            as={Link}
                            onClick={() => { handleShowBtnChart() }}
                            active={true}
                          >
                            {t("landing_button_moderate")} <bold className="border-left border-white pl-1">X</bold>
                          </Button>
                          }
                          {(showBtnChart === 3) &&
                            <Button
                              variant="outline-primary"
                              className="ml-2 btn-dashboard"
                              size="sm"
                              as={Link}
                              onClick={() => { handleShowBtnChart() }}
                              active={true}
                            >
                              {t("landing_button_critical")} <bold className="border-left border-white pl-1">X</bold>
                            </Button>
                          }
                          <Button
                            variant="outline-primary"
                            className="ml-2 btn-dashboard"
                            size="sm"
                            as={Link}
                            onClick={() => { setBtnChart(1); setActBtn(1); setProjectsToShow(); setShowBtnChart(0) }}
                            active={(actBtn === 1) ? true : false}
                          >
                            <i className="fa fa-clipboard mr-2"></i>{t("landing_button_latestmodifiedprojects")}
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="ml-2 btn-dashboard"
                            size="sm"
                            as={Link}
                            onClick={() => { setBtnChart(2); setActBtn(2); setProjectsToShow() }}
                            active={(actBtn === 2) ? true : false}
                          >
                            <i className="fa fa-chart-bar mr-2"></i>{t("landing_button_resourcesvsactivities")}
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="ml-2 btn-dashboard"
                            size="sm"
                            as={Link}
                            onClick={() => { setBtnChart(3); setActBtn(3) }}
                            active={(actBtn === 3) ? true : false}
                          >
                            <i className="fa fa-chart-bar mr-2"></i>{t("landing_button_projectsvsactivities")}
                          </Button>
                        </Row>
                        <Row className={(projects.projects?.length === 0) ? "" : "overflow-auto mt-3"} style={{ maxHeight: 380 }}>
                          {(btnChart === 1) &&
                            <div >
                              {totalProgressProjects ? (
                                <SuperTableV2
                                  data={(!projectsToShow) ? _.filter(projects.projects, (item) =>
                                    item.PctCompletadoProyecto < 100 ? true : false
                                  ) : projectsToShow}
                                  columns={columns}
                                  noSearch={false}
                                  noGroup={true}
                                  title_searchbox = {t("landing_searchbox")}
                                  onRowClick={goToEditProject}
                                  sortAsc={true}
                                  className="table-dashboard"
                                />
                              ) : (
                                <div className="text-center pt-1">
                                  <img
                                    src={emptyImg}
                                    alt="Sin datos"
                                    className="img-fluid mb-3 animated pulse slower infinite"
                                  />
                                  <h5>{t("landing_noproject_create")}</h5>
                                  <p>
                                    {t("landing_noproject_noyet")}
                                  </p>
                                  <Button as={Link} to="/project">
                                    <i className="fa fa-plus-circle mr-2"></i>{t("landing_noproject_create")}
                                  </Button>
                                </div>
                              )}
                            </div>
                          }
                          {(btnChart === 2) && <>
                            <Chart
                              id="chart"
                              dataSource={recAct}
                              palette={colorPvsA}
                            >
                              <Title text={t("landing_titlechart_resourcesvsactivities")}>
                                <Font size={20} />
                              </Title>
                              <CommonSeriesSettings argumentField="resource" type="stackedBar" />
                              <Series valueField="PorIniciar" name={t("landing_barchart_convention_start")} />
                              <Series valueField="EnCurso" name={t("landing_barchart_convention_inprogress")} />
                              <Series valueField="terminadas" name={t("landing_barchart_convention_complete")} />
                              <Legend
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                visible={true}
                                itemTextPosition="right"
                              />
                              <Size width={800} height={370} />
                              <Tooltip enabled={true} customizeTooltip={formatTooltipRvA} />
                            </Chart>
                          </>
                          }
                          {(btnChart === 3) && <>
                            <Chart
                              id="chart"
                              dataSource={proAct}
                              palette={colorPvsA}
                            >
                              <Title text={t("landing_titlechart_projectsvsactivities")}>
                                <Font size={20} />
                              </Title>
                              <CommonSeriesSettings argumentField="Proyecto" type="stackedBar" />
                              <Series valueField="PorIniciar" name={t("landing_barchart_convention_start")} />
                              <Series valueField="EnCurso" name={t("landing_barchart_convention_inprogress")} />
                              <Series valueField="terminadas" name={t("landing_barchart_convention_complete")} />
                              <Legend
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                visible={true}
                                itemTextPosition="right"
                              />
                              <Size width={800} height={370} />
                              <Tooltip enabled={true} customizeTooltip={formatTooltipRvA} />
                            </Chart>
                          </>
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
      <React.Fragment>
        {selectedTab != "templates" && !projects.error && !projects.loading ? (
          <>
          <VirtualAsistent/>
          <Button
            as={Link}
            to="/project"
            variant="success"
            className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
            style={{ bottom: "1rem", right: "1rem" }}
            data-tip={(t("landing_lenguaje") == "es")? selectTooltip(10) : selectTooltip_en(10)}
            data-place="top"
            data-tour="step-2"
          >
            <i className="fa fa-plus mr-2"></i>{t("landing_btn_create")}
          </Button>
          </>
        ) : (
          ""
        )}
      </React.Fragment>
      <OnBoarding
        show={
          !projects.loading && projects.projects.length === 0 ? true : false
        }
      />
      {!projects.loading && projects.projects.length === 0 ? (
        <WelcomeModal />
      ) : null}
    </React.Fragment>
  );
};

export default Landing;
