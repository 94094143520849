import React from "react";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import img from '../assets/ban-solid.png'
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NoAccess = () => {   

    return(
    <>
    <Layout>
    <TitleCommon
          title={{
            text: "Acceso Restrigido",
            icon: "clipboard"
          }}
          //   button={{
          //     text: "Volver",
          //     icon: "arrow-left",
          //     url: "/",
          //   }}
        />
    </Layout>
    <div className="text-center pt-4 mt-4">
        <img
        src={img}
        alt="Sin datos"
        className="img-fluid mb-3 animated pulse slower infinite no_access_icon"
        // width="30"
        />
        <h5 className ="mb-4 mt-2" >Lo sentimos , no tienes acceso a esta funcionalidad</h5>
       {/*  <p>
        Aún no tienes ningún proyecto en curso, inicia
        ahora creando uno.
        </p> */}
        <Button as={Link} to="/projects">
        <i className="fa fa-plus-circle mr-2"></i> ir al centro de proyectos
        </Button>
    </div>
    </>
);
};

export default NoAccess;