import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { getData } from "../../helpers";
import { useTranslation } from "react-i18next";

const ModalEditSprint = (props) => {
  const [t,i18n] =useTranslation("planning")

  const { show, setShow } = props;
  const [formData, setFormData] = useState(props.data.current || {});
  const [excludeDates, setExcludeDates] = useState([]);
  const ddlSprintStatus = useSelector((state) => state.scrum.ddlSprintStatus);

  const handleChange = (e) => {
    setFormData((prev) => {
      let dummie = { ...prev };
      if (e.target.name === "FechaInicio") dummie.FechaFin = "";
      return { ...dummie, [e.target.name]: e.target.value };
    });
  };

  const handleFocus = (e) => {
    try {
      e.target.select();
    } catch (err) {
      // can't select input text
    }
  };

  const handleSaveData = () => {
    props.update(formData);
  };

  const handleProps = {
    onFocus: handleFocus,
    onChange: handleChange
  };

  /* change input data */
  const _getValue = (selector) => {
    if (
      typeof formData != "undefined" &&
      typeof formData[selector] != "undefined"
    ) {
      let val = formData[selector];
      return val;
    } else {
      return null;
    }
  };
  const dateChanged = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
          [e.target.name]: e.target.value,
        }
      });
  };
/* change input data */
/* exclude dates */
const getExcludedDates = () => {
  Promise.all([
    getData(`excluded-dates/agile`, {})
  ]).then((values) => {
    setExcludeDates(values[0])
  });
};
useEffect(() => {
  getExcludedDates()
}, []);

const allExcludeDates = excludeDates.map((date) => new Date(date));

/* exclude dates */

  // set state with props
  useEffect(() => {
    setFormData(props.data.current || {});
  }, [show]);

  // render tooltips
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        contentClassName="border-0 shadow-lg"
        animation={false}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveData();
          }}
        >
          <Modal.Header>
            <Modal.Title className="h5 w-100">
              {t("modal_editsprint_text1")} {formData.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("modal_editsprint_text2")}</Form.Label>
                      <Form.Control
                        as="select"
                        {...handleProps}
                        name="Estado"
                        value={formData.Estado || ''}
                      >
                        <option value="">{t("modal_editsprint_text2_1")}...</option>
                        {ddlSprintStatus !== undefined &&
                          ddlSprintStatus.map((item, index) => {
                            return (
                              <option key={item.Id} value={item.Id}>
                                {item.EstadoSprint}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Alias</Form.Label>
                      <Form.Control
                        {...handleProps}
                        placeholder={t("modal_editsprint_text3_1")}
                        name="Alias"
                        value={formData.Alias || ''}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("modal_editsprint_text4")}</Form.Label>
                      <br />
                     {/*  <Form.Control
                        {...handleProps}
                        type="date"
                        name="FechaInicio"
                        value={moment(formData.FechaInicio)
                          .utc()
                          .format("YYYY-MM-DD")}
                      /> */}
                       <ReactDatePicker
                        selected={
                          _getValue("FechaInicio") &&
                          moment(_getValue("FechaInicio")).add(5, "hours")._d
                        }
                        dateFormat="dd/MM/yyyy"
                        excludeDates={allExcludeDates}
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        wrapperClassName="w-100"
                        className="form-control"
                        onChange={(date) => {
                          let e = { target: {} };
                          e.target.name = "FechaInicio";
                          e.target.value = date;
                          dateChanged(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>{t("modal_editsprint_text5")}</Form.Label>
                      <br />
                    {/*   <Form.Control
                        {...handleProps}
                        type="date"
                        name="FechaFin"
                        value={moment(formData.FechaFin)
                          .utc()
                          .format("YYYY-MM-DD")}
                        min={formData.FechaInicio}
                        disabled={formData.FechaInicio ? false : true}
                      /> */}
                        <ReactDatePicker
                        selected={
                          _getValue("FechaFin") &&
                          moment(_getValue("FechaFin")).add(5, "hours")._d
                        }
                        dateFormat="dd/MM/yyyy"
                        excludeDates={allExcludeDates}
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        wrapperClassName="w-100"
                        className="form-control"
                        minDate={formData.FechaInicio}
                        disabled={formData.FechaInicio ? false : true}
                        onChange={(date) => {
                          let e = { target: {} };
                          e.target.name = "FechaFin";
                          e.target.value = date;
                          dateChanged(e);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="dark"
              onClick={() => {
                setShow(false);
              }}
            >
              <i className="fa fa-times mr-2"></i>{t("modal_editsprint_text6")}
            </Button>
            <Button type="button" variant="success" onClick={handleSaveData}>
              <i className="fa fa-save mr-2"></i>{t("modal_editsprint_text7")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(ModalEditSprint);
