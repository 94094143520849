import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists, desencry, } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { fetchUsers, fetchUsersAccess } from "../redux";
import Loading from "../components/Loading";
import Loader from "../components/Loader";
import SuperTableV2 from "../components/SuperTableV2";
import { useMemo } from "react";
import NoAccess from "./NoAccess";
import { useTranslation } from "react-i18next";
const Users = () => {
  const access = window.localStorage.getItem("accessToUsers")
  const desData = desencry(access,'accessCloudProjectDevUser')
  const [t,i18n] =useTranslation("users")
  const [show, setShow] = useState(false);
  const [userEdit, setUserEdit] = useState({});
  const [showView, setShowView] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
/* new Feature user counter */
  const users = useSelector((state) => state.users);
  const totalUsersActive = useMemo(() => _.filter(users.users, {Estado:"Activo"}).length,
  [users.users]);
  const totalUsersInactive = useMemo(() => _.filter(users.users, {Estado:"Inactivo"}).length,
  [users.users]);
/* new Feature user counter */

  const dispatch = useDispatch();

  const edituser = (data) => {
    setUserEdit(data.data);
    handleShow();
  };

  const columns = [
    {
      caption: "Username",
      selector: "Username",
      width: 200,
      customRender: (row) => {
        return <span className="text-primary">{row.data.Username}</span>;
      }
    },
    { caption: t("resources_tablecolumn_name"), selector: "Nombre", width: 200 },
    { caption: t("resources_tablecolumn_mail"), selector: "Correo", width: 200 },
    { caption: t("resources_tablecolumn_role"), selector: "Rol", width: 200 },
    { caption: t("resources_tablecolumn_hability"), selector: "Habilidad", width: 200 },
    // { caption: "Creado Por", selector: "CreadoPor" },
    {
      caption: t("resources_tablecolumn_creation"),
      selector: "FechaCreado",
      width: 200,
      customRender: (row) => {
        return <span>{moment(row.data.FechaCreado).format("DD/MM/YYYY")}</span>;
      }
    },
    {
      caption: t("resources_tablecolumn_lastlogin"),
      selector: "LastLogin",
      width: 200,
      customRender: (row) => {
        return <span>
          {row.data.LastLogin == null? t("resources_tablecolumn_noregister"):moment(row.data.LastLogin).format("DD/MM/YYYY")}
          </span>;
      }
    }
  ];

  useEffect(() => {
    dispatch(fetchUsers());
  /*   dispatch(fetchUsersAccess()) */
  }, []);




  return (<>
    { (desData ==="true") ? 
    <React.Fragment>
      <Layout loadUser={false} users = {users.users}>
        <TitleCommon
          title={{
            text: t("resources_title"),
            icon: "user"
          }}
          // button={{
          //   text: "Volver",
          //   icon: "arrow-left",
          //   url: "/",
          // }}
        />
        <Container fluid>
          <Tab.Container defaultActiveKey="first" transition={false}>
            <Row className="bg-light">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="first">{t("resources_button_active")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">{t("resources_button_inactive")}</Nav.Link>
                  </Nav.Item>
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    className="animate__animated animate__fadeIn"
                  >
                    <p className="mb-2 mb-md-n4">
                      {t("resources_active_title1")}{" "}
                      <b className="text-primary">({totalUsersActive}) {t("resources_active_title2")}</b> {t("resources_active_title3")}
                    </p>
                    {users.loading ? (
                      // <Loading />
                      <Loader />
                    ) : users.error ? (
                      "error"
                    ) : (
                      <SuperTableV2
                        data={_.filter(users.users, { Estado: "Activo" })}
                        columns={columns}
                        onRowClick={edituser}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    className="animate__animated animate__fadeIn"
                  >
                    <p className="mb-2 mb-md-n4">
                      {t("resources_active_title1")}{" "}
                      <b className="text-primary">({totalUsersInactive}) {t("resources_active_title4")}</b> {t("resources_active_title3")}
                    </p>
                    {users.loading ? (
                      <Loading />
                    ) : users.error ? (
                      "error"
                    ) : (
                      <SuperTableV2
                        data={_.filter(users.users, { Estado: "Inactivo" })}
                        columns={columns}
                        onRowClick={edituser}
                      />
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
      <React.Fragment>
        <Button
          variant="success"
          onClick={() => {
            setUserEdit({
              Estado: "Activo",
              new: true
            });
            handleShow();
          }}
          className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
          style={{ bottom: "1rem", right: "1rem" }}
        >
          <i className="fa fa-plus mr-2"></i>{t("resources_btn_create")}
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          contentClassName="border-0 shadow-lg"
        >
          <Form
            onSubmit={(e) => {
             
              e.preventDefault();
              const success = () => {
                handleClose();
                dispatch(fetchUsers());
              };
              saveData("users", userEdit, success);
              // handleClose();
            }}
          >
            <Modal.Header >
              <Modal.Title className="h5">
                {userEdit.new ? t("resources_btn_create") : t("resources_btn_edit")} {t("resources_modal_title")}
              </Modal.Title>
              <Button onClick={handleClose} variant="light">X</Button>
            </Modal.Header>
            <Modal.Body>
              {/* {JSON.stringify(userEdit)} */}
              <Form.Check
                type="switch"
                id="SwitchEstado"
                name="Estado"
                label={
                  userEdit.Estado === "Activo"
                    ? t("resources_modal_title2")
                    : t("resources_modal_title3")
                }
                className="mb-3"
                onChange={(e) => {
                 
                  if (e.target.checked) {
                    e.target.value = "Activo";
                  } else {
                    e.target.value = "Inactivo";
                  }
                  inputChange(e, setUserEdit);
                }}
                checked={userEdit.Estado === "Activo" ? true : false}
              />
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Label>
                    Username<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    name="Username"
                    placeholder=""
                    value={userEdit.Username || ""}
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    required
                    disabled={userEdit.new ? false : true}
                  />
                  {alreadyExists(users.users, {
                    Username: userEdit.Username
                  }) &&
                    userEdit.new && (
                      <Form.Text className="text-danger">
                        {t("resources_modal_validation_1")}
                      </Form.Text>
                    )}
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Label>
                    {t("resources_modal_mail")}<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    name="Correo"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.Correo || ""}
                    type="email"
                    required
                  />
                  {alreadyExists(users.users, { Correo: userEdit.Correo }) &&
                    userEdit.new && (
                      <Form.Text className="text-danger">
                        {t("resources_modal_validation_2")}
                      </Form.Text>
                    )}
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Label>
                    {t("resources_modal_password")}<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    name="Password"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.Password || ""}
                    type="password"
                    required
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={3} className="mb-3">
                  <Form.Label>
                    {t("resources_modal_firstname")}<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    name="PrimerNombre"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.PrimerNombre || ""}
                    required
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Label>{t("resources_modal_secondname")}</Form.Label>
                  <Form.Control
                    name="SegundoNombre"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.SegundoNombre || ""}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Label>
                    {t("resources_modal_firstlastname")}<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    name="PrimerApellido"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.PrimerApellido || ""}
                    required
                  />
                </Col>
                {( t("resources_lenguaje") == "es") &&
                <Col md={3} className="mb-3">
                  <Form.Label>{t("resources_modal_secondlastname")}</Form.Label>
                  <Form.Control
                    name="SegundoApellido"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.SegundoApellido || ""}
                  />
                </Col>
                }
              </Row>
              <hr />
              <Row>
                <Col md={4} className="mb-3">
                  <Form.Label>
                    {t("resources_modal_role")}<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="IdRol"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.IdRol || ""}
                    required
                  >
                    <option value="">Seleccionar...</option>
                    <option value="1">Admin</option>
                    <option value="2">Gerente de Proyecto</option>
                    <option value="3">Miembro de Equipo</option>
                    <option value="4">Ejecutivo</option>
                  </Form.Control>
                </Col>
                {/* <Col md={4} className="mb-3">
                  <Form.Label>
                    LoS<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="IdRol"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.IdRol || ""}
                    required
                  >
                    <option value="">Seleccionar...</option>
                    <option value="1">Advisory</option>
                    <option value="2">Tax & Legal</option>
                    <option value="3">Financial Services</option>
                  </Form.Control>
                </Col> */}
                <Col md={8} className="mb-3">
                  <Form.Label>
                    {t("resources_modal_profession")}<small className="text-danger">*</small>
                  </Form.Label>
                  <Form.Control
                    name="ProfesionCargo"
                    onChange={(e) => {
                      inputChange(e, setUserEdit);
                    }}
                    value={userEdit.ProfesionCargo || ""}
                    required
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={handleClose}>
                <i className="fa fa-times mr-2"></i>{t("resources_modal_cancel")}
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={
                  (alreadyExists(users.users, {
                    Username: userEdit.Username
                  }) &&
                    userEdit.new) ||
                  (alreadyExists(users.users, { Correo: userEdit.Correo }) &&
                    userEdit.new)
                    ? true
                    : false
                }
              >
                <i className="fa fa-save mr-2"></i>{t("resources_modal_save")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  :<>
  <NoAccess/>
 
  </>
  }
 </> );
};

export default Users;
