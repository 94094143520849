import React, { useState } from "react";
import { Button, Form as BootsForm, Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  templateName: Yup.string().required(
    "Por favor dale un nombre a la plantilla."
  ),
  templateMethodology: Yup.string().required(
    "Por favor selecciona una metodología."
  ),
});

const createTemplate = (dataTemplate) => {
  alert("POST new template");
  console.log(dataTemplate);
};

const CreateTemplate = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button
        onClick={handleShow}
        variant="info"
        className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-1s"
        style={{ bottom: "1rem", right: "1rem" }}
      >
        <i className="fa fa-plus mr-2"></i>Crear plantilla
      </Button>
      <Modal show={show} onHide={handleClose} contentClassName="border-0">
        <Formik
          initialValues={{ templateName: "", templateMethodology: "" }}
          onSubmit={(dataTemplate) => {
            createTemplate(dataTemplate);
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            return (
              <Form>
                <Modal.Header >
                  <Modal.Title className="h5">Nueva plantilla</Modal.Title>
                  <Button onClick={handleClose} variant="light">X</Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="templateName" className="form-label">
                      Nombre de la plantilla
                    </label>
                    <Field
                      name="templateName"
                      className="form-control"
                      required
                    />
                    <ErrorMessage name="templateName">
                      {(errorMsg) => (
                        <small className="text-danger form-text">
                          {errorMsg}
                        </small>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="form-group">
                    <label htmlFor="templateName" className="form-label">
                      Metodología
                    </label>
                    <Field
                      name="templateMethodology"
                      className="form-control"
                      as="select"
                      required
                    >
                      <option value="">Seleccionar metodología...</option>
                      <option value="Tradicional">Tradicional</option>
                      <option value="Ágil">Ágil</option>
                    </Field>
                    <ErrorMessage name="templateMethodology">
                      {(errorMsg) => (
                        <small className="text-danger form-text">
                          {errorMsg}
                        </small>
                      )}
                    </ErrorMessage>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="dark" onClick={handleClose}>
                    <i className="fa fa-times mr-2"></i>Cancelar
                  </Button>
                  <Button
                    variant="success"
                    type="submit"
                    //    onClick={handleClose}
                    // disabled={props.errors ? true : false}
                  >
                    <i className="fa fa-save mr-2"></i>Guardar
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default CreateTemplate;
