import React from "react";

import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
  ConstantLine,
  Grid,
  Label,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";

const sprintsData = [
  {
    sprint: 'Backlog',
    ptsRemaining: 190,
  },
  {
    sprint: 'Sprint 1',
    ptsRemaining: 150,
    velocity: 23,
  },
  {
    sprint: 'Sprint 2',
    ptsRemaining: 115,
    velocity: 35,
  },
  {
    sprint: 'Sprint 3',
    ptsRemaining: 110,
    velocity: 21,
  },
  {
    sprint: 'Sprint 4',
    ptsRemaining: 90,
    velocity: 26,
  },
  {
    sprint: 'Sprint 5',
    ptsRemaining: 74,
    velocity: 24,
  },
  {
    sprint: 'Sprint 6',
    ptsRemaining: 65,
    velocity: 24,
  },
  {
    sprint: 'Sprint 7',
    ptsRemaining: 45,
    velocity: 36,
  },
  //   {
  //     sprint: 8,
  //     ptsRemaining: null,
  //     velocity: null,
  //   },
  //   {
  //     sprint: 9,
  //     ptsRemaining: null,
  //     velocity: null,
  //   },
  //   {
  //     sprint: 10,
  //     ptsRemaining: null,
  //     velocity: null,
  //   },
];

const ProductBurndown = (props) => {
  const [t,i18n] =useTranslation("planning")

  return (
    <Chart
      dataSource={props.data}
      //   palette="Harmony Light"
      style={{ height: "338px" }}
    >
      <CommonSeriesSettings argumentField="sprint" />
      <Series
        name={t("hvagile_chartburndown_conventionts_expected")}
        valueField="ptsRemaining"
        axis="points"
        type="line"
        color="#29b9d0"
        barPadding={0.6}
      />
      <Series
        name={t("hvagile_chartburndown_conventionts_remaining")}
        valueField="velocity"
        axis="points"
        type="line"
        color="#6ab41b"
      />

      <ArgumentAxis>
        <Label overlappingBehavior="stagger" />
        {/* <Grid visible={true}/> */}
      </ArgumentAxis>

      <ValueAxis name="points" position="left" tickInterval={30} />
      <Tooltip
        enabled={true}
        shared={true}
        // customizeTooltip={customizeTooltip}
      />

      <Legend verticalAlignment="bottom" horizontalAlignment="center" visible={true} />
    </Chart>
  );
};

function customizeTooltip(pointInfo) {
  return {
    html: `<div><div class="tooltip-header">${pointInfo.argumentText}</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>${pointInfo.points[0].seriesName}</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div><div class="series-name"><span class='bottom-series-name'>${pointInfo.points[1].seriesName}</span>: </div><div class="value-text"><span class='bottom-series-value'>${pointInfo.points[1].valueText}</span>% </div></div></div>`,
  };
}

function customizePercentageText({ valueText }) {
  return `${valueText}%`;
}

export default ProductBurndown;
