import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import StepWizard from "react-step-wizard";
import img1 from "../assets/iso-scrum.png";
import img2 from "../assets/iso.png";
import WelcomeStep from "./WelcomeStep";
import { useTranslation } from "react-i18next";

const WelcomeModal = () => {
  const [show, setShow] = useState(true);
  const [t,i18n] = useTranslation("onBoarding")

  const handleClose = () => {
    window.localStorage.setItem("welcomeMsg", true);
    setShow(false);
  };

  let showWelcome = window.localStorage.getItem("welcomeMsg");

  const welcomeModalToFront = () => {
    let dialogModal = document.getElementsByClassName("welcome-modal");
    if (dialogModal.length > 0) {
      dialogModal[0].parentElement.style.zIndex = 1070;
    }
  };

  useEffect(() => {
    welcomeModalToFront();
  }, []);

  if (showWelcome) {
    return "";
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0 p-3"
      dialogClassName="welcome-modal"
      centered
      size="lg"
    >
      <Modal.Header>
        <h5>
          {t("stepbystep_welcome_tittle1")} <span className="text-primary">Cloud Projects</span>
        </h5>
        <Button onClick={handleClose} variant="light">X</Button>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 overflow-hidden">
          <StepWizard>
            <WelcomeStep
              img={img1}
              title={t("stepbystep_welcome_tittle2")}
            /*   msg={
                <p className="text-left">
                  Gracias por hacer parte de la familia Cloud Projects, de aquí
                  en adelante estaremos acompañandote en la estructuración de
                  tus proyectos y ofreciendote diferentes alternativas para que
                  puedas mantener tus objetivos siempre visibles. A media que
                  vas usando la herramienta, se volverá un aliado en la gestión
                  de proyectos que te permitirá estar tranquilo y con la
                  información siempre a la mano.{" "}
                  <b className="text-primary">
                    "El poder de lo relevante y lo simple, se fusionan!"
                  </b>
                </p>
              } */
              msg={
                <p className="text-left">{t("stepbystep_welcome_text1_1")}
                  <b className="text-primary">
                      {t("stepbystep_welcome_text1_2")}
                  </b>
                </p>
              }
            />
            <WelcomeStep
              img={img2}
              title={t("stepbystep_welcome_tittle3")}
              msg={
                <div className="text-left">
                  <ul>
                    <li>
                      {t("stepbystep_welcome_text2_line1")}
                    </li>
                    <li>
                      {t("stepbystep_welcome_text2_line2")}
                    </li>
                    <li>
                      {t("stepbystep_welcome_text2_line3")}
                    </li>
                    <li>
                      {t("stepbystep_welcome_text2_line4")}
                    </li>
                    <li>
                      {t("stepbystep_welcome_text2_line5")}
                    </li>
                    <li>{t("stepbystep_welcome_text2_line6")}</li>
                  </ul>
                </div>
              }
            />
            <WelcomeStep
              img={img1}
              title={t("stepbystep_welcome_tittle4")}
              msg={
                <div className="text-left">
                  <p>
                    {t("stepbystep_welcome_text3_line1_1")} <b>{t("stepbystep_welcome_text3_line1_2")}</b>
                   {t("stepbystep_welcome_text3_line1_3")}
                  </p>
                  <p>{t("stepbystep_welcome_text3_line2")}</p>
                  <ul>
                    <li>
                     {t("stepbystep_welcome_text3_line3")}
                    </li>
                    <li>
                     {t("stepbystep_welcome_text3_line4")}
                    </li>
                  </ul>
                </div>
              }
            />
            <WelcomeStep
              img={img1}
              title={t("stepbystep_welcome_tittle4")}
              msg={
                <div className="text-left">
                  <p>{t("stepbystep_welcome_text4_line1")}</p>
                  <ul>
                    <li>
                      {t("stepbystep_welcome_text4_line2")}
                    </li>
                    <li>
                      {t("stepbystep_welcome_text4_line3")}
                    </li>
                  </ul>
                </div>
              }
              close={handleClose}
            />
          </StepWizard>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomeModal;
