import { LOAD_BOARDS, ERROR_BOARDS } from "./boardsTypes";

const initial = {
  projects: [],
  loading: true,
  error: false,
};

const projectsReducer = (state = initial, action) => {
  switch (action.type) {
    case LOAD_BOARDS:
      return {
        projects: action.payload,
        loading: false,
        error: "",
      };
    case ERROR_BOARDS:
      return {
        projects: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default projectsReducer;
