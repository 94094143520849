import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Logo from "../assets/logo.png";
import BgPage from "../assets/pmo-office.jpg";
import { API_ROUTE, Login as LoginUser, headersRequest, Organizations } from "../helpers";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import spanish_flag from "../assets/flags/spain.png"
import usa_flag from "../assets/flags/united-states.png"
import en_flag from "../assets/flags/united-kingdom.png"


const Login = () => {
  const [t,i18n] =useTranslation("login")
  const users = useSelector((state) => state.users);
  let actlg = (window.localStorage.getItem("idiomaLogin"))? window.localStorage.getItem("idiomaLogin"):""
  const [checklg, setChecklg]=useState(actlg)
  const [watch ,setwatch] = useState("")
  const  [ddlOrganizations,setDdlOrganizations] = useState([])
  let history = useHistory();

  const credentials = useRef({
    email: null,
    pass: null,
    organization: ""
  });

  const saveDataLocal = (data) => {
    localStorage.setItem("tokenuserid", data.token);
    localStorage.setItem("cm9sX3VzdWFyaW8=", data.rol);
    localStorage.setItem("nombreUser", data.nombreUser);
    localStorage.setItem("nombreOrganizacion", data.nombreOrganizacion);
    localStorage.setItem("OID", data.OID);
    localStorage.setItem("UID", data.UID);
    localStorage.setItem("rol", data.rol);
    localStorage.setItem("idioma",data.idioma)
  };

  const redirAfterLogin = () => {
    let rol = localStorage.getItem("rol")
    let rol2 = localStorage.getItem("cm9sX3VzdWFyaW8=")
    if(rol == 81 ){
      history.push("/tasks")
    }else {
      history.push("/landing");
    }
  };

  const badLogin = () => {
    swal("¡Ups!", t("login_tittle0"), "error");
    localStorage.clear();
  };

  const successLogin = (data) => {
    saveDataLocal(data);
    redirAfterLogin();
    // hide welcome msg
  };

  const handlelglogin_es = (e)=> {
    setChecklg("es")
  }

  const handlelglogin_en = (e)=> {
    setChecklg("en")
  }

  useEffect(()=>{
    window.localStorage.setItem("idiomaLogin",checklg)
    i18n.changeLanguage(checklg)
  },[checklg])

  useEffect(()=>{
    Organizations(watch,setDdlOrganizations)
  },[watch])
 

  return (
    <Container
      fluid
      className="bg-dark"
      style={{
        backgroundImage: `url(${BgPage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Row>
          <Col
            md={{ span: 5, offset: 0 }}
            of
            className="py-5 px-3 bg-white shadow-lg d-flex flex-column justify-content-center"
            style={{ minHeight: "100vh" }}
          >
            <div className="d-flex align-items-end justify-content-start">
              <img src={Logo} alt="Logo" height="70" className="mr-2" />
              <p className="h6 border-left pl-3">
                Cloud <span className="text-primary">Projects</span>
              </p>
            </div>
            <Form >
              {['radio'].map((type) => (
                <div key={`reverse-${type}`} className="my-2 d-flex">
                  <div className="d-flex align-items-center mr-4 ">
                  <Form.Check
                  /*   reverse */
                    /* checked ={(checklg =="es")? true : false} */
                    checked = {(checklg == "es" || checklg =="")? true: false}
                    label={t("login_es")}
                    name="group1"
                    type={type}
                    id={`reverse-${type}-1`}
                    onChange={handlelglogin_es}
                  />
                  <img className="ml-2" src={spanish_flag} width={15} height={15} alt="flag"/>
                  </div>
                    
                  <div className="d-flex align-items-center ml-4">
                  <Form.Check
                    /* reverse */
                   /*  checked ={(checklg =="en")? true : false} */
                    checked = {(checklg == "en")? true: false}
                    label={t("login_en")}
                    name="group1"
                    type={type}
                    id={`reverse-${type}-2`}
                    onChange={handlelglogin_en}
                  />
                   <img className="ml-2" src={en_flag} width={15} height={15} alt="flag"/>
                  </div>
                  {/* <Form.Check
                    reverse
                    disabled
                    label="3 (disabled)"
                    type={type}
                    id={`reverse-${type}-3`}
                  /> */}
                </div>
              ))}
            </Form>
            <Tabs defaultActiveKey="login" className="mt-2">
              <Tab eventKey="login" title={t("login_tittle1")}>
                <h5 className="mt-3">{t("login_tittle1")}</h5>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    LoginUser(credentials.current, successLogin, badLogin);
                    /* LoginUser(successLogin, badLogin); */
                  }}
                >
                  <Form.Group controlId="email">
                    <Form.Label>{t("login_mail")}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("login_textbox_mail")}
                      required
                      onBlurCapture={(e)=>{
                        setwatch(e.target.value)
                      }}
                      onChange={(e) => {
                        /* setwatch(e.target.value) */
                        credentials.current.email = e.target.value;
                      }}
                    />
                  </Form.Group>
                  <Form.Label className="mt-1">{t("login_select_organization")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="organizations" //editar de acuerdo a la base
                    className="mb-2"
                    required
                    onChange={(e) => {
                     
                      credentials.current.organization = e.target.value
                      /* inputChange(e, setSaveBaselineCopyTo);  {/* <option value="">Seleccionar Línea Organizacion...</option> **/

                    }}
                  > 
                  {ddlOrganizations?.length > 0 ?<>
                        <option value="">{t("login_select_your_organization")}</option>
                        { ddlOrganizations?.map((item) => {
                        return (
                          <option key={item.IdOrganizacion} value={`${item.IdOrganizacion},${item.Organizacion}`}>
                            {item.Organizacion}
                          </option>
                        ); 
                      }) }
                      </>
                  :
                  <option value="">{t("login_select_your_organization")}</option>
                  }
                  </Form.Control>

                  <Form.Group controlId="pass">
                    <Form.Label>{t("login_tittle_pass")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("login_textbox_pass")}
                      onChange={(e) => {
                        credentials.current.pass = e.target.value;
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="accept">
                    {/* <Form.Check
                      type="checkbox"
                      label={
                        <span>
                          Al ingresar estás aceptando nuestros{" "}
                          <a
                            href="https://projects.co.pwc.com/fonada/_layouts/15/CustomLoginPageFBA/terminos-y-condiciones-cp.pdf"
                            target="_blank"
                          >
                            términos y condiciones
                          </a>
                        </span>
                      }
                      required
                    /> */}
                     <span >
                      {t("login_t&C")}{" "}
                        <a
                          href="https://cloudprojects.co.pwc.com/cloudprojectsdocs/files/terminos-y-condiciones-cp.pdf"
                          target="_blank"
                        >
                          {t("login_t&C_1")}
                        </a>
                     </span>
                    <br/>
                    <a
                    href="https://projects.co.pwc.com/democloudprojects/recuperarcontrase%C3%B1a.aspx"
                    target="blank">
                      {t("login_forgotpass")}
                    </a>
                    
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                  <Button variant="primary" type="submit">
                    {t("login_button")}
                  </Button>
                  <Button style={{backgroundColor:"#D04A02", borderColor:"#ffb600"}} target="_blank" href="https://projects.co.pwc.com/admin-cloud-projects-pro/Registrese.aspx">
                    {t("login_register")}
                  </Button>
                  </div>
                </Form>
              </Tab>
              {/* <Tab eventKey="register" title="Crear cuenta">
                <h5 className="mt-3">Crear cuenta</h5>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    LoginUser(credentials.current, successLogin, badLogin);
                  }}
                >
                  <Form.Group controlId="email">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Tu nombre"
                      required
                      onChange={(e) => {
                        credentials.current.email = e.target.value;
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Tu correo electrónico"
                      required
                      onChange={(e) => {
                        credentials.current.email = e.target.value;
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="pass">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Tu contraseña"
                      onChange={(e) => {
                        credentials.current.pass = e.target.value;
                      }}
                    />
                  </Form.Group>
                 
                  <Button variant="success" type="submit">
                    Crear cuenta
                  </Button>
                </Form>
              </Tab> */}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Login;
