import React from "react";
import emptyImg from "../assets/people-1.png";

const ErrorMsg = (props) => {
  return (
    <div className="text-center pt-4 mt-4">
      <img
        src={emptyImg}
        alt="Sin datos"
        className="img-fluid mb-3 animated pulse slower infinite"
        // width="30"
      />
      <h5 className="text-danger">{props.title}</h5>
      <p>{props.msg}</p>
      {/* <Button as={Link} to="/project">
        <i className="fa fa-plus-circle mr-2"></i>Crear proyecto
      </Button> */}
    </div>
  );
};

export default ErrorMsg;
