import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import FormProjectGeneral from "../components/FormProjectGeneral";
import UserStoriesDeliverables from "../components/UserStoriesDeliverables";

const EditTemplate = () => {
  const [mainData, setMainData] = useState({ new: true });
  const [ddlForm, setDdlForm] = useState([]);

  let { uid } = useParams();

  const getAllData = () => {
    Promise.all([getData(`projects/${uid}`, [])]).then((values) => {
      setMainData(values[0]);
    });
  };

  useEffect(() => {
    if (uid) {
      getAllData();
    }
    Promise.all([getData("edit-project-ddls", [])]).then((values) => {
      setDdlForm(values[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: uid ? "Editar Plantilla de Proyecto" : "Crear Plantilla de Proyecto",
            icon: uid ? "pen" : "plus",
          }}
          color={uid ? "secondary" : "info"}
          button={{
            text: "Volver",
            icon: "arrow-left",
            url: "/projects",
          }}
        />
        <Container fluid>
          <Tab.Container defaultActiveKey="first" transition={false}>
            <Row className="bg-light">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item>
                    <Nav.Link eventKey="first">Datos generales</Nav.Link>
                  </Nav.Item>
                  {!mainData.new && (
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        {mainData.Metodologia === "Tradicional"
                          ? "Entregables"
                          : "Historias de usuario"}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    className="animate__animated animate__fadeIn"
                  >
                    <FormProjectGeneral
                      mainData={mainData}
                      setMainData={setMainData}
                      ddlForm={ddlForm}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    className="animate__animated animate__fadeIn"
                  >
                    <UserStoriesDeliverables
                      uid={uid}
                      method={mainData.Metodologia /*Tradicional o Ágil*/}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default EditTemplate;
