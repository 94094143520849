import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { API_ROUTE, headersRequest ,getData } from "../../helpers";
import axios from "axios";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import {
  Button,
  Card,
  ProgressBar,
} from "react-bootstrap";
import SuperTable from "../../components/SuperTable"
import ModalEditTaslkTradicional from "../GanttSchedule/ModalEditTaslkTradicional";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ActivitiesByResourcesTraditional = () => {
  const [t,i18n] =useTranslation("schedule")
  const traditional = useSelector((state) => state.traditional);
  const [showModalEditTask, setShowModalEditTask] = useState(false);
  const [uidTask, setUidTask] = useState()
  const [dataTask, setDataTask] = useState([])
  const [resourceTask, setResourceTask] = useState([]);
  const [successByResource, setSuccessByResource] = useState([]);
  const [fechas, setFechas] = useState([]);
  const [showHours, setShowHours] = useState(false)
  let { uid } = useParams();

  const getDataSucces = () => {
    Promise.all([
      getData(`hv-project-success-by-resource/${uid}`, {}),
      getData(`excluded-dates`, {})
    ]).then((values) => {
        setSuccessByResource(values[0]);
        setFechas(values[1])
    });
  };

  useEffect(() => {
    if (uid) {
      getDataSucces();
    }
  }, []);

  const handleShowHours = () => {
   
    setShowHours(value => value = !value)
  }


  const columns = [
    {
      caption: t("schedule_modal_resources_tablecolumn_activity"),
      selector: "Actividad",
      customRender: (row) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
            
              if (row.data.IdActividad) {
              
                setUidTask(row.data.IdActividad)
                setShowModalEditTask(true)
                setDataTask(row.data)
              }

              /* const getResourcesBytaskwithId = () => {
                let getAssignmentsR = new Promise((resolve, rej) => {
                  axios
                    .get(
                      `${API_ROUTE}schedule-tasks-resources-assignments-byactivity/${row.data.IdActividad}`,
                      headersRequest()
                    )
                    .then((res) => {
                      
                      setResourceTask(res.data)
                      resolve(res.data);
                    });
                });
              } */

              const getResourcesBytaskwithId = async () => {
                try {
                  const res = await axios.get(`${API_ROUTE}schedule-tasks-resources-assignments-byactivity/${row.data.IdActividad}`,
                    headersRequest()
                  );
               
                  setResourceTask(res.data)
                }
                catch (error) {
                  console.error(error)
                }
              };
              getResourcesBytaskwithId()
            }}
          >
            {row.data.Destacada && (
              <small>
                <i class="fa fa-star text-warning mr-1"></i>
              </small>
            )}
            {row.data.Actividad}
          </a>
        );
      },
      minWidth: 210,
    },
    {
      caption: t("schedule_modal_resources_tablecolumn_start"),
      selector: "FechaInicio",
      width: 110,
    },
    {
      caption: t("schedule_modal_resources_tablecolumn_end"),
      selector: "FechaFin",
      width: 110,
    },
    {
      caption: t("schedule_modal_resources_tablecolumn_advanced"),
      selector: "pctcompletado",
      width: 110,
      customRender: (row) => {
        return <span>{row.data.pctcompletado}%</span>;
      },
    },
    {
      caption: t("schedule_modal_resources_tablecolumn_resource_last"),
      selector: "ultimamodificacion",
      width: 110,
    },
  ];
  const resourcesHeader = (data) => {
    let lg_user = window.localStorage.getItem("idioma")
    if(lg_user == "es"){
      return data.map((activity) => {
        return {
          ...activity,
          Recurso: `${activity.Recurso} (${t("schedule_modal_resources_tablecolumn_activities")}: ${activity.CantActividadesRecurso || "-"
            } | ${t("schedule_modal_resources_tablecolumn_start")}: ${activity.FechaInicioRecurso || "-"} | ${t("schedule_modal_resources_tablecolumn_end")}: ${activity.FechaFinRecurso || "-"
            } | % ${t("schedule_modal_resources_tablecolumn_advanced")}: ${activity.pctcompletadoRecurso} )`,
        };
      });
    }else {
      return data.map((activity) => {
        
        return {
          ...activity,
          Resource: `${activity.Recurso} (${t("schedule_modal_resources_tablecolumn_activities")}: ${activity.CantActividadesRecurso || "-"
            } | ${t("schedule_modal_resources_tablecolumn_start")}: ${activity.FechaInicioRecurso || "-"} | ${t("schedule_modal_resources_tablecolumn_end")}: ${activity.FechaFinRecurso || "-"
            } | % ${t("schedule_modal_resources_tablecolumn_advanced")}: ${activity.pctcompletadoRecurso} )`,
        };
      });
    }
  };
  const getDataResourcer = () => {
    const getDataOption = resourceTask.map((reso) => {
      return {
        value: reso.id,
        label: reso.Recurso
      }
    })
    return getDataOption
  };
  let options = getDataResourcer();

  return (
    <>
      
      <DataGrid
        dataSource={resourcesHeader(traditional.activitiesByResourceTraditional)}
        allowColumnReordering={true}
        showBorders={true}
      >
         
        <GroupPanel visible={true} />
        <SearchPanel visible={true} placeholder={t("schedule_modal_resources_searchbox")} />
        <Grouping autoExpandAll={false} />

        <Column dataField={t("schedule_modal_resources_compliance_tablecolumn_resource")} caption={t("schedule_modal_resources_compliance_tablecolumn_resource")} groupIndex={0} />
       
        {columns.map((itmCol, index) => {
          return (
            <Column
              key={index}
              dataField={itmCol.selector}
              caption={itmCol.caption}
              width={itmCol.width}
              minWidth={itmCol.minWidth}
              cellRender={(row) => {
                return (
                  <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
                );
              }}
            />
          );
        })}
        {(showModalEditTask) &&
          <ModalEditTaslkTradicional
            {...{ setShowModalEditTask, uidTask, dataTask, options }}
          />
        }
        <Paging defaultPageSize={50} />
       
      </DataGrid>
      <Button 
        variant="outline-primary"
        className="mt-3 mb-3"
        onClick={handleShowHours}
        >

            {/* <i className="fa fa-tachometer-alt mr-2"></i> */}
            <i className="fa fa-solid fa-clock mr-2"></i>
                 {t("schedule_modal_resources_button_compliance")}
      </Button>
      {(showHours) &&
      <Card className="mb-5">
            <Card.Body>
              <SuperTable
                columns={[
                  {
                    caption: t("schedule_modal_resources_tablecolumn_resource"),
                    selector: "Recurso",
                  },
                  {
                    caption: "Hrs. Plan.",
                    selector: "HorasxRecursoPlan",
                    customRender: (row) => (
                      <span>{Math.round(row.data.HorasxRecursoPlan)} Hrs.</span>
                    ),
                  },
                  {
                    caption: "Hrs. Comp.",
                    selector: "HorasxRecursoEjec",
                    customRender: (row) => (
                      <span>{Math.round(row.data.HorasxRecursoEjec)} Hrs.</span>
                    ),
                  },
                  {
                    caption: t("schedule_modal_resources_compliance_tablecolumn_compliance"),
                    customRender: (row) => (
                      <span className="d-flex align-items-center justify-content-end">
                        <ProgressBar
                          variant="success"
                          now={Math.round(
                            (row.data.HorasxRecursoEjec * 100) /
                              row.data.HorasxRecursoPlan
                          )}
                          style={{ height: "10px", width: "calc(100%)" }}
                          className="mr-2"
                        />
                        <b>
                          {Math.round(
                            (row.data.HorasxRecursoEjec * 100) /
                              row.data.HorasxRecursoPlan
                          )}
                          %
                        </b>
                      </span>
                    ),
                  },
                ]}
                data={successByResource}
                title_search = {t("schedule_modal_resources_searchbox")}
              />
            </Card.Body>
          </Card>
          }
    </>
  );
};

export default ActivitiesByResourcesTraditional;