import { useReducer } from "react";
import { useState } from "react";

const useTaskFiles = () => {
  let initialState = {
    files: [],
    loading: false,
    error: false
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "LOADING":
        return { ...initialState, loading: true };
      case "ERROR":
        return { ...initialState, error: true };
      case "SET_FILES":
        return { ...initialState, files: action.payload };
      case "RESET":
        return initialState;
      default:
        throw new Error("Without action");
    }
  };

  const [files, dispatchFiles] = useReducer(reducer, initialState);
  return [files, dispatchFiles];
};

export default useTaskFiles;
