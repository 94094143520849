import React, { useState, useEffect, useMemo } from "react";
import { Form, ListGroup, Button } from "react-bootstrap";

const SearchTasks = (props) => {
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchActive, setSearchActive] = useState(false);

  let suggestedOptions = useMemo(() => {
    return options.filter(
      (opt) => opt.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );
  }, [searchTerm]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleFocus = () => {
    setSearchActive(true);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setSearchActive(false);
    }, 200);
  };

  const handleClickTask = (id) => {
    document.getElementById("card-" + id).click();
  };

  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);

  const costumerWidth = (props.costW) ? props.costW : "300px" 
  return (
    <div className="d-flex ml-lg-4 position-relative align-items-center">
      {searchTerm ? (
        <Button
          className="mr-2 animate__animated animate__fadeIn animate__faster"
          variant="outline-primary"
          size="sm"
          onClick={() => setSearchTerm("")}
        >
          <i className="fa fa-times"></i>
        </Button>
      ) : (
        <span>
          <i className="fa fa-search mr-2 text-muted"></i>
        </span>
      )}
      <Form.Control
        size="sm"
        type="text"
        placeholder={props.title_search}
        style={{ minWidth: costumerWidth }}
        value={searchTerm || ""}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {searchTerm && searchActive ? (
        suggestedOptions.length ? (
          <ListGroup
            className="position-absolute shadow-lg animate__animated animate__fadeIn animate__faster"
            style={{
              maxWidth: 300,
              maxHeight: 400,
              top: 40,
              zIndex: 9999,
              overflow: "auto",
            }}
          >
            {suggestedOptions.map((option) => (
              <ListGroup.Item
                key={option.id}
                action
                onClick={() => handleClickTask(option.id)}
              >
                {option.title}
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <ListGroup
            className="position-absolute shadow-lg animate__animated animate__fadeIn animate__faster"
            style={{ maxWidth: 300, top: 40, zIndex: 9999 }}
          >
            <ListGroup.Item>
              Sin resultados<i className="fa fa-frown-open text-muted ml-2"></i>
            </ListGroup.Item>
          </ListGroup>
        )
      ) : null}
    </div>
  );
};

export default SearchTasks;
