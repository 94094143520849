export const FETCH_GENERAL_DATA = "FETCH_GENERAL_DATA";
export const FETCH_RESOURCES_DATA = "FETCH_RESOURCES_DATA";
export const FETCH_TAGS_DATA = "FETCH_TAGS_DATA";
export const FETCH_BACKLOG_COLUMNS = "FETCH_BACKLOG_COLUMNS";
export const FETCH_USER_STORIES = "FETCH_BACKLOG_COLUMNS";
export const FETCH_DDL_USER_STORIES = "FETCH_DDL_USER_STORIES";
export const FETCH_DDL_SPRINT_STATUS = "FETCH_DDL_SPRINT_STATUS";
export const FETCH_RESOURCES = "FETCH_RESOURCES";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_ACTIVITIES_DATA = "FETCH_ACTIVITIES_DATA";
export const FETCH_SPRINT_COLUMNS = "FETCH_SPRINT_COLUMNS";
export const FETCH_TEAM = "FETCH_TEAM";
export const LOADING_DATA = "LOADING_DATA";

export const UPDATE_BACKLOG = "UPDATE_BACKLOG";
