import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Dropdown, Form, Modal, Nav, Navbar, Row } from "react-bootstrap";
import { Link, NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { selectTooltip, selectTooltip_en } from "../utils/selectTooltip";
import swal from "sweetalert";
import Logo from "./assets/logo.png";
import { fetchUsers } from "../redux";
import { alreadyExists, desencry, getData, inputChange, saveData } from "../helpers";
import { useCallback } from "react";
/* import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs.js"; */
import { useIdleTimer } from "react-idle-timer/legacy";
import { useTranslation } from "react-i18next";
import spanish_flag from "../assets/flags/spain.png"
import usa_flag from "../assets/flags/united-states.png"
import en_flag from "../assets/flags/united-kingdom.png"

const timeout = 1200_000
const promptBeforeIdle = 301_000

const StyledButton = styled.div`
  text-decoration: none !important;
  color: ${(props) => props.theme.muted};
  transition: all ease 0.3s;
  &:hover,
  &:active,
  &.active {
    color: ${(props) => props.theme.primary};
  }
  span {
    font-size: 0.8rem;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const TopBar = (props) => {

  const [t,i18n] =useTranslation("global")
  const [userLg, setUserLg] = useState(window.localStorage.getItem("idioma"))
  const [showBtns,setShowBtns] = useState()
  
  const change = window.localStorage.getItem("rol")
  useEffect(()=>{
    setShowBtns(change)
  },[change])

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch()
  let history = useHistory();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [saveAllUsers, setSaveAllUsers]= useState([])
  const [passwordToMatch,setPasswordToMatch]=useState()
  const handleCloseShowEditProfile = () => { setShowEditProfile(false)}
  
  //state for idle
  const [state, setState] = useState('Active')
  const [remaining, setRemaining]= useState(timeout)
  const [open, setOpen] = useState(false)
  
  //funtion for idle
  const onIdle = ()=> {
    setState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    console.log("entra al onactive")
    setState('Active')
    setOpen(false)
  }
  
  const onPrompt = () =>{
    setState('Prompted')
    setOpen(true)
  }
  
  // extra items form useIdle
  
  const {getRemainingTime , activate} = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle : 500
  })
  
  useEffect(()=>{
    const interval = setInterval(()=>{
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)
    return ()=>{
      clearInterval(interval)
    }
  },[])
  
  const handleStillHere = () => {
    activate()
    
  } 

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'
  
  const convertir = () => {
    let minutes = Math.floor(((remaining - 1) / 60 ) % 60)
    /* minutes = (minutes < 10 )? '0' + minutes : minutes; */
    let sec = (remaining - 1) % 60;
    sec = (sec < 10)? '0'+ sec: sec;
    return `${minutes} :  ${sec}`
    
  }
  
let tiempoConver = convertir()
const autoLogout = (open) => {
  if(remaining <= 0 ){
   
    localStorage.clear();
    history.push("/");
    return
  }
  if(open){
    let textWarning = `${t("modal_zzz_1")} ${(remaining <=1)? "0:00": tiempoConver}`
    let btnText =( remaining <=1 ) ? t("modal_zzz_2") : t("modal_zzz_3")
    let titleText = ( remaining <=1) ? t("modal_zzz_4") : t("modal_zzz_5")
    
    swal({
      title: titleText,
      text: textWarning,
      icon: "warning",
      button : btnText,
    }).then((willDelete) => {
      console.log(willDelete)
      if (willDelete && remaining > 0) {
        setOpen(false)
        return activate()
      }else {
          return console.log("entra en return") /* setOpen(false) */
        }
      })
      
    }
} 
autoLogout(open)

const getAllResources = () => {
  Promise.all([
    getData(`users`,[])])
    .then((values) => {
      setSaveAllUsers(values[0])
      const userUID = localStorage.getItem("UID")
      const infoUser = saveAllUsers?.filter( user => user.IdUsuario ===  userUID)
      setUserProfile(infoUser[0])
      setPasswordToMatch(infoUser[0]?.Password)
      /* setShowEditProfile(true) */
      
     /*  setGetAllActivitiesByResources(values[0]) */
  });
};

const handleLoadInfoProfile =(e) => {
 /*    e.preventDefault()
    e.stopPropagation() */
    e.stopPropagation()
    e.preventDefault()
    getAllResources()
    
 /*  if(props.loadUser === false){
    console.log("entra al if")
    const userUID = localStorage.getItem("UID")
      const infoUser = props.users.filter( user => user.IdUsuario ===  userUID)
      setUserProfile(infoUser[0])
      
    }else {
      console.log("entra al else")
      dispatch(fetchUsers())
    } */
   /*  const userUID = localStorage.getItem("UID")
    const infoUser = saveAllUsers?.filter( user => user.IdUsuario ===  userUID)
    setUserProfile(infoUser[0])
    console.log(infoUser) */
   /*  setShowEditProfile(true) */
    
}
  const handleOpenShowEditProfile = (e) => { 
    /* e.stopPropagation()
    getAllResources() */
   /*  handleLoadInfoProfile(e) */
    setShowEditProfile(true) 
    }
  
  useEffect(()=>{
    /*  const lenguaje_user = window.localStorage.getItem("idioma") */
    i18n.changeLanguage(userLg)
   /*  console.log(lenguaje_user) */
  /*   let user_lenguaje = localStorage.getItem("ULG")
    i18n.changeLanguage(user_lenguaje) */
 /*    i18n.changeLanguage("en") */

  },[userLg])
  const idOrganization1ITS = '40200F8F-15A6-42C0-BE58-16EDBADA514C'
  const idOrganization2PAS = '8CAADED8-A836-4CB3-8822-878440CC4E30'
  const idOrganization = window.localStorage.getItem('OID')
  return ( 
    <>
    <Navbar bg="white" expand="lg" className="py-0 shadow-sm">
      <NavLink to="/landing" className="text-dark">
        <div className="d-flex justify-content-center align-items-center">
          <img src={Logo} alt="Logo" height={60} className="mr-3 my-1" />
          <span className="h6 border-left pl-3">
          Cloud <span className="text-primary">Projects</span>{" "}
          </span>
        </div>
        {/* <img src={Logo} alt="Logo" height={60} className="mr-3 my-1" />
        <span className="h6 border-left pl-3">
          Cloud <span className="text-primary">Projects</span>{" "}
          
          {<small>
            <b className="text-primary">Lite</b>
          </small>}
        </span> */}
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto mr-3 align-items-center">
        {(showBtns ==="Miembro de Equipo" || showBtns ==="81"
        ) ? "":
          <StyledButton
            as={NavLink}
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            to="/landing"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(39): selectTooltip_en(39)}
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-chart-line"></i>
              <span className="mt-1">Dashboard</span>
            </div>
          </StyledButton>
          }
          <StyledButton
            as={NavLink}
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            to="/projects"
            data-tour="step-1"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(5): selectTooltip_en(5)}
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-clipboard"></i>
              <span className="mt-1">{t("topBar_projects")}</span>
            </div>
          </StyledButton>
          { (idOrganization == idOrganization1ITS || idOrganization == idOrganization2PAS) &&
              <StyledButton
              as={NavLink}
              className="mx-3 py-2 d-flex w-100"
              activeClassName="active"
              to="/risks"
              data-tip={(t("global_lenguaje") == "es")? selectTooltip(45): selectTooltip_en(45)}
            >
              <div className="d-lg-flex flex-column align-items-center">
                <i className="mr-2 mr-md-0 fas fa-exclamation-triangle"></i>
                <span className="mt-1">Riesgos</span>
              </div>
            </StyledButton>
          }
          <StyledButton
            as={NavLink}
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            to="/boards"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(6): selectTooltip_en(6)}
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-border-all"></i>
              <span className="mt-1 text-nowrap">{t("topBar_boards")}</span>
            </div>
          </StyledButton>
          <StyledButton
            as={NavLink}
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            to="/tasks"
            data-tour="step-4"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(7): selectTooltip_en(7)}
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-tasks"></i>
              <span className="mt-1 text-nowrap">{t("topBar_myTask")}</span>
            </div>
          </StyledButton>
          {(
            showBtns ==="Miembro de Equipo" || showBtns ==="81" ||
            showBtns ==="Ejecutivo" || showBtns ==="108"
          ) ? "":
          <StyledButton
            as={NavLink}
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            to="/productivity"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(8) :selectTooltip_en(8)}
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-star"></i>
              <span className="mt-1">{t("topBar_productivity")}</span>
            </div>
          </StyledButton>
          } 
          {/* <StyledButton
            as={NavLink}
            className="mx-3 disabled"
            activeClassName="active"
            to="/audit"
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-search-plus"></i>
              <span className="mt-1">Auditoría</span>
            </div>
          </StyledButton> */}
          {(
            showBtns ==="Miembro de Equipo" || showBtns ==="81"||
            showBtns ==="Gerente de Proyecto" || showBtns ==="54" ||
            showBtns ==="Ejecutivo" || showBtns ==="108"
          ) ? "":
          <StyledButton
            as={NavLink}
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            to="/resources"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(9): selectTooltip_en(9)}
          >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-user"></i>
              <span className="mt-1">{t("topBar_users")}</span>
            </div>
          </StyledButton>
            }
          <StyledButton
            className="mx-3 py-2 d-flex w-100"
            activeClassName="active"
            data-tip={(t("global_lenguaje") == "es")? selectTooltip(41): selectTooltip_en(41)}
          >
            <a href="https://www.pwc.com/co/es/nuestros-servicios/consultoria/pas/home.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none text-reset"
              >
            <div className="d-lg-flex flex-column align-items-center">
              <i className="mr-2 mr-md-0 fa fa-handshake"></i> 
              <span className="mt-1 text-nowrap">{t("topBar_services")}</span>
            </div>
            </a>
          </StyledButton>
        </Nav>
        <div className="py-3 pl-md-3 d-flex justify-content-between justify-content-md-end">
          <div
            className="d-flex flex-column justify-content-center"
            style={{ margin: "-5px 0" }}
          >
            <b>{t("topBar_greeting")} {localStorage.getItem("nombreUser")}</b>
            <small
              style={{
                maxWidth: "140px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              title={localStorage.getItem("nombreOrganizacion")}
            >
              {localStorage.getItem("nombreOrganizacion")}
            </small>
          </div>
          <Dropdown
             className="mx-2"
             onClick={(e)=>handleLoadInfoProfile(e)}
             >
              <Dropdown.Toggle
                variant="outline-primary"
                size="sm"
              >
                {/* <i className="fa fa-ellipsis-v"></i> */}
              </Dropdown.Toggle>
              <Dropdown.Menu 
               className="dropdown-main-edit-user"
                /* as={Link} to={`/project/${row.data.IdProyecto}`} */>
                <Dropdown.Item
                 className="dropdown-item-main-edit-user"
                 onClick={handleOpenShowEditProfile}
                >
                  {t("topBar_setting")}
                </Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
         {/*  <Dropdown>
            <Dropdown.Toggle
              variant = "outline-info"
              size="sm"
              id="dropdown-basic"
              className="ml-1"
              title="helps"
            >
              <i className="fa fa-question-circle mr-1"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu 
              >
                  <Dropdown.Item
                    href="/helps"
                    target = "_blank"
                    className="bg-filterOnActive"
                    >
                    Ver Ayudas
                  </Dropdown.Item>    
            </Dropdown.Menu>
          </Dropdown> */}
          <div className="ml-3">
            <Button
              size="sm"
              variant="outline-danger"
              title={t("topBar_exit_aux")}
              onClick={() => {
                localStorage.clear();
                history.push("/");
              }}
            >
              <i className="fa fa-power-off mr-2"></i>{t("topBar_exit")}
            </Button>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
    <Modal
    show={showEditProfile}
    onHide={handleCloseShowEditProfile}
    size="xl"
    contentClassName="border-0 shadow-lg"
    >
      <Form
        onSubmit={(e) => {
             
          e.preventDefault();
          localStorage.setItem("idioma",userProfile.Idioma)
          setUserLg(userProfile.Idioma)
          userProfile.ToMatch = passwordToMatch
          const success = () => {
            handleCloseShowEditProfile();
            dispatch(fetchUsers());
          };
          saveData("users", userProfile, success);
        }}
      >
      <Modal.Header >
        <Modal.Title className="h5">
          {t("modal_welcome")} {userProfile?.PrimerNombre}
          {/* {userEdit.new ? "Crear" : "Editar"} Recurso */}
        </Modal.Title>
        <Button onClick={handleCloseShowEditProfile} variant="light">X</Button>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>
         {/*  <div >
          <span>Idioma</span>
          <div className="d-flex mb-2"> */}
         {/*  <div className="ml-3 ">
            <Button
              size="sm"
              variant="outline-danger"
              title="Cerrar sesión"
              onClick={() => {
               i18n.changeLanguage("es")
               
              }}
            >
              ES
            </Button>
          </div> */}
         {/*  <div className="ml-3">
            <Button
              size="sm"
              variant="outline-danger"
              title="Cerrar sesión"
              onClick={() => {
                i18n.changeLanguage("en")
              }}
            >
              EN
            </Button>
          </div> */}
         {/*  </div>
          </div> */}
        </Modal.Title>
        <Row>
          <Col md={3} className="mb-1">
            <Form.Label>
              {t("modal_lenguaje")} <img width={15} src={userProfile?.Idioma =="es" ? spanish_flag : en_flag} /> 
            </Form.Label>
            <Form.Control
              as="select"
              name="Idioma"
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.Idioma || ""}
           
            >
              <option value="">{t("modal_select")}...</option>
              <option value="es">{t("modal_lenguaje_es")}</option>
              <option value="en">{t("modal_lenguaje_en")}</option>
              
            </Form.Control>
          </Col>
        </Row>
        <hr/>
        <Form.Check
          type="switch"
          id="SwitchEstado"
          name="Estado"
          disabled
          label={
            userProfile?.Estado === "Activo"
              ? t("modal_title2")
              : t("modal_title3")
          }
          className="mb-3"
          checked={userProfile?.Estado === "Activo" ? true : false}
        />
        <Row>
          <Col md={4} className="mb-3">
            <Form.Label>
              Username<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              name="Username"
              placeholder="Ingrese nombre usuario"
              disabled
              value={userProfile?.Username || ""}
             /*  onChange={(e) => {
                inputChange(e, setUserEdit);
              }} */
              required
              /* disabled={userEdit.new ? false : true} */
            />
           {/*  {alreadyExists(users.users, {
              Username: userEdit.Username
            }) &&
              userEdit.new && (
                <Form.Text className="text-danger">
                  El nombre de usuario ya existe.
                </Form.Text>
              )} */}
          </Col>
          <Col md={4} className="mb-3">
            <Form.Label>
              {t("modal_email")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              name="Correo"
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.Correo || ""}
              type="email"
              required
            />
            {alreadyExists(users.users, { Correo: userProfile?.Correo }) &&
              userProfile.new && (
                <Form.Text className="text-danger">
                  {t("modal_validation_2")}
                </Form.Text>
             ) }
          </Col>
          <Col md={4} className="mb-3">
            <Form.Label>
              {t("modal_password")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              name="Password"
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.Password|| ""}
              type="password"
              required
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3} className="mb-3">
            <Form.Label>
              {t("modal_firtsName")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              name="PrimerNombre"
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.PrimerNombre || ""}
              required
            />
          </Col>
          <Col md={3} className="mb-3">
            <Form.Label>{t("modal_secondName")}</Form.Label>
            <Form.Control
              name="SegundoNombre"
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.SegundoNombre || ""}
            />
          </Col>
          <Col md={3} className="mb-3">
            <Form.Label>
              {t("modal_firstLastName")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              name="PrimerApellido"
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.PrimerApellido || ""}
              required
            />
          </Col>
          {( userLg == "es") &&
          <Col md={3} className="mb-3">
          <Form.Label>{t("modal_secondLastName")}</Form.Label>
          <Form.Control
            name="SegundoApellido"
            onChange={(e) => {
              inputChange(e, setUserProfile);
            }}
            value={userProfile?.SegundoApellido || ""}
          />
        </Col> } 
          
       </Row>
        <hr />
       <Row>
          <Col md={4} className="mb-3">
            <Form.Label>
              {t("modal_rol")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              as="select"
              name="IdRol"
              disabled
              /*  onChange={(e) => {
                inputChange(e, setUserEdit);
              }} */
              value={userProfile?.IdRol || ""}
              required
            >
              <option value="">Seleccionar...</option>
              <option value="1">Admin</option>
              <option value="2">Gerente de Proyecto</option>
              <option value="3">Miembro de Equipo</option>
              <option value="3">Ejecutivo</option>
            </Form.Control>
          </Col>
          <Col md={8} className="mb-3">
            <Form.Label>
              {t("modal_profession")}<small className="text-danger">*</small>
            </Form.Label>
            <Form.Control
              name="ProfesionCargo"
              
              onChange={(e) => {
                inputChange(e, setUserProfile);
              }}
              value={userProfile?.ProfesionCargo || ""}
              required
            />
          </Col>
        </Row>
     </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={handleCloseShowEditProfile}>
         <i className="fa fa-times mr-2"></i>{t("modal_cancel")}
        </Button>
        <Button
          variant="success"
          type="submit"
          disabled={
            (alreadyExists(users.users, {
              Username: userProfile?.Username
            }) &&
            userProfile?.new) ||
            (alreadyExists(users.users, { Correo: userProfile?.Correo }) &&
            userProfile?.new)
              ? true
              : false
          }
        >
          <i className="fa fa-save mr-2"></i>{t("modal_save")}
        </Button>
     </Modal.Footer>
</Form>
</Modal>
</>
  );
};

export default TopBar;
