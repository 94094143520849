import React from "react";
import styled from "styled-components";
import "./preloader.css";

const LoadingData = styled.div`
  /* background-color: rgba(255, 255, 255, .9); */
  /* background-color: #343a40; */
  background: linear-gradient(#ffffff,#e8e8e8);
  /* background-color: transparent; */
  /* backdrop-filter: blur(20px); */
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.pointer-events-none {
    pointer-events: none;
  }
`;

const Loader = () => {
  return (
    <LoadingData
      id="preloader"
      // className={showLoader ? "" : "animated fadeOut pointer-events-none"}
    >
      {/* <img
        src="/img/planning-1.png"
        className="animated pulse mb-3"
        width="300"
        alt="Cargando..."
      />
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Cargando...</span>
      </div> */}
      <div
        // className={showLoader ? "animated bounceIn" : "animated bounceOut faster"}
        className="aniamte__animated animate__bounceIn"
      >
        <div className="boxes">
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </LoadingData>
  );
};

export default Loader
