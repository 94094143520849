import {
  LOADING_DATA,
  FETCH_GENERAL_DATA,
  FETCH_RESOURCES_DATA,
  FETCH_TAGS_DATA,
  FETCH_TAGS,
  FETCH_USER_STORIES,
  FETCH_RESOURCES,
  FETCH_BACKLOG_COLUMNS,
  FETCH_ACTIVITIES_DATA,
  FETCH_SPRINT_COLUMNS,
  FETCH_TEAM,
  FETCH_DDL_USER_STORIES,
  FETCH_DDL_SPRINT_STATUS,
  UPDATE_BACKLOG,
} from "./scrumTypes";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";

const loadingData = () => {
  return {
    type: LOADING_DATA,
  };
};

const fetchScrumRequest = (projectData) => {
  return {
    type: FETCH_GENERAL_DATA,
    payload: projectData,
  };
};
const fetchScrumResources = (resources) => {
  return {
    type: FETCH_RESOURCES_DATA,
    payload: resources,
  };
};
const fetchScrumTags = (tags) => {
  return {
    type: FETCH_TAGS_DATA,
    payload: tags,
  };
};

const fetchColumnsBacklogSprints = (columnsBacklogSprints) => {
  return {
    type: UPDATE_BACKLOG,
    payload: columnsBacklogSprints,
  };
};

const fetchUserStories = (stories) => {
  return {
    type: FETCH_USER_STORIES,
    payload: stories,
  };
};

const fetchDdlUserStories = (stories) => {
  return {
    type: FETCH_DDL_USER_STORIES,
    payload: stories,
  };
};

const fetchDdlSprintStatus = (status) => {
  return {
    type: FETCH_DDL_SPRINT_STATUS,
    payload: status,
  };
};

const fetchResources = (resources) => {
  return {
    type: FETCH_RESOURCES,
    payload: resources,
  };
};

const fetchTags = (tags) => {
  return {
    type: FETCH_TAGS,
    payload: tags,
  };
};

export const updateBacklog = (lanes) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BACKLOG,
      payload: lanes,
    });
  };
};

export const fetchProjectGeneral = (uid) => {
  return (dispatch) => {
    axios.get(`${API_ROUTE}/projects/${uid}`, headersRequest()).then((res) => {
      dispatch(fetchScrumRequest(res.data));
    });
    axios
      .get(`${API_ROUTE}/company-resources`, headersRequest())
      .then((res) => {
        dispatch(fetchScrumResources(res.data));
      });
      axios
      .get(`${API_ROUTE}/tags/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchScrumTags(res.data));
      });
  };
};

export const fetchSprintGeneral = (uid,uidAux) => {
  return (dispatch) => {
    axios.get(`${API_ROUTE}/sprints/${uid}`, headersRequest()).then((res) => {
      dispatch(fetchScrumRequest(res.data));
    });
    axios
      .get(`${API_ROUTE}/company-resources`, headersRequest())
      .then((res) => {
        dispatch(fetchScrumResources(res.data));
      });

      if(uidAux){
        axios
      .get(`${API_ROUTE}/tags/${uidAux}`, headersRequest())
      .then((res) => {
        dispatch(fetchScrumTags(res.data));
      });

      }
      
  };
};

export const fetchProjectColumnsBacklogSprints = (uid) => {
  // this fetch brings all the data of the product backlog
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/product-backlog/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchColumnsBacklogSprints(res.data));
      });
  };
};

export const fetchProjectUserStories = (uid) => {
  // this fetch brings all the data of the product backlog
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/product-backlog-stories/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchUserStories(res.data));
      });
  };
};

export const fetchProjectDdlUserStories = (uid) => {
  // this fetch brings all the data of the product backlog
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/product-backlog-ddl-user-stories/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchDdlUserStories(res.data));
      });
  };
};

export const fetchProjectDdlSprintStatus = () => {
  // this fetch brings all the data of the product backlog
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/ddl-sprint-status`, headersRequest())
      .then((res) => {
        dispatch(fetchDdlSprintStatus(res.data));
      });
  };
};

export const fetchProjectResources = (uid) => {
  // this fetch brings all the data of the product backlog
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/product-backlog-resources/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchResources(res.data));
      });
  };
};

export const fetchProjectTags = (uid) => {
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/product-backlog-tags/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchTags(res.data));
      });
  };
};

export const fetchProjectColumnsKanbanSprints = (uid) => {
  // this fetch brings all the data of the product backlog
  return (dispatch) => {
    dispatch(loadingData());
    axios
      .get(`${API_ROUTE}/sprint-backlog/${uid}`, headersRequest())
      .then((res) => {
        dispatch(fetchColumnsBacklogSprints(res.data));
      });
  };
};

export const createBacklogLane = (uid) => {
  return (dispatch) => {
    alert("POST new lane Backlog for" + uid);
    dispatch(
      updateBacklog({
        lanes: [
          {
            id: Date.now(),
            title: "Product Backlog",
            cards: [],
          },
        ],
      })
    );
  };
};
