import axios from "axios";
import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from "./usersTypes";

import { API_ROUTE, headersRequest } from "../../helpers";

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS,
  };
};

export const fetchUsersRequestSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchUsersRequestFailure = (errorMsg) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: errorMsg,
  };
};

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(fetchUsersRequest());
    axios.get(`${API_ROUTE}users`, headersRequest()).then((response) => {
      dispatch(fetchUsersRequestSuccess(response.data));
    });
  };
};
