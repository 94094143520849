import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import imgEmpty from "../assets/iso.png";
import { API_ROUTE, headersRequest } from "../helpers";
import axios from "axios";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

const UserStoriesDeliverables = (props) => {
  const [stories, setStories] = useState([]);
  const [t,i18n] = useTranslation("create")

  const getStoriesData = () => {
    axios
      .get(`${API_ROUTE}deliverables/${props.uid}`, headersRequest())
      .then((res) => {
        console.log(res.data);
        setStories(res.data);
        console.log(props.method);
      })
      .catch((err) =>
        swal("¡Ups!", "Hubo un error al cargar los elementos", "error")
      );
  };

  const addStory = () => {
    axios
      .post(
        `${API_ROUTE}deliverables`,
        {
          CODProyecto: props.uid,
        },
        headersRequest()
      )
      .then((res) => {
        setStories((prev) => {
          return [...prev, { id: res.data.Id }];
        });
        window.scrollTo(0, window.pageYOffset + 500);
      })
      .catch((err) => {
        swal("¡Ups!", "Hubo un error al crear el elemento", "error");
      });
    // let dummiePromise = new Promise((resolve, reject) => {
    //   setStories((prev) => {
    //     return [...prev, newStory()];
    //   });
    //   resolve(true);
    // });
    // dummiePromise.then(() => {
    //   let storiesInput = document.querySelectorAll(
    //     "#stories input[name='title']"
    //   );
    //   storiesInput[storiesInput.length - 1].focus();
    //   window.scrollTo(0, window.pageYOffset + 500);
    // });
  };

  const delStory = (storyUID) => {
    axios
      .delete(`${API_ROUTE}deliverables/${storyUID}`, headersRequest())
      .then((res) => {
        setStories((prev) => {
          return prev.filter((item) => item.id !== storyUID);
        });
      })
      .catch((err) => {
        swal("¡Ups!", "Hubo un error al eliminar el elemento", "error");
      });
  };

  const saveStories = () => {
    stories.forEach((story) => {
      if (story.modified) {
        axios
          .put(`${API_ROUTE}deliverables/${story.id}`, story, headersRequest())
          .then(() => {
            swal(t("project_modal_done_1"),t("project_modal_done_3"), "success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((err) =>
            swal("¡Ups!", "Hubo un error al actualizar el elemento", "error")
          );
      }
    });
  };

  useEffect(() => {
    getStoriesData();
  }, []);

  return (
    <div>
      {stories.length ? (
        <div className="d-flex flex-column" id="stories">
          {stories.map((story, index) => {
            return (
              <Card
                key={story.id}
                className={`mb-3 animate__animated animate__fadeInUp ${
                  stories.length === index + 1 && "shadow"
                }`}
              >
                {/* {JSON.stringify(story)} */}
                <Card.Body className="p-3">
                  <div className="d-flex justify-content-between w-100">
                    <h6 className="text-primary">
                      {index + 1}.{" "}
                      {props.method === "Tradicional"
                        ? t("createhistory_form_text1_2")
                        : t("createhistory_form_text1_1")}{" "}
                      |{" "}
                      <span className="text-dark">{story.title || "..."}</span>
                    </h6>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        delStory(story.id);
                      }}
                    >
                      <i className="fa fa-trash mr-2"></i>{t("createhistory_form_delete")}
                    </Button>
                  </div>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      addStory();
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <Form.Group className="m-0">
                          <Form.Label>{t("createhistory_form_text2")}</Form.Label>
                          <Form.Control
                            name="title"
                            minLength={2}
                            placeholder={`${t("createhistory_form_textbox1_1")} ${
                              props.method === "Tradicional"
                                ? t("createhistory_form_textbox1_3")
                                : t("createhistory_form_textbox1_2")
                            }`}
                            value={story.title || ''}
                            onChange={(e) => {
                                setStories((prev) => {
                                  let dummie = [...prev];
                                  dummie[index].title = e.target.value;

                                  dummie[index].modified = true;
                                  return dummie;
                                });
                            }}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col md={8}>
                        <Form.Group className="m-0">
                          <Form.Label>{t("createhistory_form_text3")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={story.description}
                            onChange={(e) => {
                              setStories((prev) => {
                                let dummie = [...prev];
                                dummie[index].description = e.target.value;
                                dummie[index].modified = true;
                                return dummie;
                              });
                            }}
                            rows={2}
                            placeholder={`${t("createhistory_form_textbox2_1")} ${
                              props.method === "Tradicional"
                                ? t("createhistory_form_textbox2_3")
                                : t("createhistory_form_textbox2_2")
                            }`}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {props.method !== "Tradicional" && (
                      <>
                        <Row
                         
                        >
                          <Col md={4}>
                            <Form.Group className="m-0">
                              <Form.Label>{t("createhistory_form_text4")}</Form.Label>
                              <Form.Control
                                name="title"
                                
                                placeholder={t("createhistory_form_textbox3")}
                                value={story.criteria}
                                onChange={(e) => {
                                  setStories((prev) => {
                                    let dummie = [...prev];
                                    dummie[index].criteria = e.target.value;
                                    dummie[index].modified = true;
                                    return dummie;
                                  });
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col md={8}>
                            <Form.Group className="m-0">
                              <Form.Label>
                                {t("createhistory_form_text5")}
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                value={story.caracteristics}
                                onChange={(e) => {
                                  setStories((prev) => {
                                    let dummie = [...prev];
                                    dummie[index].caracteristics =
                                      e.target.value;
                                    dummie[index].modified = true;
                                    return dummie;
                                  });
                                }}
                                rows={1}
                                placeholder={t("createhistory_form_textbox4")}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Form.Group className="m-0">
                              <Form.Label>{t("createhistory_form_text6")}</Form.Label>
                              <Form.Control
                                name="title"
                                placeholder={t("createhistory_form_textbox5")}
                                value={story.result}
                                onChange={(e) => {
                                  setStories((prev) => {
                                    let dummie = [...prev];
                                    dummie[index].result = e.target.value;
                                    dummie[index].modified = true;
                                    return dummie;
                                  });
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="m-0">
                              <Form.Label>{t("createhistory_form_text7")}</Form.Label>
                              <Form.Control
                                as="select"
                                value={story.priority}
                                onChange={(e) => {
                                  setStories((prev) => {
                                    let dummie = [...prev];
                                    dummie[index].priority = e.target.value;
                                    dummie[index].modified = true;
                                    return dummie;
                                  });
                                }}
                                rows={1}
                                placeholder="Prioridad"
                              >
                                <option value="">{t("createhistory_form_text7_1")}...</option>
                                <option value="Alta">{t("createhistory_form_text7_2")}</option>
                                <option value="Media">{t("createhistory_form_text7_3")}</option>
                                <option value="Baja">{t("createhistory_form_text7_4")}</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="m-0">
                              <Form.Label>{t("createhistory_form_text8")}</Form.Label>
                              <Form.Control
                                name="title"
                                type="number"
                                max={100}
                                step={0.01}
                                min={0}
                                placeholder={t("createhistory_form_text8")}
                                value={story.points}
                                onChange={(e) => {
                                  setStories((prev) => {
                                    let dummie = [...prev];
                                    dummie[index].points = e.target.value;
                                    dummie[index].modified = true;
                                    return dummie;
                                  });
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}

                    {stories.length === index + 1 && (
                      <Button
                        type="submit"
                        size="sm"
                        className="float-right mt-3"
                      >
                        <i className="fa fa-plus mr-2"></i>
                        {props.method === "Tradicional"
                          ? t("createhistory_form_newhistory_2")
                          : t("createhistory_form_newhistory_1")}
                      </Button>
                    )}
                  </Form>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      ) : (
        <div className="text-center py-4">
          <img src={imgEmpty} alt="No hay historias" width="230" />
          <h5>
            {t("createhistory_empty_text1_1")}
            {props.method === "Tradicional"
              ? t("createhistory_empty_text1_3")
              : t("createhistory_empty_text1_2")}
          </h5>
          <p>
            {t("createhistory_empty_text2_1")}
            {props.method === "Tradicional"
              ? t("createhistory_empty_text2_3")
              : t("createhistory_empty_text2_2")}
          </p>
          <Button onClick={() => addStory()}>
            <i className="fa fa-plus mr-2"></i>
            {props.method === "Tradicional"
              ? t("createhistory_form_newhistory_2")
              : t("createhistory_form_newhistory_1")}
          </Button>
        </div>
      )}
      {stories.length ? (
        <React.Fragment>
          {stories.filter((story) => !story.title).length > 0 ? (
            <Alert
              variant="warning"
              className="position-fixed m-0"
              style={{ bottom: "1rem", right: "1rem" }}
            >
              {props.method === "Tradicional"
                ? t("createhistory_deliverables")
                : t("createhistory_stories")}
              {t("createhistory_shouldhave")}
            </Alert>
          ) : (
            <Button
              onClick={() => {
                // console.log("save stories");
                saveStories();
              }}
              variant="success"
              className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-1s"
              style={{ bottom: "1rem", right: "1rem" }}
            >
              <i className="fa fa-save mr-2"></i>{t("project_button_save")}
            </Button>
          )}
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserStoriesDeliverables;
