import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";


const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grip-template-rows: auto;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  grid-gap: 1rem;
  .cursor-pointer {
    cursor: pointer;
  }
  .card {
    grid-column: 1 / 2;
    grid-row:1;
    height: 65px;
    margin-bottom : 20px;
    transition: all ease 0.3s;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    }
    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const TagsModel = (props) => {
  const [t,i18n] =useTranslation("tags")
  return ( <>
    <StyledGrid>
      {(props.addCard) &&
      <Card
        className={`border-0 rounded bg-light text-center cursor-pointer`}
        onClick={() => {props.handleShow();}}
      >
        <Card.Body 
            className="p-1 pb-2 d-block" 
            >
          <i
            className=" fa fa-plus-circle"></i>
          <h6 className="mb-0">{t("tags_button_newtag")}</h6>
        </Card.Body>
      </Card>
      }
      </StyledGrid>
      <div className="tag-box mb-2">
        {props.data.map((element) => {
          return (
            
              <div className="tag-box-grid mb-3">
                <div className="d-flex justify-content-start tag-body">
                  <div style={{ background: element.Color || "#cd5a91" }} className="tag-text d-flex align-items-center ">
                    <h6 className="mb-0 ml-4 text-white text-left">{element.NombreEtiqueta}</h6>
                  </div>
                  <div className="tag-triangel mr-1"  style={{ borderLeftColor: element.Color || "#cd5a91" }} >
                  </div>
                </div>
                <div>
                  <i
                    className="fa fa-edit cursor-pointer mr-2 text-info tag-edit"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        props.selectTagToEdit(element); 
                      }}
                  ></i>
                </div>
                <div>
                    <i
                      className="fa fa-trash cursor-pointer text-primary tag-delete"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        props.deleteTag(element);
                      }}
                  ></i>
                </div>
              </div>
          );
        })}
       </div>
    </>
  );
};

export default TagsModel;