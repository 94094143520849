import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists, desencry, API_ROUTE, headersRequest, checkDirectoryName } from "../helpers";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import styled from "styled-components";
import NoAccess from "./NoAccess";
import FileManager, { ContextMenu, FileSelectionItem, Item, ItemView, Toolbar, Notifications }  from "devextreme-react/file-manager";
import { Permissions } from "devextreme-react/file-manager";
import folder from '../assets/iconFolders/folder.svg'
import doc from '../assets/iconFolders/doc.svg'
import excel from '../assets/iconFolders/excel.svg'
import img from '../assets/iconFolders/img.svg'
import pdf from '../assets/iconFolders/pdf.svg'
import pwp from '../assets/iconFolders/pwp.svg'
import txt from '../assets/iconFolders/txt.svg'
import axios from "axios";
import { CommonSeriesSettingsHoverStyle } from "devextreme-react/chart";
import { useTranslation } from "react-i18next";

const StyledIndicator = styled.div`
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
`;

const TraditionalFiles = () => {
  const [t,i18n] =useTranslation("schedule")
  const access = window.localStorage.getItem("accessToHvProject")
  const desData = desencry(access,'accessCloudProjectDevHvProjecte')

  const [mainData, setMainData] = useState({ new: true });
  const [activitiesList, setActivitiesList] = useState([]);
  const [successByResource, setSuccessByResource] = useState([]);
  const [getDirectory , setGetDirectory] = useState([])
  const [showFiles, setShowFiles]= useState(false)
  let { uid } = useParams();
  const userUID = localStorage.getItem("UID")
  const getAllData = () => {
    Promise.all([
      getData(`projects/${uid}`, []),
      getData(`hv-project-activities/${uid}`, []),
      getData(`hv-project-header/${uid}`, {}),
      getData(`hv-project-success-by-resource/${uid}`, {}),
    ]).then((values) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setMainData({ ...values[0], ...values[2] });
        setActivitiesList(values[1]);
        setSuccessByResource(values[3]);
      });
    });
  };

  const getAllDirectory =() => {
    Promise.all([
      getData(`traditional-directory/${uid}`, {})
    ]).then((values) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setGetDirectory(values[0].files)
        setShowFiles(true)
      });
    });
  }
 
  useEffect(() => {
    if (uid) {
      getAllData();
      getAllDirectory();
    }
  }, []);

  const customizeIcon= (fs) => {
    if(fs.isDirectory){
      return folder
    }
    const fExtension = fs.getFileExtension()
    switch (fExtension) {
      case '.txt':
        return txt
      case '.doc':
        return doc
      case '.docx':
        return doc
      case '.pdf':
        return pdf
      case '.png':
        return img
      case '.jpeg':
        return img
      case '.xls':
        return excel
      case '.xlsx':
        return excel
      case '.pptx':
        return pwp
    }
  }
   // "image/png, image/jpeg, .pdf, .doc, .docx, .xls"

  const onDirectoryCreating = (onDiCre) => {
    let checkName = onDiCre.name
    if(onDiCre.parentDirectory.pathKeys.length > 5 ){
      return swal("Directorio no creado", "No es posible crear más sub-directorios", "error").then(()=>getAllDirectory())
    }
    let nameDirectoryExist = checkDirectoryName(getDirectory,onDiCre) 
    if(nameDirectoryExist.length > 0 ){
      return swal("Directorio no creado", ` Este destino ya tiene un directorio con el nombre "${checkName}"`, "error").then(()=>getAllDirectory())
    }
    const metadata = onDiCre
    let directory
    if(metadata.parentDirectory.dataItem){
      const getParentID = (!metadata.parentDirectory.dataItem?.id)? null : metadata.parentDirectory.dataItem?.id
      const getPath = (!metadata.parentDirectory?.path)? null : metadata.parentDirectory?.path
      directory = {
          name : metadata.name,
          parentID : getParentID,
          userID: userUID,
          path : `${getPath}/${metadata.name}`
      }
    }else {
      directory = {
        name : metadata.name,
        parentID : null,
        userID: userUID,
        path : metadata.name
    }
  }
    axios
    .post(`${API_ROUTE}traditional-directory/${uid}`,directory, headersRequest() )
    .then(() => {
      getAllDirectory()
    })
    .catch((err) => {
    alert("error al crear el directorio, por favor refresca la página");
    });
  }
  const onFileUpload = (fu) =>{
    
    const pathFile = (fu.destinationDirectory?.path)? fu.destinationDirectory?.path : "$root$"
    const getParentId = (fu.destinationDirectory?.dataItem) ? fu.destinationDirectory?.dataItem.id : null
    const valuePath ={
      path : pathFile,
      parentId : getParentId 
    }
    const allMetadata = {...fu,...valuePath}
    axios
    .put(`${API_ROUTE}traditional-project-attachments/${uid}`,
    allMetadata,
    { headers: {
      "Content-Type": "multipart/form-data",
      tokenuserid: localStorage.getItem("tokenuserid")
    }}
    )
    .then((res)=> {
      getAllDirectory()
    })
    .catch((err) => {
      alert("error al cargar el archivo, por favor refresca la página");
    });

  }
  const onItemDeleting = (onItDe) => {
  
    /* 
     return axios.delete(
    API_ROUTE + "agile-task-attachments/" + dataForDelete.filurl,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        tokenuserid: localStorage.getItem("tokenuserid"),
        idFileToDelete : dataForDelete.filId
      }
    }
  );*/
    
    let titleWarning = (onItDe.item.isDirectory)? "¿Eliminar Directorio?" : "¿Eliminar Archivo?" ;
    let textWarning =(onItDe.item.isDirectory)? "Al eliminar el directorio, se eliminaran los Sub-directorios y archivos contenidos" : "Se eliminará el archivo de manera permanente" ;
    let typeFile =(onItDe.item.isDirectory)? "Directorio" :"Archivo";
    let dataPath = onItDe.item.relativeName
    let isDirectory = onItDe.item.isDirectory ? "true":"false"
    
    let infoToDelete  = {
      dataPath : onItDe.item.relativeName,
      isDirectory : onItDe.item.isDirectory
    }
    const deleteItem = {
      directoryId : onItDe.item.dataItem.id,
    }
  
    axios
    .delete(`${API_ROUTE}tradicional-directorio/${uid}/${deleteItem.directoryId}`, 
      {
        data:"source",
        headers: {
        "Content-Type": "multipart/form-data",
        tokenuserid: localStorage.getItem("tokenuserid"),
        user_lg : localStorage.getItem("idioma"),
        pathFileToDelete: dataPath,
        directory: isDirectory,
      },
      },
     )
    .catch((error)=>{
      console.error(error)
    })
    .then((res)=> {
      swal("¡Hecho!", `${typeFile} eliminado satisfactoriamente` , "success").then(() =>
      getAllDirectory()
      
    );
    }
    )
    /* swal({
      title: titleWarning,
      text: textWarning,
      icon: "warning",
      buttons: true,
      buttons: ["Cancelar", "Sí, eliminar"],
      dangerMode: true 
    }).then((willDelete) => {
      if (willDelete) {
        const deleteItem = {
          directoryId : onItDe.item.dataItem.id,
        }
        axios
        .delete(`${API_ROUTE}tradicional-directorio/${uid}/${deleteItem.directoryId}`, headersRequest())
        .catch((error)=>{
          console.error(error)
        })
        .then((res)=> {
          swal("¡Hecho!", `${typeFile} eliminado satisfactoriamente` , "success").then(() =>
          getAllDirectory()
          
        );
        }
        )
      }else{
        window.location.reload()
      }
    }); */
   
  };

  const onItemRenamed = (onItRe) => {
    
    const renameItem = {
      id : onItRe.sourceItem.dataItem?.id,
      newName:onItRe.sourceItem.dataItem?.name,
      parentID: onItRe.sourceItem.dataItem?.parentId, 
      isDirectory : onItRe.sourceItem.dataItem.isDirectory,
      userID: userUID,
      path : onItRe.sourceItem.path,
      newPath : `${onItRe.sourceItem.parentPath}/${onItRe.sourceItem.dataItem?.name}`
    }
  
    axios.put(`${API_ROUTE}traditional-directory/${uid}`,   
    renameItem,
      headersRequest()
    )
    .then(()=>{
      getAllDirectory()
    })
    .catch((err) => {
      alert("error al actualizar el directorio, por favor refresca la página");
    });

  }
  const onItemCopied =(onItCo)=> {
    console.log(onItCo)
  }
  const onItemMoved = (onItMov)=> {
    
    let newParentId = (onItMov.parentDirectory.dataItem)? onItMov.parentDirectory.dataItem.id : null;
    const moveItem = {
      name : onItMov.itemName,
      id : onItMov.sourceItem.dataItem?.id,
      parentID: onItMov.sourceItem.dataItem?.parentId,
      isDirectory : onItMov.sourceItem.dataItem.isDirectory,
      userID: userUID,
      path : onItMov.sourceItem.path,
      newPath : onItMov.itemPath,
      newParentid : newParentId
    }
    axios.put(`${API_ROUTE}traditional-directory-moveitem/${uid}`,   
    moveItem,
      headersRequest()
    )
    .then(()=>{
      getAllDirectory()
    })
    .catch((err) => {
      alert("error al mover el directorio, por favor refresca la página");
    });
  }
  const onItemDownloading = (onItDo)=> {
  onItDo.cancel= true
  const filePath = onItDo.item.relativeName

  axios.get(`${API_ROUTE}traditional-project-attachments-download/${uid}`
    , {
      responseType: "blob",
      timeout: 30000 ,
      headers: {
        tokenuserid: localStorage.getItem("tokenuserid"),
        path : filePath
      }
    })
    .then((res) => {
      const fileName = res.config.headers.path
      var file = window.URL.createObjectURL(res.data);
      const ruta = document.createElement('a')
      ruta.href = file
      ruta.download = fileName
      ruta.innerText = 'd'
      document.body.appendChild(ruta)
      ruta.click()
      document.body.removeChild(ruta)
      /* window.open(file);  */
     /* .put(`${API_ROUTE}traditional-project-attachments/${uid}`,
    allMetadata, 
    { headers: {
      "Content-Type": "multipart/form-data",
      tokenuserid: localStorage.getItem("tokenuserid")
    }}
    )
      
      */
    })
    .catch((err) => {
      alert("error al descargar el archivo, por favor refresca la página");
    });;
  }
  return (<>
    { (desData ==="true" ) ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("schedule_button_documentation"),
            icon: "folder",
          }}
          color="secondary"
          goBack={{
            text: t("schedule_back"),
            icon: "arrow-left",
          }}
        />
        <Container fluid className="bg-light">
          <Row>
            <Col className="py-3">
              <h5 className="mb-0">
                <span className="text-primary">{t("schedule_nameproject")}: </span>
                <span>{mainData.Nombre}</span>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-between mb-2">
                <small>
                  <i className="fa fa-play mr-2 text-secondary"></i>{t("schedule_modal_resources_tablecolumn_start")}:{" "}
                  {mainData.FechaInicioProg || "-"}
                </small>
                <small>
                  <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
                  {t("schedule_modal_resources_tablecolumn_end")}: {mainData.FechaFinProg || "-"}
                </small>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="pt-2">
          <Card className="bg-white border-0 position-relative mb-3 shadow-sm">
            <Card.Body className="px-2 pt-2 pb-0">
              <Row>
                <Col md={6} lg={2} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        {mainData.CODProyecto || "-"}
                      </span>
                      <small className="text-primary">{t("schedule_files_code")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        {mainData.Propietario || "-"}
                      </span>
                      <small className="text-primary">{t("schedule_files_owner")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={2} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        {mainData.FechaModificado || "-"}
                      </span>
                      <small className="text-primary">{t("schedule_modified")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.Gerente || "-"}</span>
                      <small className="text-primary">{t("schedule_manager")}</small>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col className="mb-3" md={12} lg={6}>
            </Col>
            <Col md={6} lg={3} className="mb-3"></Col>
            <Col md={6} lg={3} className="mb-3"></Col>
          </Row>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="h6 text-muted">
                {t("schedule_files_documents")}
              </Card.Title>
              {(showFiles === true)?
              <FileManager
                fileSystemProvider = {getDirectory}
                height={450}
                customizeThumbnail={customizeIcon}
                onDirectoryCreating = {onDirectoryCreating}
                onItemDeleting = {onItemDeleting}
                onItemRenamed = {onItemRenamed}
                onFileUploading ={onFileUpload}
                onItemCopying = {onItemCopied}
                onItemMoved = {onItemMoved}
                onItemDownloading ={onItemDownloading}
                >
                <Notifications showPopup={false} showPanel={false} ></Notifications>
                  <ItemView 
                  mode="thumbnails"
                  showFolders={true}
                  showParentFolder={false}
                  />
                <Toolbar>
                  <Item name="create" text="New Directory" icon="newfolder"  />
                  <Item name="upload" text="Upload" icon="upload"  />
                  <FileSelectionItem name="download" icon="download" text="Download"/>
                  <FileSelectionItem name="rename" icon="rename" text="Rename"/>
                  <FileSelectionItem name="delete" icon="trash" text="Delete"/>
                  {/* <FileSelectionItem name="copy" icon="copy" text="Copy"/> */}
                  <FileSelectionItem name="move" icon="movetofolder" text="Move"/>
                  <FileSelectionItem name="clearSelection" icon="remove" text="Clear selection"/>
                </Toolbar>
                <ContextMenu>
                  <Item name="create" text="New Directory"/>
                  <Item name="delete" text="Delete"/>
                  <Item name="rename" text="Rename"/>
                  <Item name="upload" text="Upload"/>
                </ContextMenu>
                <Permissions
                create={true}
                /* copy={true} */
                move= {true}
                delete = {true}
                rename = {true}
                upload = { true}
                download = {true}
                >
                </Permissions> 
              </FileManager>
              :
              <h4>{t("schedule_files_loads")}...</h4>
              }
            </Card.Body>
          </Card> 
        </Container>
      </Layout>
    </React.Fragment>
     :<>
     <NoAccess />
     </>
     }
    </>
  );
};

export default TraditionalFiles;
