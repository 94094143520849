import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Tour from "reactour";

const OnBoarding = (props) => {
  const [t,i18n] = useTranslation("onBoarding")
  const [showTour, setShowTour] = useState(false);
  const startTour = () => setShowTour(true);
  const finishTour = () => setShowTour(false);

  const [show, setShow] = useState(true);

  const neverMoreShow = () => {
    window.localStorage.setItem("onBoardProjects", true);
  };

  const handleClose = () => {
    setShow(false);
    startTour();
  };
  const handleCloseEnd = () => {
    // when user saw all the cards
    setShow(false);
    finishTour();
    neverMoreShow();
  };
  const handleCloseSkip = () => {
    setShow(false);
  };
  const handleCloseSkipForever = () => {
    setShow(false);
    neverMoreShow();
  };

  let showOnboarding = window.localStorage.getItem("onBoardProjects");

  const steps = [
    {
      selector: '[data-tour="step-1"]',
      content: (
       /*  <p>
          Si quieres estructurar un proyecto, puedes dirigirte al botón{" "}
          <b>proyectos</b> y allí, encontrarás un espacio de trabajo en donde
          almacenarás la cartera de tus proyectos.
        </p> */
        <p>{t("stepbystep_guidedtour_projects_step1_1")} <b>{t("stepbystep_guidedtour_projects_step1_2")}</b> {t("stepbystep_guidedtour_projects_step1_3")} </p>
      ),
    },
    {
      selector: '[data-tour="step-2"]',
      content: (
       /*  <p>
          Cuando ingreses a tu <b>cartera de proyectos</b>, encontrarás un botón
          llamado <b>crear</b>, al darle clic, podrás diligenciar la información
          principal de tus proyectos, su caracterización, sus actividades y
          responsables.
          <br />
          <br /> <b>Recomendación:</b> entre más información le suministres al
          sistema, podrás analizar mucho más!
        </p> */
        <p>{t("stepbystep_guidedtour_projects_step2_line1_1")} 
        <b>{t("stepbystep_guidedtour_projects_step2_line1_2")}</b>{t("stepbystep_guidedtour_projects_step2_line1_3")} <b>{t("stepbystep_guidedtour_projects_step2_line1_4")}</b>{t("stepbystep_guidedtour_projects_step2_line1_5")}
        <br />
        <br />
        <b>{t("stepbystep_guidedtour_projects_step2_line2_1")}</b> {t("stepbystep_guidedtour_projects_step2_line2_2")}
        </p>
      ),
    },
    {
      content: (
       /*  <p>
          Puedes crear tus proyectos con la herramienta Gantt o Kanban, es
          decir, tradicionales o ágiles, recuerda que dependiendo de la
          situación, podrás elegir la metodología. Si no estas seguro, puedes
          hacer el siguiente test para elegir la opción que más te convenga.{" "}
          <a
            href="https://projects.co.pwc.com/testagilidad/test/index.html#/"
            target="_blank"
          >
            Realizar Test
          </a>
        </p> */
        <p>
          {t("stepbystep_guidedtour_projects_step3_1")}
          <a
            href="https://projects.co.pwc.com/testagilidad/test/index.html#/"
            target="_blank"
          >
            {t("stepbystep_guidedtour_projects_step3_2")}
          </a>
        </p>
      ),
    },
    {
      selector: '[data-tour="step-1"]',
      content: (
       /*  <p>
          Cuando hayas creado tu proyecto, se listarán en el espacio de trabajo
          llamado "cartera de proyectos" dentro del espacio de trabajo de <b>Proyectos</b>, 
          allí verás todos los proyectos con sus principales atributos. 
          Basta con darle clic al nombre que elegiste y verás un entorno de 
          planeación de tus actividades, las fechas de compromiso y tambien 
          conformarás tu equipo de trabajo que te acompañará durante su ejecución. 
          A medida que vayas diligenciando información, el sistema te hará 
          automáticamente un informe ejecutivo del proyecto, en donde te mostrará
          en un solo lugar y de manera consolidada, su estado de salud.
        </p> */
        <p>{t("stepbystep_guidedtour_projects_step4_1")}<b>{t("stepbystep_guidedtour_projects_step4_2")}</b>{t("stepbystep_guidedtour_projects_step4_3")}</p>
      ),
    },
    {
      selector: '[data-tour="step-4"]',
      content: (
        <p>
        {t("stepbystep_guidedtour_projects_step5")}
        </p>
      ),
    },
    {
      content: (
        <div className="text-center pt-3">
          <p>{t("stepbystep_guidedtour_projects_step6")}</p>
          <Button variant="success" onClick={handleCloseEnd} size="md">
            {t("stepbystep_welcome_button_thanks")}
          </Button>
        </div>
      ),
    },
  ];

  if (showOnboarding || !props.show) {
    return (
      <>
        <Button
          variant="info"
          className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
          style={{ bottom: "1rem", left: "1rem" }}
          onClick={startTour}
        >
          <i className="fa fa-question-circle"></i>
        </Button>
        <Tour
          onRequestClose={finishTour}
          steps={steps}
          isOpen={showTour}
          // maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor="#e0301e"
        />
      </>
    );
  }

  return (
    <div>
      <Modal
        show={show}
        // onHide={handleClose}
        contentClassName="border-0"
        centered
        // size="sm"
      >
        <Modal.Body>
          <h5>{t("stepbystep_guidedtour")}</h5>
          <p>
            {t("stepbystep_guidedtour_tittle")}
          </p>
          <div className="d-flex justify-content-between">
            <div>
              <Button
                variant="outline-primary"
                onClick={handleCloseSkip}
                size="md"
                className="mr-2"
              >
                <i className="fa fa-times mr-2"></i>{t("stepbystep_guidedtour_button_skip")}
              </Button>{" "}
              <a href="#" onClick={handleCloseSkipForever} className="small">
                {t("stepbystep_guidedtour_button_donotseemore")}.
              </a>
            </div>
            <Button variant="success" onClick={handleClose} size="md">
              ¡{t("stepbystep_guidedtour_button_showme")}!<i className="fa fa-arrow-right ml-2"></i>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Tour
        onRequestClose={finishTour}
        steps={steps}
        isOpen={showTour}
        // maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor="#e0301e"
      />
    </div>
  );
};

export default OnBoarding;
