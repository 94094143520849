import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import VirtualAsistentSideBar from "./VirtualAsistentSideBar";
import { selectTooltip , selectTooltip_en } from "../utils/selectTooltip";
import { useTranslation } from "react-i18next";

const TitleCommon = (props) => {
  const [t,i18n] =useTranslation("onBoarding")

  const history = useHistory()

  const [stateBtn,setStateBtn] = useState(false)
  const handleStateBtn = () =>{
    setStateBtn((value)=>value =!value)
  }
  return (
    <>
      <Container
        fluid
        className={`bg-${props.color || "primary"} position-relative shadow-sm`}
        style={{ zIndex: 1 }}
      >
        <div className="d-flex align-items-center">
          {props.button ? (
            <Link to={props.button.url} className="bg-dark mr-3 p-2 px-3 text-white ml-n3">
              <i className={`fa mr-lg-2 fa-${props.button.icon}`}></i>
              <span className="d-none d-lg-inline-block">
                {props.button.text}
              </span>
            </Link>
          ) : (
            ""
          )}
          {props.goBack ? (
            <div style={{cursor:"pointer"}} onClick={history.goBack} className="bg-dark mr-3 p-2 px-3 text-white ml-n3">
              <i className={`fa mr-lg-2 fa-${props.goBack.icon}`}></i>
              <span className="d-none d-lg-inline-block">
                {props.goBack.text}
              </span>
            </div>
            ) : (
            ""
            )}
          <h1
            className={`py-2 text-${
              props.titleColor || "white"
            } m-0 h6 animate__animated animate__fadeInDown animate__faster`}
          >
            <i className={`fa fa-${props.title.icon} mr-3`}></i>
            <span data-tip={props.title.tooltip || null} data-place="right">
              {props.title.text}
            </span>
          </h1>
        </div>
        {/* {props.button ? (
          <Link to={props.button.url}>
            <Button
              variant={props.button.variant || "dark"}
              className="position-absolute"
              style={{ right: "1rem", bottom: "-.9rem", zIndex: 1 }}
              size="sm"
            >
              <i className={`fa mr-lg-2 fa-${props.button.icon}`}></i>
              <span className="d-none d-lg-inline-block">
                {props.button.text}
              </span>
            </Button>
          </Link>
        ) : (
          ""
        )} */}
      </Container>
      <div className={`btnAsisVirt fixed-bottom ${(stateBtn)?"bg-dark btnAsisVirt-active":"bg-dark"}`} 
           onClick={handleStateBtn}
           data-tip={(t("onboarding_lenguaje") =="es")? selectTooltip(42): selectTooltip_en(42)}
           >
        <i className={`fa fa-arrow-left i-BtnVirtual text-white p-2 ${(stateBtn)?"i-BtnVirtualActive":""}`}></i>
      </div>
      {(stateBtn) && 
      <>
        <div className="conten-btn-AsisVirt fixed-bottom">
          <VirtualAsistentSideBar/>
          <a 
            href="mailto:co_cloudprojects@pwc.com?Subject= Help Desk Cloud Projects" target="_blank" >
            <div data-toggle="tooltip" data-placement="top" title={t("help_title_aux_videos_a_6")} className='bg-danger ma-btn-secondary-sidebar rounded-circle text-white animate__animated animate__fadeInRight'> 
                <i className="fa fa-question-circle animate__animated animate__bounceIn"></i>  
            </div>
          </a>
        </div>
      </>
      }
      {/* {props.subTitle && (
        <Container fluid className="bg-dark" style={{ zIndex: -1 }}>
          <Row>
            <Col className="pt-3 pb-2 text-white">
              <h5>
                <span>{props.subTitle.text || "..."}</span>
              </h5>
            </Col>
          </Row>
        </Container>
      )} */}
    </>
  );
};

export default TitleCommon;
