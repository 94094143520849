import React from "react";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import img from '../assets/ban-solid.png'
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HelpVideoView from "../components/HelpVideoView";
import { useState } from "react";

const Helps = () => {   
  const [showHelps, setShowHelps] = useState(false);
  const [nameVideoHelp, setNameVideoHelp] = useState("");
  const handleCloseHelps = () => setShowHelps(false);
  const [idHelp, setIdHelp] = useState()

  const agilProjectsList = [ 
    {
      id:1,
      nameVideo : "Crear sprint",
      url : "crearSprint"
    },
    {
      id:2 ,
      nameVideo : "Ordenar tablas en recursos",
      url : "ordenarTablaRecursos"
    },
    {
      id:3 ,
      nameVideo : "Ordenar tablas en historias de usuario",
      url : "ordenarTablaHistoriaUsuario"
    }, 
    {
      id:4 ,
      nameVideo : "Agregar recurso",
      url : "agregarRecurso"
    },
    {
      id:5 ,
      nameVideo : "Filtrar Sprint",
      url : "filtrarSprint"
    }, 
  ]
  
  const tradicionalProjectsList = [ 
    {
      id:1 ,
      nameVideo : "Agregar nueva tarea",
      url : "agregarTarea"
    },
    {
      id:2 ,
      nameVideo : "Agregar recurso",
      url : "agregarRecurso"
    },
    {
      id:3 ,
      nameVideo : "Crear dependencia de tarea",
      url : "crearDependenciaTarea"
    }, 
    {
      id:4,
      nameVideo : "Crear Hito",
      url : "crearHito"
    },    
  ]
  const generalList = [ 
    {
      id:1 ,
      nameVideo : "Editar información de usuario",
      url : "editUsuarioInfo"
    },
    {
      id:2 ,
      nameVideo : "Crear usuario ( Solo Administradores )",
      url : "crearUsuario"
    },
    {
      id:3 ,
      nameVideo : "Ver mis asignaciones",
      url : "verAsignaciones"
    },
      
  ]
  const handleVideosHelp = (info) => {
   
    setNameVideoHelp(info.nameVideo)
    setIdHelp(info)
    setShowHelps(true)
  }
    return(
    <>
    <Layout>
    <TitleCommon
          title={{
            text: "Ayudas",
            icon: "clipboard"
          }}
          //   button={{
          //     text: "Volver",
          //     icon: "arrow-left",
          //     url: "/",
          //   }}
        />
    </Layout>
    <div className="text-center pt-4 mt-4">
    <Card className="mb-5">
            <Card.Body>
              <Card.Title className="mb-5">Videos tutoriales</Card.Title>
              <Row className="justify-content-center mb-3" >
              <Col className="mb-3 p-0 d-flex flex-column align-items-start" md={3} lg={3}>
              <h6 className="mb-3">Proyectos Tradicionales</h6>
              <div>
                  <ul className="list-group list-group-flush">
              {tradicionalProjectsList.map((item,idx) => {
                return <li onClick={()=>handleVideosHelp({id:item.id, type:"Tradicional",nameVideo: item.nameVideo, url : item.url })} key={idx} className="list-group-item list-group-item-action d-flex flex-column align-items-start pt-0">{item.nameVideo}</li>
                })
              }
                </ul>
               </div>
              </Col>
              <Col className="mb-3 p-0 d-flex flex-column align-items-start" md={3} lg={3}>
              <h6 className="mb-3">Proyectos Agiles</h6>
              <div>
                  <ul className="list-group list-group-flush">
              {agilProjectsList.map((item,idx) => {
                return  <li onClick={()=>handleVideosHelp({id:item.id, type:"Agil", nameVideo: item.nameVideo,url : item.url})} key={idx} className="list-group-item list-group-item-action d-flex flex-column align-items-start pt-0">{item.nameVideo}</li>          
              })

              }
              </ul>
              </div>
              </Col>
            </Row>
            <Row className="justify-content-center" >
            <Col className="mb-3 p-0 d-flex flex-column align-items-start" md={3} lg={3}>
              <h6 className="mb-3">Generalidades</h6>
              <div>
                  <ul className="list-group list-group-flush">
              {generalList.map((item,idx) => {
                return  <li onClick={()=>handleVideosHelp({id:item.id, type:"Generalidades",nameVideo: item.nameVideo, url : item.url})}  key={idx} className="list-group-item list-group-item-action d-flex flex-column align-items-start pt-0">{item.nameVideo}</li>
                       
              })
              }
                </ul>
              </div>
              </Col>
              <Col className="mb-3 p-0 d-flex flex-column align-items-start" md={3} lg={3}> </Col>


            </Row>
          
            </Card.Body>
          </Card>
    </div>
    <Modal
      show={showHelps}
      onHide={handleCloseHelps}
      contentClassName="border-0 shadow-lg"
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h5">Tutorial: {nameVideoHelp} </Modal.Title>
        <Button onClick={handleCloseHelps} variant="light">X</Button>
      </Modal.Header>
      <Modal.Body>
        <HelpVideoView info = {idHelp} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={handleCloseHelps}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
    </>
);
};

export default Helps;