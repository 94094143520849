import { gantt } from "dhtmlx-gantt";
import { subHours } from "date-fns";
import axios from 'axios';
import { API_ROUTE, headersRequest } from "../../helpers";
import _ from "lodash";

export const dayFormatter = gantt.ext.formatters.durationFormatter({
  enter: "day",
  store: "hour",
  format: "day",
  hoursPerDay: 8,
  hoursPerWeek: 40,
  daysPerMonth: 30,
  short: true,
});

export const hourFormatter = gantt.ext.formatters.durationFormatter({
  enter: "hour",
  store: "hour",
  format: "hour",
  short: true,
});

export const autoFormatter = gantt.ext.formatters.durationFormatter({
  enter: "day",
  store: "day",
  format: "auto",
});

export const calculatePlanneProgress = (ganttMet, task) => {
  const currentDate = new Date();
  const totalWorkDays = ganttMet.calculateDuration(
    task.start_date,
    task.end_date,
    "day",
    true
  );
  const totalPassDays = ganttMet.calculateDuration(
    task.start_date,
    currentDate,
    "day",
    true
  );

  if (totalPassDays > totalWorkDays) {
    return 100;
  } else if (totalPassDays < 0) {
    return 0;
  }

  const progress = (totalPassDays / totalWorkDays) * 100;
  return parseFloat(progress.toFixed(2));
  // esta ecuacion tendria  q usarse cuando se cargan las actividades
  // en el valor de planned_progress y pasarlo los parametros indicados,
  //se pasa el gantt q ya tiene instanciados los feriados y horarios de trabajo
};

//aux and logic for new gantt reFactor

export const gantLayout = {
  css: "gantt_container",
  cols: [
    {
      width: 500,
      min_width: 300,
      rows: [
        {
          view: "grid",
          scrollX: "gridScroll",
          scrollable: true,
          scrollY: "scrollVer",
        },
        { view: "scrollbar", id: "gridScroll", group: "horizontal" },
      ],
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
        { view: "scrollbar", id: "scrollHor", group: "horizontal" },
      ],
    },
    { view: "scrollbar", id: "scrollVer" },
  ],
};

export const setLightBoxSections = (gantt, t) => {
  gantt.config.lightbox.sections = [
    {
      name: "description",
      label: "Actividad",
      height: 30,
      map_to: "text",
      type: "textarea",
      focus: true,
    },
    { name: "time", height: 35, map_to: "auto", type: "datepicker" },
    {
      name: "type",
      height: 30,
      map_to: "type",
      type: "typeselect",
      options: [
        { key: "project", label: "Fase" },
        { key: "task", label: "Tarea" },
        { key: "milestone", label: "Hito" },
      ],
    },
    {
      name: "progress",
      height: 30,
      map_to: "progress",
      type: "progress_number",
    },
    {
      name: "owner",
      height: 150,
      map_to: "owner_id",
      type: "resources",
      default_value: 8,
      options: gantt.serverList("resourceList"),
    },
  ];
  gantt.locale.labels.section_type = t("schedule_gantt_type");
  gantt.locale.labels.section_description = t("schedule_gantt_activity");
  gantt.locale.labels.section_owner = t("schedule_gantt_resources");
  gantt.locale.labels.section_time = t("schedule_gantt_date");
  gantt.locale.labels["section_progress"] = t("schedule_gantt_%progress");
  gantt.render();
};

export const sectionForMilestones = (gantt) => {
  gantt.config.lightbox["milestone" + "_sections"] = [
    {
      name: "description",
      label: "Actividad",
      height: 50,
      map_to: "text",
      type: "textarea",
      focus: true,
    },
    {
      name: "time",
      height: 35,
      map_to: "auto",
      type: "duration",
      single_date: true,
    },

    {
      name: "type",
      height: 50,
      map_to: "type",
      type: "typeselect",
      options: [
        { key: "project", label: "Fase" },
        { key: "task", label: "Tarea" },
        { key: "milestone", label: "Hito" },
      ],
    },
    {
      name: "progress",
      height: 50,
      map_to: "progress",
      type: "select",
      options: [
        { key: "0", label: "Sin Iniciar" },
        { key: "0.1", label: "10%" },
        { key: "0.2", label: "20%" },
        { key: "0.3", label: "30%" },
        { key: "0.4", label: "40%" },
        { key: "0.5", label: "50%" },
        { key: "0.6", label: "60%" },
        { key: "0.7", label: "70%" },
        { key: "0.8", label: "80%" },
        { key: "0.9", label: "90%" },
        { key: "1", label: "Completa" },
      ],
    },

    {
      name: "owner",
      height: 120,
      map_to: "owner_id",
      type: "resources",
      default_value: 8,
      options: gantt.serverList("resourceList"),
    },
  ];
};

export const sectionForProjects = (gantt) => {
  gantt.config.lightbox["project" + "_sections"] = [
    {
      name: "description",
      label: "Actividad",
      height: 50,
      map_to: "text",
      type: "textarea",
      focus: true,
    },
    {
      name: "time",
      height: 35,
      map_to: "auto",
      type: "duration",
      readonly: true,
    },
    //{name:"time", height:35, map_to:"auto", type:"datepicker"},
    {
      name: "type",
      height: 50,
      map_to: "type",
      type: "typeselect",
      options: [
        { key: "project", label: "Fase" },
        { key: "task", label: "Tarea" },
        { key: "milestone", label: "Hito" },
      ],
    },
  ];
};

export const configColumns = (gantt, t, showColumns ,rol, showDuration) => {
    let textEditor = { type: "text", map_to: "text" };
    let dateEditor = {
      type: "date",
      map_to: "start_date",
      min: new Date(2018, 0, 1),
      max: new Date(2019, 0, 1),
    };
    let durationEditor = {
      type: "duration", // antes number
      map_to: "duration",
      formatter: autoFormatter, // sin formato
      min: 0,
      max: 1000,
    };
  
    let dayDurationEditor = {
      type: "duration",
      map_to: "duration",
      formatter: dayFormatter,
      min: 0,
      max: 1000,
    };
    let hourDurationEditor = {
      type: "duration",
      map_to: "duration",
      formatter: hourFormatter,
      min: 0,
      max: 1000,
    };
    var getInput = function (node) {
        return node.querySelector("input");
      };
    gantt.config.editor_types.AllCost = {
      show: (id, column, config, placeholder) => {
        let typeOfTask = gantt.getTaskType(gantt.getTask(id));
        let min = config.min || 0, max = config.max || 100;
        let html ="<div><input class='bg-light' disabled type='number' min='" + min +"' max='" + max + "' name='" + column.name +"'></div>";
        let html2 = "<div><input  type='number' min='" + min + "' max='" + max + "' name='" + column.name +"'></div>";
        placeholder.innerHTML = typeOfTask == "project" ? html : html2;
      },
      hide: () => {},
      set_value: function (value, id, column, node) {
        getInput(node).value = value;
      },
      get_value: function (id, column, node) {
        return getInput(node).value || 0;
      },
      is_changed: function (value, id, column, node) {
        var currentValue = this.get_value(id, column, node);
        return Number(value) !== Number(currentValue);
      },
      is_valid: function (value, id, column, node) {
        return !isNaN(parseInt(value, 10));
      },
      focus: function (node) {
        var input = getInput(node);
        if (!input) {
          return;
        }
        if (input.focus) {
          input.focus();
        }
  
        if (input.select) {
          input.select();
        }
      },
    };
  
    gantt.config.editor_types.AllCostReal = {
      show: (id, column, config, placeholder) => {
        let typeOfTask = gantt.getTaskType(gantt.getTask(id));
        let min = config.min || 0, max = config.max || 100;
        let taskInfoForCost = gantt.getTask(id);
        let taskInfoForCostReal = gantt.getTask(id)?.realcost;
        let taskInfoForCostPlan = gantt.getTask(id)?.plancost;
        let forColor = () => {
          if (taskInfoForCostReal > taskInfoForCostPlan) {
            return "bg-danger";
          } else if (taskInfoForCostReal > taskInfoForCostPlan) {
            return "bg-success";
          } else {
            return "";
          }
        };
  
        let html ="<div><input class='bg-light' disabled type='number' min='" + min + "' max='" + max + "' name='" + column.name + "'></div>";
        let html2 = "<div><input  type='number' min='" + min + "' max='" + max + "' name='" + column.name + "'></div>";
        placeholder.innerHTML = typeOfTask == "project" ? html : html2;
      },
      hide: () => {},
      set_value: function (value, id, column, node) {
        getInput(node).value = value;
      },
      get_value: function (id, column, node) {
        return getInput(node).value || 0;
      },
      is_changed: function (value, id, column, node) {
        var currentValue = this.get_value(id, column, node);
        return Number(value) !== Number(currentValue);
      },
      is_valid: function (value, id, column, node) {
        return !isNaN(parseInt(value, 10));
      },
      focus: function (node) {
        var input = getInput(node);
        if (!input) {
          return;
        }
        if (input.focus) {
          input.focus();
        }
  
        if (input.select) {
          input.select();
        }
      },
    };
  
    let planEditor = {
      type: "AllCost",
      map_to: "plancost",
      min: 0,
      max: 9999999999,
    };
    let realEditor = {
      type: "AllCostReal",
      map_to: "realcost",
      min: 0,
      max: 9999999999,
    };
  
    let textEditorColum = { type: "text", map_to: "text" };
    let start_dateEditor = { type: "date", map_to: "start_date" };
    let end_dateEditor = { type: "date", map_to: "end_date" };
  
    function byId(list, id) {
        for (var i = 0; i < list.length; i++) {
          if (list[i].key == id) return list[i].label || "";
        }
        return "";
      }
    
    const confiColumnsSTD = [
        {
          name: "N°",
          label: "N°",
          align: "center",
          min_width: 50,
          resize: true,
          template: (item) => {
            return item.$index + 1;
          },
        },
        { name: "add", align: "center" },
        {
          name: "id",
          label: "UniqueIndentifier",
          align: "center",
          min_width: 300,
          resize: true,
          hide: showColumns.activeUnique,
        },
    
        {
          name: "wbs",
          label: "Wbs",
          width: "60",
          hide: showColumns.activeWbs,
          template: gantt.getWBSCode,
          resize: true,
        },
        {
          name: "text",
          label: t("schedule_gantt_activity"),
          tree: true,
          min_width: 250,
          width: "*",
          resize: true,
          hide: showColumns.activeTask,
          editor: textEditorColum,
          template: (item) => {
            let normalText = `<b>${item.text}</b>`;
            if (item.type == "project") {
              return normalText;
            } else {
              return item.text;
            }
          },
        },
        { name: "type", label: "Tipo", resize: false, hide: true },
        {
          name: "start_date",
          label: t("schedule_gantt_start"),
          align: "center",
          min_width: 100,
          resize: true,
          editor: start_dateEditor,
          hide: showColumns.activeStart,
          template: (item) => {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_date
            );
            return dateWithFormat;
          },
        },
        {
          name: "end_date",
          label: t("schedule_gantt_end"),
          align: "center",
          min_width: 100,
          resize: true,
          editor: end_dateEditor,
          hide: showColumns.activeEnd,
          template: (item) => {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_date
            );
            return dateWithFormat;
          },
        },
        {
          name: "duration",
          label: t("schedule_gantt_duration"),
          align: "center",
          resize: true,
          hide: true,
          with: 110,
          editor: durationEditor,
          template: (item) => {
            return item.duration;
          },
          /*  hide: showColumns.activeDuration, */
        },
        {
          name: "dayDuration",
          label: "Duración(d)",
          resize: true,
          align: "center",
          editor: dayDurationEditor,
          with: 110,
          hide: showDuration,
          template: (task) => {
            return dayFormatter.format(task.duration);
          },
        },
        {
          name: "hourDuration",
          label: "Duración(hrs)",
          resize: true,
          align: "center",
          editor: hourDurationEditor,
          with: 50,
          hide: !showDuration,
          template: (task) => {
            return hourFormatter.format(task.duration);
          },
        },
        {
          name: "plancost",
          label: t("schedule_gantt_cost"),
          min_width: 90,
          align: "center",
          resize: true,
          hide: showColumns.activeCost,
          editor: planEditor,
          template: (item) => {
            let valuePlan = item.plancost;
            const formatterCOP = new Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            });
            let plancost = formatterCOP.format(valuePlan);
            let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
            if (item.type == "project") {
              return normalText;
            } else {
              return plancost;
            }
          },
        },
        {
          name: "realcost",
          label: t("schedule_gantt_actualCost"),
          min_width: 90,
          align: "center",
          resize: true,
          editor: realEditor,
          hide: showColumns.activeCostReal,
          template: (item) => {
            let valueRealCost = item.realcost;
            const formatterCOP = new Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            });
    
            let taskInfoForCost = gantt.getTask(item.id);
            let taskInfoForCostReal = gantt.getTask(item.id)?.realcost;
            let taskInfoForCostPlan = gantt.getTask(item.id)?.plancost;
            let forColor =
              taskInfoForCostReal > taskInfoForCostPlan ? "#F1948A" : "#ABEBC6";
    
            let realCost = formatterCOP.format(valueRealCost);
            let normalText = `<div style="background-color: ${forColor}" class="w-100"><b>${realCost}</b></div>`;
            if (item.type == "project") {
              return normalText;
            } else {
              return normalText;
            }
          },
        },
        {
          name: "predecessor",
          label: t("schedule_gantt_predecessor"),
          min_width: 90,
          align: "center",
          resize: true,
          hide: showColumns.activePrede,
          template: function (item) {
            let formatter = gantt.ext.formatters.durationFormatter({
              enter: "day",
              store: "hour",
              format: "day",
              hoursPerDay: 24,
              hoursPerWeek: 40,
              daysPerMonth: 30,
              short: true,
            });
            let linksFormatter = gantt.ext.formatters.linkFormatter({
              durationFormatter: formatter,
            });
    
            let links = item.$target;
            let labels = [];
            for (let i = 0; i < links.length; i++) {
              let link = gantt.getLink(links[i]);
              if (
                !labels.find((x) => {
                  return x == linksFormatter.format(link);
                })
              )
                labels.push(linksFormatter.format(link));
            }
            let predecessors = labels.join(", ");
    
            return predecessors;
          },
        },
        {
          name: "progress",
          label: t("schedule_gantt_progress"),
          min_width: 90,
          align: "center",
          hide: showColumns.activeReal,
          template: function (item) {
            return Math.round(item.progress * 100) + "%";
          },
        },
        {
          name: "planned",
          label: " % Plan.",
          min_width: 90,
          align: "center",
          hide: showColumns.activePlan,
          template: function (item) {
            if (item.planned) {
              return `${item.planned}%`;
            } else {
              return 0;
            }
          },
        },
        {
          name: "indicator",
          label: "Indica.",
          min_width: 110,
          align: "center",
          hide: showColumns.activeIndi,
          template: function (item) {
            if (item.type != "project") {
              const iconCritical =
                "<i class='fa fa-exclamation-circle text-danger'></i>";
              const iconCriticalExt =
                "<i class='fa fa-exclamation-circle text-dark'></i>";
              const iconModerate =
                "<i class='fa fa-exclamation-circle text-warning'></i>";
              const iconOntime = "<i class='fa fa-check-circle text-success'></i>";
              const selectState = (indicator) => {
                if (indicator == 1) {
                  return iconOntime;
                } else if (indicator == 2) {
                  return iconModerate;
                } else if (indicator == 3) {
                  return iconCritical;
                } else {
                  return iconCriticalExt;
                }
              };
              const state = selectState(item.indicator);
              const valueOfIndi = byId(
                gantt.serverList("indicator"),
                item.indicator
              );
              const textOfIndi = `<div class="text-center w-100">${state}<b class="ml-1">${valueOfIndi}</b></div>`;
              return textOfIndi;
            } else {
              return;
            }
          },
        },
        //lb_1
        {
          name: "start_baseline_date",
          label: "Inicio LB 1",
          align: "center",
          min_width: 100,
          readonly: true,
          hide: showColumns.actLB_start_1,
          resize: true,
          template: (item) => {
            if (item.start_baseline_date) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.start_baseline_date
              );
              return dateWithFormat;
            } else {
              return "-";
            }
          },
        },
        {
          name: "end_baseline_date",
          label: "Fin LB 1 ",
          align: "center",
          min_width: 100,
          readonly: true,
          resize: true,
          hide: showColumns.actLB_end_1,
          template: (item) => {
            if (item.end_baseline_date) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.end_baseline_date
              );
              return `${dateWithFormat}`;
            } else {
              return "-";
            }
          },
        },
        {
          name: "durationLB_1",
          label: "Duración LB 1",
          align: "center",
          resize: true,
          hide: showColumns.actLB_duration_1,
          template: (item) => {
            if (item.durationLB_1) {
              return item.durationLB_1;
            } else {
              return 0;
            }
          },
        },
        {
          name: "costoLB1",
          label: "Costo LB 1",
          min_width: 100,
          align: "center",
          resize: true,
          hide: showColumns.actLB_cost_1,
          template: (item) => {
            if (item.costoLB1 != null) {
              let valuePlan = item.costoLB1;
              const formatterCOP = new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              });
              let plancost = formatterCOP.format(valuePlan);
              let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
              if (item.type == "project") {
                return normalText;
              } else {
                return plancost;
              }
            } else {
              return 0;
            }
          },
        },
        //lb_2
        {
          name: "start_baseline_date_2",
          label: "Inicio LB 2",
          align: "center",
          min_width: 100,
          readonly: true,
          hide: showColumns.actLB_start_2,
          resize: true,
          template: (item) => {
            if (item.start_baseline_date_2) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.start_baseline_date_2
              );
              return dateWithFormat;
            } else {
              return "-";
            }
          },
        },
        {
          name: "end_baseline_date_2",
          label: "Fin LB 2",
          align: "center",
          min_width: 100,
          readonly: true,
          resize: true,
          hide: showColumns.actLB_end_2,
          template: (item) => {
            if (item.end_baseline_date_2) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.end_baseline_date_2
              );
              return `${dateWithFormat}`;
            } else {
              return "-";
            }
          },
        },
        {
          name: "durationLB_2",
          label: "Duración LB 2",
          align: "center",
          resize: true,
          hide: showColumns.actLB_duration_2,
          template: (item) => {
            if (item.durationLB_2) {
              return item.durationLB_2;
            } else {
              return 0;
            }
          },
        },
        {
          name: "costoLB2",
          label: "Costo LB 2",
          min_width: 100,
          align: "center",
          resize: true,
          hide: showColumns.actLB_cost_2,
          template: (item) => {
            if (item.costoLB2 != null) {
              let valuePlan = item.costoLB2;
              const formatterCOP = new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              });
              let plancost = formatterCOP.format(valuePlan);
              let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
              if (item.type == "project") {
                return normalText;
              } else {
                return plancost;
              }
            } else {
              return 0;
            }
          },
        },
        //lb_3
        {
          name: "start_baseline_date_3",
          label: "Inicio LB 3",
          align: "center",
          min_width: 100,
          readonly: true,
          hide: showColumns.actLB_start_3,
          resize: true,
          template: (item) => {
            if (item.start_baseline_date_3) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.start_baseline_date_3
              );
              return dateWithFormat;
            } else {
              return "-";
            }
          },
        },
        {
          name: "end_baseline_date_3",
          label: "Fin LB 3",
          align: "center",
          min_width: 100,
          readonly: true,
          resize: true,
          hide: showColumns.actLB_end_3,
          template: (item) => {
            if (item.end_baseline_date_3) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.end_baseline_date_3
              );
              return `${dateWithFormat}`;
            } else {
              return "-";
            }
          },
        },
        {
          name: "durationLB_3",
          label: "Duración LB 3",
          align: "center",
          resize: true,
          hide: showColumns.actLB_duration_3,
          template: (item) => {
            if (item.durationLB_3) {
              return item.durationLB_3;
            } else {
              return 0;
            }
          },
        },
        {
          name: "costoLB3",
          label: "Costo LB 3",
          min_width: 100,
          align: "center",
          resize: true,
          hide: showColumns.actLB_cost_3,
          template: (item) => {
            if (item.costoLB3 != null) {
              let valuePlan = item.costoLB3;
              const formatterCOP = new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              });
              let plancost = formatterCOP.format(valuePlan);
              let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
              if (item.type == "project") {
                return normalText;
              } else {
                return plancost;
              }
            } else {
              return 0;
            }
          },
        },
        //lb_4
        {
          name: "start_baseline_date_4",
          label: "Inicio LB 4",
          align: "center",
          min_width: 100,
          readonly: true,
          hide: showColumns.actLB_start_4,
          resize: true,
          template: (item) => {
            if (item.start_baseline_date_4) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.start_baseline_date_4
              );
              return dateWithFormat;
            } else {
              return "-";
            }
          },
        },
    
        {
          name: "end_baseline_date_4",
          label: "Fin LB 4",
          align: "center",
          min_width: 100,
          readonly: true,
          resize: true,
          hide: showColumns.actLB_end_4,
          template: (item) => {
            if (item.end_baseline_date_4) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.end_baseline_date_4
              );
              return `${dateWithFormat}`;
            } else {
              return "-";
            }
          },
        },
        {
          name: "durationLB_4",
          label: "Duración LB 4",
          align: "center",
          resize: true,
          hide: showColumns.actLB_duration_4,
          template: (item) => {
            if (item.durationLB_4) {
              return item.durationLB_4;
            } else {
              return 0;
            }
          },
        },
        {
          name: "costoLB4",
          label: "Costo LB 4",
          min_width: 100,
          align: "center",
          resize: true,
          hide: showColumns.actLB_cost_4,
          template: (item) => {
            if (item.costoLB4 != null) {
              let valuePlan = item.costoLB4;
              const formatterCOP = new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              });
              let plancost = formatterCOP.format(valuePlan);
              let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
              if (item.type == "project") {
                return normalText;
              } else {
                return plancost;
              }
            } else {
              return 0;
            }
          },
        },
        //lb_5
        {
          name: "start_baseline_date_5",
          label: "Inicio LB 5",
          align: "center",
          min_width: 100,
          readonly: true,
          hide: showColumns.actLB_start_5,
          resize: true,
          template: (item) => {
            if (item.start_baseline_date_5) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.start_baseline_date_5
              );
              return dateWithFormat;
            } else {
              return "-";
            }
          },
        },
        {
          name: "end_baseline_date_5",
          label: "Fin LB 5",
          align: "center",
          min_width: 100,
          readonly: true,
          resize: true,
          hide: showColumns.actLB_end_5,
          template: (item) => {
            if (item.end_baseline_date_5) {
              let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
                item.end_baseline_date_5
              );
              return `${dateWithFormat}`;
            } else {
              return "-";
            }
          },
        },
        {
          name: "durationLB_5",
          label: "Duración LB 5",
          align: "center",
          resize: true,
          hide: showColumns.actLB_duration_5,
          template: (item) => {
            if (item.durationLB_5) {
              return item.durationLB_5;
            } else {
              return 0;
            }
          },
        },
        {
          name: "costoLB5",
          label: "Costo LB 5",
          min_width: 100,
          align: "center",
          resize: true,
          hide: showColumns.actLB_cost_5,
          template: (item) => {
            if (item.costoLB5 != null) {
              let valuePlan = item.costoLB5;
              const formatterCOP = new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
                minimumFractionDigits: 0,
              });
              let plancost = formatterCOP.format(valuePlan);
              let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
              if (item.type == "project") {
                return normalText;
              } else {
                return plancost;
              }
            } else {
              return 0;
            }
          },
        },
      ];
      const confiColumnsME = [
        {
          name: "wbs",
          label: "Wbs",
          width: "40",
          template: gantt.getWBSCode,
          resize: true,
          hide: showColumns.activeWbs,
        },
        {
          name: "id",
          label: "UniqueIndentifier",
          align: "center",
          min_width: 300,
          resize: true,
          hide: showColumns.activeUnique,
        },
        {
          name: "text",
          label: t("schedule_gantt_activity"),
          tree: true,
          min_width: 250,
          width: "*",
          resize: true,
          hide: showColumns.activeTask,
          editor: textEditorColum,
          template: (item) => {
            let normalText = `<b>${item.text}</b>`;
            if (item.type == "project") {
              return normalText;
            } else {
              return item.text;
            }
          },
        },
        { name: "type", label: "Tipo", resize: false, hide: true },
        {
          name: "start_date",
          label: t("schedule_gantt_start"),
          align: "center",
          min_width: 100,
          resize: true,
          editor: start_dateEditor,
          hide: showColumns.activeStart,
          template: (item) => {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.start_date
            );
            return dateWithFormat;
          },
        },
        {
          name: "end_date",
          label: t("schedule_gantt_end"),
          align: "center",
          min_width: 100,
          resize: true,
          editor: end_dateEditor,
          hide: showColumns.activeEnd,
          template: (item) => {
            let dateWithFormat = new Intl.DateTimeFormat("es-CL").format(
              item.end_date
            );
            return dateWithFormat;
          },
        },
        {
          name: "duration",
          label: t("schedule_gantt_duration"),
          align: "center",
          resize: true,
          editor: durationEditor,
          hide: showColumns.activeDuration,
        },
        {
          name: "plancost",
          label: t("schedule_gantt_cost"),
          min_width: 90,
          align: "center",
          resize: true,
          hide: showColumns.activeCost,
          editor: planEditor,
          template: (item) => {
            let valuePlan = item.plancost;
            const formatterCOP = new Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            });
            let plancost = formatterCOP.format(valuePlan);
            let normalText = `<div class="bg-light w-100"><b>${plancost}</b></div>`;
            if (item.type == "project") {
              return normalText;
            } else {
              return plancost;
            }
          },
        },
        {
          name: "realcost",
          label: t("schedule_gantt_actualCost"),
          min_width: 90,
          align: "center",
          resize: true,
          editor: realEditor,
          hide: showColumns.activeCostReal,
          template: (item) => {
            let valueRealCost = item.realcost;
            const formatterCOP = new Intl.NumberFormat("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            });
    
            let taskInfoForCost = gantt.getTask(item.id);
            let taskInfoForCostReal = gantt.getTask(item.id)?.realcost;
            let taskInfoForCostPlan = gantt.getTask(item.id)?.plancost;
            let forColor = taskInfoForCostReal > taskInfoForCostPlan ? "#F1948A" : "#ABEBC6";
            let realCost = formatterCOP.format(valueRealCost);
            let normalText = `<div style="background-color: ${forColor}" class="w-100"><b>${realCost}</b></div>`;
            if (item.type == "project") {
              return normalText;
            } else {
              return normalText;
            }
          },
        },
        {
          name: "predecessor",
          label: t("schedule_gantt_predecessor"),
          min_width: 90,
          align: "center",
          resize: true,
          hide: true,
          template: function (item) {
            let formatter = gantt.ext.formatters.durationFormatter({
              enter: "day",
              store: "hour",
              format: "day",
              hoursPerDay: 24,
              hoursPerWeek: 40,
              daysPerMonth: 30,
              short: true,
            });
            let linksFormatter = gantt.ext.formatters.linkFormatter({
              durationFormatter: formatter,
            });
    
            let links = item.$target;
            let labels = [];
            for (let i = 0; i < links.length; i++) {
              let link = gantt.getLink(links[i]);
              if (
                !labels.find((x) => {
                  return x == linksFormatter.format(link);
                })
              )
                labels.push(linksFormatter.format(link));
            }
            let predecessors = labels.join(", ");
    
            return predecessors;
          },
        },
        {
          name: "progress",
          label: t("schedule_gantt_progress"),
          min_width: 90,
          align: "center",
          hide: showColumns.activeReal,
          template: function (item) {
            if (item.progress >= 1) return t("schedule_gantt_completed");
            if (item.progress == 0) return t("schedule_gantt_tobestarted");
            return Math.round(item.progress * 100) + "%";
          },
        },
        {
          name: "planned",
          label: " % Plan.",
          min_width: 90,
          align: "center",
          hide: showColumns.activePlan,
          template: function (item) {
            console.log(item);
            if (item.planned) {
              if (item.planned >= 100) return t("schedule_gantt_completed");
              if (item.planned == 0) return t("schedule_gantt_tobestarted");
              return item.planned + "%";
            }
            return t("schedule_gantt_completed");
          },
        },
        {
          name: "indicator",
          label: "Indica.",
          min_width: 110,
          align: "center",
          hide: showColumns.activeIndi,
          template: function (item) {
            console.log(item);
    
            if (item.type != "project") {
              const iconCritical =
                "<i class='fa fa-exclamation-circle text-danger'></i>";
              const iconCriticalExt =
                "<i class='fa fa-exclamation-circle text-dark'></i>";
              const iconModerate =
                "<i class='fa fa-exclamation-circle text-warning'></i>";
              const iconOntime = "<i class='fa fa-check-circle text-success'></i>";
              const selectState = (indicator) => {
                if (indicator == 1) {
                  return iconOntime;
                } else if (indicator == 2) {
                  return iconModerate;
                } else if (indicator == 3) {
                  return iconCritical;
                } else {
                  return iconCriticalExt;
                }
              };
              const state = selectState(item.indicator);
              const valueOfIndi = byId(
                gantt.serverList("indicator"),
                item.indicator
              );
              const textOfIndi = `<div class="text-center w-100">${state}<b class="ml-1">${valueOfIndi}</b></div>`;
              return textOfIndi;
            } else {
              return;
            }
          },
        },
      ];

      gantt.config.columns = rol == "Miembro de Equipo" ? confiColumnsME : confiColumnsSTD;  
};

const setTask = (data,gantt) => {

  const userid = window.localStorage.getItem("UID");
  const dataUniqueResource = data?.map((items)=> {
    return {
      ...items,
      resources: _.uniqBy(items.resources,"IdRecurso"), 
    }
  })
  
  const dataByRol = (data) => {
    const rol = window.localStorage.getItem("rol");
    if (rol == "Miembro de Equipo") {
      const dataByRolMember = data?.filter((task) => {
        if (task.tipo != "project") {
          return task?.resources
            .map((recur) => recur.IdRecurso)
            .includes(userid);
        } else {
          return task;
        }
      });
    /*   return dataByRolMember; */
      return data
    } else {
      return data;
    }
  };
  let datafilter = dataByRol(dataUniqueResource);
  let task = [];
  for (let row of datafilter) {
    let res = [];
    if (row.resources?.length > 0) {
      for (let itm of row.resources) {
        res.push({
          resource_id: itm.IdRecurso,
          value: itm.HorasAsignadas,
          asignacion_id: itm.IdAsignacion,
        });
      }
    }
    // criterios indicadores 
    function getIndicator (plan, real) {
      const realPor = real * 100
      const desvi = plan - realPor
      if(desvi < 2 ){
        return 1
      }else if(desvi >= 2 && desvi < 5 ){
        return 2
      }else if(desvi >=5 && desvi < 10 ){
        return 3
      }
      else {
        return 4
      }
    }

    const readOnlyByRol = (data) => {
      if(data.tipo != "project"){
          const readByMember = data.recursos?.includes(userid)
          return !readByMember
      }else {
        return false
      }
    };

    const rolForRead = window.localStorage.getItem("rol");

    task.push({
      id: row.id,
      text: row.title,
      start_date: row.start,
      end_date: row.end,
      progress: row.progress,
      custom_duration : row.Duracion,

      start_baseline_date : (row.fechaInicioLB_1 != null)? new Date(row.fechaInicioLB_1) : null ,
      end_baseline_date: (row.fechaFinLB_1 != null)? new Date(row.fechaFinLB_1) : null ,
      modification_date_baseline : row.fechaModificacion_1 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_1) ) : null,
      baseline_number_1 : row.numeroLB_1,
      durationLB_1 : row.duracionLB_1,
      costoLB1 : row.costoLB_1,
      creation_dateLB1: row.fechaCreacionLB_1 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_1) ) : null ,

      start_baseline_date_2 : (row.fechaInicioLB_2 != null)? new Date(row.fechaInicioLB_2) : null ,
      end_baseline_date_2: (row.fechaFinLB_2 != null)? new Date(row.fechaFinLB_2) : null ,
      modification_date_baseline_2 : row.fechaModificacion_2 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_2) ) : null,
      baseline_number_2 : row.numeroLB_2,
      durationLB_2 : row.duracionLB_2,
      costoLB2 : row.costoLB_2,
      creation_dateLB2: row.fechaCreacionLB_2 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_2) ) : null,

      start_baseline_date_3 : (row.fechaInicioLB_3 != null)? new Date(row.fechaInicioLB_3) : null ,
      end_baseline_date_3: (row.fechaFinLB_3 != null)? new Date(row.fechaFinLB_3) : null ,
      modification_date_baseline_3 :row.fechaModificacion_3 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_3) ) : null ,
      baseline_number_3 : row.numeroLB_3,
      durationLB_3 : row.duracionLB_3,
      costoLB3 : row.costoLB_3,
      creation_dateLB3: row.fechaCreacionLB_3 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_3) ) : null,

      start_baseline_date_4 : (row.fechaInicioLB_4 != null)? new Date(row.fechaInicioLB_4) : null ,
      end_baseline_date_4: (row.fechaFinLB_4 != null)? new Date(row.fechaFinLB_4) : null ,
      modification_date_baseline_4 : row.fechaModificacion_4 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_4) ) : null ,
      baseline_number_4 : row.numeroLB_4,
      durationLB_4 : row.duracionLB_4,
      costoLB4 : row.costoLB_4,
      creation_dateLB4: row.fechaCreacionLB_4 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_5) ) : null,

      start_baseline_date_5 : (row.fechaInicioLB_5 != null)? new Date(row.fechaInicioLB_5) : null,
      end_baseline_date_5: (row.fechaFinLB_5 != null)? new Date(row.fechaFinLB_5) : null ,
      modification_date_baseline_5 : row.fechaModificacion_5 != null ?  new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaModificacion_5) ) : null ,
      baseline_number_5 : row.numeroLB_5,
      durationLB_5 : row.duracionLB_5,
      costoLB5 : row.costoLB_5,
      creation_dateLB5: row.fechaCreacionLB_5 != null ? new Intl.DateTimeFormat("es-CL").format( new Date(row.fechaCreacionLB_5) ) : null ,

      parent: row.parentId,
      plancost: row.cost,
      realcost: row.actualCost,
      type: row.tipo,
      readonly: (rolForRead == "Miembro de Equipo")? readOnlyByRol(row): false,
      open: true,
      owner_id: res,
      indicator: getIndicator(row.plannedProgress, row.progress),
      planned : row.plannedProgress
    });
  }
  let alltask = { data: task };
  gantt.parse(alltask);
/*   setTaskForBaseLine(task) */
  gantt.render();
};

const setCalender = (data,gantt) => {
  let excluded = [];
  let formatDate = gantt.date.str_to_date("%Y/%m/%d");
  for (let i = 0; i < data.length; i++) {
    let datex = formatDate(data[i]);
    excluded.push(datex);
    gantt.setWorkTime({
      date: datex,
      hours: false,
    });
  }
  gantt.serverList("excluded", excluded);
  gantt.render();
};

const setResour = (data,gantt) => {
  let resoList = data?.map((resource) => {
    return { key: resource.id, label: resource.text };
  });
  gantt.serverList("resourceList", resoList);
  gantt.render()
  /* setLightBoxSections();
  sectionForMilestonesProjects(); */
  /* sectionForProjects() */
  /* sectionForMilestonesProjects()
    sectionForProjects() */
};

export const getGanttData = async (uid, gantt) => {
  try {
    // Hacer las solicitudes en paralelo con Promise.all para rendimiento
    const [tasksResponse, dependenciesResponse, assignmentsResponse, excludedDatesResponse, resourcesResponse] = await Promise.all([
      axios.get(`${API_ROUTE}v2/schedule-tasks/${uid}`, headersRequest()),
      axios.get(`${API_ROUTE}schedule-tasks-dependencies/${uid}`, headersRequest()),
      axios.get(`${API_ROUTE}schedule-tasks-resources-assignments/${uid}`, headersRequest()),
      axios.get(`${API_ROUTE}excluded-dates`, headersRequest()),
      axios.get(`${API_ROUTE}company-resources`, headersRequest())
    ]);

    // Procesar tareas
    const processedTasks = tasksResponse.data.tasks.tasks.map(task => {
      // Formateo de fechas con date-fns
      /* const startFormatted = subHours(new Date(task.start), 5).toISOString();
      const endFormatted = subHours(new Date(task.end), 5).toISOString(); */
      return {
        ...task,
        start: new Date(task.start),
        end: new Date(task.end),
        parentId: task.parentId || 0,
        cost: task.cost || 0,
        resources: tasksResponse.data.tasks.hours.filter(resourhours => resourhours.IdActividad === task.id),
      };
    });

    // Actualizar estados en React
    /* setAllAct(processedTasks);
    setTask(processedTasks); */
    setTask(processedTasks, gantt)
    setCalender(excludedDatesResponse.data , gantt)
    setResour(resourcesResponse.data ,gantt)

    gantt.serverList("asigHours", tasksResponse.data.tasks.hours);
    /* setDependen(tasksResponse.data.links);
    setCalender(excludedDatesResponse.data);
    setResour(resourcesResponse.data); */

    // Manejo de variables globales (referencias)
    /* excludedDates.current = excludedDatesResponse.data;
    companyResources.current = resourcesResponse.data; */

   /*  console.log("Datos cargados exitosamente"); */

  } catch (error) {
    console.error("Error al cargar los datos:", error);
  }
};

export const zoomConfig = (gantt) => {
   const confiZoom = { levels : [
    {
      name: "day",
      scale_height: 27,
      min_column_width: 80,
      scales: [{ unit: "day", step: 1, format: "%d" }],
    },
    {
      name: "week",
      scale_height: 50,
      min_column_width: 50,
      scales: [
        {
          unit: "week",
          step: 1,
          format: function (date) {
            var dateToStr = gantt.date.date_to_str("%d %M");
            var endDate = gantt.date.add(date, -6, "day");
            var weekNum = gantt.date.date_to_str("%W")(date);
            return (
              "#" +
              weekNum +
              ", " +
              dateToStr(date) +
              " - " +
              dateToStr(endDate)
            );
          },
        },
        { unit: "day", step: 1, format: "%j" },
      ],
    },
    {
      name: "month",
      scale_height: 50,
      min_column_width: 120,
      scales: [
        { unit: "month", format: "%F, %Y" },
        { unit: "week", format: "Week #%W" },
      ],
    },
    {
      name: "quarter",
      height: 50,
      min_column_width: 90,
      scales: [
        { unit: "month", step: 1, format: "%M" },
        {
          unit: "quarter",
          step: 1,
          format: function (date) {
            var dateToStr = gantt.date.date_to_str("%M");
            var endDate = gantt.date.add(
              gantt.date.add(date, 3, "month"),
              -1,
              "day"
            );
            return dateToStr(date) + " - " + dateToStr(endDate);
          },
        },
      ],
    },
    {
      name: "year",
      scale_height: 50,
      min_column_width: 30,
      scales: [{ unit: "year", step: 1, format: "%Y" }],
    },
  ]}
  return gantt.ext.zoom.init(confiZoom);
};


